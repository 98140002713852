import { Descriptions, Divider } from "antd";
import React, { useEffect, useState } from "react";
import * as WEBSERVICES from '../../config/WebServices'


const largeDescriptionItemLabelStyle = {
  flex: 5,
  fontSize: 12,
  fontWeight: 700,
  color: '#171441',
  paddingLeft: 20,
}
const largeDescriptionItemContentStyle = {
  flex: 5,
  fontSize: 12,
  fontWeight: 600,
  color: '#393874',
}
const LandStatus = (props: any) => {
  
  const [landPointInfo, setLandPointInfo] = useState({} as any)
  const [landParcelInfo, setLandParcelInfo] = useState({} as any)

  useEffect(() => {
    const landStatusInfo = async () => {

      let url = WEBSERVICES.URL_A_P_LAND_STATUS + props.id + '/' + props.active;

      await fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson && responseJson.status === true) {
            setLandPointInfo(responseJson.data.parcel)
            setLandParcelInfo(responseJson.data.point)
          }
        })
    }
    landStatusInfo()
  }, []);
  

  return (
    <>
      {Array.isArray(landPointInfo) ? (
        landPointInfo.map((item: any, index: any) => (
          <Descriptions column={1} className="auction-table" key={index}>
            <Descriptions.Item
              key={index}
              label={item?.short_name}
              labelStyle={largeDescriptionItemLabelStyle}
              contentStyle={largeDescriptionItemContentStyle}
              style={{
                paddingBottom: 10,
              }}
            >
              {item?.value}
            </Descriptions.Item>
          </Descriptions>
        ))
      ) : (
        <p>Мэдээлэл байхгүй байна</p> // This will render if landStatusInfo is not an array
      )}
      {Array.isArray(landParcelInfo) ? (
        landParcelInfo.map((item: any, index: any) => (
          <Descriptions column={1} className="auction-table" key={index}>
            <Descriptions.Item
              key={index}
              label={item?.short_name}
              labelStyle={largeDescriptionItemLabelStyle}
              contentStyle={largeDescriptionItemContentStyle}
              style={{
                paddingBottom: 10,
              }}
            >
              {item?.value}
            </Descriptions.Item>
          </Descriptions>
        ))
      ) : (
        <p>Мэдээлэл байхгүй байна</p> // This will render if landStatusInfo is not an array
      )}
    </>
  );
}

export default LandStatus;