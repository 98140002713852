import React from 'react'
import { Layout, Row, Col, Typography, Flex, Image } from 'antd'

import FooterLayout from './../../layouts/Footer'

const { Content } = Layout
const { Text, Title, Paragraph } = Typography

const AboutUs: React.FC = () => {
  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        <Row gutter={20}>
          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Title
                  level={4}
                  style={{
                    color: '#393874',
                    width: '100%',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    marginBottom: 20,
                  }}
                >
                  Бидний тухай
                </Title>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Image
                  src="/images/about-1.jpg"
                  alt=""
                  preview={false}
                  style={{ minWidth: '100%', objectFit: 'cover' }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Flex justify="center" vertical style={{ height: '100%' }}>
                  <Title
                    level={1}
                    style={{ fontSize: 36, color: '#C9C9C9', margin: 0 }}
                  >
                    БИД
                  </Title>
                  <Text style={{ fontSize: 18, color: '#393874' }}>
                    Дижитал эринд бүтээлч нээлттэй нийгмийг бүтээнэ
                  </Text>
                  <div
                    style={{
                      background: '#0654DE',
                      borderRadius: 5,
                      width: 25,
                      height: 4,
                      marginTop: 2,
                      marginBottom: 10,
                    }}
                  />
                  <Paragraph style={{ color: '#393874', maxWidth: 600 }}>
                    Газрын цахим биржийн зорилго нь Газрын дуудлага худалдаа,
                    төсөл сонгон шалгаруулалт, худалдаа, түрээс, барьцаа, зах
                    зээлийн үнийн мэдээ цуглуулах үйл ажиллагааг цахим хэлбэрээр
                    олон нийтэд нээлттэй, ил тод, түргэн шуурхай явуулахад
                    оршино.
                  </Paragraph>
                </Flex>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Flex
                  justify="center"
                  align="flex-end"
                  vertical
                  style={{ height: '100%' }}
                >
                  <Title
                    level={1}
                    style={{ fontSize: 36, color: '#C9C9C9', margin: 0 }}
                  >
                    ЗОРИЛГО ЗОРИЛТ
                  </Title>
                  <Text style={{ fontSize: 18, color: '#393874' }}>
                    Эрхэмлэн дээдлэх зарчим
                  </Text>
                  <div
                    style={{
                      background: '#0654DE',
                      borderRadius: 5,
                      width: 25,
                      height: 4,
                      marginTop: 2,
                      marginBottom: 10,
                    }}
                  />
                  <Paragraph
                    style={{
                      color: '#393874',
                      maxWidth: 600,
                      textAlign: 'right',
                    }}
                  >
                    Газрын цахим биржийн зорилго нь Газрын дуудлага худалдаа,
                    төсөл сонгон шалгаруулалт, худалдаа, түрээс, барьцаа, зах
                    зээлийн үнийн мэдээ цуглуулах үйл ажиллагааг цахим хэлбэрээр
                    олон нийтэд нээлттэй, ил тод, түргэн шуурхай явуулахад
                    оршино.
                  </Paragraph>
                </Flex>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Image
                  src="/images/about-2.jpg"
                  alt=""
                  preview={false}
                  style={{ minWidth: '100%', objectFit: 'cover' }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Image
                  src="/images/about-3.jpg"
                  alt=""
                  preview={false}
                  style={{ minWidth: '100%', objectFit: 'cover' }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Flex justify="center" vertical style={{ height: '100%' }}>
                  <Title
                    level={1}
                    style={{ fontSize: 36, color: '#C9C9C9', margin: 0 }}
                  >
                    ГАЗРЫН БИРЖИЙН ТОДОРХОЙЛОЛТ
                  </Title>
                  <Text style={{ fontSize: 18, color: '#393874' }}>
                    Дижитал эринд бүтээлч нээлттэй нийгмийг бүтээнэ
                  </Text>
                  <div
                    style={{
                      background: '#0654DE',
                      borderRadius: 5,
                      width: 25,
                      height: 4,
                      marginTop: 2,
                      marginBottom: 10,
                    }}
                  />
                  <Paragraph style={{ color: '#393874', maxWidth: 600 }}>
                    “Газрын бирж” гэж төрийн өмчийн газрыг өмчлүүлэх,
                    эзэмшүүлэх, ашиглуулах болон иргэн, хуулийн этгээдийн газар
                    өмчлөх, эзэмших эрхийг бусдад шилжүүлэх, ашиглуулахад зохих
                    журмын дагуу дуудлага худалдаа, төсөл сонгон шалгаруулалт
                    зохион байгуулах, зах зээлийн үнэ ханш бусад мэдээлэл,
                    лавлагаа эрхлэх тусгай дүрэм, програм хангамж бүхий цогц үйл
                    ажиллагааг хэлнэ.
                  </Paragraph>
                </Flex>
              </Col>
              <Col
                xs={{ offset: 0, span: 24 }}
                sm={{ offset: 0, span: 24 }}
                md={{ offset: 0, span: 24 }}
                lg={{ offset: 3, span: 18 }}
                xl={{ offset: 4, span: 16 }}
                xxl={{ offset: 4, span: 16 }}
              >
                <div style={{ margin: '60px 0' }}>
                  <Image
                    src="/images/about-4.png"
                    alt=""
                    preview={false}
                    style={{ maxWidth: 920, textAlign: 'center' }}
                  />
                </div>
                <div style={{ marginBottom: 60 }}>
                  <Text
                    style={{
                      fontSize: 20,
                      color: '#393874',
                      fontWeight: 700,
                      marginBottom: 20,
                      display: 'block',
                    }}
                  >
                    Нийтлэг чиг үүрэг:
                  </Text>
                  <Paragraph style={{ color: '#393874', fontWeight: 700 }}>
                    1. Улсын хэмжээнд салбарт мөрдөж буй эрх зүйн бичиг баримтын
                    хэрэгжилтэд хяналт тавих;
                  </Paragraph>
                  <Paragraph style={{ color: '#393874', fontWeight: 700 }}>
                    2. Газар зохион байгуулалт, геодези, зураг зүй, хот
                    байгуулалтын салбарын үйл ажиллагааг хүчин төгөлдөр
                    мөрдөгдөж буй эрх зүйн бичиг баримтыг үндэслэн удирдан
                    зохион байгуулж, хэрэглэгчийг мэдээлэл, мэргэжил арга
                    зүйгээр хангах;
                  </Paragraph>
                  <Paragraph style={{ color: '#393874', fontWeight: 700 }}>
                    3. Агентлагийн эрхлэх асуудлын хүрээний ажлуудыг гүйцэтгэхэд
                    үр шим хүртэгсдийн хамтын ажиллагааг хангах, мэргэжлийн
                    болон бусад байгууллагуудын хамтын ажиллагаа, салбарын
                    чадавхийг сайжруулах;
                  </Paragraph>
                  <Paragraph style={{ color: '#393874', fontWeight: 700 }}>
                    4. Улсын Их Хурал, Засгийн газар, газрын асуудал эрхэлсэн
                    төрийн захиргааны төв байгууллагаас өгсөн үүрэг даалгаврыг
                    биелүүлэх, чиглэлийг мөрдөж ажиллах, үнэн зөв мэдээллээр
                    тухай бүр хангах;
                  </Paragraph>
                  <Paragraph style={{ color: '#393874', fontWeight: 700 }}>
                    5. Агентлагийн цахим хуудаст төлөвлөж буй болон гүйцэтгэсэн
                    ажлуудынхаа мэдээллийг цаг хугацаанд нь үнэн зөв оруулах.
                  </Paragraph>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Flex
                  justify="center"
                  align="flex-end"
                  vertical
                  style={{ height: '100%' }}
                >
                  <Title
                    level={1}
                    style={{ fontSize: 36, color: '#C9C9C9', margin: 0 }}
                  >
                    АЛБАН ХААГЧДЫН ЧИГ ҮҮРЭГ
                  </Title>
                  <Text style={{ fontSize: 18, color: '#393874' }}>
                    Эрхэмлэн дээдлэх зарчим
                  </Text>
                  <div
                    style={{
                      background: '#0654DE',
                      borderRadius: 5,
                      width: 25,
                      height: 4,
                      marginTop: 2,
                      marginBottom: 10,
                    }}
                  />
                  <Paragraph
                    style={{
                      color: '#393874',
                      maxWidth: 600,
                      textAlign: 'right',
                    }}
                  >
                    <strong>
                      1. Газрын биржийн талаарх хууль тогтоомжийг боловсронгуй
                      болгох ажлын хүрээнд:
                    </strong>
                    <br />- Газрын биржийн талаарх дүрэм, журам боловсруулах,
                    баталгаажуулах <br />- Биржийн зөвлөлийг байгуулах, үйл
                    ажиллагааны журмыг тогтоох <br />- Биржийн үйл ажиллагааны
                    хураамжийн хувь хэмжээг тогтоох, батлуулах <br />- Цахим
                    биржийн програм хангамжийг ажиллуулах газрын авлага
                    боловсруулах
                  </Paragraph>
                </Flex>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Image
                  src="/images/about-5.jpg"
                  alt=""
                  preview={false}
                  style={{ minWidth: '100%', objectFit: 'cover' }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Image
                  src="/images/about-5.jpg"
                  alt=""
                  preview={false}
                  style={{ minWidth: '100%', objectFit: 'cover' }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Flex justify="center" vertical style={{ height: '100%' }}>
                  <Paragraph style={{ color: '#393874', maxWidth: 600 }}>
                    <strong>
                      2. Мэргэжлийн удирдлага, заавар зөвлөгөөгөөр хангах ажлын
                      хүрээнд:
                    </strong>
                    <br />- Газрын биржийн талаарх хууль тогтоомж, журам,
                    дүрмийн хэрэгжилтийг хангах ажлыг орон нутагт зохион
                    байгуулах <br />- Орон нутгийн газрын биржийн асуудал
                    хариуцсан мэргэжилтнүүд, мэргэжлийн удирдлага, заавар арга
                    зүйгээр хангах <br />- Цахим програм хангамжид нэвтрэх
                    эрхийг газрын мэргэжилтнүүд /аймаг, нийслэл, сум, дүүрэг/-д
                    нээх, програм хангамжид ажиллах заавар удирдамжаар хангах{' '}
                    <br />- Биржийн үйл ажиллагаа болон тайланг мэдээлэх,
                    сурталчлах
                  </Paragraph>
                </Flex>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Flex
                  justify="center"
                  align="flex-end"
                  vertical
                  style={{ height: '100%' }}
                >
                  <Paragraph
                    style={{
                      color: '#393874',
                      maxWidth: 600,
                      textAlign: 'right',
                    }}
                  >
                    <strong>
                      3. Цахим биржийн үйл ажиллагааг эрхлэх, хяналт тавих ажлын
                      хүрээнд:
                    </strong>
                    <br />- Газрын биржийн цахим програм хангамжийг админ
                    эрхтэйгээр ажиллуулах, өдөр тутмын хэвийн үйл ажиллагааг
                    хангах <br />- Орон нутагт явагдаж буй дуудлага худалдаа,
                    төсөл сонгон шалгаруулалтын явцад хяналт тавих <br />- Зах
                    зээлийн үнийн мэдээний өгөгдлийн сан байгуулах, үнийн
                    мэдээгээр үйлчлэх - Газар эзэмших эрхийн барьцааны
                    бүртгэлийн байдалд хяналт тавих, тайлан мэдээг гаргах <br />
                    - Газар эзэмших эрхийн барьцааны лавлагааг эрхлэх <br />-
                    Биржийн орлогыг төвлөрүүлэх, хяналт тавих <br />- Цахим
                    програм хангамжаар дамжуулан хийгдэж байгаа газрын арилжаанд
                    хяналт тавих
                  </Paragraph>
                </Flex>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Image
                  src="/images/about-5.jpg"
                  alt=""
                  preview={false}
                  style={{ minWidth: '100%', objectFit: 'cover' }}
                />
              </Col>
              <div style={{ marginTop: 100 }}>
                <FooterLayout isFull />
              </div>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default AboutUs
