import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Breadcrumb,
  Layout,
  Row,
  Col,
  Flex,
  Typography,
  Card,
  Space,
  Button,
  Divider,
  List,
  Form,
  Input,
} from 'antd'
import type { SearchProps } from 'antd/es/input/Search'
import {
  ArrowRightOutlined,
  SearchOutlined,
  MailOutlined,
} from '@ant-design/icons'
import PriceQuoteItem from './../../../components/PriceQuoteItem'
import { AuctionPriceQuoteData } from './../../../constants/dummy.constants'
import FooterLayout from './../../../layouts/Footer'

const { Content } = Layout
const { Text, Title } = Typography
const { Meta } = Card
const { Search } = Input

const onSearch: SearchProps['onSearch'] = (value, _e, info) =>
  console.log(info?.source, value)

const PriceQuotes: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id: detail_id } = useParams()

  const [contactForm] = Form.useForm()

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        <Row gutter={20}>
          {/* <Col xs={24} sm={24} md={16} lg={16} xl={18} xxl={19}> */}
          <Col span={24}>
            <Flex
              style={{ marginTop: 40, marginBottom: 20 }}
              justify="space-between"
              align="center"
              wrap="wrap"
            >
              <Meta
                style={{ marginBottom: 20 }}
                title={
                  <Title
                    level={4}
                    style={{
                      color: '#393874',
                      marginBottom: 5,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    #2023-15415 - Завхан Улиастай 1-р баг
                  </Title>
                }
                description={
                  <Breadcrumb
                    separator={<ArrowRightOutlined />}
                    items={[
                      {
                        title: 'Эхлэл',
                        href: '/',
                      },
                      {
                        title: 'Дуудлага худалдаа',
                        href: '/auction',
                      },
                      {
                        title: 'Дуудлага худалдаа дэлгэрэнгүй',
                        href: '/auction/12345',
                      },
                      {
                        title: 'Үнийн санал харах',
                        className: 'breadcrumb-active',
                      },
                    ]}
                  />
                }
              />
              <Space size="middle" wrap>
                <Button type="primary">Дуудлага худалдаанд оролцох</Button>
                <Button style={{ borderColor: '#F67E0E', color: '#F67E0E' }}>
                  Үнийн санал харах
                </Button>
                <Button style={{ borderColor: '#FF1D1D', color: '#FF1D1D' }}>
                  Замчлах
                </Button>
              </Space>
            </Flex>
            <List
              grid={{
                gutter: 20,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 2,
                xl: 2,
                xxl: 2,
              }}
              dataSource={AuctionPriceQuoteData}
              pagination={{ position: 'bottom', align: 'start' }}
              renderItem={(item) => (
                <List.Item>
                  <PriceQuoteItem item={item} />
                </List.Item>
              )}
              style={{ marginBottom: 80 }}
            />
            {/* <div className="hide-on-md-or-less"> */}
            <FooterLayout isFull />
            {/* </div> */}
          </Col>
          {/* <Col xs={24} sm={24} md={8} lg={8} xl={6} xxl={5}>
            <Card style={{ marginBottom: 20, marginTop: 115 }}>
              <SearchOutlined
                style={{ fontSize: 16, color: '#393874', fontWeight: 700 }}
              />
              <Text
                style={{
                  marginLeft: 8,
                  color: '#171441',
                  fontWeight: 600,
                }}
              >
                Хайх
              </Text>

              <Search
                style={{ marginTop: 10, marginBottom: 20 }}
                styles={{
                  input: {
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20,
                    minHeight: 33,
                  },
                }}
                placeholder="ID #2023-00005"
                onSearch={onSearch}
                enterButton={
                  <Button
                    style={{
                      borderTopRightRadius: 20,
                      borderBottomRightRadius: 20,
                      minHeight: 33,
                      backgroundColor: '#393874',
                    }}
                  >
                    <SearchOutlined
                      style={{ fontSize: 16, color: '#fff', fontWeight: 700 }}
                    />
                  </Button>
                }
              />
            </Card>
            <div className="show-on-md-or-less">
              <FooterLayout />
            </div>
          </Col> */}
        </Row>
      </Content>
    </Layout>
  )
}

export default PriceQuotes
