import React, { useEffect, useState } from 'react'
import {
  Layout,
  Row,
  Col,
  Divider,
  Card,
  Button,
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  ConfigProvider,
  Flex,
  Typography,
  List,
  Pagination,
  Spin,
  Empty,
} from 'antd'
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'

import FooterLayout from './../../layouts/Footer'
import MCard from './../../components/Card'

import * as WEBSERVICES from '../../config/WebServices'

import mnMN from 'antd/lib/locale/mn_MN'
import dayjs from 'dayjs'
import 'dayjs/locale/mn'
import SmartHelper from 'helper/SmartHelper'
import { useLocation, useNavigate } from 'react-router-dom'

dayjs.locale('mn')

const { Option } = Select
const { Content } = Layout
const { RangePicker } = DatePicker
const { Title, Paragraph } = Typography
const { Meta } = Card

const Auction: React.FC = () => {
  const [form] = Form.useForm()

  const location = useLocation()
  const navigate = useNavigate()

  const [loaded, setLoaded] = useState(false)
  const [auctions, setAuctions] = useState([] as any)
  const [sources, setSources] = useState([] as any)
  const [landUses, setLandUses] = useState([] as any)
  const [statuses, setStatuses] = useState([] as any)
  const [isAttendButton, setIsAttendButton] = useState(false)

  const [selectedAuctionOnline, setSelectedAuctionOnline] = useState(null as any)
  const [selectedSources, setSelectedSources] = useState(null as any)
  const [winnerAuction, setWinnerAuction] = useState(false)
  const [selectedLandUses, setSelectedLandUses] = useState(null as any)
  const [selectedSearchKeyword, setSelectedSearchKeyword] = useState(null as any)
  const [selectedStatusId, setSelectedStatusId] = useState(location?.state?.statusId)
  const [selectedToday, setSelectedToday] = useState(location?.state?.today)
  const [selectedFindBy, setSelectedFindBy] = useState(location?.state?.findBy)

  const [selectedStartDate, setSelectedStartDate] = useState('' as string)
  const [selectedEndDate, setSelectedEndDate] = useState('' as string)

  const [shouldSearchAuction, setShouldSearchAuction] = useState(true)
  const [shouldCallFirstTime, setShouldCallFirstTime] = useState(true)

  useEffect(() => {
    async function fetchData() {
      await fetchAuctionFilter()

      let entity = SmartHelper.getEntity();
      if(entity != null) {
        setIsAttendButton(true);
      }
    }

    if (shouldSearchAuction) {
      searchAuction(1)
      setShouldSearchAuction(false) // Reset shouldSearchAuction
    }

    if (shouldCallFirstTime) {
      fetchData().then(() => {
        setLoaded(true)
        setShouldCallFirstTime(false)
      })
    }
  }, [shouldSearchAuction])

  const onFinish = (values: any) => {
  }

  const navigateToMap = () => {
    navigate('/map?type=auction')
    navigate(0);
  }

  const navigateToAttend = () => {
    navigate('/auction/attend')
    navigate(0);
  }

  const navigateToToday = () => {
    navigate('/auction/today')
    navigate(0);
  }

  const onReset = async () => {
    form.resetFields()
    setSelectedAuctionOnline(null)
    setSelectedSources(null)
    setSelectedLandUses(null)
    setSelectedSearchKeyword(null)
    setSelectedStatusId(null)
    setSelectedStartDate('')
    setSelectedEndDate('')
    setSelectedToday(null)
    setSelectedFindBy(null)

    setShouldSearchAuction(true)
  }

  const onChangePagination = async (page: any) => {
    await searchAuction(page)
  }

  const onChangeRangePicker = (dates: any, dateStrings: [string, string]) => {
    setSelectedStartDate(dateStrings[0])
    setSelectedEndDate(dateStrings[1])
    setSelectedToday(null)
  }

  const searchAuction = (page: any) => {
    let search = ''
    search += selectedAuctionOnline && typeof selectedAuctionOnline !== undefined? '&is_online=' + selectedAuctionOnline: ''
    search += selectedSources && typeof selectedSources !== undefined? '&source=' + selectedSources: ''
    search += selectedLandUses && typeof selectedLandUses !== undefined? '&landuse=' + selectedLandUses: ''
    search += selectedSearchKeyword && typeof selectedSearchKeyword !== undefined? '&keyword=' + selectedSearchKeyword: ''
    search += selectedStatusId && typeof selectedStatusId !== undefined? '&status=' + selectedStatusId: ''
    search += selectedStartDate && typeof selectedStartDate !== undefined && selectedStartDate !== ''? '&start_date=' + selectedStartDate: ''
    search += selectedEndDate && typeof selectedEndDate !== undefined && selectedEndDate !== ''? '&end_date=' + selectedEndDate: ''
    search += selectedToday && typeof selectedToday !== undefined && selectedToday !== ''? '&today=1': ''
    search += selectedFindBy && typeof selectedFindBy !== undefined && selectedFindBy !== '' ? '&entity_id=' + selectedFindBy : ''

    if(page?.winner === true){
      search += '&winner=true'
      setWinnerAuction(true)
    }

    fetchAuctionsList(page, search)
  }

  const searchByStatus = (statusId: any) => {
    setSelectedStatusId(statusId)
    setShouldSearchAuction(true)
  }

  const searchByToday = () => {
    setSelectedToday(1)
    setShouldSearchAuction(true)
  }

  const fetchAuctionsList = async (page: any, search: any = '') => {
    setLoaded(false)
    let url =
      WEBSERVICES.URL_AUCTION_PAGINATION_LIST +
      '?au1=' +
      SmartHelper.getSession('au1') +
      '&au2=' +
      SmartHelper.getSession('au2') +
      '&au3=' +
      SmartHelper.getSession('au3') +
      '&page=' +
      page +
      search

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setAuctions(responseJson.data)
        } else {
          setAuctions([])
        }
      })
      .catch((error) => {
        setAuctions([])
      })
      .finally(() => {
        setLoaded(true)
      })
  }

  const fetchAuctionFilter = async () => {
    let url = WEBSERVICES.URL_AUCTION_FILTER +
    '?au1=' +
    SmartHelper.getSession('au1') +
    '&au2=' +
    SmartHelper.getSession('au2') +
    '&au3=' +
    SmartHelper.getSession('au3')

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setSources(responseJson.sources)
          setLandUses(responseJson.landUses)
          setStatuses(responseJson.statuses)
        } else {
          setSources([])
          setLandUses([])
          setStatuses([])
        }
      })
      .catch((error) => {
        setSources([])
        setLandUses([])
        setStatuses([])
      })
  }

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        {!loaded && <Spin size="large" fullscreen />}
        <Row gutter={20}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Card style={{ boxShadow: '0px 4px 19px #D8DEE9' }}>
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ marginTop: 0 }}
              >
                Дэлгэрэнгүй хайлт
              </Divider>
              <Form
                layout="inline"
                form={form}
                onFinish={onFinish}
                className="auction-search-form"
                style={{ maxWidth: 'none' }}
                initialValues={{
                  is_auction_online: null,
                  auction_source: null,
                  land_use: null,
                  search_keyword: null,
                }}
              >
                <Form.Item name="is_auction_online" style={{ width: 150 }}>
                  <Select
                    placeholder="ДХ явагдах хэлбэр"
                    allowClear
                    value={selectedAuctionOnline}
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '24px',
                      borderStyle: 'none',
                      boxShadow: '#0433dd33 0px 3px 5px -1px, #0433dd24 0px 6px 10px 0px, #0433dd1f 0px 1px 18px 0px',
                      boxSizing: 'border-box',
                      fontFamily: 'inherit',
                      lineHeight: 'normal',
                      transition: 'background 280ms ease, box-shadow 280ms ease',
                    }}
                    onChange={(value) => {
                      setSelectedAuctionOnline(value)
                    }}
                  >
                    <Option value="1">Цахимаар</Option>
                    <Option value="0">Танхимаар</Option>
                  </Select>
                </Form.Item>
                <Form.Item name="auction_source" style={{ width: 'auto', minWidth: 165 }}>
                  <Select
                  placeholder="Хаанаас зарласан"
                  allowClear
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: '24px',
                    borderStyle: 'none',
                    boxShadow: '#0433dd33 0px 3px 5px -1px, #0433dd24 0px 6px 10px 0px, #0433dd1f 0px 1px 18px 0px',
                    boxSizing: 'border-box',
                    fontFamily: 'inherit',
                    lineHeight: 'normal',
                    transition: 'background 280ms ease, box-shadow 280ms ease',
                  }}
                  dropdownStyle={{ whiteSpace: 'normal', maxWidth: 300 }}
                  onChange={(value) => {
                    setSelectedSources(value)
                  }}
                  popupMatchSelectWidth={false}
                  >
                  {sources.map((item: any, index: any) => {
                    return (
                    <Option key={index} value={item.id}>
                      <span style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                      {item.name}
                      </span>
                    </Option>
                    )
                  })}
                  </Select>
                </Form.Item>
                <Form.Item name="land_use" style={{ width: 'auto', minWidth: 165 }}>
                  <Select
                  placeholder="Газрын зориулалт"
                  allowClear
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: '24px',
                    borderStyle: 'none',
                    boxShadow: '#0433dd33 0px 3px 5px -1px, #0433dd24 0px 6px 10px 0px, #0433dd1f 0px 1px 18px 0px',
                    boxSizing: 'border-box',
                    fontFamily: 'inherit',
                    lineHeight: 'normal',
                    transition: 'background 280ms ease, box-shadow 280ms ease',
                  }}
                  dropdownStyle={{ whiteSpace: 'normal', maxWidth: 300 }}
                  onChange={(value) => {
                    setSelectedLandUses(value)
                  }}
                  popupMatchSelectWidth={false}
                  >
                  {landUses.map((item: any, index: any) => {
                    return (
                    <Option key={index} value={item.code}>
                      <span style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                      {item.description}
                      </span>
                    </Option>
                    )
                  })}
                  </Select>
                </Form.Item>
                <Form.Item name="search_keyword" style={{ width: 170 }}>
                  <Input
                    placeholder="Хайх утга"
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '24px',
                      borderStyle: 'none',
                      boxShadow: '#0433dd33 0px 3px 5px -1px, #0433dd24 0px 6px 10px 0px, #0433dd1f 0px 1px 18px 0px',
                      boxSizing: 'border-box',
                      fontFamily: 'inherit',
                      lineHeight: 'normal',
                      transition: 'background 280ms ease, box-shadow 280ms ease',
                      height: 32,
                    }}
                    onChange={(e) => {
                      setSelectedSearchKeyword(e.target.value)
                    }}
                  ></Input>
                </Form.Item>
                <Form.Item name="search_date" style={{ width: 200 }}>
                  <ConfigProvider locale={mnMN}>
                    <RangePicker onChange={onChangeRangePicker}    style={{
                    backgroundColor: '#fff',
                    borderRadius: '24px',
                    borderStyle: 'none',
                    boxShadow: '#0433dd33 0px 3px 5px -1px, #0433dd24 0px 6px 10px 0px, #0433dd1f 0px 1px 18px 0px',
                    boxSizing: 'border-box',
                    fontFamily: 'inherit',
                    lineHeight: 'normal',
                    transition: 'background 280ms ease, box-shadow 280ms ease',
                  }}/>
                  </ConfigProvider>
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button
                      htmlType="button"
                      type="primary"
                      onClick={onReset}
                      shape="circle"
                      icon={<DeleteOutlined />}
                      danger
                    />
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        searchAuction(1)
                      }}
                    >
                      Хайх
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Card>
            <Flex
              style={{ marginTop: 40 }}
              justify="space-between"
              align="center"
              wrap="wrap"
            >
              <Meta
                title={
                  <Title
                    level={4}
                    style={{
                      color: '#393874',
                      marginBottom: 5,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    Дуудлага худалдаа
                  </Title>
                }
                description={
                  <Paragraph style={{ color: '#6E6B98', fontWeight: 500 }}>
                    Нийт {auctions?.total} дуудлага худалдаа байна
                  </Paragraph>
                }
              />
              <Space size="middle" wrap>
                {selectedFindBy !== undefined && selectedFindBy !== null ? 
                  <Button className='AP-winner-hover-border' style={{ background: '#f1b44c', color: '#fff' }} onClick={() => searchAuction({ winner: true })}>
                  Ялсан ДТ
                  </Button> : ''}

                <Button type="primary" onClick={onReset}>
                  Нийт ДХ жагсаалт
                </Button>
                <Button
                  onClick={() => {navigateToMap()}}
                  >Газрын зургаар
                </Button>
                <Button
                  onClick={() => {
                    navigateToToday()
                  }}
                >
                  Өнөөдөр болж буй
                </Button>
                {isAttendButton === true && (
                  <Button                   
                    type="primary" style={{ backgroundColor: '#4ED442' }}
                    onClick={() => {navigateToAttend()}}>
                    Оролцож буй
                  </Button>
                )}
                
              </Space>
            </Flex>
            <Space style={{ marginBottom: 10, marginTop: 10 }} wrap>
              <Button
                type="text"
                onClick={() => {
                  searchByStatus(null)
                }}
              >
                Бүгдийг харах
              </Button>
              {statuses.map((item: any, index: any) => {
                return (
                  <Button
                    key={index}
                    type="text"
                    style={{ color: item.color }}
                    onClick={() => {
                      searchByStatus(item.id)
                    }}
                  >
                    {item.name} - {item.total}
                  </Button>
                )
              })}
            </Space>
            <List
              grid={{
                gutter: 20,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 4,
                xxl: 6,
              }}
              dataSource={auctions.data}
              renderItem={(item) => (
                <List.Item>
                  <MCard item={item} url={'detail'} wA={winnerAuction}/>
                </List.Item>
              )}
              style={{ marginBottom: 5 }}
              locale={{
                emptyText: <Empty description="Бичлэг байхгүй байна" />,
              }}
            />
            <Pagination
              onChange={onChangePagination}
              total={auctions?.total}
              style={{ marginBottom: 20 }}
              pageSize={12}
            />

            <FooterLayout isFull />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default Auction
