import React from 'react'
import {
  List,
  Layout,
  Col,
  Row,
  Flex,
  Typography,
  Divider,
  Form,
  Input,
  Button,
} from 'antd'
import { InfoCircleOutlined, MailOutlined } from '@ant-design/icons'
import BlogItem from '../../../components/BlogItem'
import FooterLayout from '../../../layouts/Footer'
import { ProjectCardData } from '../../../constants/dummy.constants'
import MCard from '../../../components/Card'

const { Content } = Layout
const { Text, Link, Title } = Typography

const ProfileProjectPage: React.FC = () => {
  const [form] = Form.useForm()

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        <Row gutter={20}>
          <Col span={24}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: '#393874',
                marginBottom: 10,
                display: 'block',
              }}
            >
              Миний Оролцсон төсөл шалгаруулалт
            </Text>
            <Title
              level={4}
              style={{
                color: '#1AA752',
                marginBottom: 20,
                marginTop: 0,
                textTransform: 'uppercase',
              }}
            >
              Төсөл шалгаруулалт
            </Title>
            <List
              grid={{
                gutter: 20,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 4,
                xxl: 4,
              }}
              dataSource={ProjectCardData}
              pagination={{ position: 'bottom', align: 'start' }}
              renderItem={(item) => (
                <List.Item>
                  <MCard item={item} url={''} />
                </List.Item>
              )}
              style={{ marginBottom: 80 }}
            />
            <FooterLayout isFull />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default ProfileProjectPage
