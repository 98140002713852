import React, { useEffect, useState } from 'react'
import {
  Layout,
  Row,
  Col,
  Form,
  Typography,
  Divider,
  Flex,
  Image,
  Button,
  Input,
  Card,
} from 'antd'
import {
  ArrowRightOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  UserAddOutlined,
  SearchOutlined,
  MailOutlined,
} from '@ant-design/icons'

import FooterLayout from './../../layouts/Footer'
import { useSearchParams, useNavigate } from 'react-router-dom'

import * as WEBSERVICES from '../../config/WebServices'
import SmartHelper from 'helper/SmartHelper'

const { Content } = Layout
const { Meta } = Card
const { Text, Title, Paragraph } = Typography

const Authorized: React.FC = () => {
  const [form] = Form.useForm()

  const [searchParams, setSearchParams] = useSearchParams();

  const [danState, setDanState] = useState(searchParams?.get("state"))
  const [danCode, setCode] = useState(searchParams?.get("code") as string | any)
  
  const navigate = useNavigate();

  useEffect(() => {
    async function checkLogin() {
      try {
        let url = WEBSERVICES.URL_ENTITY_LOGIN_DAN+"?code="+danCode
        
        await fetch(url)
          .then((response) => response.json())
          .then(async (responseJson) => {
              if(responseJson && responseJson.status === true) {

                let entity = responseJson.data;

                let url = WEBSERVICES.URL_ENTITY_FIND+"?register="+entity.regnum;

                await fetch(url)
                  .then((response) => response.json())
                  .then(async (responseJson) => {
                    if(responseJson?.status === true) {            
                      entity = responseJson?.entity;      

                      if(parseInt(entity.login_type) == 1) {            
                        let localCitizen = {loginType: entity.login_type, firstname: entity.firstname, lastname: entity.lastname, register: entity.register_number, image: entity.profile_photo};
                        SmartHelper.storeSession('auth', JSON.stringify(localCitizen));
                      } else {
                        let localLegal = {loginType: entity.login_type, entityname: entity.entity_name, register: entity.register_number, firstname: entity.firstname, lastname: entity.lastname, image: entity.profile_photo};
                        SmartHelper.storeSession('auth', JSON.stringify(localLegal));
                      }

                      localStorage.setItem('sessionToken', responseJson.token);

                      SmartHelper.storeSession('entity', JSON.stringify(entity));
                      SmartHelper.storeSession('isAuth', true);
                      
                      navigate('/profile');
                      navigate(0);
                    } else {
                      alert('Нэвтрэх үед алдаа гарлаа. Ахин нэвтэрнэ үү: 1');
                      setTimeout(() => {
                          navigate('/home');
                          navigate(0);
                      }, 500);  
                    }
                  })
                  .catch((error) => {
                    alert('Нэвтрэх үед алдаа гарлаа. Ахин нэвтэрнэ үү: 2');
                    setTimeout(() => {
                        navigate('/home');
                        navigate(0);
                    }, 500);
                  })
              } else {
                alert('Нэвтрэх үед алдаа гарлаа. Ахин нэвтэрнэ үү: 3');
                setTimeout(() => {
                    navigate('/home');
                    navigate(0);
                }, 500);
              }
          })
          .catch((error) => {
            alert('Нэвтрэх үед алдаа гарлаа. Ахин нэвтэрнэ үү: 4');
            setTimeout(() => {
              navigate('/home');
              navigate(0);
            }, 500);
          })
      } catch (error) {
        console.log(error);
      }
    }

    checkLogin();
  }, [])

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        <Row gutter={20}>
          <Title
            level={4}
            style={{
              color: '#393874',
              width: '100%',
              textAlign: 'center',
              textTransform: 'uppercase',
              marginBottom: 20,
            }}
          >
            Нэвтрэлт
          </Title>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Card style={{ marginBottom: 20 }}>
              <CheckCircleOutlined style={{ color: '#1AA752' }} />
              <Text
                style={{
                  marginLeft: 8,
                  color: '#171441',
                  fontWeight: 600,
                }}
              >
                Нэвтрэлтийн мэдээлэл
              </Text>
              
              {!danCode && 
                <>
                  <Divider style={{ marginTop: 10, borderColor: '#FF0000' }} />
                  <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: '#FF0000', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <>
                          <Text style={{ color: '#FF0000', fontWeight: 600 }}>
                            Нэвтрэх үед алдаа гарлаа. Та дахин оролдоно уу.
                          </Text>
                        </>
                      }
                    />
                </>
              }

            </Card>
            <div className="hide-on-md-or-less" style={{ marginTop: 100 }}>
                <FooterLayout />
              </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default Authorized
