import React, { useEffect, useState } from 'react'
import {
  Layout,
  Row,
  Col,
  Divider,
  Card,
  Button,
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  ConfigProvider,
  Flex,
  Typography,
  List,
  Pagination,
  Spin,
} from 'antd'
import {
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons'

import FooterLayout from './../../layouts/Footer'
import PCard from './../../components/PCard'

import * as WEBSERVICES from '../../config/WebServices'

import mnMN from 'antd/lib/locale/mn_MN'
import dayjs from 'dayjs'
import { Dayjs } from 'dayjs';
import 'dayjs/locale/mn'
import SmartHelper from 'helper/SmartHelper'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

dayjs.locale('mn')

const { Option } = Select
const { Content } = Layout
const { RangePicker } = DatePicker
const { Text, Link, Title, Paragraph } = Typography
const { Meta } = Card

const Project: React.FC = () => {
  const [form] = Form.useForm()

  const location = useLocation();
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false)
  const [projects, setProjects] = useState([] as any)

  const [invests, setInvests] = useState([] as any)
  const [landTypes, setLandTypes] = useState([] as any)
  const [statuses, setStatuses] = useState([] as any)

  const [selectedProjectOnline, setSelectedProjectOnline] = useState(null as any)
  const [selectedInvest, setSelectedInvest] = useState(null as any)
  const [winnerProject, setWinnerProject] = useState(false)
  const [selectedLandType, setSelectedLandType] = useState(null as any)
  const [selectedSearchKeyword, setSelectedSearchKeyword] = useState(null as any)
  const [selectedStatusId, setSelectedStatusId] = useState(location?.state?.statusId)
  const [selectedToday, setSelectedToday] = useState(location?.state?.today)
  const [selectedStartDate, setSelectedStartDate] = useState('' as string);
  const [selectedEndDate, setSelectedEndDate] = useState('' as string);
  const [selectedFindBy, setSelectedFindBy] = useState(location?.state?.findBy)

  const [shouldSearchProject, setShouldSearchProject] = useState(true);
  const [shouldCallFirstTime, setShouldCallFirstTime] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await fetchProjectFilter();
    }

    if (shouldSearchProject) {
      searchProject(1);
      setShouldSearchProject(false);
    }

    if (shouldCallFirstTime) {
      fetchData().then(() => {
        setLoaded(true);
        setShouldCallFirstTime(false);
      })
    }

  }, [shouldSearchProject])

  const onFinish = (values: any) => {
  }

  const navigateToMap = () => {
    navigate('/map?type=project')
    navigate(0);
  }

  const onReset = async () => {
    form.resetFields();
    setSelectedProjectOnline(null);
    setSelectedInvest(null);
    setSelectedLandType(null);
    setSelectedSearchKeyword(null);
    setSelectedStatusId(null);
    setSelectedStartDate('');
    setSelectedEndDate('');
    setSelectedToday(null);
    setSelectedFindBy(null);

    setShouldSearchProject(true);
  }

  const onChangePagination = async (page: any) => {
    await searchProject(page);
  }

  const onChangeRangePicker = (dates: any, dateStrings: [string, string]) => {
    setSelectedStartDate(dateStrings[0]);
    setSelectedEndDate(dateStrings[1]);
    setSelectedToday(null);
  }

  const searchProject = (page: any) => {
    let search = "";
    search += (selectedProjectOnline && typeof selectedProjectOnline !== undefined) ? "&is_online=" + selectedProjectOnline : "";
    search += (selectedInvest && typeof selectedInvest !== undefined) ? "&invest=" + selectedInvest : "";
    search += (selectedLandType && typeof selectedLandType !== undefined) ? "&landtype=" + selectedLandType : "";
    search += (selectedSearchKeyword && typeof selectedSearchKeyword !== undefined) ? "&keyword=" + selectedSearchKeyword : "";
    search += (selectedStatusId && typeof selectedStatusId !== undefined) ? "&status=" + selectedStatusId : "";
    search += (selectedStartDate && typeof selectedStartDate !== undefined && selectedStartDate !== '') ? "&start_date=" + selectedStartDate : "";
    search += (selectedEndDate && typeof selectedEndDate !== undefined && selectedEndDate !== '') ? "&end_date=" + selectedEndDate : "";
    search += (selectedToday && typeof selectedToday !== undefined && selectedToday !== '') ? "&today=1" : "";
    search += (selectedFindBy && typeof selectedFindBy !== undefined && selectedFindBy !== '') ? "&entity_id=" + selectedFindBy : "";

    if (page?.winner === true) {
      search += '&winner=true'
      setWinnerProject(true)
    }

    fetchProjectsList(page, search);
  }

  const searchByStatus = (statusId: any) => {
    setSelectedStatusId(statusId);
    setShouldSearchProject(true);
  }

  const searchByToday = () => {
    setSelectedToday(1);
    setShouldSearchProject(true);
  }

  const fetchProjectsList = async (page: any, search: any = "") => {
    setLoaded(false);
    let url = WEBSERVICES.URL_PROJECT_PAGINATION_LIST + "?au1=" + SmartHelper.getSession("au1") + "&au2=" + SmartHelper.getSession("au2") + "&au3=" + SmartHelper.getSession("au3") + "&page=" + page + search;

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setProjects(responseJson.data);
        } else {
          setProjects([]);
        }
      })
      .catch((error) => {
        setProjects([]);
      })
      .finally(() => {
        setLoaded(true);
      });
  }

  const fetchProjectFilter = async () => {
    let url = WEBSERVICES.URL_PROJECT_FILTER;

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setInvests(responseJson.investTypes);
          setLandTypes(responseJson.projectLandTypes);
          setStatuses(responseJson.statuses);
        } else {
          setInvests([]);
          setLandTypes([]);
          setStatuses([]);
        }
      })
      .catch((error) => {
        setInvests([]);
        setLandTypes([]);
        setStatuses([]);
      });
  }

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        {!loaded && <Spin size="large" fullscreen />}
        <Row gutter={20}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Card style={{ boxShadow: '0px 4px 19px #D8DEE9' }}>
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ marginTop: 0, }}
              >
                Дэлгэрэнгүй хайлт
              </Divider>
              <Form
                layout="inline"
                form={form}
                onFinish={onFinish}
                className="auction-search-form"
                style={{ maxWidth: 'none', }}
                initialValues={{
                  is_project_online: null,
                  invest: null,
                  land_type: null,
                  search_keyword: null,
                }}
              >
                <Form.Item name="is_project_online" style={{ width: 170, }}>
                  <Select
                    placeholder="ТСШ явагдах хэлбэр"
                    allowClear
                    value={selectedProjectOnline}
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '24px',
                      borderStyle: 'none',
                      boxShadow: '#0433dd33 0px 3px 5px -1px, #0433dd24 0px 6px 10px 0px, #0433dd1f 0px 1px 18px 0px',
                      boxSizing: 'border-box',
                      fontFamily: 'inherit',
                      lineHeight: 'normal',
                      transition: 'background 280ms ease, box-shadow 280ms ease',
                    }}
                    onChange={(value) => {
                      setSelectedProjectOnline(value)
                    }}
                  >
                    <Option value="1">Цахимаар</Option>
                    <Option value="0">Уламжлалтаар</Option>
                  </Select>
                </Form.Item>
                <Form.Item name="invest" style={{ width: 'auto', minWidth: 165 }}>
                  <Select
                    placeholder="Хөрөнгө оруулалтын хэлбэр"
                    allowClear
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '24px',
                      borderStyle: 'none',
                      boxShadow: '#0433dd33 0px 3px 5px -1px, #0433dd24 0px 6px 10px 0px, #0433dd1f 0px 1px 18px 0px',
                      boxSizing: 'border-box',
                      fontFamily: 'inherit',
                      lineHeight: 'normal',
                      transition: 'background 280ms ease, box-shadow 280ms ease',
                    }}
                    dropdownStyle={{ whiteSpace: 'normal', maxWidth: 300 }}
                    onChange={(value) => {
                      setSelectedInvest(value)
                    }}
                    popupMatchSelectWidth={false}
                  >
                    {invests.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item.id}>
                          <span style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                            {item.name}
                          </span>
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item name="land_type" style={{ width: 'auto', minWidth: 165 }}>
                  <Select
                    placeholder="Газрын төрөл"
                    allowClear
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '24px',
                      borderStyle: 'none',
                      boxShadow: '#0433dd33 0px 3px 5px -1px, #0433dd24 0px 6px 10px 0px, #0433dd1f 0px 1px 18px 0px',
                      boxSizing: 'border-box',
                      fontFamily: 'inherit',
                      lineHeight: 'normal',
                      transition: 'background 280ms ease, box-shadow 280ms ease',
                    }}
                    dropdownStyle={{ whiteSpace: 'normal', maxWidth: 300 }}
                    onChange={(value) => {
                      setSelectedLandType(value)
                    }}
                    popupMatchSelectWidth={false}
                  >
                    {landTypes.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item.id}>
                          <span style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                            {item.name}
                          </span>
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item name="search_keyword" style={{ width: 170 }}>
                  <Input
                    placeholder="Хайх утга"
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '24px',
                      borderStyle: 'none',
                      boxShadow: '#0433dd33 0px 3px 5px -1px, #0433dd24 0px 6px 10px 0px, #0433dd1f 0px 1px 18px 0px',
                      boxSizing: 'border-box',
                      fontFamily: 'inherit',
                      lineHeight: 'normal',
                      transition: 'background 280ms ease, box-shadow 280ms ease',
                      height: 32,
                    }}
                    onChange={(e) => {
                      setSelectedSearchKeyword(e.target.value)
                    }}
                  ></Input>
                </Form.Item>
                <Form.Item name="search_date" style={{ width: 200 }}>
                  <ConfigProvider locale={mnMN}>

                    <RangePicker onChange={onChangeRangePicker}
                      style={{
                        backgroundColor: '#fff',
                        borderRadius: '24px',
                        borderStyle: 'none',
                        boxShadow: '#0433dd33 0px 3px 5px -1px, #0433dd24 0px 6px 10px 0px, #0433dd1f 0px 1px 18px 0px',
                        boxSizing: 'border-box',
                        fontFamily: 'inherit',
                        lineHeight: 'normal',
                        transition: 'background 280ms ease, box-shadow 280ms ease',
                      }} />
                  </ConfigProvider>
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button
                      htmlType="button"
                      type="primary"
                      onClick={onReset}
                      shape="circle"
                      icon={<DeleteOutlined />}
                      danger
                    />
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        searchProject(1)
                      }}
                    >
                      Хайх
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Card>
            <Flex
              style={{ marginTop: 40 }}
              justify="space-between"
              align="center"
              wrap="wrap"
            >
              <Meta
                title={
                  <Title
                    level={4}
                    style={{
                      color: '#393874',
                      marginBottom: 5,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    Төсөл шалгаруулалт
                  </Title>
                }
                description={
                  <Paragraph style={{ color: '#6E6B98', fontWeight: 500 }}>
                    Нийт {projects?.total} төсөл шалгаруулалт байна
                  </Paragraph>
                }
              />
              <Space size="middle" wrap>
                {selectedFindBy !== undefined && selectedFindBy !== null ?
                  <Button className='AP-winner-hover-border' style={{ background: '#f1b44c', color: '#fff' }} onClick={() => searchProject({ winner: true })}>
                    Ялсан ТСШ
                  </Button> : ''}

                <Button type="primary" onClick={onReset}>
                  Нийт ТСШ жагсаалт
                </Button>
                <Button
                  onClick={() => { navigateToMap() }}
                >Газрын зургаар
                </Button>
                <Button
                  onClick={() => {
                    searchByToday()
                  }}
                >
                  Өнөөдөр болж буй
                </Button>
                <Button type="primary" style={{ backgroundColor: '#4ED442' }}>
                  Оролцож буй
                </Button>
              </Space>
            </Flex>
            <Space style={{ marginBottom: 10, marginTop: 10 }} wrap>
              <Button
                type="text"
                onClick={() => {
                  searchByStatus(null)
                }}
              >
                Бүгдийг харах
              </Button>
              {statuses.map((item: any, index: any) => {
                return (
                  <Button
                    key={index}
                    type="text"
                    style={{ color: item.color }}
                    onClick={() => {
                      searchByStatus(item.id)
                    }}
                  >
                    {item.name} - {item.total}
                  </Button>
                )
              })}
            </Space>
            <List
              grid={{
                gutter: 20,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 4,
                xxl: 6,
              }}
              dataSource={projects.data}
              renderItem={(item) => (
                <List.Item>
                  <PCard item={item} url={'detail'} wP={winnerProject} />
                </List.Item>
              )}
              style={{ marginBottom: 5 }}
            />
            <Pagination
              onChange={onChangePagination}
              total={projects?.total}
              style={{ marginBottom: 20 }}
            />

            <FooterLayout isFull />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default Project
