import { Card, Col, Collapse, Divider, Empty, Image, Layout, Row, Spin, Typography } from 'antd';
import FooterLayout from 'layouts/Footer';
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import type { CollapseProps } from 'antd';
import { useParams } from 'react-router-dom';
import * as WEBSERVICES from '../../../config/WebServices'
import ContractIndex from 'components/Winner/contract';
import NotesIndex from 'components/Winner/note';
import ApplicationIndex from 'components/Winner/application';
import PayIndex from 'components/Winner/qpay';
import CertificateIndex from 'components/Winner/certificate';
import SmartHelper from 'helper/SmartHelper'


dayjs.locale('mn')

const { Content } = Layout
const { Text } = Typography

const WinnerDetail: React.FC = () => {
  const { id: id, active: active } = useParams()

  const [loaded, setLoaded] = useState(false)
  const [contractContent, setContractContent] = useState([] as any)
  // const [notesContent, setNotesContent] = useState([] as any)
  const [payContent, setPayContent] = useState([] as any)
  const [applicationContent, setApplicationContent] = useState([] as any)
  const [certificateContent, setCertificateContent] = useState([] as any)

  let entity = SmartHelper.getEntity();

  useEffect(() => {
    

    const winnerDetail = async () => {
      let url = WEBSERVICES.URL_A_P_WINNER_DETAIL + "id=" + id + "&active=" + active + "&entity_id=" + entity.id;

      await fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
          
          if(responseJson.data.contract.status === true){                       
            setContractContent(<ContractIndex qrCode={responseJson.qrCode} project={responseJson.data.contract.data.detail} contract={responseJson.data.contract.data.contract} entity={entity} id={id} active={active}/>)
          }else{
            var noData = <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 80 }}
              description={
                <Typography.Text style={{fontSize: '15px', fontWeight: 'bold'}}>
                  Ялагчтай байгуулах гэрээ үүсээгүй байна !!!
                </Typography.Text>
              }/>
            setContractContent(noData)
          }

          // if (responseJson.data.contract.status === true) {            
          //   setNotesContent(<NotesIndex id={id} active={active} />)
          // } else {
          //   var noNote = <Empty
          //     image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          //     imageStyle={{ height: 80 }}
          //     description={
          //       <Typography.Text style={{fontSize: '15px', fontWeight: 'bold'}}>
          //         Ажлын хэсгийн дүгнэлт гараагүй байна !!!
          //       </Typography.Text>
          //     }/>
          //   setNotesContent(noNote)
          // }

          if (responseJson.data.qpay.status === true) {
            setPayContent(<PayIndex contract={responseJson.data.contract.data.contract} info={responseJson.data.contract.data.detail} id={id} active={active} />)
          } else {
            var pay = <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 80 }}
              description={
                <Typography.Text style={{fontSize: '15px', fontWeight: 'bold'}}>
                  Төлбөр төлөх боломжгүй байна !!!
                </Typography.Text>
              }/>
            setPayContent(pay)
          }

          if (responseJson.data.application.status === true) {
            setApplicationContent(<ApplicationIndex appStatus={responseJson.data.application.data} />)
          } else {
            var application = <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 80 }}
              description={
                <Typography.Text style={{fontSize: '15px', fontWeight: 'bold'}}>
                  Өргөдөл үүсээгүй байна !!!
                </Typography.Text>
              }/>
            setApplicationContent(application)
          }

          if (responseJson.data.print.status === true) {
            setCertificateContent(<CertificateIndex id={id} active={active} />)
          } else {
            var contractAndCerfi = <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 80 }}
              description={
                <Typography.Text style={{fontSize: '15px', fontWeight: 'bold'}}>
                  Гэрээ гэрчилгээ хэвлэх боломжгүй байна !!!
                </Typography.Text>
              }/>
            setCertificateContent(contractAndCerfi)
          }
        })
      .catch((error) => {

      });
    }

    winnerDetail().then(() => {
      setLoaded(true);
    });
  },[]);



  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Ялагчтай байгуулах гэрээ',
      children: <p style={{width: "100%"}} >{contractContent}</p>,
    },
    // {
    //   key: '2',
    //   label: 'Төсөл сонгон шалгаруулалтын тэмдэглэл',
    //   children: <p> {notesContent} </p>,
    // },
  ];

  return (
  <Layout>
    <Content style={{ padding: '0 20px', marginTop: 20 }}>
      {!loaded && <Spin size="large" fullscreen />}
      <Row gutter={20}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Row gutter={20}>
              <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-contract.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Ялагчийн гэрээ / Ажлын хэсгийн тэмдэглэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <Collapse accordion defaultActiveKey={['1']} items={items} />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-pay.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Нэхэмжлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  {payContent}
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-process.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Өргөдлийн явц
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  {applicationContent}

                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-certificate.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Гэрээ / Гэрчилгээ хэвлэх
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  {certificateContent}

                </Card>
              </Col>
            </Row>
   
            <FooterLayout isFull />
        </Col>
      </Row>
    </Content>
  </Layout>
  )
}

export default WinnerDetail