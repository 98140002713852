import React, {useEffect, useState} from 'react'
import {
  Card,
  Flex,
  Typography,
  Image,
  Divider,
  Button,
  Table,
  Row,
  Col,
  notification,
  Spin,
  Empty,
} from 'antd'
import { UserOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import 'dayjs/locale/mn'

import * as CONFIGS from '../../config/Configs'
import * as WEBSERVICES from '../../config/WebServices'

import SmartHelper from 'helper/SmartHelper'
import Echo from 'laravel-echo';
import io from 'socket.io-client';
declare global {
  interface Window {
      io: typeof io;
  }
}

window.io = io;

dayjs.locale('mn')

const { Text, Title } = Typography

interface AuctionItemProps {
  item: any
  spacing?: number
}


const AuctionItem: React.FC<AuctionItemProps> = ({ item, spacing }) => {
  const [api, contextHolder] = notification.useNotification();
  const [bids, setBids] = useState([] as any)
  const [columns, setColumns] = useState([] as any)
  const [loaded, setLoaded] = useState(false)
  const [minBid, setMinBid] = useState(null as any)
  const [maxBid, setMaxBid] = useState(null as any)
  const [minBidAmount, setMinBidAmount] = useState(item.min_bid_amount)
  const [maxBidAmount, setMaxBidAmount] = useState(item.max_bid_amount)
  const [isWinning, setIsWinning] = useState(false);
  const [endTime, setEndTime] = useState("00:00:00");

  useEffect(() => {

    async function fetchData() {
      await fetchAuctionBids(item.id) ;    
      await updateAuctionTime(item.id);
    }

    fetchData().then(() => {
      setLoaded(true)
    })

    const socket = io('https://socket.mle.mn', {
        transports: ['websocket', 'polling'],
        autoConnect: true
    });

    socket.on('connect', () => {
    });

    socket.on('disconnect', () => {
      socket.connect(); // This should be automatic, but can be triggered manually if needed
    });
    
    socket.on('connect_error', (error: any) => {
        console.error('Connection Error:', error);
    });

    const echo = new Echo({
        broadcaster: 'socket.io',
        host: 'https://socket.mle.mnn',
    });

    const channel1 = echo.channel('auction-channel-'+item.id)
        .listen('.AuctionBidPlaced', (e: any) => {
            if(e !== null && e.data) {
              let lastBid = JSON.parse(e.data);

              const newBid = {
                id: lastBid.id,
                entity_id: lastBid.entity_id, 
                bidtime: lastBid.bidtime,
                bid: lastBid.bid,
                color: '#000000',
              };

              setBids((prevDataSource: any) => [newBid, ...prevDataSource]);
            }
        });

      const channel2 = echo.channel('auction-time-'+item.id)
        .listen('.AuctionTimeChanged', (e: any) => {
            if(e !== null && e.data) {
              setEndTime(e.data);
            }
        });

    setColumns([
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        hidden: true
      },
      {
        title: 'Хэрэглэгч',
        dataIndex: 'entity_id',
        key: 'entity_id',
        render: (text: string) => "#"+text,
      },
      {
        title: 'Огноо',
        dataIndex: 'bidtime',
        key: 'bidtime',
      },
      {
        title: 'Үнэ',
        dataIndex: 'bid',
        key: 'bid',
        render: (value: string) => SmartHelper.formatCurrencyBid(value) + '₮'
      },
    ]);
        
    return () => {
        channel1.stopListening('.AuctionBidPlaced');
        channel2.stopListening('.AuctionTimeChanged');
        echo.disconnect();
    };
}, []);

  const fetchAuctionBids = async (auctionId: any) => {
    let url = WEBSERVICES.URL_AUCTION_BIDS+"?auction_id="+auctionId;

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          if(responseJson.data.length > 0) {
            let bids = responseJson.data;
            setBids(bids)
          }
        } else {
          setBids([])
        }
      })
      .catch((error) => {
        setBids([])
      })
  }

  const updateAuctionTime = async (auctionId: any) => {
    let url = WEBSERVICES.URL_AUCTION_UPDATE_TIME+auctionId;

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        
      })
      .catch((error) => {
      })
  }

  const getCoverImage = (images: any): string | any => {
    if(images && images.length > 0) {
      return CONFIGS.URL_AUCTION_ROOT+images[0].file_url;
    }

    return "";
  }

  return (
    <>
      {!loaded && <Spin size="large" fullscreen />}
      {contextHolder}
      <Card
        style={{
          boxShadow: '0px 4px 19px #D8DEE9',
          margin: spacing ? '0 10px 20px 10px' : 0,
          borderColor: item.item_border_color,
        }}
        styles={{
          header: {
            borderBottom: 'none',
          },
        }}
        title={
          <>
            <div style={{ marginTop: 40 }}>
              <Row gutter={20}>
                <Col span={12}>
                  <Image
                    src="/images/icon-map.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Дуудлага худалдааны цаг
                  </Text>
                </Col>
                <Col span={12}>
                <div className="time-container">
                  <div className="time-part">{endTime}</div>
                </div>
                </Col>
              </Row>
              <Divider style={{ marginTop: 10 }} />
              <Title
                level={4}
                style={{
                  color: '#393874',
                  marginBottom: 20,
                  marginTop: 0,
                  textTransform: 'uppercase',
                }}
              >
                <span style={{ color: '#0654DE' }}>#{item.code}</span> - {item.admin_unit_one.name}, {item.admin_unit_two.name}, {item.admin_unit_three.name}
              </Title>
            </div>
            <Row gutter={20}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <div>
                  <Table
                    rowKey={(record) => `id-${record.id}`}
                    pagination={false}
                    columns={columns}
                    dataSource={bids}
                    scroll={{ y: 250 }}
                    className={"auction-item-table-blue"} 
                    onRow={(record: any) => ({
                      style: {
                        color: record.color,
                      }
                    })}
                    locale={{
                      emptyText: <Empty description="Бичлэг байхгүй байна" />,
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <div>
                  <Flex justify="space-between">
                    <div>
                      <UserOutlined
                        style={{ color: '#0654DE', fontSize: 10 }}
                      />
                      <Text
                        style={{
                          marginLeft: 8,
                          color: '#0654DE',
                          fontSize: 10,
                        }}
                      >
                        {item.totalParticipant} Оролцогч байна
                      </Text>
                    </div>
                  </Flex>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  
                </div>
              </Col>
              
            </Row>
          </>
        }
      />
    </>
  )
}

export default AuctionItem
