import React, {useEffect, useState} from 'react'
import {
  Card,
  Flex,
  Typography,
  Image,
  Divider,
  Button,
  Table,
  Row,
  Col,
  notification,
  Spin,
  Empty,
} from 'antd'
import { UserOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import 'dayjs/locale/mn'

import * as CONFIGS from '../../config/Configs'
import * as WEBSERVICES from '../../config/WebServices'

import SmartHelper from 'helper/SmartHelper'
import Echo from 'laravel-echo';
import io from 'socket.io-client';
declare global {
  interface Window {
      io: typeof io;
  }
}

window.io = io;

dayjs.locale('mn')

const { Text, Title } = Typography

interface AuctionItemProps {
  item: any
  spacing?: number
}


const AuctionItem: React.FC<AuctionItemProps> = ({ item, spacing }) => {
  const [api, contextHolder] = notification.useNotification();
  const [bids, setBids] = useState([] as any)
  const [columns, setColumns] = useState([] as any)
  const [entity, setEntity] = useState([] as any)  
  const [loaded, setLoaded] = useState(false)
  const [minBid, setMinBid] = useState(null as any)
  const [maxBid, setMaxBid] = useState(null as any)
  const [minBidAmount, setMinBidAmount] = useState(item.min_bid_amount)
  const [maxBidAmount, setMaxBidAmount] = useState(item.max_bid_amount)
  const [isWinning, setIsWinning] = useState(false);
  const [endTime, setEndTime] = useState("00:00:00");

  useEffect(() => {

    async function fetchData(entity: any) {
      await fetchAuctionBids(item.id, entity) ;    
      await updateAuctionTime(item.id);
    }

    let entity = SmartHelper.getEntity();
    setEntity(entity);

    fetchData(entity).then(() => {
      setLoaded(true)
    })

    /*const socket = io('https://socket.mle.mn', {
        transports: ['websocket', 'polling'],
        autoConnect: true
    });

    socket.on('connect', () => {
    });

    socket.on('disconnect', () => {
      socket.connect();
    });
    
    socket.on('connect_error', (error: any) => {
      console.error('Connection Error:', error);
    });*/

    const echo = new Echo({
        broadcaster: 'socket.io',
        //host: window.location.hostname + ':6001',
        host: 'https://socket.mle.mn',
    });

    const channel1 = echo.channel('auction-channel-'+item.id)
        .listen('.AuctionBidPlaced', (e: any) => {
            if(e !== null && e.data) {
              let lastBid = JSON.parse(e.data);

              const newBid = {
                id: lastBid.id,
                entity_id: entity.id === lastBid.entity_id? "Таны санал": lastBid.entity_id, 
                bidtime: lastBid.bidtime,
                bid: lastBid.bid,
                color: '#000000',
              };

              //entity.id === lastBid.entity_id? setIsWinning(true): setIsWinning(false);

              setMinBid(parseFloat(lastBid.bid) + parseFloat(item.min_bid_amount));
              setMaxBid(parseFloat(lastBid.bid) + parseFloat(item.max_bid_amount));

              setBids((prevDataSource: any) => [newBid, ...prevDataSource]);
            }
        });

      const channel2 = echo.channel('auction-time-'+item.id)
        .listen('.AuctionTimeChanged', (e: any) => {
            if(e !== null && e.data) {
              setEndTime(e.data);
            }
        });

    setColumns([
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        hidden: true
      },
      {
        title: 'Хэрэглэгч',
        dataIndex: 'entity_id',
        key: 'entity_id',
        render: (text: string) => entity.id === text? "#Таны санал": "#"+text,
      },
      {
        title: 'Огноо',
        dataIndex: 'bidtime',
        key: 'bidtime',
      },
      {
        title: 'Үнэ',
        dataIndex: 'bid',
        key: 'bid',
        render: (value: string) => SmartHelper.formatCurrencyBid(value) + '₮'
      },
    ]);
        
    return () => {
        channel1.stopListening('.AuctionBidPlaced');
        channel2.stopListening('.AuctionTimeChanged');
        echo.disconnect();
    };
}, []);

const sendBid = async (bid: any) => {
  try {
    const token = localStorage.getItem('sessionToken');

    const response = await fetch(WEBSERVICES.URL_AUCTION_BID_STORE+item.id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        auction_id: item.id,
        entity_id: entity.id,
        bid: bid,
        token: token
      })
    });

    if (!response.ok) {
      throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
    }

    const result = await response.json();
    
    if(result.status === 'success') {
      openNotificationSuccess(result.msg)
    } else if(result.status === 'error') {
      openNotificationFailed(result.msg)
    } else {
      throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
    }
  } catch (error) {
    openNotificationFailed(error);
  }
}

  const openNotificationSuccess = (msg: any) => {
    api.success({
      message: 'Амжилттай илгээгдлээ',
      description: msg,
      placement: 'topRight',
    })
  }
  const openNotificationFailed = (msg: any) => {
    api.error({
      message: 'Үнэ илгээгдсэнгүй',
      description: msg,
      placement: 'topRight',
    })
  }

  const fetchAuctionBids = async (auctionId: any, entity: any) => {
    let url = WEBSERVICES.URL_AUCTION_BIDS+"?auction_id="+auctionId;

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          if(responseJson.data.length > 0) {
            let bids = responseJson.data;
            setBids(bids)

            setMinBid(parseFloat(bids[0].bid) + parseFloat(item.min_bid_amount));
            setMaxBid(parseFloat(bids[0].bid) + parseFloat(item.max_bid_amount));

            //entity.id === bids[0].entity_id? setIsWinning(true): setIsWinning(false);
            
          } else {
            setMinBid(parseFloat(item.auction_price) + parseFloat(item.min_bid_amount));
            setMaxBid(parseFloat(item.auction_price) + parseFloat(item.max_bid_amount));
          }
        } else {
          setBids([])
        }
      })
      .catch((error) => {
        setBids([])
      })
  }

  const updateAuctionTime = async (auctionId: any) => {
    let url = WEBSERVICES.URL_AUCTION_UPDATE_TIME+auctionId;

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        
      })
      .catch((error) => {
      })
  }

  const getCoverImage = (images: any): string | any => {
    if(images && images.length > 0) {
      return CONFIGS.URL_AUCTION_ROOT+images[0].file_url;
    }

    return "";
  }

  return (
    <>
      {!loaded && <Spin size="large" fullscreen />}
      {contextHolder}
      <Card
        style={{
          boxShadow: '0px 4px 19px #D8DEE9',
          margin: spacing ? '0 10px 20px 10px' : 0,
          borderColor: item.item_border_color,
        }}
        styles={{
          header: {
            borderBottom: 'none',
          },
        }}
        title={
          <>
            <div style={{ marginTop: 40 }}>
              <Row gutter={20}>
                <Col span={12}>
                  <Image
                    src="/images/icon-map.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Оролцож буй дуудлага худалдаа
                  </Text>
                </Col>
                <Col span={12}>
                <div className="time-container">
                  <div className="time-part">{endTime}</div>
                </div>
                </Col>
              </Row>
              <Divider style={{ marginTop: 10 }} />
              <Title
                level={4}
                style={{
                  color: '#393874',
                  marginBottom: 20,
                  marginTop: 0,
                  textTransform: 'uppercase',
                }}
              >
                <span style={{ color: '#0654DE' }}>#{item.code}</span> - {item.admin_unit_one.name}, {item.admin_unit_two.name}, {item.admin_unit_three.name}
              </Title>
            </div>
            <Row gutter={20}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <div>
                  <Image
                    src={getCoverImage(item.documents)}
                    fallback="/images/noimage.png"
                    preview={false}
                    style={{ marginBottom: 15, borderRadius: 5 }}
                  />
                  <Flex justify="space-between">
                    <Text style={{ fontWeight: 600, color: '#393874' }}>
                      Үнийн санал илгээх
                    </Text>
                    <div>
                      <UserOutlined
                        style={{ color: '#0654DE', fontSize: 10 }}
                      />
                      <Text
                        style={{
                          marginLeft: 8,
                          color: '#0654DE',
                          fontSize: 10,
                        }}
                      >
                        {item.totalParticipant} Оролцогч байна
                      </Text>
                    </div>
                  </Flex>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  <Row gutter={20}>
                    <Col span={12}>
                      <Flex vertical justify="center" align="center">
                        <Text style={{ color: '#FF1D1D', marginBottom: 5 }}>
                          Доод үнэ илгээх
                        </Text>
                        <Button
                          style={{ borderColor: '#FF1D1D', color: '#FF1D1D' }}
                          block
                          onClick={() => (sendBid(minBidAmount))}
                        >
                          {SmartHelper.formatCurrencyBid(minBid)} '₮'
                        </Button>
                      </Flex>
                    </Col>
                    <Col span={12}>
                      <Flex
                        vertical
                        justify="center"
                        align="center"
                        style={{ paddingBottom: 10 }}
                      >
                        <Text style={{ color: '#0654DE', marginBottom: 5 }}>
                          Дээд үнэ илгээх
                        </Text>
                        <Button
                          style={{ borderColor: '#0654DE', color: '#0654DE' }}
                          block
                          onClick={() => (sendBid(maxBidAmount))}
                        >
                          {SmartHelper.formatCurrencyBid(maxBid)} '₮'
                        </Button>
                      </Flex>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <div>
                  <Table
                    rowKey={(record) => `id-${record.id}`}
                    pagination={false}
                    columns={columns}
                    dataSource={bids}
                    scroll={{ y: 250 }}
                    className={"auction-item-table-blue"} 
                    onRow={(record: any) => ({
                      style: {
                        color: record.color,
                      }
                    })}
                    locale={{
                      emptyText: <Empty description="Бичлэг байхгүй байна" />,
                    }}
                  />
                </div>
              </Col>
            </Row>
          </>
        }
      />
    </>
  )
}

export default AuctionItem
