import React, { useEffect, useState } from 'react'
import { Col, Row, Typography, Flex } from 'antd'

import * as WEBSERVICES from 'config/WebServices'
import './Footer.css'; // Import the CSS file for styling

const { Text, Title, Paragraph, Link } = Typography

const footerTitleStyle: React.CSSProperties = {
  fontSize: 12,
  fontWeight: 700,
  color: '#fff',
  display: 'inline-block',
  borderBottom: '2px solid #FFDA00',
  paddingBottom: 16,
  marginBottom: 32,
  textTransform: 'uppercase',
};

interface FooterProps {
  isFull?: boolean;
}

const FooterLayout: React.FC<FooterProps> = ({ isFull = false }) => {
  const [footerData, setFooterData] = useState({} as any)

  useEffect(() => {
    fetchFooterData();
  }, [])

  const fetchFooterData = async () => {
    let url = WEBSERVICES.URL_CONFIG_FOOTER_DATA;

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setFooterData(responseJson)
        } else {
          setFooterData([])
        }
      })
      .catch((error) => {
        setFooterData([])
      })
  }

  return (
    <div className={`footer-container ${isFull ? 'full' : ''}`}>
      <Row
        style={{
          background: 'linear-gradient(94.15deg, #0D41FF, 60%, #082799 100%)',
          position: 'relative',
          paddingTop: 40,
          margin: 0,
        }}
        gutter={[30, 60]}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundImage: 'url(/images/footer-pattern.png)',
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'repeat',
          }}
        />
        <Col md={24} lg={12} xl={12} xxl={12}>
          <div style={{ padding: '0 15px' }}>
            <Title style={footerTitleStyle}>Бидний тухай</Title>
            <Paragraph
              style={{
                color: '#fff',
                fontWeight: 600,
                lineHeight: '25px',
                maxWidth: 460,
              }}
            >
              {footerData?.aboutus}
            </Paragraph>
          </div>
        </Col>
        <Col md={24} lg={isFull ? 6 : 12} xl={isFull ? 6 : 12} xxl={6}>
          <div style={{ padding: '0 15px' }}>
            <Title style={footerTitleStyle}>Бусад мэдээлэл</Title>
            <Paragraph>
              <ul>
                <li>
                  <Link href="/home" className="footer-link">
                    Нүүр хуудас
                  </Link>
                </li>
                <li>
                  <Link
                    href={footerData?.linkabout}
                    className="footer-link"
                    target="_blank"
                  >
                    Бидний тухай
                  </Link>
                </li>
                <li>
                  <Link href={footerData?.linknews} className="footer-link">
                    Мэдээ
                  </Link>
                </li>
                <li>
                  <Link href="/intro-video" className="footer-link">
                    Тусламж
                  </Link>
                </li>
                <li>
                  <Link
                    href={footerData?.linklegal}
                    className="footer-link"
                    target="_blank"
                  >
                    Эрх зүйн эмхэтгэл
                  </Link>
                </li>
              </ul>
            </Paragraph>
          </div>
        </Col>
        <Col md={24} lg={isFull ? 6 : 12} xl={isFull ? 6 : 12} xxl={6}>
          <div style={{ padding: '0 15px' }}>
            <Title style={footerTitleStyle}>Харилцах хаяг</Title>
            <Paragraph
              style={{
                color: '#fff',
                fontWeight: 600,
                lineHeight: '31px',
                marginBottom: 60,
              }}
            >
              Хаяг: {footerData?.address}<br/>Имэйл: {footerData?.email} <br/>Утас: {footerData?.phone} <br/>Факс: {footerData?.fax}
            </Paragraph>
          </div>
        </Col>
        <Col span={24} style={{ backgroundColor: '#022BC0' }}>
          <Row
            style={{
              height: 46,
              width: '100%',
            }}
            align="middle"
            justify="center"
          >
            <Text style={{ color: '#fff' }}>
              © {new Date().getFullYear()} www.mle.mn Бүх эрх хуулиар
              хамгаалагдсан. Smart Data LLC бүтээв.
            </Text>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default FooterLayout;
