import React, { useEffect, useState } from 'react'
import {
  Layout,
  Row,
  Col,
  Form,
  Typography,
  Divider,
  Flex,
  Image,
  Button,
  Input,
  Card,
  Space,
  InputNumber,
  Upload,
  Modal,
  Spin,
  ConfigProvider,
  DatePicker,
  Table,
  Popconfirm, 
  Empty
} from 'antd'
import {
  ArrowRightOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  UserAddOutlined,
  SearchOutlined,
  MailOutlined,
  InboxOutlined,
  DeleteOutlined
} from '@ant-design/icons'

import mnMN from 'antd/lib/locale/mn_MN'
import dayjs from 'dayjs'

import 'dayjs/locale/mn'

import FooterLayout from './../../../layouts/Footer'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import * as CONFIGS from '../../../config/Configs'
import * as WEBSERVICES from '../../../config/WebServices'

import SmartHelper from 'helper/SmartHelper'

dayjs.locale('mn')

const { Content } = Layout
const { Meta } = Card
const { Text, Title, Link } = Typography
const {Dragger} = Upload

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

const requestFormLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
}

interface ProjectJoinProps {
  isReadOnly: boolean;
}

const ProjectJoin: React.FC<ProjectJoinProps> = ({ isReadOnly }) => {
  const [feeForm] = Form.useForm()
  const [pawnForm] = Form.useForm()

  const { id: detailId } = useParams()

  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false)

  const [project, setProject] = useState({} as any)
  const [projectDocuments, setProjectDocuments] = useState([] as any)
  const [isProjectStart, setIsProjectStart] = useState(false)
  const [isProjectEnd, setIsProjectEnd] = useState(false)

  const [feeBase64Image, setFeeBase64Image] = useState(null as any);
  const [fileList, setFileList] = useState([] as any);
  const [base64Files, setBase64Files] = useState([] as any);

  const [feeDate, setFeeDate] = useState(dayjs() as any)
  const [pawnDate, setPawnDate] = useState("" as any)

  const [isShowModal, setIsShowModal] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [message, setMessage] = useState("")

  const [isAuth, setIsAuth] = useState(false)  
  const [auth, setAuth] = useState([] as any)  
  const [entity, setEntity] = useState([] as any)  
  const [participant, setParticipant] = useState([] as any)  
  const [isParticipantExist, setIsParticipantExist] = useState(false)  

  const [isEntityApproved, setIsEntityApproved] = useState(false);
  const [isParticipantApproved, setIsParticipantApproved] = useState(false);
  const [defaultFeeFile, setDefaultFeeFile] = useState("/images/icon-upload-multiple.jpg");

  const [backHome, setBackHome] = useState(false);

  const [columns, setColumns] = useState([] as any)

  useEffect(() => {
    const fetchProjectData = async () => {
      
      let url = WEBSERVICES.URL_PROJECT_DETAIL+detailId;
        
      await fetch(url)
          .then((response) => response.json())
          .then(async (responseJson) => {
            let entity = SmartHelper.getEntity();
            let project:any = null;

            setIsAuth(SmartHelper.getSession('isAuth') as boolean | false);
            setAuth(SmartHelper.getAuth());    
            setEntity(entity);

            if(responseJson && responseJson.status === true) {
              project = responseJson.data;
              let isProjectEnd = responseJson.isProjectEnd;

              setProject(responseJson.data);
              setIsProjectStart(responseJson.isProjectStart);
              setIsProjectEnd(responseJson.isProjectEnd);

              if(!isReadOnly && isProjectEnd === true) {
                setBackHome(true);
              }

              await findParticipant(project.id, entity.id);
              await findParticipantDocument(project.id, entity.id);
            } else {
              setProject({});
            }
            
            setIsEntityApproved(entity.is_active === true? true: false);

            if(!entity || !project) {
              setBackHome(true);
            } else {
              setColumns([
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  hidden: true
                },
                {
                  title: 'Файлын нэр',
                  dataIndex: 'file_name',
                  key: 'file_name',        
                  render: (text: any, record: any) => (
                    <Link
                      href={CONFIGS.URL_PROJECT_ROOT+record.file_url}            
                      target="_blank"
                    >
                      {record.file_name}
                    </Link>
                  )
                },
                {
                  title: 'Илгээсэн огноо',
                  dataIndex: 'uploaded',
                  key: 'uploaded',
                },      
                {
                  title: 'Устгах',
                  key: 'action',
                  render: (text: any, record: any) => {
                    if(!isReadOnly) {
                        return (                                    
                          <Popconfirm
                            title="Та устгахдаа итгэлтэй байна уу?"
                            onConfirm={() => handleDocumentDelete(record, project?.id, entity?.id)}
                            okText="Тийм"
                            cancelText="Үгүй"
                          >
                            <Button
                              type="primary"
                              danger
                            >
                              Устгах
                            </Button>
                          </Popconfirm>
                        
                      )
                    }
                  },
                },
              ]);
            }
          })
          .catch((error) => {
              setProject({});
              setBackHome(true);
          });       
    }    

    if(backHome) {
      navigate("/project");
      navigate(0);
    }

    fetchProjectData().then(() => {
      setLoaded(true);
    });

    
  }, [detailId, backHome, isParticipantExist])

  const findParticipant = async (projectId: any, entityId: any ) => {
    let url = WEBSERVICES.URL_PROJECT_PARTICIPANT+"?project_id="+projectId+"&entity_id="+entityId;
    
    let participant: any = null;
    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson?.status === true) {            
          participant = responseJson?.data;     

          setIsParticipantExist(true);

          if(participant.fee_date != null && participant.fee_date != "")
          {
            setFeeDate(dayjs(participant.fee_date, 'YYYY-MM-DD'))
          }
          
          if(participant.fee_file != null && participant.fee_file != "")
          {
            setDefaultFeeFile(CONFIGS.URL_PAYMENT_ROOT+participant.fee_file)
            convertImageToBase64(CONFIGS.URL_PAYMENT_ROOT_IMAGE+participant.fee_file);
          }

          setParticipant(participant);
        }
      })
      .catch((error) => {

      })
  }

  const findParticipantDocument = async (projectId: any, entityId: any ) => {
    let url = WEBSERVICES.URL_PROJECT_PARTICIPANT_DOCUMENT_FIND+"?project_id="+projectId+"&entity_id="+entityId;
    
    let documents = [];
    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson?.status === true) {            
          documents = responseJson?.data;     
          setProjectDocuments(documents);
        }
      })
      .catch((error) => {

      })
  }

  const convertImageToBase64 = async (imageUrl: any) => {
    try {
      const response = await fetch(imageUrl, {
        mode: 'cors',
      });
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onloadend = () => {
        setFeeBase64Image(reader.result);
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error converting image to base64:', error);
    }
  };

  const showMessage = (msg: string, isError: boolean) => {
    setIsShowModal(true);
    setMessage(msg);
    setIsSuccess(!isError);
  }
  
  const handleDocumentDelete = async (row: any, projectId: any, entityId: any) => {
    let url = WEBSERVICES.URL_PROJECT_PARTICIPANT_DOCUMENT_DELETE+"?document_id="+row.id+"&project_id="+projectId+"&entity_id="+entityId;
    
    await fetch(url)
      .then((response) => response.json())
      .then(async (responseJson) => {
        if(responseJson?.status === true) {            
          showMessage(`${row.file_name} амжилттай устгалаа.`, false);
        } else {
          showMessage('Устгахад алдаа гарлаа дахин оролдоно уу!', true);
        }
        await findParticipantDocument(project.id, entity.id);          
      })
      .catch((error) => {
        showMessage('Устгахад алдаа гарлаа дахин оролдоно уу!', true);
      })
  };


  const handleFeeUpload = (file: any) => {
    if (file.type.startsWith('image/')) {      
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {      
        setFeeBase64Image(reader.result);
      };
    } else {
      console.log('error');
    }
    return false;
  }


  const handleUploadDocument = async (file: any) => {
    const isPdf = file.type === "application/pdf";

    if (!isPdf) {
      showMessage(`${file.name} Заавал PDF файл сонгоно.`, true);

      return Upload.LIST_IGNORE;
    }

    try {
      const base64 = await convertFileToBase64(file);
      setBase64Files((prevFiles: any) => [...prevFiles, {uid: file.uid, data: base64, name: file.name}]);
      setFileList((prevList: any) => [...prevList, file]);

      showMessage(`${file.name} Файлыг амжилттай сонголоо.`, false);
      return false;
    } catch (error) {      
      showMessage(`${file.name} Файлыг сонгоход алдаа гарлаа.`, true);
      return Upload.LIST_IGNORE; 
    }
  }

  const convertFileToBase64 = (file: any) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject("Failed to convert file to base64");
        }
      };
      reader.onerror = () => {
        reject("Failed to read file");
      };
      reader.readAsDataURL(file);
    });
  };

  const handleRemoveDocument = (file: any) => {
    setFileList((prevList: any) => prevList.filter((item: any) => item.uid !== file.uid));
    setBase64Files((prevFiles: any) => prevFiles.filter((base64: any) => base64.uid !== file.uid));
  }

  const handleFeePayment = (date: any, dateString: string | string[]) => {
    if (date && date.isValid()) {
      setFeeDate(date);
    }
  }

  const handlePawnPayment = (date: any, dateString: string | string[]) => {
    setPawnDate(dateString);
  }

  const sendRequest = async() => {
    setLoaded(false);

    let msg = '';

    let feeDateSend = feeDate.format("YYYY-MM-DD");

    if(!feeDateSend) {
      msg += '<p>Дэнчин гүйлгээний огноо заавал оруулна.</p>';
    }

    if(!feeBase64Image) {
      msg += '<p>Дэнчин гүйлгээний баримт заавал оруулна.</p>';
    }
    
    if(msg == "") {
      try {
        const response = await fetch(WEBSERVICES.URL_PROJECT_PARTICIPANT_STORE, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            entity_id: entity.id,
            project_id: project.id,
            fee_date: feeDateSend,
            fee_file: feeBase64Image
          })
        });
  
        if (!response.ok) {
          throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
        }
  
        const result = await response.json();
        if(result.status === true) {
          let msg = '<p>Амжилттай таны хүсэлтийг илгээлээ. Та хугацаа дуусахаас өмнө мэдээллийг ахин илгээх боломжтой.</p>';
          showMessage(msg, false);

          setLoaded(true);
        } else {
          throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
        }
      } catch (error) {
        let msg = `<p>${error}</p>`;
        showMessage(msg, true);
        setLoaded(true);
      }
    } else {
      showMessage(msg, true);
      setLoaded(true);
    }
  }

  const sendProjectDocument = async() => {
    setLoaded(false);

    let msg = '';

    if(fileList.length <= 0) {
      msg += '<p>Та ямар нэгэн файл сонгоогүй байн.</p>';
    }

    if(msg == "") {
      try {
        const response = await fetch(WEBSERVICES.URL_PROJECT_DOCUMENT_STORE, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            entity_id: entity.id,
            project_id: project.id,
            project_files: base64Files,
          })
        });
  
        if (!response.ok) {
          throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
        }
  
        const result = await response.json();
        if(result.status === true) {
          let msg = '<p>Амжилттай таны хүсэлтийг илгээлээ. Та хугацаа дуусахаас өмнө мэдээллийг ахин илгээх боломжтой.</p>';
          showMessage(msg, false);
          setLoaded(true);

          await findParticipantDocument(project.id, entity.id);
        } else {
          throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
        }
      } catch (error) {
        let msg = `<p>${error}</p>`;        
        showMessage(msg, true);
        setLoaded(true);
      }
    } else {      
      showMessage(msg, true);
      setLoaded(true);
    }
  }

  const handleOk = () => {
    setIsShowModal(false);
  }

  const handleCancel = () => {
    setIsShowModal(false);
  }

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
      {
          (!loaded) &&
            <Spin size="large" fullscreen/>
        }
        <Row gutter={20}>
          <Title
            level={4}
            style={{
              color: '#393874',
              width: '100%',
              textAlign: 'center',
              textTransform: 'uppercase',
              marginBottom: 20,
            }}
          >
            ({project?.code}) дугаартай төсөл сонгон шалгаруулалтанд оролцох / {project.end_date}
          </Title>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Card style={{ marginBottom: 20 }}>
              <CheckCircleOutlined style={{ color: '#1AA752' }} />
              <Text
                style={{
                  marginLeft: 8,
                  color: '#171441',
                  fontWeight: 600,
                }}
              >
                Төсөл сонгон шалгаруулалтын мэдээлэл
              </Text>
              {isEntityApproved? (
                <>
                  <Divider style={{ marginTop: 10, borderColor: '#1AA752' }} />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: '#1AA752', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <Text style={{ color: '#1AA752', fontWeight: 400 }}>
                          Таны бүртгэл баталгаажсан байна
                        </Text>
                      }
                    />
                  </>
              ): (
                <>
                  <Divider style={{ marginTop: 10, borderColor: 'red' }} />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: 'red', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <Text style={{ color: 'red', fontWeight: 400 }}>
                          Таны бүртгэл баталгаажаагүй байна. Та хувийн мэдээлэл хэсгээр орж мэдээллээ баталгаажуулна уу.
                        </Text>
                      }
                    />
                </>
              )}
              {/*
              {isParticipantApproved? (
                <>
                  <Divider style={{ marginTop: 10, borderColor: '#1AA752' }} />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: '#1AA752', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <Text style={{ color: '#1AA752', fontWeight: 400 }}>
                          Таны оролцох хүсэлт баталгаажсан байна. Дуудлага худалдаа явагдах өдөр <strong>{project.start_date}</strong>
                        </Text>
                      }
                    />
                  </>
              ): (
                <>
                  <Divider style={{ marginTop: 10, borderColor: 'red' }} />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: 'red', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <Text style={{ color: 'red', fontWeight: 400 }}>
                          Таны оролцох хүсэлт баталгаажаагүй байна. Та баталгаажуулах мэдээллээ шинээр илгээх, засварлах боломжтой.
                        </Text>
                      }
                    />
                </>
              )}
              */}
            </Card>            
            <Row gutter={20}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              {(isEntityApproved) && (              
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Хураамжын мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <Form
                    layout={'horizontal'}
                    form={feeForm}
                    {...requestFormLayout}
                    style={{
                      maxWidth: '100%',
                    }}
                  >
                    <Form.Item
                      name="amount"
                      labelAlign="left"
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Тушаах дүн
                        </Text>
                      }
                    >
                      <Text style={{ color: '#000000' }}>{SmartHelper.formatCurrency(project.fee)}₮</Text>
                    </Form.Item>
                    <Form.Item
                      name="account_description"
                      labelAlign="left"
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Хураамж тушаах дансны мэдээлэл
                        </Text>
                      }
                    >
                      <Text style={{ color: '#000000' }}>{project.account_huraamj}</Text>
                    </Form.Item>
                    <Form.Item
                      name="description"
                      labelAlign="left"
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Гүйлгээний утга
                        </Text>
                      }
                    >
                      <Text style={{ color: '#000000' }}>
                        ДХ-{project.code}-{auth.register}
                      </Text>
                    </Form.Item>
                    <Form.Item
                      name="fee_date"
                      labelAlign="left"
                      style={{ textAlign: 'left' }}
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Гүйлгээ хийсэн огноо 
                        </Text>
                      }
                    >
                      <ConfigProvider locale={mnMN}>
                        <DatePicker onChange={handleFeePayment} value={feeDate}/>
                      </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                      name="fee_file"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      noStyle
                      wrapperCol={{ span: 24 }}                      
                    >
                      <Dragger
                        name="files"
                        multiple={false}
                        maxCount={1}
                        accept='.jpg, .png'
                        beforeUpload={handleFeeUpload}                        
                        style={{
                          backgroundColor: 'rgba(227, 231, 239, 0.5)',
                          height: 160,
                        }}
                        
                      >
                        {feeBase64Image ? (
                        <img src={feeBase64Image} alt="Uploaded" style={{ maxWidth: '100%' }} />
                        ) : (
                          <div className="ant-upload-drag-icon">
                          <Image
                            src={defaultFeeFile}
                            width={160}
                            height={160}
                            alt=""
                            preview={false}
                            style={{ marginBottom: 8 }}
                          />
                        </div>        
                        )}
                                       
                        <p
                          className="ant-upload-text"
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: '#393874',
                          }}
                        >
                          Энд дарж файлаа сонгоно уу
                        </p>
                        <p className="ant-upload-hint">
                          Хураамж тушаасан баримтын зураг (jpg, png формат байна)
                        </p>
                      </Dragger>
                    </Form.Item>
                  </Form>
                  <Divider style={{ marginTop: 10 }} />
                  {!isReadOnly && (
                    <Form
                      layout={'horizontal'}
                      {...requestFormLayout}
                      style={{
                        maxWidth: '100%',
                      }}
                    >
                      <Form.Item
                        wrapperCol={{ span: 24 }}
                        style={{ marginTop: 20 }}
                      >
                        <Space>
                          <Button
                            type="primary"
                            style={{ backgroundColor: '#1AA752', width: 250 }}
                            onClick={() => {sendRequest()}}
                          >
                            Хураамж төлсөн баримт илгээх
                          </Button>
                        </Space>
                      </Form.Item>
                    </Form>
                  )}
                  
                </Card>
              )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              {(isEntityApproved && !isReadOnly) && (              
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Төслийн файлуудаа сонгох
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <Form
                    layout={'horizontal'}
                    form={pawnForm}
                    {...requestFormLayout}
                    style={{
                      maxWidth: '100%',
                    }}
                  >
                    <Form.Item
                      name="pawn_file"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      noStyle
                      wrapperCol={{ span: 24 }}                      
                    >
                      <Dragger
                        name="files"
                        multiple={true}
                        accept='.pdf'
                        fileList={fileList}
                        beforeUpload={handleUploadDocument}       
                        onRemove={handleRemoveDocument}                 
                        style={{
                          backgroundColor: 'rgba(227, 231, 239, 0.5)',
                          height: 160,
                        }}
                        showUploadList={{
                          showRemoveIcon: true,
                          removeIcon: <DeleteOutlined />,
                        }}
                      >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">Төслийн PDF файлуудаа хавсаргана уу</p>
                          <p className="ant-upload-hint">
                            Төслийн файлууд зөвхөн PDF файлууд байна.
                          </p>
                      </Dragger>
                    </Form.Item>
                  </Form>
                  <Divider style={{ marginTop: 10 }} />                  
                  <Form
                    layout={'horizontal'}
                    {...requestFormLayout}
                    style={{
                      maxWidth: '100%',
                    }}
                  >
                    <Form.Item
                      wrapperCol={{ span: 24 }}
                      style={{ marginTop: 20 }}
                    >
                      <Space>
                        <Button
                          type="primary"
                          style={{ backgroundColor: '#1AA752', width: 250 }}
                          onClick={() => {sendProjectDocument()}}
                        >
                          Төслийн файлууд илгээх
                        </Button>
                      </Space>
                    </Form.Item>
                  </Form> 
                </Card>
              )}
              {(isEntityApproved) && (              
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Өмнө оруулсан файлууд
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  
                  <Table
                    rowKey={(record) => `id-${record.id}`}
                    pagination={false}
                    columns={columns}
                    dataSource={projectDocuments}
                    scroll={{ y: 250 }}                    
                    locale={{
                      emptyText: <Empty description="Оруулсан файл байхгүй байна" />,
                    }}
                  />
                </Card>
              )}
              </Col>  
            </Row>
            
            <div className="hide-on-md-or-less" style={{ marginTop: 100 }}>
              <FooterLayout />
            </div>
          </Col>
        </Row>
        <Modal
            title="Анхааруулга"
            open={isShowModal}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="ok" type="primary" onClick={handleOk}>
                Хаах
              </Button>,
            ]}
          >
          <div style={{color: isSuccess? 'green': 'red'}} dangerouslySetInnerHTML={{ __html: message }} />
        </Modal>
      </Content>
    </Layout>
  )
}

export default ProjectJoin
