import React, { useEffect, useState } from "react";
import SmartHelper from 'helper/SmartHelper'
import { Alert, Button, Checkbox, notification } from "antd";
import * as WEBSERVICES from '../../../config/WebServices'

const ContractIndex = (detail: any) => {

  const [detailInfo, setDetailInfo] = useState([] as any)
  const [contractInfo, setContractInfo] = useState([] as any)
  const [contractCreator, setContractCreator] = useState([] as any)
  const [entityInfo, setEntity] = useState([] as any)
  const [qrCode, setQrCode] = useState([] as any)

  const numberWithCommas = (price: any) => {
    price = price.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(price))
      price = price.replace(pattern, "$1,$2");
    return price;
  }

  useEffect(() => {
    
    detail.active === 1 ? setDetailInfo(detail.auction) : setDetailInfo(detail.project); 
    
    setContractInfo(detail.contract)
    
    setContractCreator(detail.contract.creator) 
     
    setEntity(detail.entity)    

    setQrCode(detail.qrCode?.data)     

  })  
  
  const decision_date = detailInfo?.land?.land_decision_date?.split('-');
  const select_date = parseInt(detail.active) === 1 ? detailInfo?.start_date?.split('-') : detailInfo?.selection_date?.split('-');
  const dead_date = detailInfo?.dead_date?.split('-');
  const contract_created = contractInfo?.created_date?.split('-');  
  const temp_c_a_update_year = contractInfo?.updated_at?.split('T');
  const temp_c_a_update_month = temp_c_a_update_year?.[1]?.split('.');  
 

  const c_alert_description = `Төсөл сонгон шалгаруулагчийн ялагчтай байгуулах гэрээ ${temp_c_a_update_year?.[0]} ${temp_c_a_update_month?.[0]} баталгаажсан байна `;

  const [isChecked, setIsChecked] = useState(false);

  const checkBoxStatus = (e:any) => {
    setIsChecked(e.target.checked);    
  };

  const key = 'updatable';

  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api.success({
      key,
      message: 'Мэдэгдэл',
      description: 'Ялагчтай байгуулах гэрээ амжилттай баталгаажлаа',
    });
  };

  const contractConfirm = async () =>{
    
    let url = WEBSERVICES.URL_WINNER_CONTRACT_CONFIRM + "id=" + detail.id + "&active=" + detail.active + "&entity=" + entityInfo.id

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.status === true){
          openNotification();
        }
      })
  }

  if (parseInt(detail?.active) === 1){
    return(
      <>
        {contextHolder}
        <div className="cMainSection">
          <div className="cHeader">
            <div>
              <img style={{width: '80px'}} src={`data:image/png;base64,${qrCode}`} alt="" />
            </div>
            <div className="chChild">
              <div>
                <p className="cMarginZero">
                  Дуудлага худалдааны зарын дугаар
                </p>
                {/* <p className="cMarginZero">
                  ИТХ-ын тогтоолын огноо
                </p>
                <p className="cMarginZero">
                  ИТХ-ын тогтоолын дугаар:
                </p>
                <p className="cMarginZero">
                  Газар зохион байгуулалтын төлөвлөлтийн нэгж талбарын дугаар:
                </p> */}
              </div>

              <div>
                <p className="cMarginZero">
                  <strong><u>{detailInfo?.code}</u></strong>
                </p>
                {/* <p className="cMarginZero">
                  2020.12.02
                </p>
                <p className="cMarginZero">
                  01/01
                </p>
                <p className="cMarginZero">
                  2.3.1.1
                </p> */}
              </div>
            </div>
          </div>

          <div className="cTextCenter">
            <h2> ДУУДЛАГА ХУДАЛДААНЫ ЯЛАГЧТАЙ БАЙГУУЛАХ ГЭРЭЭ </h2>
          </div>

          <div className="cHeaderDate">
            <p className="cMarginZero">
              {contract_created?.[0]} оны {contract_created?.[1]} сарын {contract_created?.[2][0]}{contract_created?.[2][1]} өдөр
            </p>
            <p className="cMarginZero">
              № 01-2021/1112
            </p>
            <p className="cMarginZero">
              {detailInfo?.admin_unit_one?.name} {detailInfo?.admin_unit_one?.code === '011' ? 'нийслэл' : 'аймаг'}
            </p>
          </div>

          <div className="cSection">
            <h3 className="cTextCenter">
              Нэг. Нийтлэг үндэслэл
            </h3>

            <div className="cSectionDetail">
              <label>
                1.1
              </label>
              <p className="cMarginZero">
                Монгол Улсын Газрын тухай хууль, Чөлөөт бүсийн тухай хууль, Засгийн газрын “Газар өмчлүүлэх, эзэмшүүлэх, ашиглуулах дуудлага худалдаа явуулах журам”, Газар зохион байгуулалт, геодези зураг зүйн газрын даргын “Газар эзэмших, ашиглах эрхийн гэрчилгээ олгох журам”, <strong><u>{detailInfo?.admin_unit_one?.name}</u></strong> /{detailInfo?.admin_unit_one?.code === '011' ? 'нийслэл' : 'аймаг'}/-ийн <strong><u>{detailInfo?.admin_unit_two?.name}</u></strong> /{detailInfo?.admin_unit_two?.au1_code === '011' ? 'дүүргийн' : 'аймгийн'}/-ийн Засаг даргын оны сарын -ны өдрийн <strong><u>{detailInfo?.land?.land_toot}</u></strong> тоот “Газар өмчлүүлэх эзэмшүүлэх, ашиглуулах эрхийн дуудлага худалдааг зохион байгуулах тухай” шийдвэрийг тус тус үндэслэн нэг талаас Газар өмчлүүлэх, эзэмшүүлэх, ашиглуулах дуудлага худалдааг зохион байгуулагч <strong>/<u>{detailInfo?.source?.name}</u>/</strong>/цаашид “Зохион байгуулагч” гэх/, нөгөө талаас дуудлага худалдаанд хамгийн өндөр үнийн санал өгч ялагчаар тодорсон  
                <strong><u> {detail?.entity?.lastname === null ? detail?.entity?.entity_name : detail?.entity?.lastname}</u></strong> {detail?.entity?.firstname === null ? '' : 'овогтой'} <strong><u>{detail?.entity?.firstname === null ? detail?.entity?.entity_name : detail?.entity?.firstname}</u></strong> РД: <strong><u>{detail?.entity?.register_number}</u></strong> /цаашид “Дуудлага худалдааны ялагч” гэх/ нар доорх нөхцөлийг харилцан тохиролцож байгуулав.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                1.2
              </label>
              <p className="cMarginZero">
                Зохион байгуулагч нь <strong><u>{select_date?.[0]}</u></strong> оны <strong><u>{select_date?.[1]}</u></strong> сарын <strong><u>{select_date?.[2]?.[0]}{select_date?.[2]?.[1]}</u></strong>-ны өдөр зохион байгуулагдсан <strong><u>{detailInfo?.admin_unit_one?.name}</u></strong> /{detailInfo?.admin_unit_one?.code === '011' ? 'нийслэл' : 'аймаг'}/-ийн <strong><u>{detailInfo?.admin_unit_two?.name}</u></strong> /{detailInfo?.admin_unit_two?.au1_code === '011' ? 'дүүргийн' : 'сумын'}/ нутагт байршилтай, <strong><u>{parseInt(detailInfo?.land?.area_m2) / 10000}га</u></strong> <strong>/<u>{numberWithCommas(parseInt(detailInfo?.land?.area_m2))}m<sup>2</sup></u>/</strong> газрыг <strong><u>{detailInfo?.landUse?.description}</u></strong> зориулалтаар дуудлага худалдааны ялагчийн <strong>/<u>{SmartHelper.getRightType(parseInt(detailInfo?.land?.land_right_type))}</u>/</strong> дуудлага худалдааны ялагч <strong><u>{detailInfo?.code}</u></strong> дугаар бүхий дуудлага худалдаагаар тогтсон газрын үнийг төлөхтэй холбогдсон харилцааг зохицуулахад гэрээний зорилго оршино.
              </p>
            </div>
          </div>

          <div className="cSection">
            <h3 className="cTextCenter">
              Хоёр. Гэрээний нөхцөл
            </h3>

            <div className="cSectionDetail">
              <label >
                2.1
              </label>
              <p className="cMarginZero">
                Газрын хэмжээ <strong><u>{numberWithCommas(parseInt(detailInfo?.land?.area_m2))}m<sup>2</sup></u></strong>
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                2.2
              </label>
              <p className="cMarginZero">
                Газрын зориулалт <strong><u>{detailInfo?.landUse?.description}</u></strong>
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                2.3
              </label>
              <p className="cMarginZero">
                Худалдан авсан үнэ: төгрөг <strong><u>{numberWithCommas(parseInt(detailInfo?.bid_amount))}</u></strong> төгрөг
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                2.4
              </label>
              <p className="cMarginZero">
                Дуудлага худалдааны төлбөрийг <strong><u>{contractInfo?.bank_name}</u></strong> алба /газар/-ны <strong><u>{contractInfo?.bank_account_name}</u></strong> (дансны нэр <strong><u>{contractInfo?.bank_account_number}</u></strong>) тоот дансанд гэрээ байгуулсан өдрөөс хойш хуанлийн <strong><u>14</u></strong> хоногийн дотор бүрэн төлөх.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                2.5
              </label>
              <p className="cMarginZero">
                Дуудлага худалдааны ялагчийн дэнчинг дуудлага худалдааны үнэ /газрын үнэ/-д оролцуулан тооцно.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                2.6
              </label>
              <p className="cMarginZero">
                Дуудлага худалдааны ялагч хүндэтгэн үзэх шалтгаангүйгээр заасан хугацаанд дуудлага худалдааны үнийг бүрэн төлөөгүй тохиолдолд тухайн дуудлага худалдааг хүчингүйд тооцож дэнчинг улсын орлого болгоно.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                2.7
              </label>
              <p className="cMarginZero">
                Дуудлага худалдааны ялагчид хүндэтгэн үзэх шалтгаан үүссэн тохиолдолд заасан хугацааг ажлын 5 хоногоор сунгаж болох бөгөөд хүндэтгэн үзэх шалтгааныг Татварын ерөнхий хуулийн 6.1.41-д зааснаар ойлгоно. Дуудлага худалдааны ялагч хүндэтгэн үзэх шалтгаан үүссэн тохиолдолд дуудлага худалдаа явуулах Ажлын хэсэгт заавал мэдэгдэх бөгөөд мэдэгдээгүй тохиолдолд хүндэтгэн үзэх шалтгаан үүссэнд тооцохгүй.
              </p>
            </div>
          </div>


          <div className="cSection">
            <h3 className="cTextCenter">
              Гурав. Зохион байгуулагчийн эрх, үүрэг
            </h3>

            <div className="cSectionDetail">
              <label >
                3.1
              </label>
              <p className="cMarginZero">
                Дуудлага худалдааны ялагч заасан хугацаанд дуудлага худалдааны үнийг бүрэн төлснөөр газар эзэмшүүлэх, ашиглуулах тухай шийдвэр гаргана.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                3.2
              </label>
              <p className="cMarginZero">
                Холбогдох хууль тогтоомжид заасан нөхцөл, журмын дагуу дуудлага худалдааны ялагчтай газар өмчлүүлэх, эзэмшүүлэх, ашиглуулах гэрээ байгуулж гэрчилгээ олгох бөгөөд ажлын 21 хоногт багтаан газрыг зохих ёсоор өмчлөгч, эзэмшигч, ашиглагчид нь хүлээлгэн өгнө.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                3.3
              </label>
              <p className="cMarginZero">
                Дуудлага худалдааны ялагч хууль тогтоомжоор заасан хугацаанд дуудлага худалдааны үнийг бүрэн төлөөгүй тохиолдолд Зохион байгуулагч нь гэрээг дангаар цуцална.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                3.4
              </label>
              <p className="cMarginZero">
                Дуудлага худалдаа явагдсан газрын нэгж талбар бүрт хувийн хэрэг нээж, холбогдох баримт бичгүүдийн хамт архивт хадгална.
              </p>
            </div>
          </div>

          <div className="cSection">
            <h3 className="cTextCenter">
              Дөрөв. Төслийн ялагчийн эрх, үүрэг
            </h3>

            <div className="cSectionDetail">
              <label >
                4.1
              </label>
              <p className="cMarginZero">
                Дуудлага худалдааны ялагч нь дуудлага худалдаагаар тогтсон газрын үнэ болох <strong><u>{numberWithCommas(parseInt(detailInfo?.bid_amount))}</u></strong> төгрөгөөс дэнчингийн үнэ болох <strong><u>{numberWithCommas(parseInt(detailInfo?.pawn))}</u></strong> төгрөгийг хасч <strong><u>{numberWithCommas(parseInt(detailInfo?.bid_amount)-parseInt(detailInfo?.pawn))}</u></strong> -ийг <strong><u>{contractInfo?.bank_account_number_two}</u></strong> тоот дансанд гэрээ байгуулсан өдрөөс хойш <strong><u>14</u></strong> хоногийн дотор буюу өдрийн дотор бүрэн төлнө.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                4.2
              </label>
              <p className="cMarginZero">
                Дуудлага худалдааны ялагч нь газрын үнийг бүрэн төлснөөр өөрийн газар өмчлөх, эзэмших, ашиглах эрхээ бусад этгээдэд шилжүүлж болох бөгөөд шилжүүлэн авах этгээд нь ялагчийн эрх, үүрэг, хариуцлагыг хүлээн зөвшөөрч байгааг бичгээр нотолсон байна.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                4.3
              </label>
              <p className="cMarginZero">
                Дуудлага худалдааны ялагч заасан хугацаанд дуудлага худалдааны үнийг бүрэн төлснөөр тухайн шатны Засаг дарга газар өмчлүүлэх, эзэмшүүлэх, ашиглуулах, Чөлөөт бүсийн захирагч газар эзэмшүүлэх, ашиглуулах тухай гаргуулан авах эрхтэй.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                4.4
              </label>
              <p className="cMarginZero">
                Тухайн газрын кадастрын зураг болон холбогдох бусад бичиг баримтыг дуудлага худалдаа зохион байгуулагчаас гаргуулан авах эрхтэй.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                4.5
              </label>
              <p className="cMarginZero">
                Үйлдвэрлэл, үйлчилгээ явуулах зориулалтаар газар эзэмших эрх авсан иргэн, аж ахуйн нэгж, байгууллага нь 90 хоногт багтаан байгаль орчны нөлөөллийн үнэлгээг хийлгэсний дараа тэдгээртэй газар эзэмших гэрээ байгуулж, эрхийн гэрчилгээ олгон, улсын бүртгэлд бүртгэнэ.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                4.6
              </label>
              <p className="cMarginZero">
                Шаардлагатай тохиолдолд газар өмчлүүлэх, эзэмшүүлэх, ашиглуулах гэрээний нөхцөлд тухайн газрыг газар зохион байгуулалтын төлөвлөгөөнд заасны дагуу ашиглахтай холбогдсон зураг төслийг 6 сарын дотор багтаан боловсруулж ирүүлнэ.
              </p>
            </div>
            
          </div>

          <div className="cSection">
            <h3 className="cTextCenter">
              Тав.  Бусад зүйл
            </h3>

            <div className="cSectionDetail">
              <label >
                5.1
              </label>
              <p className="cMarginZero">
                Талууд гэрээнд гарын үсэг зурж, тамга тэмдэг дарснаар хүчин төгөлдөр болох ба эх хувьд засварласан буюу бусад аргаар оруулсан аливаа нэмэлт, өөрчлөлтийг хүчингүйд тооцно.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                5.2
              </label>
              <p className="cMarginZero">
                Энэ гэрээгээр зохицуулагдаагүй харилцааг Монгол Улсын Иргэний хуулийн холбогдох зүйл, заалтаар зохицуулна.
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                5.3
              </label>
              <p className="cMarginZero">
                Гэрээний аль нэг заалт хүчин төгөлдөр бус болох нь гэрээ бүхэлдээ хүчин төгөлдөр бус болох үндэслэл болохгүй
              </p>
            </div>

            <div className="cSectionDetail">
              <label >
                5.4
              </label>
              <p className="cMarginZero">
                Талуудын хооронд Гэрээнээс үүдэн гарсан аливаа маргааныг харилцан зөвшилцөх замаар, шийдвэрлэх бөгөөд зөвшилцөлд эс хүрвэл маргааныг Монгол Улсын шүүхээр шийдвэрлүүлнэ.
              </p>
            </div>
          </div>

          <div className="cSection">
            <h3 className="cTextCenter">
              Гэрээ байгуулсан
            </h3>

            <div className="cSectionDetail cWrap">
              <div className="cWidth50">
                <h4 className="cTextCenter">
                  Зохион байгуулагчийг төлөөлж:
                </h4>
                <p className="cMarginZero">
                  <strong><u>{contractCreator?.c_orgName}</u></strong> -ны Дарга <strong><u>{contractCreator?.c_DirectorLast}</u></strong> Овогтой <strong><u>{contractCreator?.c_DirectorFirst}</u></strong> /Гарын үсэг/ _________________________ Тамга
                </p>

                <p className="cMarginZero">
                  <strong><u>{contractCreator?.c_type}</u></strong>: <strong><u>{contractCreator?.c_last}</u></strong> овогтой <strong><u>{contractCreator?.c_first}</u></strong> /Гарын үсэг/ ________________________________
                </p>

                <p className="cMarginZero">
                  Утас: <strong><u>{contractCreator?.c_phone}</u></strong>
                </p>
              </div>
              <div className="cWidth50">
                <h4 className="cTextCenter">
                  Төслийн ялагчийг төлөөлж:
                </h4>
                {
                  entityInfo.entity_type === 'citizen_with_legal_capacity' ?

                    <div>
                      <p className="cMarginZero">
                        {entityInfo.legal_name} иргэн {entityInfo.lastname} овогтой {entityInfo.firstname} /Гарын үсэг/ _________________________________ /тамга/
                      </p>

                      <p className="cMarginZero">
                        Регистрийн №: {entityInfo.register_number}
                      </p>
                      <p className="cMarginZero">
                        Хаяг: {detailInfo?.w_e_address?.au1.name} {detailInfo?.w_e_address?.au2.au1_code === '011' ? 'хот' : 'аймаг'} {detailInfo?.w_e_address?.au2.name} {detailInfo?.w_e_address?.au1.au2_code === '011' ? 'сум' : 'дүүрэг'} {detailInfo?.w_e_address?.au3?.name} {entityInfo.street} {entityInfo.building} {entityInfo.door} {entityInfo.address_desc}
                      </p>
                      <p className="cMarginZero">
                        Утас: {entityInfo?.phone_number}, {entityInfo?.home_number}
                      </p>
                    </div>

                    :
                    <div>
                      <p className="cMarginZero">
                        {entityInfo.legal_name} хуулийн этгээд  /Гарын үсэг/ _________________________________ /тамга/
                      </p>

                      <p className="cMarginZero">
                        Регистрийн №: {entityInfo.register_number}
                      </p>
                      <p className="cMarginZero">
                        Хаяг: {detailInfo?.w_e_address?.au1.name} {detailInfo?.w_e_address?.au2.au1_code === '011' ? 'хот' : 'аймаг'} {detailInfo?.w_e_address?.au2.name} {detailInfo?.w_e_address?.au1.au2_code === '011' ? 'сум' : 'дүүрэг'} {detailInfo?.w_e_address?.au3?.name} {entityInfo.street} {entityInfo.building} {entityInfo.door} {entityInfo.address_desc}
                      </p>
                      <p className="cMarginZero">
                        Утас: {entityInfo?.phone_number}, {entityInfo?.home_number}
                      </p>
                    </div>
                }
              </div>
            </div>
          </div>

          <div className="cSection">
            <h3 className="cTextCenter">
              Гэрээ баталгаажуулах
            </h3>

            {contractInfo?.auction_winner_signature === true ?
              <Alert
                style={{ textAlign: "justify" }}
                message="Мэдэгдэл"
                description={c_alert_description}
                type="success"
                showIcon
              />
              :
              <div className="cSectionDetail" style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Checkbox onChange={checkBoxStatus} style={{ display: 'flex', alignItems: 'center' }}  >
                  <h4>Энэхүү гэрээг хүлээн зөвшөөрч байна</h4>
                </Checkbox>
                <Button onClick={contractConfirm} disabled={!isChecked} type="primary" >
                  Баталгаажуулах
                </Button>
              </div>
            }


          </div>

        </div>
      </>
    )
  }else{
    return (
      <>
        {contextHolder}
        {parseInt(contractInfo.contract_type) === 1 ?
          <div className="cMainSection">
            
            <div className="cHeader">
              <div>
                <img style={{ width: '80px' }} src={`data:image/png;base64,${qrCode}`} alt="" />
              </div>
              <div className="chChild">
                <div>
                  <p className="cMarginZero">
                    Төсөл сонгон шалгаруулалтын зарын дугаар:
                  </p>
                  {/* <p className="cMarginZero">
                  ИТХ-ын тогтоолын огноо
                </p>
                <p className="cMarginZero">
                  ИТХ-ын тогтоолын дугаар:
                </p>
                <p className="cMarginZero">
                  Газар зохион байгуулалтын төлөвлөлтийн нэгж талбарын дугаар:
                </p> */}
                </div>

                <div>
                  <p className="cMarginZero">
                    <strong><u>{detailInfo?.code}</u></strong>
                  </p>
                  {/* <p className="cMarginZero">
                  2020.12.02
                </p>
                <p className="cMarginZero">
                  01/01
                </p>
                <p className="cMarginZero">
                  2.3.1.1
                </p> */}
                </div>
              </div>

              {/* <div>
                <p className="cMarginZero">
                  Төсөл сонгон шалгаруулалтын зарын дугаар:
                </p>
                <p className="cMarginZero">
                  ИТХ-ын тогтоолын огноо,
                </p>
                <p className="cMarginZero">
                  ИТХ-ын тогтоолын дугаар:
                </p>
                <p className="cMarginZero">
                  Газар зохион байгуулалтын төлөвлөгөөний дугаар:
                </p>
              </div>
  
              <div>
                <p className="cMarginZero">
                  <strong><u>{detailInfo?.code}</u></strong>
                </p>
                <p className="cMarginZero">
                  2020.12.02
                </p>
                <p className="cMarginZero">
                  01/01
                </p>
                <p className="cMarginZero">
                  2.3.1.1
                </p>
              </div> */}
            </div>
  
            <div className="cTextCenter">
              <h2> ТӨСӨЛ СОНГОН ШАЛГАРУУЛАЛТЫН ЯЛАГЧТАЙ БАЙГУУЛАХ ГЭРЭЭ </h2>
            </div>
  
            <div className="cHeaderDate">
              <p className="cMarginZero">
                {contract_created?.[0]} оны {contract_created?.[1]} сарын {contract_created?.[2]} өдөр
              </p>
              <p className="cMarginZero">
                № 01-2021/1111
              </p>
              <p className="cMarginZero">
                {detailInfo?.admin_unit_one?.name} {detailInfo?.admin_unit_one?.code === '011' ? 'нийслэл' : 'аймаг'}
              </p>
            </div>
  
            <div className="cSection">
              <h3 className="cTextCenter">
                Нэг. Нийтлэг үндэслэл
              </h3>
  
              <div className="cSectionDetail">
                <label>
                  1.1
                </label>
                <p className="cMarginZero">
                  Монгол Улсын Газрын тухай хууль, Засгийн газрын “Газар эзэмшүүлэх, ашиглуулах төсөл сонгон шалгаруулах журам”, Газар зохион байгуулалт, геодези зураг зүйн газрын даргын “Газар эзэмших, ашиглах эрхийн гэрчилгээ олгох журам”, <strong><u>{detailInfo?.admin_unit_one?.name}</u></strong> /{detailInfo?.admin_unit_one?.code === '011' ? 'нийслэл' : 'аймаг'}/-ийн <strong><u>{detailInfo?.admin_unit_two?.name}</u></strong> /{detailInfo?.admin_unit_two?.au1_code === '011' ? 'дүүргийн' : 'аймгийн'}/-ийн Засаг даргын <strong><u>{decision_date?.[0]}</u></strong> оны <strong><u>{decision_date?.[1]}</u></strong> сарын <strong><u>{decision_date?.[2]}</u></strong>-ны өдрийн <strong><u>{detailInfo?.land?.land_decision_toot}</u></strong> тоот “Газар эзэмшүүлэх, ашиглуулах төсөл сонгон шалгаруулалт зохион байгуулах тухай” шийдвэрийг тус тус үндэслэн нэг талаас Газар эзэмшүүлэх, ашиглуулах төсөл сонгон шалгаруулалт зохион байгуулагч <strong>/<u>{detailInfo?.source?.pro_source}</u>/</strong> цаашид “Зохион байгуулагч” гэх/, нөгөө талаас төсөл сонгон шалгаруулалтад төсөл нь шалгарсан /цаашид “Төслийн ялагч” гэх/ нар доорх нөхцөлийг харилцан тохиролцож байгуулав.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  1.2
                </label>
                <p className="cMarginZero">
                  Зохион байгуулагч нь <strong><u>{select_date?.[0]}</u></strong> оны <strong><u>{select_date?.[1]}</u></strong> сарын <strong><u>{select_date?.[2]?.[0]}{select_date?.[2]?.[1]}</u></strong> -ны өдөр зохион байгуулагдсан <strong><u>{detailInfo?.admin_unit_one?.name}</u></strong> /{detailInfo?.admin_unit_one?.code === '011' ? 'нийслэл' : 'аймаг'}/-ийн <strong><u>{detailInfo?.admin_unit_two?.name}</u></strong> сум /{detailInfo?.admin_unit_two?.au1_code === '011' ? 'дүүргийн' : 'аймгийн'}/-ийн нутагт байршилтай, <strong><u>{parseInt(detailInfo?.land?.area_m2) / 10000}га</u></strong> <strong>/<u>{numberWithCommas(parseInt(detailInfo?.land?.area_m2))}m<sup>2</sup></u>/</strong> газрыг <strong><u>{detailInfo?.landUse?.description}</u></strong> зориулалтаар төслийн ялагчийн <strong>/<u>{SmartHelper.getRightType(parseInt(detailInfo?.land?.land_right_type))}</u>/</strong>-д шилжүүлэх харилцааг зохицуулахад гэрээний зорилго оршино.
                </p>
              </div>
            </div>
  
            <div className="cSection">
              <h3 className="cTextCenter">
                Хоёр. Гэрээний нөхцөл
              </h3>
  
              <div className="cSectionDetail">
                <label >
                  2.1
                </label>
                <p className="cMarginZero">
                  Газрын хэмжээ <strong><u>{numberWithCommas(parseInt(detailInfo?.land?.area_m2))}m<sup>2</sup></u></strong>
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  2.2
                </label>
                <p className="cMarginZero">
                  Газрын зориулалт <strong><u>{detailInfo?.landUse?.description}</u></strong>
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  2.3
                </label>
                <p className="cMarginZero">
                  Газрын байршил <strong><u>{detailInfo?.address} {detailInfo?.land?.land_address_detail}</u></strong>
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  2.4
                </label>
                <p className="cMarginZero">
                  Төслийн ялагчид хүндэтгэн үзэх шалтгаан үүссэн тохиолдолд заасан хугацааг ажлын 5 хоногоор сунгаж болох бөгөөд хүндэтгэн үзэх шалтгааныг Татварын ерөнхий хуулийн 6.1.41-д зааснаар ойлгоно. Төслийн ялагч хүндэтгэн үзэх шалтгаан үүссэн тохиолдолд төсөл сонгон шалгаруулалт зохион байгуулах Ажлын хэсэгт заавал мэдэгдэх бөгөөд мэдэгдээгүй тохиолдолд хүндэтгэн үзэх шалтгаан үүссэнд тооцохгүй.
                </p>
              </div>
            </div>
  
  
            <div className="cSection">
              <h3 className="cTextCenter">
                Гурав. Зохион байгуулагчийн эрх, үүрэг
              </h3>
  
              <div className="cSectionDetail">
                <label >
                  3.1
                </label>
                <p className="cMarginZero">
                  Холбогдох хууль тогтоомжид заасан нөхцөл, журмын дагуу төсөл сонгон шалгаруулалтын ялагчтай газар эзэмшүүлэх, ашиглуулах гэрээ байгуулж, гэрчилгээ олгох бөгөөд ажлын 21 өдөрт багтаан тухайн газрын эзэмшигч, ашиглагчид газрыг
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  3.2
                </label>
                <p className="cMarginZero">
                  Төслийн ялагч нь хууль тогтоомжоор заасан хугацаанд гэрээ байгуулаагүй тохиолдолд Зохион байгуулагч нь гэрээг дангаар цуцлаж, төсөл сонгон шалгаруулалтаар шалгарсан тухай төсөл сонгон шалгаруулах комиссын шийдвэрийг хүчингүй болгоно.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  3.3
                </label>
                <p className="cMarginZero">
                  Төсөл сонгон шалгаруулалт зохион байгуулагдсан газрын нэгж талбарын хувийн хэрэг нээж, холбогдох баримт бичгүүдийн хамт архивт хадгална.
                </p>
              </div>
            </div>
  
            <div className="cSection">
              <h3 className="cTextCenter">
                Дөрөв. Төслийн ялагчийн эрх, үүрэг
              </h3>
  
              <div className="cSectionDetail">
                <label >
                  4.1
                </label>
                <p className="cMarginZero">
                  Төслийн ялагч нь тухайн шатны Засаг даргаас газар ашиглуулах тухай гаргуулан авах эрхтэй.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  4.2
                </label>
                <p className="cMarginZero">
                  Тухайн газрын кадастрын зураг болон холбогдох бусад бичиг баримтыг төсөл сонгон шалгаруулалт зохион байгуулагчаас гаргуулан авах эрхтэй.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  4.3
                </label>
                <p className="cMarginZero">
                  Үйлдвэрлэл, үйлчилгээ явуулах зориулалтаар газар эзэмших эрх авсан иргэн, аж ахуйн нэгж, байгууллага нь 90 хоногт багтаан байгаль орчны нөлөөллийн үнэлгээг хийлгэсний дараа тэдгээртэй газар эзэмших гэрээ байгуулж, эрхийн гэрчилгээ олгон, улсын бүртгэлд бүртгэнэ.
                </p>
              </div>
            </div>
  
            <div className="cSection">
              <h3 className="cTextCenter">
                Тав.  Бусад зүйл
              </h3>
  
              <div className="cSectionDetail">
                <label >
                  5.1
                </label>
                <p className="cMarginZero">
                  Талууд гэрээнд гарын үсэг зурж, тамга тэмдэг дарснаар хүчин төгөлдөр болох ба эх хувьд засварласан буюу бусад аргаар оруулсан аливаа нэмэлт, өөрчлөлтийг хүчингүйд тооцно.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  5.2
                </label>
                <p className="cMarginZero">
                  Энэ гэрээгээр зохицуулагдаагүй харилцааг Монгол Улсын Иргэний хуулийн холбогдох зүйл, заалтаар зохицуулна.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  5.3
                </label>
                <p className="cMarginZero">
                  Гэрээний аль нэг заалт хүчин төгөлдөр бус болох нь гэрээ бүхэлдээ хүчин төгөлдөр бус болох үндэслэл болохгүй.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  5.4
                </label>
                <p className="cMarginZero">
                  Талуудын хооронд Гэрээнээс үүдэн гарсан аливаа маргааныг харилцан зөвшилцөх замаар, шийдвэрлэх бөгөөд зөвшилцөлд эс хүрвэл маргааныг Монгол Улсын шүүхээр шийдвэрлүүлнэ.
                </p>
              </div>
            </div>
  
            <div className="cSection">
              <h3 className="cTextCenter">
                Гэрээ байгуулсан
              </h3>```````````````````````````````` 

  
              <div className="cSectionDetail cWrap">
                <div className="cWidth50">
                  <h4 className="cTextCenter">
                    Зохион байгуулагчийг төлөөлж:
                  </h4>
                  <p className="cMarginZero">
                    <strong><u>{contractCreator?.c_orgName}</u></strong> -ны Дарга <strong><u>{contractCreator?.c_DirectorLast}</u></strong> Овогтой <strong><u>{contractCreator?.c_DirectorFirst}</u></strong> /Гарын үсэг/ _________________________ Тамга
                  </p>

                  <p className="cMarginZero">
                    <strong><u>{contractCreator?.c_type}</u></strong>: <strong><u>{contractCreator?.c_last}</u></strong> овогтой <strong><u>{contractCreator?.c_first}</u></strong> /Гарын үсэг/ ________________________________
                  </p>

                  <p className="cMarginZero">
                    Утас: <strong><u>{contractCreator?.c_phone}</u></strong>
                  </p>
                </div>
                <div className="cWidth50">
                  <h4 className="cTextCenter">
                    Төслийн ялагчийг төлөөлж:
                  </h4>
                  {
                    entityInfo.entity_type === 'citizen_with_legal_capacity' ?
  
                      <div>
                        <p className="cMarginZero">
                          {entityInfo.legal_name} иргэн {entityInfo.lastname} овогтой {entityInfo.firstname} /Гарын үсэг/ _________________________________ /тамга/
                        </p>
  
                        <p className="cMarginZero">
                          Регистрийн №: {entityInfo.register_number}
                        </p>
                        <p className="cMarginZero">
                          Хаяг: {detailInfo?.w_e_address?.au1.name} {detailInfo?.w_e_address?.au2.au1_code === '011' ? 'хот' : 'аймаг'} {detailInfo?.w_e_address?.au2.name} {detailInfo?.w_e_address?.au1.au2_code === '011' ? 'сум' : 'дүүрэг'} {detailInfo?.w_e_address?.au3?.name} {entityInfo.street} {entityInfo.building} {entityInfo.door} {entityInfo.address_desc}
                        </p>
                        <p className="cMarginZero">
                          Утас: {entityInfo?.phone_number}, {entityInfo?.home_number}
                        </p>
                      </div>
  
                      :
                      <div>
                        <p className="cMarginZero">
                          {entityInfo.legal_name} хуулийн этгээд  /Гарын үсэг/ _________________________________ /тамга/
                        </p>

                        <p className="cMarginZero">
                          Регистрийн №: {entityInfo.register_number}
                        </p>
                        <p className="cMarginZero">
                          Хаяг: {detailInfo?.w_e_address?.au1.name} {detailInfo?.w_e_address?.au2.au1_code === '011' ? 'хот' : 'аймаг'} {detailInfo?.w_e_address?.au2.name} {detailInfo?.w_e_address?.au1.au2_code === '011' ? 'сум' : 'дүүрэг'} {detailInfo?.w_e_address?.au3?.name} {entityInfo.street} {entityInfo.building} {entityInfo.door} {entityInfo.address_desc}
                        </p>
                        <p className="cMarginZero">
                          Утас: {entityInfo?.phone_number}, {entityInfo?.home_number}
                        </p>
                      </div>
                  }
                </div>
              </div>
            </div>
  
            <div className="cSection">
              <h3 className="cTextCenter">
                Гэрээ баталгаажуулах
              </h3>
  
              {contractInfo?.project_winner_signature === true ?
                <Alert
                  style={{ textAlign: "justify" }}
                  message="Мэдэгдэл"
                  description={c_alert_description}
                  type="success"
                  showIcon
                />
                :
                <div className="cSectionDetail" style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Checkbox onChange={checkBoxStatus} style={{ display: 'flex', alignItems: 'center' }}  >
                    <h4>Энэхүү гэрээг хүлээн зөвшөөрч байна</h4>
                  </Checkbox>
                  <Button onClick={contractConfirm} disabled={!isChecked} type="primary" >
                    Баталгаажуулах
                  </Button>
                </div>
              }
  
  
            </div>
  
          </div>
          :
          <div className="cMainSection">
            <div className="cHeader">

              <div>
                <img style={{ width: '80px' }} src={`data:image/png;base64,${qrCode}`} alt="" />
              </div>
              <div className="chChild">
                <div>
                  <p className="cMarginZero">
                    Төсөл сонгон шалгаруулалтын зарын дугаар:
                  </p>
                  {/* <p className="cMarginZero">
                  ИТХ-ын тогтоолын огноо
                </p>
                <p className="cMarginZero">
                  ИТХ-ын тогтоолын дугаар:
                </p>
                <p className="cMarginZero">
                  Газар зохион байгуулалтын төлөвлөлтийн нэгж талбарын дугаар:
                </p> */}
                </div>

                <div>
                  <p className="cMarginZero">
                    <strong><u>{detailInfo?.code}</u></strong>
                  </p>
                  {/* <p className="cMarginZero">
                  2020.12.02
                </p>
                <p className="cMarginZero">
                  01/01
                </p>
                <p className="cMarginZero">
                  2.3.1.1
                </p> */}
                </div>
              </div>
              
              {/* <div>
                
                <p className="cMarginZero">
                  Төсөл сонгон шалгаруулалтын зарын дугаар:
                </p>
                <p className="cMarginZero">
                  Чөлөөт бүс, Тусгай хамгаалалттай нутгийн:
                </p>
                <p className="cMarginZero">
                  Удирдлагын газрын тогтоолын огноо, дугаар
                </p>
                <p className="cMarginZero">
                  Газар зохион байгуулалтын төлөвлөгөөний дугаар:
                </p>
              </div>
  
              <div>
                <p className="cMarginZero">
                  <u>{detailInfo?.code}</u>
                </p>
                <p className="cMarginZero">
                  565
                </p>
                <p className="cMarginZero">
                  01/01
                </p>
                <p className="cMarginZero">
                  2.3.1.1
                </p>
              </div> */}
            </div>
  
            <div className="cTextCenter">
              <h2> ТӨСӨЛ СОНГОН ШАЛГАРУУЛАЛТЫН ЯЛАГЧТАЙ БАЙГУУЛАХ ГЭРЭЭ <br /> (ЧӨЛӨӨТ БҮС, ТУСГАЙ ХАМГААЛАЛТТАЙ ГАЗАР НУТАГ)
              </h2>
            </div>
  
            <div className="cHeaderDate">
              <p className="cMarginZero">
                {contract_created?.[0]} оны {contract_created?.[1]} сарын {contract_created?.[2]} өдөр
              </p>
              <p className="cMarginZero">
                № 01-2021/1111
              </p>
              <p className="cMarginZero">
                {detailInfo?.admin_unit_one?.name} {detailInfo?.admin_unit_one?.code === '011' ? 'нийслэл' : 'аймаг'}
              </p>
            </div>
  
            <div className="cSection">
              <h3 className="cTextCenter">
                Нэг. Нийтлэг үндэслэл
              </h3>
  
              <div className="cSectionDetail">
                <label>
                  1.1
                </label>
                <p className="cMarginZero">
                  Монгол Улсын Газрын тухай хууль, Засгийн газрын “Газар эзэмшүүлэх, ашиглуулах төсөл сонгон шалгаруулах журам”, Газар зохион байгуулалт, геодези зураг зүйн газрын даргын “Газар эзэмших, ашиглах эрхийн гэрчилгээ олгох журам”, <strong><u>{detailInfo?.admin_unit_one?.name}</u></strong> /{detailInfo?.admin_unit_one?.code === '011' ? 'нийслэл' : 'аймаг'}/-ийн <strong><u>{detailInfo?.admin_unit_two?.name}</u></strong> /{detailInfo?.admin_unit_two?.au1_code === '011' ? 'дүүргийн' : 'аймгийн'}/-ийн Засаг даргын <strong><u>{decision_date?.[0]}</u></strong> оны <strong><u>{decision_date?.[1]}</u></strong> сарын <strong><u>{decision_date?.[2]}</u></strong>-ны өдрийн <strong><u>{detailInfo?.land?.land_decision_toot}</u></strong> тоот “Газар эзэмшүүлэх, ашиглуулах төсөл сонгон шалгаруулалт зохион байгуулах тухай” шийдвэрийг тус тус үндэслэн нэг талаас Газар эзэмшүүлэх, ашиглуулах төсөл сонгон шалгаруулалт зохион байгуулагч <strong><u>{detailInfo?.source?.pro_source}</u></strong> /цаашид “Зохион байгуулагч” гэх/, нөгөө талаас төсөл сонгон шалгаруулалтад төсөл нь шалгарсан /цаашид “Төслийн ялагч” гэх/ нар доорх нөхцөлийг харилцан тохиролцож байгуулав.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  1.2
                </label>
                <p className="cMarginZero">
                  Зохион байгуулагч нь <strong><u>{select_date?.[0]}</u></strong> оны <strong><u>{select_date?.[1]}</u></strong> сарын <strong><u>{select_date?.[2]?.[0]}{select_date?.[2]?.[1]}</u></strong>-ны өдөр зохион байгуулагдсан <strong><u>{detailInfo?.admin_unit_one?.name}</u></strong> /{detailInfo?.admin_unit_one?.code === '011' ? 'нийслэл' : 'аймаг'}/-ийн <strong><u>{detailInfo?.admin_unit_two?.name}</u></strong> сум /{detailInfo?.admin_unit_two?.au1_code === '011' ? 'дүүргийн' : 'аймгийн'}/-ийн нутагт байршилтай, <strong><u>{parseInt(detailInfo?.land?.area_m2) / 10000}га</u></strong> <strong>/<u>{numberWithCommas(parseInt(detailInfo?.land?.area_m2))}m<sup>2</sup></u>/</strong> газрыг <strong><u>{detailInfo?.landUse?.description}</u></strong> зориулалтаар төслийн ялагчийн <strong>/<u>{SmartHelper.getRightType(parseInt(detailInfo?.land?.land_right_type))}</u>/</strong>-д шилжүүлэх төслийн ялагч Чөлөөт бүсийн тухай хуулийн 22.5, Улсын тэмдэгтийн хураамжийн тухай хуулийн 39.1.11-д заасан төлбөрийг төлөхтэй холбогдсон харилцааг зохицуулахад гэрээний зорилго оршино.
                </p>
              </div>
            </div>
  
            <div className="cSection">
              <h3 className="cTextCenter">
                Хоёр. Гэрээний нөхцөл
              </h3>
  
              <div className="cSectionDetail">
                <label >
                  2.1
                </label>
                <p className="cMarginZero">
                  Газрын хэмжээ <strong><u>{numberWithCommas(parseInt(detailInfo?.land?.area_m2))}m<sup>2</sup></u></strong>
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  2.2
                </label>
                <p className="cMarginZero">
                  Газрын зориулалт <strong><u>{detailInfo?.landUse?.description}</u></strong>
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  2.3
                </label>
                <p className="cMarginZero">
                  Газрын байршил <strong><u>{detailInfo?.address} {detailInfo?.land?.land_address_detail}</u></strong>
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  2.4
                </label>
                <p className="cMarginZero">
                  Төслийн ялагч холбогдох хуульд заасан төлбөрийг <strong><u>{contractInfo?.bank_id}</u></strong> алба /газар/-ны <strong><u>{contractInfo?.bank_account_name}</u></strong> (дансны нэр <strong><u>{contractInfo?.bank_account_number}</u></strong> ) тоот дансанд гэрээ байгуулсан өдрөөс хойш 14 хоногийн дотор бүрэн төлөх.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  2.5
                </label>
                <p className="cMarginZero">
                  Төслийн ялагч хүндэтгэн үзэх шалтгаангүйгээр заасан хугацаанд төлбөрийг бүрэн төлөөгүй тохиолдолд тухайн тухайн төслийг хүчингүйд тооцно.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  2.6
                </label>
                <p className="cMarginZero">
                  Төслийн ялагчид хүндэтгэн үзэх шалтгаан үүссэн тохиолдолд заасан хугацааг ажлын 5 хоногоор сунгаж болох бөгөөд хүндэтгэн үзэх шалтгааныг Татварын ерөнхий хуулийн 6.1.41-д зааснаар ойлгоно. Төслийн ялагч хүндэтгэн үзэх шалтгаан үүссэн тохиолдолд төсөл сонгон шалгаруулалт зохион байгуулах Ажлын хэсэгт заавал мэдэгдэх бөгөөд мэдэгдээгүй тохиолдолд хүндэтгэн үзэх шалтгаан үүссэнд тооцохгүй.
                </p>
              </div>
            </div>
  
  
            <div className="cSection">
              <h3 className="cTextCenter">
                Гурав. Зохион байгуулагчийн эрх, үүрэг
              </h3>
  
              <div className="cSectionDetail">
                <label >
                  3.1
                </label>
                <p className="cMarginZero">
                  Төслийн ялагч заасан хугацаанд холбогдох төлбөрийг бүрэн төлснөөр газар эзэмшүүлэх, ашиглуулах тухай шийдвэр гаргана.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  3.2
                </label>
                <p className="cMarginZero">
                  Холбогдох хууль тогтоомжид заасан нөхцөл, журмын дагуу төсөл сонгон шалгаруулалтын ялагчтай газар эзэмшүүлэх, ашиглуулах гэрээ байгуулж, гэрчилгээ олгох бөгөөд ажлын 21 өдөрт багтаан тухайн газрын эзэмшигч, ашиглагчид газрыг актаар хүлээлгэн өгнө.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  3.3
                </label>
                <p className="cMarginZero">
                  Төслийн ялагч нь хууль тогтоомжоор заасан хугацаанд төлбөрийг төлөөгүй тохиолдолд Зохион байгуулагч нь гэрээг дангаар цуцлаж, төсөл сонгон шалгаруулалтаар шалгарсан тухай төсөл сонгон шалгаруулах комиссын шийдвэрийг хүчингүй болгоно.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  3.4
                </label>
                <p className="cMarginZero">
                  Төсөл сонгон шалгаруулалт зохион байгуулагдсан газрын нэгж талбарын хувийн хэрэг нээж, холбогдох баримт бичгүүдийн хамт архивт хадгална.
                </p>
              </div>
            </div>
  
            <div className="cSection">
              <h3 className="cTextCenter">
                Дөрөв. Төслийн ялагчийн эрх, үүрэг
              </h3>
  
              <div className="cSectionDetail">
                <label >
                  4.1
                </label>
                <p className="cMarginZero">
                  Төслийн ялагч нь газрын үнэ болох болох <strong><u>{numberWithCommas(parseInt(contractInfo?.winner_land_price))}</u>₮</strong> төгрөг /мөнгөн дүн бичгээр бичих/-ийг <strong><u>{contractInfo?.bank_account_number_two}</u></strong> тоот дансанд гэрээ байгуулсан өдрөөс хойш 14 хоногийн дотор буюу <strong><u>{dead_date?.[0]}</u></strong> оны <strong><u>{dead_date?.[1]}</u></strong> дугаар сарын <strong><u>{dead_date?.[2]}</u></strong>-ний өдрийн дотор бүрэн төлнө.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  4.2
                </label>
                <p className="cMarginZero">
                  Төслийн ялагч нь газрын үнийг бүрэн төлснөөр Чөлөөт бүсийн захирагчаас газар эзэмшүүлэх, ашиглуулах, Байгаль орчны сайдын газар ашиглуулах тухай шийдвэрийг үндэслэн Байгаль орчны яамны Тусгай хамгаалалттай нутгийн удирдлагын газрын даргаас газар ашиглуулах тухай гаргуулан авах эрхтэй.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  4.3
                </label>
                <p className="cMarginZero">
                  Тухайн газрын кадастрын зураг болон холбогдох бусад бичиг баримтыг төсөл сонгон шалгаруулалт зохион байгуулагчаас гаргуулан авах эрхтэй.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  4.4
                </label>
                <p className="cMarginZero">
                  Төслийн ялагч нь “Чөлөөт бүсийн тухай” хуулийн 12.5-д заасан үндэслэлээр хуулийн этгээдийн бүртгэлээс хасагдсан тохиолдолд гэрээний 3.1-д заасан төлбөрийг нэхэмжлэх эрхгүй болно.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  4.5
                </label>
                <p className="cMarginZero">
                  Үйлдвэрлэл, үйлчилгээ явуулах зориулалтаар газар эзэмших эрх авсан иргэн, аж ахуйн нэгж, байгууллага нь 90 хоногт багтаан байгаль орчны нөлөөллийн үнэлгээг хийлгэсний дараа тэдгээртэй газар эзэмших гэрээ байгуулж, эрхийн гэрчилгээ олгон, улсын бүртгэлд бүртгэнэ.
                </p>
              </div>
            </div>
  
            <div className="cSection">
              <h3 className="cTextCenter">
                Тав.  Бусад зүйл
              </h3>
  
              <div className="cSectionDetail">
                <label >
                  5.1
                </label>
                <p className="cMarginZero">
                  Талууд гэрээнд гарын үсэг зурж, тамга тэмдэг дарснаар хүчин төгөлдөр болох ба эх хувьд засварласан буюу бусад аргаар оруулсан аливаа нэмэлт, өөрчлөлтийг хүчингүйд тооцно.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  5.2
                </label>
                <p className="cMarginZero">
                  Энэ гэрээгээр зохицуулагдаагүй харилцааг Монгол Улсын Иргэний хуулийн холбогдох зүйл, заалтаар зохицуулна.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  5.3
                </label>
                <p className="cMarginZero">
                  Гэрээний аль нэг заалт хүчин төгөлдөр бус болох нь гэрээ бүхэлдээ хүчин төгөлдөр бус болох үндэслэл болохгүй.
                </p>
              </div>
  
              <div className="cSectionDetail">
                <label >
                  5.4
                </label>
                <p className="cMarginZero">
                  Талуудын хооронд Гэрээнээс үүдэн гарсан аливаа маргааныг харилцан зөвшилцөх замаар, шийдвэрлэх бөгөөд зөвшилцөлд эс хүрвэл маргааныг Монгол Улсын шүүхээр шийдвэрлүүлнэ.
                </p>
              </div>
            </div>
  
            <div className="cSection">
              <h3 className="cTextCenter">
                Гэрээ байгуулсан
              </h3>
  
              <div className="cSectionDetail cWrap">
                <div className="cWidth50">
                  <h4 className="cTextCenter">
                    Зохион байгуулагчийг төлөөлж:
                  </h4>
                  <p className="cMarginZero">
                    <strong><u>{contractCreator?.c_orgName}</u></strong> -ны Дарга <strong><u>{contractCreator?.c_DirectorLast}</u></strong> Овогтой <strong><u>{contractCreator?.c_DirectorFirst}</u></strong> /Гарын үсэг/ _________________________ Тамга
                  </p>

                  <p className="cMarginZero">
                    <strong><u>{contractCreator?.c_type}</u></strong>: <strong><u>{contractCreator?.c_last}</u></strong> овогтой <strong><u>{contractCreator?.c_first}</u></strong> /Гарын үсэг/ ________________________________
                  </p>

                  <p className="cMarginZero">
                    Утас: <strong><u>{contractCreator?.c_phone}</u></strong>
                  </p>
                </div>
                <div className="cWidth50">
                  <h4 className="cTextCenter">
                    Төслийн ялагчийг төлөөлж:
                  </h4>
  
  
  
                  {
                    entityInfo.entity_type === 'citizen_with_legal_capacity' ?
  
                      <div>
                        <p className="cMarginZero">
                          {entityInfo.legal_name} иргэн {entityInfo.lastname} овогтой {entityInfo.firstname} /Гарын үсэг/ _________________________________ /тамга/
                        </p>
  
                        <p className="cMarginZero">
                          Регистрийн №: {entityInfo.register_number}
                        </p>
                        <p className="cMarginZero">
                          Хаяг: {detailInfo?.w_e_address?.au1.name} {detailInfo?.w_e_address?.au2.au1_code === '011' ? 'хот' : 'аймаг'} {detailInfo?.w_e_address?.au2.name} {detailInfo?.w_e_address?.au1.au2_code === '011' ? 'сум' : 'дүүрэг'} {detailInfo?.w_e_address?.au3?.name} {entityInfo.street} {entityInfo.building} {entityInfo.door} {entityInfo.address_desc}
                        </p>
                        <p className="cMarginZero">
                          Утас: {entityInfo?.phone_number}, {entityInfo?.home_number}
                        </p>
                      </div>
  
                      :
                      <div>
                        <p className="cMarginZero">
                          {entityInfo.legal_name} хуулийн этгээд  /Гарын үсэг/ _________________________________ /тамга/
                        </p>

                        <p className="cMarginZero">
                          Регистрийн №: {entityInfo.register_number}
                        </p>
                        <p className="cMarginZero">
                          Хаяг: {detailInfo?.w_e_address?.au1.name} {detailInfo?.w_e_address?.au2.au1_code === '011' ? 'хот' : 'аймаг'} {detailInfo?.w_e_address?.au2.name} {detailInfo?.w_e_address?.au1.au2_code === '011' ? 'сум' : 'дүүрэг'} {detailInfo?.w_e_address?.au3?.name} {entityInfo.street} {entityInfo.building} {entityInfo.door} {entityInfo.address_desc}
                        </p>
                        <p className="cMarginZero">
                          Утас: {entityInfo?.phone_number}, {entityInfo?.home_number}
                        </p>
                      </div>
                  }
  
  
                </div>
              </div>
            </div>
            <div className="cSection">
              <h3 className="cTextCenter">
                Гэрээ баталгаажуулах
              </h3>
  
              {contractInfo?.project_winner_signature === true ?
                <Alert
                  style={{ textAlign: "justify" }}
                  message="Мэдэгдэл"
                  description={c_alert_description}
                  type="success"
                  showIcon
                />
                :
                <div className="cSectionDetail" style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Checkbox onChange={checkBoxStatus} style={{ display: 'flex', alignItems: 'center' }}  >
                    <h4>Энэхүү гэрээг хүлээн зөвшөөрч байна</h4>
                  </Checkbox>
                  <Button onClick={contractConfirm} disabled={!isChecked} type="primary" >
                    Баталгаажуулах
                  </Button>
                </div>
              }
            </div>
          </div>
        }
      </>
    );
  }

}

export default ContractIndex;