import React, { PropsWithChildren, useState, useEffect, useContext, ReactNode } from 'react'
import {
  Layout,
  Menu,
  Image,
  Flex,
  Typography,
  Button,
  Form,
  Select,
  Modal,
  Dropdown,
  Avatar,
  Badge,
  Space,
  // Tooltip,
  FloatButton,
  Drawer,
  Grid,
  Spin,
} from 'antd'
import type { MenuProps, GetProp, FormProps } from 'antd'
import {
  HomeOutlined,
  UserSwitchOutlined,
  FileSearchOutlined,
  ReloadOutlined,
  BulbOutlined,
  GlobalOutlined,
  LogoutOutlined,
  LoginOutlined,
  PlayCircleOutlined,
  MoonOutlined,
  SunOutlined,
  SearchOutlined,
  BankOutlined,
  DownOutlined,
  BellOutlined,
  QuestionCircleOutlined,
  ArrowUpOutlined,
  MenuOutlined,
  CloseOutlined,
  FileDoneOutlined,
  LineChartOutlined,
} from '@ant-design/icons'
import Clock from 'react-live-clock'

import { redirect, useNavigate } from 'react-router-dom'

import * as WEBSERVICES from 'config/WebServices'
import * as CONFIGS from 'config/Configs'

import SmartHelper from 'helper/SmartHelper'

import LayoutContext from '../../LayoutContext';

const { Header, Content, Sider } = Layout
const { Text } = Typography
const { Option } = Select
const { useBreakpoint } = Grid

type MenuItem = GetProp<MenuProps, 'items'>[number]
type FieldType = {
  province_city?: string
  soum_district?: string
  bag_khoroo?: string
}

const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
}

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
}

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem
}

const sideMenuItems: MenuItem[] = [
  getItem('Нүүр', '/home', <HomeOutlined />),
  getItem('Дуудлага худалдаа', '/auction', <UserSwitchOutlined />),
  getItem('Төсөл шалгаруулалт', '/project', <FileSearchOutlined />),
  getItem('Худалдаа/Түрээс', '/sales', <ReloadOutlined />),
  getItem('Үнийн мэдээ', '/price-info', <BulbOutlined />),
  getItem('Газрын зураг', '/map', <GlobalOutlined />),
]

const items: MenuProps['items'] = [
  {
    key: '1',
    label: 'Хувийн мэдээлэл',
  },
  {
    key: '2',
    label: 'Миний зарууд',
  },
  {
    key: '3',
    label: 'Оролцсон ДХ',
  },
  {
    key: '4',
    label: 'Оролцсон ТСШ',
  },
  {
    key: '5',
    label: 'Таалагдсан зар',
  },
  {
    key: '6',
    label: 'Гарах',
  },
]

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<PropsWithChildren> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { openDAN, setOpenDAN } = useContext(LayoutContext);


  const [form] = Form.useForm()
  //const [openDAN, setOpenDAN] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>('dark')
  const [isAuth, setIsAuth] = useState(false)
  const [auth, setAuth] = useState([] as any)
  const [entity, setEntity] = useState([] as any)
  const [favorite, setFavorite] = useState(true);
  const [loaded, setLoaded] = useState(false)

  const screens = useBreakpoint()

  const [aimagCities, setAimagCities] = useState([] as any[])
  const [soumDistricts, setSoumDistricts] = useState([] as any[])
  const [bagKhoroos, setBagKhoroos] = useState([] as any[])
  
  const [selectedAimagCity, setSelectedAimagCity] = useState(
    SmartHelper.getSession('au1')
  )
  const [selectedSoumDistrict, setSelectedSoumDistrict] = useState(
    SmartHelper.getSession('au2')
  )
  const [selectedBagKhoroo, setSelectedBagKhoroo] = useState(
    SmartHelper.getSession('au3')
  )
  
  useEffect(() => {
    async function fetchData() {
      await fetchAimagCity().then(async () => {
        await fetchSoumDistrict(SmartHelper.getSession('au1')).then(
          async () => {
            await fetchBagKhoroo(SmartHelper.getSession('au2'))
          }
        )
      })
    }

    fetchData()
    setIsAuth(SmartHelper.getSession('isAuth') as boolean | false)
    setAuth(SmartHelper.getAuth())
    setEntity(SmartHelper.getEntity())
  }, [])

  const navigate = useNavigate()

  const handleNavigationClick = (e: any) => {
    navigate(e.key)
    navigate(0)
  }
  const handleMenuClick = (e: any) => {
    const intValue = parseInt(e.key, 10)

    switch (intValue) {
      case 1:
        navigate('/profile')
        navigate(0)
        break
      case 2:
        navigate('/sales', { state: { findBy: entity.id } })
        navigate(0)
        break
      case 3:
        navigate('/auction', { state: { findBy: entity.id } })
        navigate(0)
        break
      case 4:
        navigate('/project', { state: { findBy: entity.id } })
        navigate(0)
        break
      case 5:
        navigate('/sales', { state: { findBy: entity.id , favorite} });
        navigate(0)
        break;
      case 6:
        logout()
        break
      default: {
      }
    }
  }

  const showModal = () => {
    setOpenDAN(true)
  }

  const danLogin = () => {
    window.location.replace(CONFIGS.XYP_DAN_REDIRECT_URL+"?response_type=code&client_id="+CONFIGS.XYP_CLIENT_ID+"&scope="+btoa(JSON.stringify(CONFIGS.XYP_SCOPE))+"&redirect_uri="+CONFIGS.XYP_REDIRECT_URL+"&state=SDW-"+SmartHelper.generateToken());
    setOpenDAN(false)    
  }

  const danLoginLegal = () => {
    window.location.replace(CONFIGS.XYP_DAN_REDIRECT_URL+"?response_type=code&client_id="+CONFIGS.XYP_CLIENT_ID+"&scope="+btoa(JSON.stringify(CONFIGS.XYP_SCOPE_LEGAL))+"&redirect_uri="+CONFIGS.XYP_REDIRECT_URL+"&state=SDW-"+SmartHelper.generateToken());
    setOpenDAN(false)    
  }

  const logout = () => {
    SmartHelper.clearSession()
    navigate('/')
    navigate(0)
  }

  const handleCancel = () => {
    setOpenDAN(false)
  }

  const handleThemeMode = () => {
    setThemeMode((prev) => (prev === 'light' ? 'dark' : 'light'))
  }

  const fetchAimagCity = async () => {
    let url = WEBSERVICES.URL_LOCATION_AIMAG_CITY_ALL

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        setAimagCities(responseJson)
      })
      .catch((error) => {
        setAimagCities([])
      })
  }

  const fetchSoumDistrict = async (code: string) => {
    if (code && code !== '') {
      let url =
        WEBSERVICES.URL_LOCATION_SOUM_DISTRICT_BY_AIMAG_CITY +
        '?aimag_city_id=' +
        code
      await fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
          setSoumDistricts(responseJson)
        })
        .catch((error) => {
          setSoumDistricts([])
        })
    }
  }

  const fetchBagKhoroo = async (code: string) => {
    if (code && code !== '') {
      let url =
        WEBSERVICES.URL_LOCATION_BAG_KHOROO_BY_SOUM_DISTRICT +
        '?soum_district_id=' +
        code
      await fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
          setBagKhoroos(responseJson)
        })
        .catch((error) => {
          setBagKhoroos([])
        })      
    }
    setLoaded(true);
  }

  const onChangeAimagCity = (value: string) => {
    fetchSoumDistrict(value)
    setBagKhoroos([])
    setSelectedAimagCity(value)
    setSelectedSoumDistrict('')
    setSelectedBagKhoroo('')
  }

  const onChangeSoumDistrict = (value: string) => {
    fetchBagKhoroo(value)
    setSelectedSoumDistrict(value)
    setSelectedBagKhoroo('')
  }

  const onChangeBagKhoroo = (value: string) => {
    setSelectedBagKhoroo(value)
  }

  const searchAdminUnits = () => {
    SmartHelper.storeSession(
      'au1',
      selectedAimagCity &&
        typeof selectedAimagCity !== undefined &&
        selectedAimagCity != null
        ? selectedAimagCity
        : ''
    )
    SmartHelper.storeSession(
      'au2',
      selectedSoumDistrict &&
        typeof selectedSoumDistrict !== undefined &&
        selectedSoumDistrict != null
        ? selectedSoumDistrict
        : ''
    )
    SmartHelper.storeSession(
      'au3',
      selectedBagKhoroo &&
        typeof selectedBagKhoroo !== undefined &&
        selectedBagKhoroo != null
        ? selectedBagKhoroo
        : ''
    )

    navigate(0)
  }

  return (
    <Layout>
      {!loaded && <Spin size="large" fullscreen />}
      <Sider
        width={260}
        breakpoint="xl"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
        }}
        onCollapse={(collapsed, type) => {
        }}
        style={{
          backgroundImage:
            themeMode === 'dark'
              ? 'url(/images/main-layout-sider-bg.png)'
              : 'url(/images/main-layout-sider-bg-light.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundColor: themeMode === 'dark' ? '#0433DD' : '#fff',
          position: 'sticky',
          overflow: 'auto',
          top: 0,
          left: 0,
          bottom: 0,
          height: '100vh',
        }}
      >
        {/* Logo Wrapper */}
        <div>
          <div
            style={{
              paddingTop: 18,
              paddingBottom: 18,
              textAlign: 'center',
              backgroundColor: '#0433DD',
            }}
          >
            <Image
              src="/images/logo-primary.png"
              width={190}
              alt="Газар зохион байгуулалт, геодиз, зураг зүйн ерөнхий газар"
              preview={false}
              onClick={() => {
                navigate('/')
                navigate(0)
              }}
              style={{cursor: 'pointer'}}
            />
          </div>
          <div
            style={{
              paddingTop: 25,
              paddingBottom: 25,
              paddingLeft: 35,
              textAlign: 'left',
              backgroundColor: '#0D41FF',
              borderTop: '2px solid #fff',
            }}
          >
            <Image
              src="/images/logo-secondary.png"
              width={160}
              alt="Газрын биржийн цахим систем"
              preview={false}
              onClick={() => {
                navigate('/home')
                navigate(0)
              }}
              style={{cursor: 'pointer'}}
            />
          </div>
        </div>
        {/* Navigation Wrapper */}
        <div>
          <Menu
            theme={themeMode}
            mode="inline"
            defaultSelectedKeys={['4']}
            items={sideMenuItems}
            style={{
              fontWeight: 500,
              paddingLeft: 16,
              paddingTop: 40,
              marginBottom: 60,
            }}
            onClick={handleNavigationClick}
          />
        </div>
        {/* Time Wrapper */}
        <div style={{ position: 'absolute', bottom: 50 }}>
          <div style={{ marginLeft: 42 }}>
            <div
              style={{
                borderRadius: 5,
                border: '2px solid #FFDA00',
                padding: '10px 25px',
                display: 'inline-flex',
                flexDirection: 'column',
                backgroundColor: '#052EC3',
              }}
            >
              <Text
                style={{
                  color: '#FFDA00',
                  fontSize: 16,
                  lineHeight: '16px',
                  fontWeight: 600,
                  letterSpacing: 1,
                  textAlign: 'center',
                }}
              >
                Системийн цаг
              </Text>
              <Text
                style={{
                  color: '#fff',
                  fontSize: 30,
                  fontWeight: 600,
                  lineHeight: '30px',
                }}
              >
                <Clock
                  format={'HH:mm:ss'}
                  ticking={true}
                  timezone={'Asia/Ulaanbaatar'}
                />
              </Text>
            </div>
          </div>
          {/* Logout */}
          {isAuth && (
            <button
              style={{
                marginLeft: 42,
                marginTop: 16,
                background: 'transparent',
                color: '#fff',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => {
                logout()
              }}
            >
              {themeMode === 'dark' ? (
                <>
                  <LogoutOutlined style={{ color: '#fff' }} />
                  <Text style={{ color: '#fff', marginLeft: 14 }}>Гарах</Text>
                </>
              ) : (
                <>
                  <LogoutOutlined style={{ color: '#393874' }} />
                  <Text style={{ color: '#393874', marginLeft: 14 }}>
                    Гарах
                  </Text>
                </>
              )}
            </button>
          )}
        </div>
      </Sider>
      <Layout>
        <Header
          style={{
            boxShadow: '0 4px 4px #E7EAF1',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            backgroundColor: '#fff',
            padding: '0 20px',
          }}
          className="xl-or-less-blue-bg"
        >
          <Flex
            justify="space-between"
            align="center"
            style={{ height: 'inherit' }}
          >
            <img
              src="/images/logo-secondary.png"
              width={180}
              alt="Газрын биржийн цахим систем"
              style={{ display: 'none' }}
              className="xl-or-less-block"
            />
            <Form
              name="main-search"
              className="main-search-form xl-or-less-hide"
              layout={'inline'}
              style={{
                maxWidth: 'inline',
              }}
              initialValues={{
                remember: true,
                aimag_city: SmartHelper.getSession('au1'),
                soum_district: SmartHelper.getSession('au2'),
                bag_khoroo: SmartHelper.getSession('au3'),
              }}
              autoComplete="off"
            >
              <Form.Item name="aimag_city" style={{ width: 160 }}>
                <Select
                  placeholder="Аймаг нийслэл"
                  onChange={onChangeAimagCity}
                  allowClear
                  showSearch
                  notFoundContent={'Аймаг нийслэл сонгоно уу!'}
                  value={selectedAimagCity}
                >
                  {aimagCities.map((item, index) => (
                  <Option value={item.code} key={index}>
                    {item.name}
                  </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="soum_district" style={{ width: 160 }}>
                <Select
                  placeholder="Сум дүүрэг"
                  onChange={onChangeSoumDistrict}
                  allowClear
                  showSearch
                  notFoundContent={'Аймаг нийслэл сонгоно уу!'}
                  value={selectedSoumDistrict}
                >
                  {soumDistricts.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="bag_khoroo" style={{ width: 160 }}>
                <Select
                  placeholder="Баг хороо"
                  onChange={onChangeBagKhoroo}
                  allowClear
                  showSearch
                  notFoundContent={'Аймаг нийслэл сонгоно уу!'}
                  value={selectedBagKhoroo}
                >
                  {bagKhoroos.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  icon={<SearchOutlined />}
                  htmlType="submit"
                  style={{
                    borderColor: '#393874',
                    color: '#393874',
                    fontWeight: 500,
                    width: 128,
                  }}
                  onClick={searchAdminUnits}
                >
                  Хайх
                </Button>
              </Form.Item>
            </Form>

            <div>
              <Flex gap="middle" align="center">
                <Button
                  type="text"
                  onClick={handleThemeMode}
                  className="hide-on-xs"
                >
                  {themeMode === 'light' ? (
                    <SunOutlined
                      style={{
                        fontSize: 18,
                      }}
                      className="xl-or-less-white"
                    />
                  ) : (
                    <MoonOutlined
                      style={{
                        fontSize: 18,
                      }}
                      className="xl-or-less-white"
                    />
                  )}
                </Button>
                <Button
                  type="primary"
                  icon={<PlayCircleOutlined />}
                  style={{
                    backgroundColor: '#393874',
                    color: '#fff',
                    borderRadius: 30,
                    width: 140,
                    height: 33,
                    fontWeight: 500,
                  }}
                  className="xxl-or-less-hide"
                  onClick={() => {
                    navigate('/intro-video')
                    navigate(0)
                  }}
                >
                  Видео үзэх
                </Button>
                {isAuth ? (
                  <>
                    <Button type="text">
                      <BellOutlined
                        style={{
                          fontSize: 18,
                        }}
                        className="xl-or-less-white"
                      />
                    </Button>
                    <Dropdown
                      menu={{
                        items,
                        selectable: true,
                        onClick: handleMenuClick,
                      }}
                      placement="bottom"
                    >
                      <Space
                        size={16}
                        style={{ cursor: 'pointer' }}
                        className="profile-responsive"
                      >
                        <Badge dot>
                          { entity.profile_photo ? (
                            <Avatar
                              shape="square"
                              style={{ borderRadius: 4 }}
                              src={"data:image/png;base64," + entity.profile_photo}
                            />
                          ) : (
                            <Avatar
                              shape="square"
                              style={{ borderRadius: 4 }}
                              icon={<BankOutlined />}
                            />  
                          ) }
                          
                        </Badge>
                        {auth?.loginType == 1 ? (
                          <Text className="xxl-or-less-hide">
                            {auth?.firstname} / {auth?.register}
                          </Text>
                        ) : (
                          <Text className="xxl-or-less-hide">
                            {auth?.entityname} / {auth?.register}
                          </Text>
                        )}

                        <DownOutlined className="xl-or-less-white xxl-or-less-hide" />
                      </Space>
                    </Dropdown>
                  </>
                ) : screens.xs ? (
                  <Button
                    type="primary"
                    icon={<LoginOutlined />}
                    style={{
                      backgroundColor: '#FFDA00',
                      color: '#0116A1',
                      fontWeight: 500,
                    }}
                    shape="circle"
                    onClick={showModal}
                  />
                ) : (
                  <Button
                    type="primary"
                    icon={<LoginOutlined />}
                    style={{
                      backgroundColor: '#FFDA00',
                      color: '#0116A1',
                      borderRadius: 30,
                      width: 160,
                      height: 33,
                      fontWeight: 500,
                    }}
                    onClick={showModal}
                  >
                    Нэвтрэх
                  </Button>
                )}
                <Button
                  style={{
                    borderRadius: 6,
                    display: 'none',
                  }}
                  onClick={() => setOpenDrawer(true)}
                  className="xl-or-less-inline-block"
                >
                  <MenuOutlined />
                </Button>
                <Drawer
                  title=""
                  onClose={() => setOpenDrawer(false)}
                  open={openDrawer}
                  style={{
                    backgroundImage:
                      themeMode === 'dark'
                        ? 'url(/images/main-layout-sider-bg.png)'
                        : 'url(/images/main-layout-sider-bg-light.png)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundColor: themeMode === 'dark' ? '#0433DD' : '#fff',
                  }}
                  closeIcon={
                    <CloseOutlined
                      style={{
                        color: themeMode === 'dark' ? '#fff' : 'inherit',
                      }}
                    />
                  }
                  footer={
                    <>
                      {/* Time Wrapper */}
                      <div style={{ marginLeft: 42 }}>
                        <div
                          style={{
                            borderRadius: 5,
                            border: '2px solid #FFDA00',
                            padding: '20px 35px',
                            display: 'inline-flex',
                            flexDirection: 'column',
                            backgroundColor: '#052EC3',
                          }}
                        >
                          <Text
                            style={{
                              color: '#FFDA00',
                              fontSize: 20,
                              fontWeight: 600,
                              letterSpacing: 1,
                            }}
                          >
                            Системийн цаг
                          </Text>
                          <Text
                            style={{
                              color: '#fff',
                              fontSize: 40,
                              fontWeight: 600,
                              lineHeight: '40px',
                            }}
                          >
                            <Clock
                              format={'HH:mm:ss'}
                              ticking={true}
                              timezone={'Asia/Ulaanbaatar'}
                            />
                          </Text>
                        </div>
                      </div>
                      {/* Logout */}
                      <button
                        style={{
                          marginLeft: 42,
                          marginTop: 16,
                          background: 'transparent',
                          color: '#fff',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                        onClick={() => {}}
                      >
                        {isAuth ? (
                          themeMode === 'dark' ? (
                            <>
                              <LogoutOutlined style={{ color: '#fff' }} />
                              <Text style={{ color: '#fff', marginLeft: 14 }}>
                                Гарах
                              </Text>
                            </>
                          ) : (
                            <>
                              <LogoutOutlined style={{ color: '#393874' }} />
                              <Text
                                style={{ color: '#393874', marginLeft: 14 }}
                              >
                                Гарах
                              </Text>
                            </>
                          )
                        ) : (
                          <></>
                        )}
                      </button>
                    </>
                  }
                >
                  <Menu
                    theme={themeMode}
                    mode="inline"
                    defaultSelectedKeys={['4']}
                    items={sideMenuItems}
                    style={{
                      fontWeight: 500,
                      paddingLeft: 16,
                      paddingTop: 40,
                      marginBottom: 100,
                    }}
                    onClick={handleNavigationClick}
                  />
                  <Form
                    name="main-search"
                    className="main-search-form"
                    layout={'vertical'}
                    style={{
                      maxWidth: 'inline',
                      paddingLeft: 42,
                    }}
                    initialValues={{
                      remember: true,
                      aimag_city: SmartHelper.getSession('au1'),
                      soum_district: SmartHelper.getSession('au2'),
                      bag_khoroo: SmartHelper.getSession('au3'),
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Form.Item name="aimag_city" style={{ width: 200 }}>
                      <Select
                        placeholder="Аймаг нийслэл"
                        onChange={onChangeAimagCity}
                        allowClear
                        showSearch
                        notFoundContent={'Аймаг нийслэл сонгоно уу!'}
                        value={selectedAimagCity}
                      >
                        {aimagCities.map((item, index) => (
                          <Option value={item.code} key={index}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="soum_district" style={{ width: 200 }}>
                      <Select
                        placeholder="Сум дүүрэг"
                        onChange={onChangeSoumDistrict}
                        allowClear
                        showSearch
                        notFoundContent={'Аймаг нийслэл сонгоно уу!'}
                        value={selectedSoumDistrict}
                      >
                        {soumDistricts.map((item, index) => (
                          <Option value={item.code} key={index}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="bag_khoroo" style={{ width: 200 }}>
                      <Select
                        placeholder="Баг хороо"
                        onChange={onChangeBagKhoroo}
                        allowClear
                        showSearch
                        notFoundContent={'Аймаг нийслэл сонгоно уу!'}
                        value={selectedBagKhoroo}
                      >
                        {bagKhoroos.map((item, index) => (
                          <Option value={item.code} key={index}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        icon={<SearchOutlined />}
                        htmlType="submit"
                        style={{
                          borderColor: '#393874',
                          color: '#393874',
                          fontWeight: 500,
                          width: 128,
                        }}
                        onClick={searchAdminUnits}
                      >
                        Хайх
                      </Button>
                    </Form.Item>
                  </Form>
                </Drawer>
              </Flex>
            </div>
          </Flex>
        </Header>
        <Content
          style={{
            minHeight: 1000,
          }}
        >
          {children}
          <FloatButton.BackTop
            icon={<ArrowUpOutlined style={{ color: '#fff' }} />}
            shape={'square'}
            className="back-top-button"
            style={{ zIndex: 150 }}
          />
          <FloatButton.Group
            style={{
              right: 10,
              top: '30%',
            }}
          >
            <FloatButton
              style={{ width: 44, height: 44 }}
              icon={<QuestionCircleOutlined style={{ fontSize: 24 }} />}
              tooltip={'Тусламж'}
              className="mle-float-buttons"
              onClick={() => {
                navigate('/intro-video')
                navigate(0)
              }}
            />
            {/*<FloatButton
              icon={<UserOutlined />}
              tooltip={'Дуудлага худалдааны хяналт'}
              className="mle-float-buttons"
            />
            <FloatButton
              icon={<FileDoneOutlined />}
              tooltip={'Төсөл шалгаруулалт хяналт'}
              className="mle-float-buttons"
            />*/}
            <FloatButton
              icon={<GlobalOutlined />}
              tooltip={'Газрын зураг'}
              className="mle-float-buttons"
              onClick={() => {
                navigate('/map')
                navigate(0)
              }}
            />
            {/*<FloatButton
              icon={<LineChartOutlined />}
              tooltip={'Хянах самбар'}
              className="mle-float-buttons"
            />*/}
          </FloatButton.Group>
        </Content>
      </Layout>
      <Modal
        open={openDAN}
        onCancel={handleCancel}
        style={{
          textAlign: 'center',
        }}
        footer={null}
        width={380}
      >
        <div style={{ paddingTop: 80, paddingBottom: 48 }}>
          <Image
            src="/images/logo-secondary-light.png"
            width={200}
            height={40}
            alt="Газрын биржийн цахим систем"
            preview={false}
          />
        </div>
        <Text style={{ fontSize: 14, color: '#393874' }}>
          Төрийн танилт нэвтрэлтийн ДАН
          <br />
          системийг ашиглан нэвтэрдэг боллоо.
        </Text>
        <Button
          style={{
            marginTop: 16,
            marginBottom: 0,
            width: 279,
            height: 35,
            background: 'linear-gradient(90deg, #09AEF5 0%, #0447C5 100%)',
            borderRadius: 40,
            color: '#fff',
          }}
          onClick={() => {danLogin()}}
        >
          <strong>ИРГЭН НЭВТРЭХ</strong>
        </Button>
        <Button
          style={{
            marginTop: 4,
            marginBottom: 36,
            width: 279,
            height: 35,
            background: 'linear-gradient(90deg, #09AEF5 0%, #0447C5 100%)',
            borderRadius: 40,
            color: '#fff',
          }}
          onClick={() => {danLoginLegal()}}
        >
          <strong>БАЙГУУЛЛАГА НЭВТРЭХ</strong>
        </Button>
        <div
          style={{
            width: 258,
            textAlign: 'left',
            margin: '0 auto',
            paddingBottom: 48,
          }}
        >
          <Text style={{ color: '#393874', fontWeight: 600, fontSize: 12 }}>
            Дан системд нэвтрэх арга
          </Text>
          <ul style={{ padding: 0, marginTop: 8 }}>
            <li>
              <Text style={{ fontSize: 12, color: '#393874' }}>
                Өөрийн нэр дээрх утасны дугаараар нэг удаагийн код авч нэвтрэх
              </Text>
            </li>
            <li>
              <Text style={{ fontSize: 12, color: '#393874' }}>
                Өөрийн харилцдаг банкны интернэт банкны эрхээр нэвтрэх
              </Text>
            </li>
          </ul>
        </div>
      </Modal>
    </Layout>
  )
}

export default MainLayout