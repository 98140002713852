class SmartHelper {
    static formatCurrency(val: string) {
        return Number(val).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    static formatCurrencyBid(val: string) {
        return Number(val).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    static formatName(lastname: string, firstname: string) {
        const lastnameInitial = lastname?.charAt(0);
        return `${lastnameInitial}.${firstname}`;
    }    

    static formattedDescription(val: string) {
        return val
            .replace(/\\n/g, '<br>')
            .replace(/\? /g, '• ');
    }    

    static storeSession(key: any, value: any) {
        if(value && typeof value !== undefined) {
            sessionStorage.setItem(key, value);
        } else {
            sessionStorage.removeItem(key);
        }
    }

    static getSession(key: any): string | any {
        return typeof sessionStorage.getItem(key) !== undefined?sessionStorage.getItem(key): "";
    }

    static clearSession() {
        sessionStorage.clear();
    }

    static getAuth() {
        return JSON.parse(this.getSession('auth'));
    }

    static getEntity() {
        return JSON.parse(this.getSession('entity'));
    }

    static generateToken(): string {
        return Math.random().toString(36).substring(2);
    }

    static getRightType(code: any): string {
        let string = "";
        switch(code) {
            case 1:
                string = "Ашиглах";
                break;
            case 2:
                string = "Эзэмших";
                break;                
            case 3:
                string = "Өмчлөх";
                break;                
            default:
                break;
        }
        return string;
    }

    static applicationSimpleType = [
      { 
        code: 1,
        title: 'Өргөдөл бүртгэгдсэн',
      },
      {
        code: 2,
        title: 'Судалгааны түвшинд байна',
      },
      {
        code: 3,
        title: 'Захирамжийн төсөлд орсон',
      },
      {
        code: 4,
        title: 'Захирамж гарсан',
      },
      {
        code: 5,
        title: 'Улсын бүртгэлд бүртгэгдсэн',
      },
      {
        code: 6,
        title: 'Гэрээ байгуулахад бэлэн болсон /Газрын албан дээр ирэх/',
      },
    ]; 
}

export default SmartHelper;