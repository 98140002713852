import { Card, Image, Typography } from 'antd'

import * as CONFIGS from '../../config/Configs'
import { useNavigate } from 'react-router-dom'

const { Meta } = Card
const { Text, Paragraph } = Typography

const BlogItem = (props: any) => {
  const news = props.item;
  const navigate = useNavigate();

  return (
    <Card
      style={{ width: '100%', border: 'none', marginBottom: 20 }}
      cover={
        <Image
          alt="example"
          src={CONFIGS.URL_NEWS_ROOT+news?.thumb}
          fallback="https://picsum.photos/254/144"
          style={{ borderRadius: 5, objectFit: 'cover', height: 150 }}
        />
      }
      styles={{ body: { padding: '8px 0 0 0' } }}
    >
      <Meta
        title={
          <>
            {/*<Text style={{ fontSize: 10, color: '#4B4B4B', fontWeight: 400 }}>
              35 минутын өмнө
            </Text>*/}
            <Paragraph
              ellipsis={{ rows: 3 }}
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: '#000',
                whiteSpace: 'wrap',
                textTransform: 'initial',
                cursor: 'pointer',
                paddingLeft: 5,
                paddingRight: 5
              }}
              onClick={() => {
                navigate(`/blogs/detail/${news.id}`);
                navigate(0);
              }}
            >
              {news?.title}
            </Paragraph>
          </>
        }
      />
    </Card>
  )
}

export default BlogItem
