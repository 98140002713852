import React, { useEffect, useState, useContext } from 'react'
import {
  Breadcrumb,
  Layout,
  Row,
  Col,
  Flex,
  Typography,
  Card,
  Button,
  Divider,
  Image,
  Descriptions,
  Tag,
  Avatar,
  List,
  Form,
  Input,
  Spin,
  Space,
  Modal,
  Result
} from 'antd'
import type { SearchProps } from 'antd/es/input/Search'
import {
  ArrowRightOutlined,
  DownloadOutlined,
  SearchOutlined,
  MailOutlined,
  HeartFilled, 
  HeartOutlined,
  HeartTwoTone,
  DeleteOutlined
} from '@ant-design/icons'

import Masonry from 'react-masonry-css'
import MapView from './../../../components/MapView'
import FooterLayout from './../../../layouts/Footer'

import dayjs from 'dayjs'
import 'dayjs/locale/mn'

import { useNavigate, useParams } from 'react-router-dom'
import * as WEBSERVICES from '../../../config/WebServices'
import * as CONFIGS from '../../../config/Configs'
import SmartHelper from 'helper/SmartHelper'

dayjs.locale('mn')

const { Content } = Layout
const { Text, Title, Link } = Typography
const { Meta } = Card
const { Search } = Input

const descriptionItemLabelStyle = {
  flex: 4,
  fontSize: 12,
  fontWeight: 700,
  color: '#171441',
  paddingLeft: 20,
}
const descriptionItemContentStyle = {
  flex: 6,
  fontSize: 12,
  fontWeight: 600,
  color: '#393874',
}
const largeDescriptionItemLabelStyle = {
  flex: 5,
  fontSize: 12,
  fontWeight: 700,
  color: '#171441',
  paddingLeft: 20,
}
const largeDescriptionItemContentStyle = {
  flex: 5,
  fontSize: 12,
  fontWeight: 600,
  color: '#393874',
}
const priceTagStyle = {
  fontSize: 12,
  fontWeight: 700,
  height: 28,
  display: 'flex',
  alignItems: 'center',
  padding: '0px 20px 0px 10px',
}

const masonryBreakpointColumns = {
  default: 2,
  992: 1,
}

const contactData = [
  {
    title: 'Зар оруулсан байгуулга:',
    description:
      'Завхан аймгийн Газрын харилцаа, барилга, хот байгуулалтын газар',
    icon: '/images/icon-contact-home.jpg',
  },
  {
    title: 'Холбоо барих утас:',
    description: '94114169 96247365 /2023-11-29 10:32:01/',
    icon: '/images/icon-contact-phone.jpg',
  },
  {
    title: 'Холбоо барих хаяг:',
    description:
      'Завхан Улиастай 1-р баг, Жинст баг Баатар зээлийн гудамж Ундрам худалдааны төвийн зүүн урд талд',
    icon: '/images/icon-contact-pin.jpg',
  },
]

const onSearch: SearchProps['onSearch'] = (value, _e, info) =>
  console.log(info?.source, value)

const RenderComponentImage = (props: any) => {
  return (
    <div
      style={{
        marginTop: 24,
        marginRight: 10,
        display: 'inline-block',
      }}
    >
      <Image
        width={100}
        height={100}
        style={{ objectFit: 'cover' }}
        src={CONFIGS.URL_AUCTION_ROOT + props.item.file_url}
        fallback="/images/noimage.png"
      />
    </div>
  )
}

const SalesDetail: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [contactForm] = Form.useForm()
  const navigate = useNavigate();
  const [sale, setSale] = useState([] as any)
  const [loaded, setLoaded] = useState(false)
  const { id: detailId } = useParams()
  const [isSalesEntity, setIsSalesEntity] = useState(false)
  const [favorite, setFavorite] = useState([] as any)

  const [isAuth, setIsAuth] = useState(false)
  const [auth, setAuth] = useState([] as any)
  const [entity, setEntity] = useState([] as any)
  const [isShowModal, setIsShowModal] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      await fetchSalesDetail(detailId);
      await fetchSalesFavorite();

      setIsAuth(SmartHelper.getSession('isAuth') as boolean | false)
      setAuth(SmartHelper.getAuth())
      setEntity(SmartHelper.getEntity())

      setLoaded(true);
    };
    fetchData();
  }, [detailId]);

  const entityId = SmartHelper.getEntity()?.id ?? 'default_value';
  
  const fetchSalesDetail = async (item: any) => {
    try {
      const response = await fetch(WEBSERVICES.URL_SALES_DETAIL + item);
      const data = await response.json();
      setSale(data);
      setIsSalesEntity(data.data.entity_id === entityId);
    } catch (error) {
      console.error("Зарын дэлгэрэнгүй датаг татахад алдаа гарлаа:", error);
      setSale(null);
    }
  };

  const fetchSalesFavorite = async () => {
    try {
      const url = `${WEBSERVICES.URL_SALES_FAVORITE_LIST}?sales_id=${detailId}&entity_id=${entityId}`;
      const response = await fetch(url);
      const data = await response.json();
      setFavorite(data);
    } catch (error) {
      console.error("Таалагдсан зарын датаг татахад алдаа гарлаа:", error);
      setFavorite(null);
    }
  };

  const sendSalesFavorite = async () => {
    setLoaded(false);
  
    try {
      const response = await fetch(WEBSERVICES.URL_SALES_FAVORITE_STORE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          entity_id: entityId,
          sales_id: detailId,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
      }
  
      const result = await response.json();
      
      if (result.status === true) {
        const msg = '<p>Амжилттай таны хүсэлтийг илгээлээ. Та хугацаа дуусахаас өмнө мэдээллийг ахин илгээх боломжтой.</p>';
        window.location.reload(); 
        showMessage(msg, false);
      } else {
        throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Таны хүсэлтэнд алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!';
      const msg = `<p>${errorMessage}</p>`;
      showMessage(msg, true);
    } finally {
      setLoaded(true);
    }
  };  
  
  const handleClick = () => {
    Modal.confirm({
      title: 'Хадгалахдаа итгэлтэй байна уу?',
      onOk: sendSalesFavorite,
      onCancel: () => console.log("Cancelled"),
    });
  };

  const addFavorite = () => {
    if (isAuth) {
      if (entity && entity.is_active === false) {
        setMessage(
          '<p>Таны бүртгэлийн мэдээллийг баталгаажуулаагүй байна. Бүртгэл баталгаажих хүртэл хүлээнэ <p>'
        )
        setIsSuccess(false)
        setIsShowModal(true)
      } else {
        handleClick()
      }
    } else {
      setMessage(
        '<p>Та зарыг хадгалахын тулд нэвтэрсэн байх шаардлагатай<p>'
      )
      setIsSuccess(false)
      setIsShowModal(true)
    }
  }

  const handleOk = () => {
    setIsShowModal(false)
  }

  const handleCancel = () => {
    setIsShowModal(false)
  }
  
  const isFavorite = favorite?.data;
  const landDetail = sale?.parcelDetail?.resultPrice?.factor_values || [];
  const formattedDescription = sale?.data?.description
    ? SmartHelper.formattedDescription(sale?.data?.description)
    : '';
  
  const showMessage = (msg: string, isError: boolean) => {
    setMessage(msg);
  }
  
  const navigateToSales = () => {
    navigate(`/sales`)
    navigate(0);
  }

  const handleSalesFavoriteDelete = async () => {
    if (!entityId) return;
    try {
      const url = `${WEBSERVICES.URL_SALES_FAVORITE_DELETE}?sales_id=${detailId}&entity_id=${entityId}`;
      const response = await fetch(url);
      const data = await response.json();

      if (data?.status) {
        showMessage(`${detailId} амжилттай устгалаа.`, false);
        window.location.reload(); 
      } else {
        showMessage('Устгахад алдаа гарлаа дахин оролдоно уу!', true);
      }
    } catch (error) {
      console.error('Устгахад алдаа гарлаа :', error);
      showMessage('Устгахад алдаа гарлаа дахин оролдоно уу!', true);
    }
  };

  const confirmFavoriteDelete = () => {
    Modal.confirm({
      title: 'Хадгалахаа болихдоо итгэлтэй байна уу?',
      onOk: handleSalesFavoriteDelete,
      onCancel: () => console.log("Cancelled"),
    });
  };
  
  const handleSalesDelete = async () => {
    if (!entityId) return;

    try {
      const url = `${WEBSERVICES.URL_SALES_DELETE}?sales_id=${detailId}&entity_id=${entityId}`;
      const response = await fetch(url);
      const data = await response.json();

      if (data?.status) {
        showMessage(`${detailId} амжилттай устгалаа.`, false);
        navigateToSales();
      } else {
        showMessage('Устгахад алдаа гарлаа дахин оролдоно уу!', true);
      }
    } catch (error) {
      console.error('Устгахад алдаа гарлаа :', error);
      showMessage('Устгахад алдаа гарлаа дахин оролдоно уу!', true);
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: 'Та өөрийн зараа устгахдаа итгэлтэй байна уу?',
      onOk: handleSalesDelete,
      onCancel: () => console.log("Cancelled"),
    });
  };

  return (
    <Layout>
      {!loaded && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          zIndex: 10
        }}>
          <Spin size="large" />
          <p style={{ marginTop: 20, fontSize: '16px', color: '#1890ff' }}>Өгөгдлийг татаж байна, түр хүлээнэ үү...</p>
        </div>
      )}
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        <Row gutter={20}>
          {/* <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={19}> */}
          <Col span={24}>
            <Flex
              style={{ marginTop: 40, marginBottom: 20 }}
              justify="space-between"
              align="center"
              wrap="wrap"
            >
              <Meta
                title={
                  <Title
                    level={4}
                    style={{
                      color: '#FF0000',
                      marginBottom: 5,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    Худалдах зар - #{sale?.data?.code}
                  </Title>
                }
                description={
                  <Breadcrumb
                    separator={<ArrowRightOutlined />}
                    items={[
                      {
                        title: 'Эхлэл',
                        href: '/',
                      },
                      {
                        title: 'Худалдаа - Түрээсийн зар',
                        href: '/sales',
                      },
                      {
                        title: 'Худалдаа - Түрээсийн зар дэлгэрэнгүй',
                        className: 'breadcrumb-active',
                      },
                    ]}
                  />
                }
              />
              <Space size="middle" wrap>
                {isSalesEntity && (
                  <Button
                  type="text"
                  onClick={confirmDelete}
                  style={{
                    background: 'transparent',
                    border: '2px solid #FF0000',
                    borderRadius: '8px',
                    padding: '4px 12px',
                  }}
                  icon={<DeleteOutlined style={{ fontSize: '20px', color: '#FF0000' }} />}
                >
                  Өөрийн зарыг устгах
                </Button>
                )}
                <Button
                  type="text"
                  onClick={isFavorite ? confirmFavoriteDelete : addFavorite}
                  icon={
                    isFavorite ? (
                      <HeartTwoTone
                        twoToneColor={['#FF0000', '#FF0000']}
                        style={{ fontSize: '20px' }}
                      />
                    ) : (
                      <HeartTwoTone
                        twoToneColor={['#CCCCCC', '#CCCCCC']}
                        style={{ fontSize: '20px' }}
                      />
                    )
                  }
                  style={{
                    background: 'transparent',
                    border: '2px solid #FF0000',
                    borderRadius: '8px',
                    padding: '4px 12px',
                  }}
                >
                  {isFavorite ? 'Хадгалагдсан' : 'Хадгалах'}
                </Button>
              </Space>
            </Flex>
            <Row gutter={20}>
              <Masonry
                breakpointCols={masonryBreakpointColumns}
                className="masonry-grid"
                columnClassName="masonry-grid-column"
              >
                <Card style={{ marginBottom: 20 }}>
                  <Flex
                    style={{ position: 'relative' }}
                    justify="space-between"
                  >
                    <div style={{ width: '90%' }}>
                      <Image
                        src="/images/icon-map.png"
                        width={13}
                        height={13}
                        alt=""
                        preview={false}
                        style={{ marginBottom: 4 }}
                      />
                      <Text
                        style={{
                          marginLeft: 8,
                          color: '#171441',
                          fontWeight: 600,
                        }}
                      >
                        Худалдах газрын зураг
                      </Text>
                      <Divider style={{ marginTop: 10 }} />
                    </div>
                  </Flex>
                  <Image.PreviewGroup>
                    {Array.isArray(sale?.data?.documents) && sale?.data?.documents.length > 0 ? (
                      <>
                        {/* Display the first document if available */}
                        {sale?.data?.documents.map((item: any, index: any) => {
                          if (index !== 0) return null;
                          return (
                            <Image
                              key={index}
                              width={'100%'}
                              height={293}
                              style={{ objectFit: 'cover' }}
                              src={CONFIGS.URL_AUCTION_ROOT + item.file_url}
                              fallback="/images/noimage.png"
                            />
                          );
                        })}
                        {/* Render other documents */}
                        {sale?.data?.documents.map((item: any, index: any) => {
                          if (index === 0) return null;
                          return <RenderComponentImage item={item} key={index} />;
                        })}
                      </>
                    ) : (
                      <div>Зураг оруулаагүй байна.</div>
                    )}
                  </Image.PreviewGroup>
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Худалдах газрын ерөнхий мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <Descriptions column={1} className="sales-general-table">
                    <Descriptions.Item
                      label="Ангилал"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {sale?.data?.ad_type?.name} зар
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Төрөл"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {sale?.data?.land?.land_type?.name} 
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Худалдах үнэ"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Tag color="#1AA752" style={priceTagStyle}>
                      {SmartHelper.formatCurrency(sale?.data?.price)} ₮
                      </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Газрын хэмжээ"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {sale?.data?.land?.area_m2} м2
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Газрын зориулалт"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {sale?.data?.land?.land_use_type?.description}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Газар ашиглалтын хэлбэр"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {sale?.data?.land?.land_right_type}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Тайлбар"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: formattedDescription }} />
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Flex justify="space-between">
                    <div>
                      <Image
                        src="/images/icon-map-sale.png"
                        width={13}
                        height={13}
                        alt=""
                        preview={false}
                        style={{ marginBottom: 4 }}
                      />
                      <Text
                        style={{
                          marginLeft: 8,
                          color: '#171441',
                          fontWeight: 600,
                        }}
                      >
                        Газрын зураг
                      </Text>
                    </div>
                    <Link
                      href="#google_map"
                      target="_blank"
                      style={{
                        marginLeft: 8,
                        fontWeight: 600,
                        textDecoration: 'underline',
                      }}
                    >
                      Байршил Google Map дээр харах
                    </Link>
                  </Flex>
                  <Divider style={{ marginTop: 10 }} />
                  <MapView geoJson={sale?.data?.land?.geojson}/>
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Нэгж талбарын мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  {sale?.parcelDetail?.resultParcel?.length > 0 ? (
                    <Descriptions column={1} className="parcel-table">
                      <Descriptions.Item
                        label="Нэгж талбарын дугаар"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {sale?.data?.land?.parcel}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Хуучин нэгж талбарын дугаар"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {sale?.parcelDetail?.resultParcel[0]?.old_parcel_id}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Аймаг хот"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {sale?.parcelDetail?.resultParcel[0]?.au1_name}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Сум дүүрэг"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {sale?.parcelDetail?.resultParcel[0]?.au2_name}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Баг Хороо"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {sale?.parcelDetail?.resultParcel[0]?.au3_name}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Гудамж"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {sale?.parcelDetail?.resultParcel[0]?.address_streetname}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Хашаа"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {sale?.parcelDetail?.resultParcel[0]?.address_khashaa}
                      </Descriptions.Item>
                    </Descriptions>
                  ) : (
                    <Text>Мэдээлэл байхгүй</Text>
                  )}
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-youtube.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Youtube видео
                  </Text>
                  <Divider style={{ marginTop: 10, borderColor: '#0654DE' }} />
                  <div style={{ maxWidth: '100%' }}>
                    <iframe
                      style={{ width: '100%' }}
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${sale?.data?.link}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Газрын дэлгэрэнгүй мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  {sale?.parcelDetail?.resultLevel?.length > 0 ? (
                    <Descriptions column={1} className="land-detail-table">
                      {landDetail.map((detail: any, index: number) => (
                        <Descriptions.Item
                          key={index}
                          label={detail.name}
                          labelStyle={largeDescriptionItemLabelStyle}
                          contentStyle={largeDescriptionItemContentStyle}
                          style={{
                            paddingBottom: 10,
                          }}
                        >
                          {detail?.factor_value}
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                  ) : (
                    <Text>Мэдээлэл байхгүй</Text>
                  )}
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Газрын үнэлгээний мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  {sale?.parcelDetail?.resultParcel?.length > 0 ? (
                    <Descriptions column={1} className="land-level-table">
                      <Descriptions.Item
                        label="Газрын суурь үнэлгээний зэрэглэл /Бүс/"
                        labelStyle={largeDescriptionItemLabelStyle}
                        contentStyle={largeDescriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {sale?.parcelDetail?.resultLevel[0]?.level_type} | {sale?.parcelDetail?.resultLevel[0]?.level_name} | {sale?.parcelDetail?.resultLevel[0]?.level_no}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Газрын суурь үнэ /1га/"
                        labelStyle={largeDescriptionItemLabelStyle}
                        contentStyle={largeDescriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {SmartHelper.formatCurrency(sale?.parcelDetail?.resultLevel[0]?.base_price_m2)} ₮
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Газрын суурь үнэ"
                        labelStyle={largeDescriptionItemLabelStyle}
                        contentStyle={largeDescriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {SmartHelper.formatCurrency(sale?.parcelDetail?.resultLevel[0]?.base_price)} ₮
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Дуудлага худалдааны анхны үнэ"
                        labelStyle={largeDescriptionItemLabelStyle}
                        contentStyle={largeDescriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {SmartHelper.formatCurrency(sale?.parcelDetail?.resultLevel[0]?.auction_amount)} ₮
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Дуудлага худалдааны анхны үнийн итгэлцүүр"
                        labelStyle={largeDescriptionItemLabelStyle}
                        contentStyle={largeDescriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {sale?.parcelDetail?.resultLevel[0]?.confidence}
                      </Descriptions.Item>
                    </Descriptions>
                  ) : (
                    <Text>Мэдээлэл байхгүй</Text>
                  )}
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Газрын төлбөр, татварын мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  {sale?.parcelDetail?.resultFee?.length > 0 ? (
                    <Descriptions column={1} className="fee-table">
                      <Descriptions.Item
                        label="Төлбөр, татварын бүс"
                        labelStyle={largeDescriptionItemLabelStyle}
                        contentStyle={largeDescriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {sale?.parcelDetail?.resultFee[0]?.zone_type} | {sale?.parcelDetail?.resultFee[0]?.zone_name} | {sale?.parcelDetail?.resultFee[0]?.zone_no}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Газрын төлбөр, татварын хувь хэмжээ"
                        labelStyle={largeDescriptionItemLabelStyle}
                        contentStyle={largeDescriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {sale?.parcelDetail?.resultFee[0]?.confidence_percent}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Газрын төлбөр, татвар/1m2/"
                        labelStyle={largeDescriptionItemLabelStyle}
                        contentStyle={largeDescriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {SmartHelper.formatCurrency(sale?.parcelDetail?.resultFee[0]?.base_fee_per_m2)} ₮
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Газрын төлбөр, татварын дүн"
                        labelStyle={largeDescriptionItemLabelStyle}
                        contentStyle={largeDescriptionItemContentStyle}
                        style={{ paddingBottom: 10 }}
                      >
                        {SmartHelper.formatCurrency(sale?.parcelDetail?.resultFee[0]?.payment)} ₮
                      </Descriptions.Item>
                    </Descriptions>
                  ) : (
                    <Text>Мэдээлэл байхгүй</Text>
                  )}
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-phone.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Холбоо барих мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <Meta
                    style={{ marginBottom: 10 }}
                    avatar={
                      <Image
                        src="/images/icon-contact-home.jpg"
                        width={31}
                        height={31}
                        alt=""
                        preview={false}
                      />
                    }
                    title={
                      <Text
                        style={{
                          color: '#000000',
                          fontSize: 14,
                          fontWeight: 600,
                          width: 150,
                        }}
                      >
                        Зар оруулсан хэрэглэгч:
                      </Text>
                    }
                    description={
                      <Text
                        style={{
                          color: '#000000',
                          fontSize: 12,
                          fontWeight: 400,
                          width: 150,
                        }}
                      >
                        {SmartHelper.formatName(
                            sale?.data?.entity?.lastname || '',
                            sale?.data?.entity?.firstname || ''
                        )}
                      </Text>
                    }
                  />
                  <Meta
                    style={{ marginBottom: 10 }}
                    avatar={
                      <Image
                        src="/images/icon-contact-phone.jpg"
                        width={31}
                        height={31}
                        alt=""
                        preview={false}
                      />
                    }
                    title={
                      <Text
                        style={{
                          color: '#000000',
                          fontSize: 14,
                          fontWeight: 600,
                          width: 150,
                        }}
                      >
                        Холбоо барих утас:
                      </Text>
                    }
                    description={
                      <Text
                        style={{
                          color: '#000000',
                          fontSize: 12,
                          fontWeight: 400,
                          width: 150,
                        }}
                      >
                        {sale?.data?.contact_phone}
                      </Text>
                    }
                  />
                </Card>
              </Masonry>
              {/* <div className="hide-on-md-or-less"> */}
              <FooterLayout isFull />
              {/* </div> */}
            </Row>
          </Col>
          {/* <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={5}>
            <Card style={{ marginBottom: 20, marginTop: 115 }}>
              <SearchOutlined
                style={{ fontSize: 16, color: '#393874', fontWeight: 700 }}
              />
              <Text
                style={{
                  marginLeft: 8,
                  color: '#171441',
                  fontWeight: 600,
                }}
              >
                Хайх
              </Text>

              <Search
                style={{ marginTop: 10, marginBottom: 20 }}
                styles={{
                  input: {
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20,
                    minHeight: 33,
                  },
                }}
                placeholder="ID #2023-00005"
                onSearch={onSearch}
                enterButton={
                  <Button
                    style={{
                      borderTopRightRadius: 20,
                      borderBottomRightRadius: 20,
                      minHeight: 33,
                      backgroundColor: '#393874',
                    }}
                  >
                    <SearchOutlined
                      style={{ fontSize: 16, color: '#fff', fontWeight: 700 }}
                    />
                  </Button>
                }
              />
            </Card>
            <div className="show-on-md-or-less">
              <FooterLayout />
            </div>
          </Col> */}
        </Row>
        <Modal
          title="Анхааруулга"
          open={isShowModal}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="ok" type="primary" onClick={handleOk}>
              Хаах
            </Button>,
          ]}
        >
          <div
            style={{ color: isSuccess ? 'green' : 'red' }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </Modal>
      </Content>
     
    </Layout>
  )
}

export default SalesDetail
