import React, {useEffect, useState} from 'react'
import {
  Breadcrumb,
  Layout,
  Row,
  Col,
  Flex,
  Typography,
  Card,
  Space,
  Button,
  Alert,
  Statistic,
  List,
  Spin,
  Modal,
  Empty,
} from 'antd'
import {
  ArrowRightOutlined,
  SyncOutlined,
  WarningFilled,
} from '@ant-design/icons'
import AuctionTodayItem from '../../../components/AuctionTodayItem'
import { AuctionAttendingItems } from '../../../constants/dummy.constants'
import FooterLayout from '../../../layouts/Footer'

import * as WEBSERVICES from '../../../config/WebServices'
import SmartHelper from 'helper/SmartHelper'

const { Content } = Layout
const { Text, Title, Paragraph } = Typography
const { Meta } = Card
const { Countdown } = Statistic

const TodayAuction: React.FC = () => {
  const [auctions, setAuctions] = useState([] as any)
  const [loaded, setLoaded] = useState(false)
  const [entity, setEntity] = useState([] as any)  

  useEffect(() => {
    async function fetchData() {
      let entity = SmartHelper.getEntity();
      if(entity != null) {
        setEntity(entity);
      }
      await fetchAuctionParticipantList()
    }

    fetchData().then(() => {
      setLoaded(true)
    })
  }, [])
  
  const fetchAuctionParticipantList = async () => {
    let url = WEBSERVICES.URL_AUCTION_TODAY;

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setAuctions(responseJson.data)
        } else {
          setAuctions([])
        }
      })
      .catch((error) => {
        setAuctions([])
      })
  }

  const refreshAuctions = () => {
    window.location.reload();
  }

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        {!loaded && <Spin size="large" fullscreen />}
        <Row gutter={20}>
          <Col span={24}>
            <Flex
              style={{ marginTop: 40 }}
              justify="space-between"
              align="center"
              wrap="wrap"
            >
              <Meta
                title={
                  <Title
                    level={4}
                    style={{
                      color: '#393874',
                      marginBottom: 5,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    Өнөөдөр болж буй дуудлага худалдаа
                  </Title>
                }
                description={
                  <Paragraph style={{ color: '#6E6B98', fontWeight: 500 }}>
                    Өнөөдөр болж буй дуудлага худалдаа
                  </Paragraph>
                }
              />
            </Flex>
            <Flex
              style={{ marginBottom: 20 }}
              justify="space-between"
              align="center"
              wrap="wrap"
            >
              <Breadcrumb
                separator={<ArrowRightOutlined />}
                items={[
                  {
                    title: 'Эхлэл',
                    href: '/',
                  },
                  {
                    title: 'Дуудлага худалдаа',
                    href: '/auction',
                  },
                  {
                    title: 'Өнөөдөр болж буй',
                    className: 'breadcrumb-active',
                  },
                ]}
              />
              <Button type="primary" icon={<SyncOutlined />} onClick={() => {refreshAuctions()}}>
                Үнийн санал шинэчлэх
              </Button>
            </Flex>
          </Col>
        </Row>
        <List
          style={{ marginTop: 40, marginBottom: 80 }}
          grid={{
            gutter: 20,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          dataSource={auctions}
          pagination={{ position: 'bottom', align: 'start' }}
          locale={{
            emptyText: <Empty description="Бичлэг байхгүй байна" />,
          }}
          renderItem={(item, index) => (
            <List.Item key={index}>
              <AuctionTodayItem item={item}/>
            </List.Item>
          )}
        />
        <FooterLayout isFull />
      </Content>
    </Layout>
  )
}

export default TodayAuction
