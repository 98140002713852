import React from 'react'
import { Layout, Flex, Grid } from 'antd'
import CoverButton from './../../components/CoverButton'
import Map from './map'
const { Content } = Layout
const { useBreakpoint } = Grid

const CoverPage: React.FC = () => {
  const screens = useBreakpoint()

  return (
    <Layout>
      <Content
        style={{
          background: 'linear-gradient(110.35deg, #3A0DFF 0.78%, #010575 100%)',
          height: '100vh',
          width: '100vw',
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            backgroundImage: 'url(/images/04.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            width: '100%',
            height: '100%',
          }}
        ></div>
        <div
              style={{
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  width: '100%',
                  height: '100%',
                }}
              />
              <Map />
            </div>
        <div
          style={{
            position: 'absolute',
            top: '80%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 3,
            width: screens.xs ? '90vw' : '70vw',
          }}
        >
          <Flex
            justify={'center'}
            align={'center'}
            gap={screens.xs ? 'middle' : 'large'}
            wrap="wrap"
            vertical={screens.xs ? true : false}
          >
            <CoverButton href="/home">Нүүр хуудас</CoverButton>
            <CoverButton href="/auction">Дуудлага худалдаа</CoverButton>
            <CoverButton href="/project">Төсөл шалгаруулалт</CoverButton>
            <CoverButton href="/sales">Худалдаа/Түрээс</CoverButton>
            <CoverButton href="/price-info">Үнийн мэдээлэл</CoverButton>
            <CoverButton href="/map">Газрын зураг</CoverButton>
          </Flex>
        </div>
      </Content>
    </Layout>
  )
}

export default CoverPage
