import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Breadcrumb,
  Layout,
  Row,
  Col,
  Flex,
  Typography,
  Card,
  Space,
  Button,
  Divider,
  Image,
  Descriptions,
  Tag,
  Form,
  Spin,
  Modal,
  Table,
  Empty,
} from 'antd'
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  UserAddOutlined,
} from '@ant-design/icons'
import MapView from './../../../components/MapView'
import FooterLayout from './../../../layouts/Footer'

import * as WEBSERVICES from '../../../config/WebServices'
import * as CONFIGS from '../../../config/Configs'

import mnMN from 'antd/lib/locale/mn_MN'
import dayjs from 'dayjs'
import 'dayjs/locale/mn'
import SmartHelper from 'helper/SmartHelper'
import GeoJSON from 'ol/format/GeoJSON'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import { getCenter } from 'ol/extent'
import PanoramaImage from 'components/Panorama/Index'

import LayoutContext from '../../../LayoutContext';
import LandStatus from 'components/LandStatus'
import { TableProps } from 'antd/lib'

dayjs.locale('mn')

const { Content } = Layout
const { Text, Title, Link } = Typography
const { Meta } = Card

const descriptionItemLabelStyle = {
  flex: 4,
  fontSize: 12,
  fontWeight: 700,
  color: '#171441',
  paddingLeft: 20,
}
const descriptionItemContentStyle = {
  flex: 6,
  fontSize: 12,
  fontWeight: 600,
  color: '#393874',
}
const largeDescriptionItemLabelStyle = {
  flex: 5,
  fontSize: 12,
  fontWeight: 700,
  color: '#171441',
  paddingLeft: 20,
}
const largeDescriptionItemContentStyle = {
  flex: 5,
  fontSize: 12,
  fontWeight: 600,
  color: '#393874',
}
const priceTagStyle = {
  fontSize: 12,
  fontWeight: 700,
  height: 28,
  display: 'flex',
  alignItems: 'center',
  padding: '0px 20px 0px 10px',
}

const AuctionDetail: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setOpenDAN } = useContext(LayoutContext);

  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id: detailId } = useParams()

  const [isAuth, setIsAuth] = useState(false)
  const [auth, setAuth] = useState([] as any)
  const [entity, setEntity] = useState([] as any)

  const [requestForm] = Form.useForm()
  const [contactForm] = Form.useForm()

  const [auction, setAuction] = useState({} as any)
  const [feeZone, setFeeZone] = useState({} as any)
  const [parcelDetail, setParcelDetail] = useState({} as any)
  const [auctionDocuments, setAuctionDocuments] = useState([] as any)
  const [loaded, setLoaded] = useState(false)

  const [isAuctionStart, setIsAuctionStart] = useState(false)
  const [isAuctionEnd, setIsAuctionEnd] = useState(false)
  const [isRegistrationStart, setIsRegistrationStart] = useState(false)
  const [isRegistrationEnd, setIsRegistrationEnd] = useState(false)

  const [isShowModal, setIsShowModal] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [message, setMessage] = useState('')
  const [panoImg, setPanoImg] = useState("");

  const [bids, setBids] = useState([] as any)
  const [columns, setColumns] = useState([] as any)
  const [isShowBiddingModal, setIsShowBiddingModal] = useState(false)

  const [isShowDocumentModal, setIsShowDocumentModal] = useState(false)
  const [documentUrl, setDocumentUrl] = useState("")
  
  const [userLocation, setUserLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const [errorLoc, setError] = useState<string | null>(null);

  const numberWithCommas = (price: any) => {
    price = price.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(price))
      price = price.replace(pattern, "$1,$2");
    return price;
  }

  type ColumnsType<T extends object> = TableProps<T>['columns'];

  interface DataType {
    key: number;
    feeZone: string;
    confidence: string;
    payment: string;
    payment_amount: string;
  }

  const FeeColumns: ColumnsType<DataType> = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Төлбөрийн бүс',
      dataIndex: 'feeZone',
      key: 'feeZone',
    },
    {
      title: 'Төлбөрийн хувь хэмжээ',
      dataIndex: 'confidence',
      key: 'confidence',
    },
    {
      title: 'Газрын төлбөр 1m2',
      dataIndex: 'payment',
      key: 'payment',
    },
    {
      title: 'Төлбөрийн дүн',
      dataIndex: 'payment_amount',
      key: 'payment_amount',
    },

  ];

  interface DocumentDataType {
    id: any
    key: number;
    file_url: string;
  }

  const documentColumns: ColumnsType<DocumentDataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      hidden: true
    },
    {
      title: 'Файлын нэр',
      dataIndex: 'file_url',
      key: 'file_url',        
      render: (text: any, record: any) => (
        <Link
          href={CONFIGS.URL_AUCTION_ROOT+record.file_url}            
          target="_blank"
        >
          {record.file_url}
        </Link>
      )
    },
    {
      title: 'Харах',
      key: 'action',
      render: (text: any, record: any) => {
        return (                                    
            <Button
              type="primary"
              onClick={() => {setDocumentUrl(CONFIGS.URL_AUCTION_ROOT+record.file_url); setIsShowDocumentModal(true)}}
            >
              Харах
            </Button>
        )
      },
    },
  ];

  let found = false;

  useEffect(() => {
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        }, (err) => {
          switch (err.code) {
            case err.PERMISSION_DENIED:
              setError('User denied the request for Geolocation.');
              break;
            case err.POSITION_UNAVAILABLE:
              setError('Location information is unavailable.');
              break;
            case err.TIMEOUT:
              setError('The request to get user location timed out.');
              break;
            default:
              setError('An unknown error occurred.');
              break;
          }
        }, {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      }
      );
    } else {
      console.log("Хөтөч дэмжихгүй байна");
    }

    
    const fetchAuctionData = async () => {
      let url = WEBSERVICES.URL_AUCTION_DETAIL + detailId
      await fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson && responseJson.status === true) {
            let auction = responseJson.data
            setIsAuctionStart(responseJson.isAuctionStart)
            setIsRegistrationStart(responseJson.isRegistrationStart)
            setIsAuctionEnd(responseJson.isAuctionEnd)
            setIsRegistrationEnd(responseJson.isRegistrationEnd)

            setFeeZone(responseJson.feeZoneInfo.data?.[0])
            
            fetchAuctionBids(auction.id);
            setAuction(auction)

            responseJson.data.image360 === null ? setPanoImg(`https://admin.mle.mn/image360/default.jpg`) : setPanoImg(`https://admin.mle.mn/image360/auction/${responseJson.data.image360}`);
            
            setParcelDetail(responseJson.parcelDetail)

            const filteredDocs = auction.documents.filter((doc: { file_type: string }) => 
              doc.file_type === 'file'
            );

            setAuctionDocuments(filteredDocs);

          } else {
            setAuction({})
            setParcelDetail({})
          }
        })
        .catch((error) => {
          setAuction({})
          setParcelDetail({})
        })
    }

    setColumns([
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        hidden: true
      },
      {
        title: 'Хэрэглэгч',
        dataIndex: 'entity_id',
        key: 'entity_id',
        render: (text: string) => "#"+text,
      },
      {
        title: 'Огноо',
        dataIndex: 'bidtime',
        key: 'bidtime',
      },
      {
        title: 'Үнэ',
        dataIndex: 'bid',
        key: 'bid',
        render: (value: string) => SmartHelper.formatCurrencyBid(value) + '₮'
      },
    ]);

    setIsAuth(SmartHelper.getSession('isAuth') as boolean | false)
    setAuth(SmartHelper.getAuth())
    setEntity(SmartHelper.getEntity())

    fetchAuctionData().then(async () => {
      setLoaded(true)      
    })
  }, [detailId])

  /*const data: DataType[] = feeZone !== undefined ? [
    {
      key: 1,
      feeZone: `${feeZone?.zone_name} | ${feeZone?.zone_no}`,
      confidence: `${feeZone?.confidence_percent}₮`,
      payment: `${feeZone?.base_fee_per_m2}₮`,
      payment_amount: `${numberWithCommas(parseInt(feeZone?.payment))}₮`,
    },
  ] : []; */
  

  const fetchAuctionBids = async (auctionId: any) => {
    let url = WEBSERVICES.URL_AUCTION_BIDS+"?auction_id="+auctionId;

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          if(responseJson.data.length > 0) {
            let bids = responseJson.data;
            setBids(bids)
          } 
        } else {
          setBids([])
        }
      })
      .catch((error) => {
        setBids([])
      })
  }

  const RenderComponentImage = (props: any) => {
    return (
      <div
        style={{
          marginTop: 24,
          marginRight: 10,
          display: 'inline-block',
        }}
      >
        <Image
          width={100}
          height={100}
          style={{ objectFit: 'cover' }}
          src={CONFIGS.URL_AUCTION_ROOT + props.item.file_url}
          fallback="/images/noimage.png"
        />
      </div>
    )
  }

  const getGoogleMapUrl = (geoJson: any) => {
    if (geoJson && typeof geoJson !== undefined) {
      const format = new GeoJSON()

      const features: any = format.readFeatures(geoJson)

      const vectorSources = new VectorSource({
        features: features,
      })

      const vectorLayer = new VectorLayer({
        source: vectorSources,
      })

      var extend: any = vectorLayer.getSource()?.getExtent()
      var center = getCenter(extend)

      var userLoc = userLocation;

      // https://www.google.com/maps/dir/47.9133696,106.9351907/47.7075694,108.4104767

      if(userLocation != null) {
        return `https://www.google.com/maps/dir/${userLoc?.latitude},${userLoc?.longitude}/${center[1]},${center[0]}`;
      } else {
        return `http://maps.google.com/maps?z=12&t=m&q=loc:${center[1]},${center[0]}`;
        
      }
    }

    return '#'
  }

  const joinAuction = () => {
    if (isAuth) {
      if (entity && entity.is_active === false) {
        setMessage(
          '<p>Таны бүртгэлийн мэдээллийг баталгаажуулаагүй байна. Бүртгэл баталгаажих хүртэл хүлээнэ <p>'
        )
        setIsSuccess(false)
        setIsShowModal(true)
      } else {
        navigate(`/auction/join/${detailId}`)
        navigate(0)
      }
    } else {
      setMessage(
        '<p>Та дуудлага худалдаанд оролцохын тулд нэвтэрсэн байх шаардлагатай<p>'
      )
      setIsSuccess(false)
      setIsShowModal(true)
    }
  }

  const handleOk = () => {
    setIsShowModal(false)
  }

  const handleCancel = () => {
    setIsShowModal(false)
  }

  
  const handleBiddingOk = () => {
    setIsShowBiddingModal(false)
  }

  const handleBiddingCancel = () => {
    setIsShowBiddingModal(false)
  }

  const handleDocumentOk = () => {
    setIsShowDocumentModal(false)
  }

  const handleDocumentCancel = () => {
    setIsShowDocumentModal(false)
  }

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        {!loaded && <Spin size="large" fullscreen />}
        <Row gutter={20}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Flex
              style={{ marginTop: 40, marginBottom: 20 }}
              justify="space-between"
              align="center"
              wrap="wrap"
            >
              <Meta
                style={{ marginBottom: 20 }}
                title={
                  <Title
                    level={4}
                    style={{
                      color: '#393874',
                      marginBottom: 5,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    {auction.code} - {auction.admin_unit_one?.name}{' '}
                    {auction.admin_unit_two?.name}{' '}
                    {auction.admin_unit_three?.name}
                  </Title>
                }
                description={
                  <Breadcrumb
                    separator={<ArrowRightOutlined />}
                    items={[
                      {
                        title: 'Эхлэл',
                        href: '/',
                      },
                      {
                        title: 'Дуудлага худалдаа',
                        href: '/auction',
                      },
                      {
                        title: 'Дуудлага худалдаа дэлгэрэнгүй',
                        className: 'breadcrumb-active',
                      },
                    ]}
                  />
                }
              />
              <Space size="middle" wrap>
                {isRegistrationStart === true &&
                  isRegistrationEnd === false && (
                    <Button
                      type="primary"
                      onClick={() => {
                        joinAuction()
                      }}
                    >
                      Дуудлага худалдаанд оролцох
                    </Button>
                  )}
                {isAuctionStart === true && (
                  <Button style={{ borderColor: '#F67E0E', color: '#F67E0E' }}
                    onClick={() => {setIsShowBiddingModal(true)}}>
                    Үнийн санал харах
                  </Button>
                )}
                <Button
                  style={{ borderColor: '#FF1D1D', color: '#FF1D1D' }}
                  onClick={() => {
                    window.open(
                      getGoogleMapUrl(auction?.land?.geojson),
                      '_blank',
                      'noreferrer'
                    )
                  }}
                >
                  Замчлах
                </Button>
              </Space>
            </Flex>
            <Row gutter={20}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                {!isAuctionEnd && isRegistrationEnd && (
                  <Card style={{ marginBottom: 20 }}>
                    <CheckCircleOutlined style={{ color: '#1AA752' }} />
                    <Text
                      style={{
                        marginLeft: 8,
                        color: '#171441',
                        fontWeight: 600,
                      }}
                    >
                      Оролцох хүсэлт
                    </Text>
                    <Divider
                      style={{ marginTop: 10, borderColor: '#FF0000' }}
                    />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: '#FF0000', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <>
                          <Text
                            style={{
                              color: '#171441',
                              fontWeight: 600,
                              display: 'block',
                            }}
                          >
                            Дуудлага худалдааны оролцогч бүртгэж дууссан байна
                          </Text>
                          <Text style={{ color: '#FF0000', fontWeight: 600 }}>
                            Уг дуудлага худалдааны бүртгэл:{' '}
                            {auction.end_reg_date} -д дууссан байна
                          </Text>
                        </>
                      }
                    />
                  </Card>
                )}
                {isRegistrationStart === false && (
                  <>
                    <Card style={{ marginBottom: 20 }}>
                      <CheckCircleOutlined style={{ color: '#1AA752' }} />
                      <Text
                        style={{
                          marginLeft: 8,
                          color: '#171441',
                          fontWeight: 600,
                        }}
                      >
                        Оролцох хүсэлт
                      </Text>
                      <Divider
                        style={{ marginTop: 10, borderColor: '#1AA752' }}
                      />
                      <Meta
                        avatar={
                          <div
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 6,
                              backgroundColor: '#F5F7F9',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CheckCircleFilled
                              style={{ color: '#1AA752', fontSize: 20 }}
                            />
                          </div>
                        }
                        description={
                          <Text style={{ color: '#1AA752', fontWeight: 600 }}>
                            Дуудлага худалдааны оролцогч бүртгэж эхлээгүй байна.
                            Оролцогч бүртгэж эхлэх огноо:{' '}
                            {auction.start_reg_date}
                          </Text>
                        }
                      />
                    </Card>
                  </>
                )}
                {isRegistrationStart === true &&
                  isRegistrationEnd === false && (
                    <>
                      <Card style={{ marginBottom: 20 }}>
                        <CheckCircleOutlined style={{ color: '#1AA752' }} />
                        <Text
                          style={{
                            marginLeft: 8,
                            color: '#171441',
                            fontWeight: 600,
                          }}
                        >
                          Оролцох хүсэлт
                        </Text>
                        <Divider
                          style={{ marginTop: 10, borderColor: '#1AA752' }}
                        />
                        <Meta
                          avatar={
                            <div
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: 6,
                                backgroundColor: '#F5F7F9',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <CheckCircleFilled
                                style={{ color: '#1AA752', fontSize: 20 }}
                              />
                            </div>
                          }
                          description={
                            <Text style={{ color: '#1AA752', fontWeight: 600 }}>
                              Дуудлага худалдааны оролцогч бүртгэж байна.
                              Оролцогч бүртгэж дуусах огноо:{' '}
                              {auction.end_reg_date}
                            </Text>
                          }
                        />
                        {!isAuth && (
                          <>
                            <Divider
                              style={{ marginTop: 10, borderColor: '#0654DE' }}
                            />
                            <Meta
                              className="auction-detail-request-meta"
                              style={{
                                backgroundColor: 'rgba(6, 84, 222, 0.1)',
                                borderRadius: 6,
                                padding: 20,
                                marginBottom: 20,
                                position: 'relative',
                              }}
                              avatar={
                                <div
                                  style={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: 6,
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <UserAddOutlined
                                    style={{ color: '#393874', fontSize: 20 }}
                                  />
                                </div>
                              }
                              description={
                                <>
                                  <Text
                                    style={{
                                      color: '#000000',
                                      fontWeight: 600,
                                      display: 'block',
                                      marginBottom: 14,
                                    }}
                                  >
                                    Та дуудлага худалдаанд оролцохыг хүсвэл
                                    бүртгэлтэй хэрэглэгчээр нэвтэрч орно уу
                                  </Text>
                                  <Button type="primary" style={{ width: 150 }} onClick={() => {setOpenDAN(true)}}>
                                    Нэвтрэх
                                  </Button>
                                </>
                              }
                            />
                          </>
                        )}
                      </Card>
                    </>
                  )}
                {isAuctionEnd && (
                  <Card style={{ marginBottom: 20 }}>
                    <CheckCircleOutlined style={{ color: '#1AA752' }} />
                    <Text
                      style={{
                        marginLeft: 8,
                        color: '#171441',
                        fontWeight: 600,
                      }}
                    >
                      Оролцох хүсэлт
                    </Text>
                    <Divider
                      style={{ marginTop: 10, borderColor: '#FF0000' }}
                    />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: '#FF0000', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <>
                          <Text
                            style={{
                              color: '#171441',
                              fontWeight: 600,
                              display: 'block',
                            }}
                          >
                            Дуудлага худалдаа явагдаж дууссан байна
                          </Text>
                          <Text style={{ color: '#FF0000', fontWeight: 600 }}>
                            Уг дуудлага худалдаа: {auction.end_date} -д дууссан
                            байна
                          </Text>
                        </>
                      }
                    />
                    {auction?.auction_close?.auction_close_type && (
                      <>
                        <Meta
                          style={{ marginTop: 20 }}
                          avatar={
                            <div
                              style={{
                                width: 40,
                                height: 40,                                
                                borderRadius: 6,
                                backgroundColor: '#F5F7F9',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <CheckCircleFilled
                                style={{ color: '#1AA752', fontSize: 20 }}
                              />
                            </div>
                          }
                          description={
                            <>
                              <Text
                                style={{
                                  color: '#171441',
                                  fontWeight: 600,
                                  display: 'block',
                                }}
                              >
                                Хаагдсан байдал: {auction?.auction_close?.auction_close_type.name}
                              </Text>                            
                            </>
                          }
                        />
                      </>
                    )}
                    
                  </Card>
                )}
                {isAuctionStart === true && isAuctionEnd === false && (
                  <>
                    <Card style={{ marginBottom: 20 }}>
                      <CheckCircleOutlined style={{ color: '#1AA752' }} />
                      <Text
                        style={{
                          marginLeft: 8,
                          color: '#171441',
                          fontWeight: 600,
                        }}
                      >
                        Дуудлага худалдааны мэдээлэл
                      </Text>
                      <Divider
                        style={{ marginTop: 10, borderColor: '#1AA752' }}
                      />
                      <Meta
                        avatar={
                          <div
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 6,
                              backgroundColor: '#F5F7F9',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CheckCircleFilled
                              style={{ color: '#1AA752', fontSize: 20 }}
                            />
                          </div>
                        }
                        description={
                          <Text style={{ color: '#1AA752', fontWeight: 600 }}>
                            Дуудлага худалдаа явагдаж байна: {auction.end_date}
                          </Text>
                        }
                      />
                    </Card>
                  </>
                )}

                <Card style={{ marginBottom: 20 }}>
                  <Flex
                    style={{ position: 'relative' }}
                    justify="space-between"
                  >
                    <div style={{ width: '90%' }}>
                      <Image
                        src="/images/icon-map.png"
                        width={13}
                        height={13}
                        alt=""
                        preview={false}
                        style={{ marginBottom: 4 }}
                      />
                      <Text
                        style={{
                          marginLeft: 8,
                          color: '#171441',
                          fontWeight: 600,
                        }}
                      >
                        Газрын фото зураг
                      </Text>
                      <Divider style={{ marginTop: 10 }} />
                    </div>
                    {/*<Button
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 6,
                        background: '#F5F7F9',
                      }}
                      icon={<DownloadOutlined />}
                    ></Button>*/}
                  </Flex>

                  <Image.PreviewGroup>                    
                    {auction.documents?.map((item: any, index: any) => {
                      
                      if(item.file_type === 'images') {
                        if (found) return null

                        found = true;
                        return (
                          <Image
                            key={index}
                            width={'100%'}
                            height={293}
                            style={{ objectFit: 'contain' }}
                            src={CONFIGS.URL_AUCTION_ROOT + item.file_url}
                            fallback="/images/noimage.png"
                          />
                        )
                      }
                    })}
                    {auction.documents?.map((item: any, index: any) => {
                      if(item.file_type === 'images') {
                        return <RenderComponentImage item={item} key={index} />
                      }
                    })}
                  </Image.PreviewGroup>
                </Card>
                <Card style={{ marginBottom: 20, border: '2px solid #FF1D1D' }}>
                  <Flex justify="space-between">
                    <Link
                      href={getGoogleMapUrl(auction?.land?.geojson)}
                      target="_blank"
                      style={{
                        marginLeft: 8,
                        fontWeight: 600,
                        textDecoration: 'underline',
                      }}
                    >
                      Байршил Google Map дээр харах
                    </Link>
                  </Flex>
                  <Divider style={{ marginTop: 10 }} />
                  <MapView geoJson={auction?.land?.geojson} />
                </Card>

                <Card style={{ marginBottom: 20 }}>
                  <Flex
                    style={{ position: 'relative' }}
                    justify="space-between"
                  >
                    <div style={{ width: '100%' }}>
                      <Image
                        src="/images/icon-map.png"
                        width={13}
                        height={13}
                        alt=""
                        preview={false}
                        style={{ marginBottom: 4 }}
                      />
                      <Text
                        style={{
                          marginLeft: 8,
                          color: '#171441',
                          fontWeight: 600,
                        }}
                      >
                        360<sup>o</sup> зураг
                      </Text>
                      <Divider style={{ marginTop: 10 }} />
                      {/* <PanoramaImage panoImg={panoImg}/> */}
                    </div>

                  </Flex>
                </Card>
                {auction?.youtube_url && (
                  <Card style={{ marginBottom: 20 }}>
                    <Image
                      src="/images/icon-youtube.png"
                      width={13}
                      height={13}
                      alt=""
                      preview={false}
                      style={{ marginBottom: 4 }}
                    />
                    <Text
                      style={{
                        marginLeft: 8,
                        color: '#171441',
                        fontWeight: 600,
                      }}
                    >
                      Youtube видео
                    </Text>
                    <Divider style={{ marginTop: 10, borderColor: '#0654DE' }} />
                    <div style={{ maxWidth: '100%' }}>
                      <iframe
                        style={{ width: '100%' }}
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${auction.youtube_url}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </Card>
                )}
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder-red.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Холбоотой бичиг баримтууд
                  </Text>
                  <Divider style={{ marginTop: 10, borderColor: '#0654DE' }} />
                  <Table
                    rowKey={(record) => `id-${record.id}`}
                    pagination={false}
                    columns={documentColumns}
                    dataSource={auctionDocuments}
                    scroll={{ y: 250 }}                    
                    locale={{
                      emptyText: <Empty description="Оруулсан файл байхгүй байна" />,
                    }}
                  />
                </Card>
                
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-phone.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Холбоо барих мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <Meta
                    style={{ marginBottom: 10 }}
                    avatar={
                      <Image
                        src="/images/icon-contact-home.jpg"
                        width={31}
                        height={31}
                        alt=""
                        preview={false}
                      />
                    }
                    title={
                      <Text
                        style={{
                          color: '#000000',
                          fontSize: 14,
                          fontWeight: 600,
                          width: 150,
                        }}
                      >
                        Зар оруулсан байгуулга
                      </Text>
                    }
                    description={
                      <Text
                        style={{
                          color: '#000000',
                          fontSize: 12,
                          fontWeight: 400,
                          width: 150,
                        }}
                      >
                        {auction?.department?.organization?.name}
                      </Text>
                    }
                  />
                  <Meta
                    style={{ marginBottom: 10 }}
                    avatar={
                      <Image
                        src="/images/icon-contact-phone.jpg"
                        width={31}
                        height={31}
                        alt=""
                        preview={false}
                      />
                    }
                    title={
                      <Text
                        style={{
                          color: '#000000',
                          fontSize: 14,
                          fontWeight: 600,
                          width: 150,
                        }}
                      >
                        Холбоо барих утас:
                      </Text>
                    }
                    description={
                      <Text
                        style={{
                          color: '#000000',
                          fontSize: 12,
                          fontWeight: 400,
                          width: 150,
                        }}
                      >
                        {auction?.contact_phone}
                      </Text>
                    }
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-visa-card.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Хураамж болон дэнчингийн мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <Descriptions column={1} className="auction-table">
                    <Descriptions.Item
                      label="Хураамж"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Text style={{ color: '#FF0000' }}>
                        {SmartHelper.formatCurrency(auction.fee)}₮
                      </Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Хураамж тушаах данс"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Text style={{ color: '#FF0000' }}>
                        {auction.account_huraamj}
                      </Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Дэнчин"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Text style={{ color: '#FF0000' }}>
                        {SmartHelper.formatCurrency(auction.pawn)}₮
                      </Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Хураамж тушаах данс"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Text style={{ color: '#FF0000' }}>
                        {auction.account_denchin}
                      </Text>
                    </Descriptions.Item>
                    {/*<Descriptions.Item
                      label="Нийт"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Tag color="#1AA752" style={priceTagStyle}>
                        2,697,500 ₮
                      </Tag>
                    </Descriptions.Item>*/}
                  </Descriptions>
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Газрын ерөнхий мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <Descriptions column={1} className="auction-table">
                    <Descriptions.Item
                      label="Газрын төрөл"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {(auction?.land?.has_property === null ||
                        auction?.land?.has_property === false) && (
                        <>Үл хөдлөх хөрөнгөгүй газар</>
                      )}

                      {auction?.land?.has_property === true && (
                        <>Үл хөдлөх хөрөнгөтэй газар</>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Анхны үнэ"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Tag color="#1AA752" style={priceTagStyle}>
                        {SmartHelper.formatCurrency(auction.auction_price)} ₮
                      </Tag>
                    </Descriptions.Item>
                    {/*<Descriptions.Item
                      label="Сүүлийн үнэ"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Tag color="#1AA752" style={priceTagStyle}>
                        1,908,000 ₮
                      </Tag>
                    </Descriptions.Item>*/}
                    <Descriptions.Item
                      label="Явагдах хэлбэр"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {(auction?.is_auction_online === null ||
                        auction?.is_auction_online === true) && <>Цахимаар</>}

                      {auction?.is_auction_online === false && <>Танхимаар</>}
                    </Descriptions.Item>
                    {/*<Descriptions.Item
                      label="Хаагдсан байдал"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Tag color="#0654DE" style={priceTagStyle}>
                        Үнэ төлөөгүй
                      </Tag>
                    </Descriptions.Item>*/}
                    <Descriptions.Item
                      label="Хаанаас зарласан"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {auction?.source?.name}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Газрын хэмжээ"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {auction?.land?.area_m2} м2
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Газрын зориулалт"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {auction?.land?.land_use_type?.description}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Газар ашиглалтын хэлбэр"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {SmartHelper.getRightType(auction?.land?.land_right_type)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Оролцогч бүртгэх хугацаа"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {auction.start_reg_date} / {auction.end_reg_date}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Дуудлага худалдаа явагдах хугацаа"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {auction.start_date} / {auction.end_date}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Бусад олон нийтийн хэрэгслээр зарласан талаар"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {auction.other_announce}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="ДХ явагдах газрын хаяг"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {(auction?.is_auction_online === null ||
                        auction?.is_auction_online === true) && <>Цахимаар</>}

                      {auction?.is_auction_online === false &&
                        auction.auction_address_detail}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Тайлбар"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {auction.auction_detail}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Нэгж талбарын мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  {auction?.land_type?.is_cadastre_parcel == true && (
                    <Descriptions column={1} className="auction-table">
                      <Descriptions.Item
                        label="Нэгж талбарын дугаар"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{
                          paddingBottom: 10,
                        }}
                      >
                        {parcelDetail?.resultParcel?.map(
                          (item: any, index: any) => {
                            return <>{item.parcel_id}</>
                          }
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Хуучин нэгж талбарын дугаар"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{
                          paddingBottom: 10,
                        }}
                      >
                        {parcelDetail?.resultParcel?.map(
                          (item: any, index: any) => {
                            return <>{item.old_parcel_id}</>
                          }
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Аймаг хот"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{
                          paddingBottom: 10,
                        }}
                      >
                        {parcelDetail?.resultParcel?.map(
                          (item: any, index: any) => {
                            return <>{item.au1_name}</>
                          }
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Сум дүүрэг"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{
                          paddingBottom: 10,
                        }}
                      >
                        {parcelDetail?.resultParcel?.map(
                          (item: any, index: any) => {
                            return <>{item.au2_name}</>
                          }
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Баг Хороо"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{
                          paddingBottom: 10,
                        }}
                      >
                        {parcelDetail?.resultParcel?.map(
                          (item: any, index: any) => {
                            return <>{item.au3_name}</>
                          }
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Гудамж"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{
                          paddingBottom: 10,
                        }}
                      >
                        {parcelDetail?.resultParcel?.map(
                          (item: any, index: any) => {
                            return <>{item.address_neighbourhood}</>
                          }
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Газрын хэмжээ"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{
                          paddingBottom: 10,
                        }}
                      >
                        {parcelDetail?.resultParcel?.map(
                          (item: any, index: any) => {
                            return <>{item.area_m2} м2</>
                          }
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                  {auction?.land_type?.is_project_parcel == true && (
                    <Descriptions column={1} className="auction-table">
                      <Descriptions.Item
                        label="Нэгж талбарын дугаар"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{
                          paddingBottom: 10,
                        }}
                      >
                        {parcelDetail?.resultProjectParcel?.parcel_id}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Төлөвлөлтийн арга хэмжээний нэр"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{
                          paddingBottom: 10,
                        }}
                      >
                        {parcelDetail?.resultProjectParcel?.plan_zone_name}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Газрын нэр"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{
                          paddingBottom: 10,
                        }}
                      >
                        {parcelDetail?.resultProjectParcel?.gazner}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Газрын хэмжээ"
                        labelStyle={descriptionItemLabelStyle}
                        contentStyle={descriptionItemContentStyle}
                        style={{
                          paddingBottom: 10,
                        }}
                      >
                        {parcelDetail?.resultProjectParcel?.area_m2} м2
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                </Card>
                {parcelDetail?.resultFee && (
                  <Card style={{ marginBottom: 20 }}>
                    <Image
                      src="/images/icon-folder.png"
                      width={13}
                      height={13}
                      alt=""
                      preview={false}
                      style={{ marginBottom: 4 }}
                    />
                    <Text
                      style={{
                        marginLeft: 8,
                        color: '#171441',
                        fontWeight: 600,
                      }}
                    >
                      Газрын төлбөр, татварын мэдээлэл
                    </Text>
                    {parcelDetail?.resultFee?.map((item: any, index: any) => {
                      return (
                        <>
                          <Divider style={{ marginTop: 10 }} />
                          <Descriptions column={1} className="auction-table">
                            <Descriptions.Item
                              label="Төлбөр, татварын бүс"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {item.zone_type} | {item.zone_name} |{' '}
                              {item.zone_no}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Газрын төлбөр, татварын хувь хэмжээ"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {item.confidence_percent}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Газрын төлбөр, татвар/1m2/"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {SmartHelper.formatCurrency(item.base_fee_per_m2)}{' '}
                              ₮
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Газрын төлбөр, татварын дүн"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {SmartHelper.formatCurrency(item.payment)} ₮
                            </Descriptions.Item>
                          </Descriptions>
                        </>
                      )
                    })}
                  </Card>
                )}
                
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Газрын үнэлгээний мэдээлэл
                  </Text>
                  {parcelDetail?.resultLevel?.map((item: any, index: any) => {
                    return (
                      <>
                        <Divider style={{ marginTop: 10 }} />
                        <Descriptions column={1} className="auction-table">
                          <Descriptions.Item
                            label="Газрын суурь үнэлгээний зэрэглэл /Бүс/"
                            labelStyle={largeDescriptionItemLabelStyle}
                            contentStyle={largeDescriptionItemContentStyle}
                            style={{
                              paddingBottom: 10,
                            }}
                          >
                            {item.level_type} | {item.level_name} |{' '}
                            {item.level_no} | {item.level_type_name}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label="Газрын суурь үнэ /1га/"
                            labelStyle={largeDescriptionItemLabelStyle}
                            contentStyle={largeDescriptionItemContentStyle}
                            style={{
                              paddingBottom: 10,
                            }}
                          >
                            {SmartHelper.formatCurrency(item.base_price)} ₮
                          </Descriptions.Item>
                          <Descriptions.Item
                            label="Дуудлага худалдааны анхны үнийн итгэлцүүр"
                            labelStyle={largeDescriptionItemLabelStyle}
                            contentStyle={largeDescriptionItemContentStyle}
                            style={{
                              paddingBottom: 10,
                            }}
                          >
                            {item.confidence}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label="Дуудлага худалдааны анхны үнэ"
                            labelStyle={largeDescriptionItemLabelStyle}
                            contentStyle={largeDescriptionItemContentStyle}
                            style={{
                              paddingBottom: 10,
                            }}
                          >
                            {SmartHelper.formatCurrency(item.auction_amount)} ₮
                          </Descriptions.Item>
                        </Descriptions>
                      </>
                    )
                  })}
                  {parcelDetail?.resultProjectPayment?.map(
                    (item: any, index: any) => {
                      return (
                        <div key={index}>
                          <Divider style={{ marginTop: 10 }} />
                          <Descriptions column={1} className="auction-table">
                            <Descriptions.Item
                              label="Газрын суурь үнэлгээний зэрэглэл /Бүс/"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {item.level_type} | {item.level_name} |{' '}
                              {item.level_no} | {item.level_type_name}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Газрын суурь үнэ /1га/"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {SmartHelper.formatCurrency(item.base_price)} ₮
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Дуудлага худалдааны анхны үнийн итгэлцүүр"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {item.confidence}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Дуудлага худалдааны анхны үнэ"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {SmartHelper.formatCurrency(item.auction_amount)}{' '}
                              ₮
                            </Descriptions.Item>
                          </Descriptions>
                        </div>
                      )
                    }
                  )}
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Газрын төлөв байдал
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <LandStatus id={detailId} active={1} />
                </Card>

                {/*<Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Төлбөрийн мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />

                  <Table pagination={false} columns={FeeColumns} dataSource={data} 
                    locale={{
                      emptyText: <Empty description="Бичлэг байхгүй байна" />,
                    }}
                  />

                  
                </Card>*/}


                {parcelDetail?.resultPrice && (
                  <Card style={{ marginBottom: 20 }}>
                    <Image
                      src="/images/icon-folder.png"
                      width={13}
                      height={13}
                      alt=""
                      preview={false}
                      style={{ marginBottom: 4 }}
                    />
                    <Text
                      style={{
                        marginLeft: 8,
                        color: '#171441',
                        fontWeight: 600,
                      }}
                    >
                      Газрын дэлгэрэнгүй мэдээлэл
                    </Text>
                    <Divider style={{ marginTop: 10 }} />
                    <Descriptions column={1} className="auction-table">
                      {parcelDetail?.resultPrice?.factor_values?.map(
                        (item: any, index: any) => {
                          return (
                            <>
                              <Descriptions.Item
                                label={item.name}
                                labelStyle={largeDescriptionItemLabelStyle}
                                contentStyle={largeDescriptionItemContentStyle}
                                style={{
                                  paddingBottom: 10,
                                }}
                                key={index}
                              >
                                {item.factor_value}
                              </Descriptions.Item>
                            </>
                          )
                        }
                      )}
                    </Descriptions>
                  </Card>
                )}
              </Col>
            </Row>
            <FooterLayout isFull />
          </Col>
        </Row>

        <Modal
          title="Анхааруулга"
          open={isShowModal}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="ok" type="primary" onClick={handleOk}>
              Хаах
            </Button>,
          ]}
        >
          <div
            style={{ color: isSuccess ? 'green' : 'red' }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </Modal>
        <Modal
          title="Үнийн санал"
          open={isShowBiddingModal}
          onOk={handleBiddingOk}
          onCancel={handleBiddingCancel}
          footer={[
            <Button key="ok" type="primary" onClick={handleBiddingCancel}>
              Хаах
            </Button>,
          ]}
        >
          <div>
          <Table
              rowKey={(record) => `id-${record.id}`}
              pagination={false}
              columns={columns}
              dataSource={bids}
              scroll={{ y: 250 }}              
              onRow={(record: any) => ({
                style: {
                  color: record.color,
                }
              })}
              locale={{
                emptyText: <Empty description="Бичлэг байхгүй байна" />,
              }}
            />
          </div>
        </Modal>
        <Modal
          title="Файлын мэдээлэл"
          open={isShowDocumentModal && documentUrl !== ""}
          onOk={handleDocumentOk}
          onCancel={handleDocumentCancel}
          footer={[
            <Button key="ok" type="primary" onClick={handleDocumentCancel}>
              Хаах
            </Button>,
          ]}
          width={900}
        >
          <div>
            <iframe src={documentUrl} height={500} width={800}>

            </iframe>
          </div>
        </Modal>
      </Content>
    </Layout>
  )
}

export default AuctionDetail
