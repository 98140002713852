import React, { useEffect, useState } from 'react'
import {
  Layout,
  Row,
  Col,
  Form,
  Typography,
  Divider,
  Image,
  Button,
  Input,
  Card,
  Space,
  Upload,
  Modal,
  Spin,
  ConfigProvider,
  DatePicker
} from 'antd'
import {
  CheckCircleOutlined,
  CheckCircleFilled,
} from '@ant-design/icons'

import mnMN from 'antd/lib/locale/mn_MN'
import dayjs from 'dayjs'
import 'dayjs/locale/mn'

import FooterLayout from './../../../layouts/Footer'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import * as CONFIGS from '../../../config/Configs'
import * as WEBSERVICES from '../../../config/WebServices'

import SmartHelper from 'helper/SmartHelper'

dayjs.locale('mn')

const { Content } = Layout
const { Meta } = Card
const { Text, Title, Paragraph } = Typography
const {Dragger} = Upload

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

const requestFormLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
}

const AuctionJoin: React.FC = () => {
  const [feeForm] = Form.useForm()
  const [pawnForm] = Form.useForm()

  const { id: detailId } = useParams()

  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false)

  const [auction, setAuction] = useState({} as any)

  const [feeBase64Image, setFeeBase64Image] = useState(null as any);
  const [pawnBase64Image, setPawnBase64Image] = useState(null as any);
  const [pawnRefundAccount, setPawnRefundAccount] = useState("" as any)

  const [feeDate, setFeeDate] = useState(dayjs() as any)
  const [pawnDate, setPawnDate] = useState(dayjs() as any)

  const [isShowModal, setIsShowModal] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [message, setMessage] = useState("")

  const [isAuth, setIsAuth] = useState(false)  
  const [auth, setAuth] = useState([] as any)  
  const [entity, setEntity] = useState([] as any)  
  const [participant, seParticipant] = useState([] as any)  

  const [isEntityApproved, setIsEntityApproved] = useState(false);
  const [isParticipantApproved, setIsParticipantApproved] = useState(false);
  const [defaultFeeFile, setDefaultFeeFile] = useState("/images/icon-upload-multiple.jpg");
  const [defaultPawnFile, setDefaultPawnFile] = useState("/images/icon-upload-multiple.jpg");

  const [backHome, setBackHome] = useState(false);

  useEffect(() => {
    const fetchAuctionData = async () => {
      
      let url = WEBSERVICES.URL_AUCTION_DETAIL+detailId;
        
      await fetch(url)
          .then((response) => response.json())
          .then(async (responseJson) => {
            let entity = SmartHelper.getEntity();
            let auction = null;

            setIsAuth(SmartHelper.getSession('isAuth') as boolean | false);
            setAuth(SmartHelper.getAuth());    
            setEntity(entity);

            if(responseJson && responseJson.status === true) {
              auction = responseJson.data;
              setAuction(responseJson.data);
              await findParticipant(auction.id, entity.id);
            } else {
              setAuction({});
            }
            
            setIsEntityApproved(entity.is_active === true? true: false);

            if(!entity || !auction) {
              setBackHome(true);
            }
          })
          .catch((error) => {
              setAuction({});
              setBackHome(true);
          });       
    }    

    if(backHome) {
      navigate("/");
      navigate(0);
    }

    fetchAuctionData().then(() => {
      setLoaded(true);
    });
  }, [detailId, backHome])

  const findParticipant = async (auctionId: any, entityId: any ) => {
    let url = WEBSERVICES.URL_AUCTION_PARTICIPANT+"?auction_id="+auctionId+"&entity_id="+entityId;
    
    let participant: any = null;
    await fetch(url)
      .then((response) => response.json())
      .then(async (responseJson) => {
        if(responseJson?.status === true) {            
          participant = responseJson?.data;                  

          pawnForm.setFieldValue("pawn_refund_account", participant.pawn_return_account);
          setPawnRefundAccount(participant.pawn_return_account);

          if(participant.fee_date != null && participant.fee_date != "")
          {
            setFeeDate(dayjs(participant.fee_date, 'YYYY-MM-DD'))
          }

          if(participant.pawn_date != null && participant.pawn_date != "")
          {
            setPawnDate(dayjs(participant.pawn_date, 'YYYY-MM-DD'))
          }

          if(participant.fee_file != null && participant.fee_file != "")
          {
            setDefaultFeeFile(CONFIGS.URL_PAYMENT_ROOT+participant.fee_file)
            const base64 = await convertImageToBase64(CONFIGS.URL_PAYMENT_ROOT_IMAGE+participant.fee_file);            
            setFeeBase64Image(base64);
          }

          if(participant.pawn_file != null && participant.pawn_file != "")
          {
            setDefaultPawnFile(CONFIGS.URL_PAYMENT_ROOT+participant.pawn_file)
            const base64 = await convertImageToBase64(CONFIGS.URL_PAYMENT_ROOT_IMAGE+participant.pawn_file);
            setPawnBase64Image(base64);
          }
  

          seParticipant(participant);

          setIsParticipantApproved(participant.approved === 1? true: false);
        }
      })
      .catch((error) => {

      })
  }

  const convertImageToBase64 = async (imageUrl: any): Promise<string | null> => {
    try {
      const response = await fetch(imageUrl, {
        mode: 'cors',
      });
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string);
        };

        reader.onerror = () => {
          reject(new Error('Failed to convert image to base64'));
        };
        
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      return null;
    }
  };

  const handleFeeUpload = (file: any) => {
    if (file.type.startsWith('image/')) {      
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {      
        setFeeBase64Image(reader.result);
      };
    } else {
      console.log('error');
    }
    return false;
  }

  const handlePawnUpload = (file: any) => {
    if (file.type.startsWith('image/')) {      
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {      
        setPawnBase64Image(reader.result);
      };
    } else {
      console.log('error');
    }
    return false;
  }

  const handleFeePayment = (date: any, dateString: string | string[]) => {
    if (date && date.isValid()) {
      setFeeDate(date);
    }
  }

  const handlePawnPayment = (date: any, dateString: string | string[]) => {
    if (date && date.isValid()) {
      setPawnDate(date);
    }
  }

  const sendRequest = async() => {
    setLoaded(false);

    let msg = '';
    let feeDateSend = feeDate.format("YYYY-MM-DD");
    let feePawnSend = pawnDate.format("YYYY-MM-DD");

    if(!feeDateSend) {
      msg += '<p>Дэнчин гүйлгээний огноо заавал оруулна.</p>';
    }

    if(!feeBase64Image) {
      msg += '<p>Дэнчин гүйлгээний баримт заавал оруулна.</p>';
    }

    if(!feePawnSend) {
      msg += '<p>Дэнчин гүйлгээний огноо заавал оруулна.</p>';;
    }

    if(!pawnBase64Image) {
      msg += '<p>Дэнчин гүйлгээний баримт заавал оруулна.</p>';;
    }

    if(!pawnRefundAccount) {
      msg += '<p>Дэнчин буцаах мэдээлэл оруулна.</p>';;
    }

    if(msg == "") {
      try {
        const response = await fetch(WEBSERVICES.URL_AUCTION_PARTICIPANT_STORE, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            entity_id: entity.id,
            auction_id: auction.id,
            pawn_date: feePawnSend,
            fee_date: feeDateSend,
            pawn_return_account: pawnRefundAccount,
            pawn_file: pawnBase64Image,
            fee_file: feeBase64Image
          })
        });
  
        if (!response.ok) {
          throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
        }
  
        const result = await response.json();
        if(result.status === true) {
          let msg = '<p>Амжилттай таны хүсэлтийг илгээлээ. Таны мэдээллийг шалгаж баталгаажуулсанаар та дараа дараагийн үйл ажиллагаанд орж болно. Таны хүсэлтийг баталгаажуулах үед гар утсанд мессеж ирэх болно.</p>';
          setIsShowModal(true);
          setMessage(msg);
          setIsSuccess(true);
          setLoaded(true);
        } else {
          throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
        }
      } catch (error) {
        let msg = `<p>${error}</p>`;
        setIsShowModal(true);
        setMessage(msg);
        setIsSuccess(false);
        setLoaded(true);
      }
    } else {
      setIsShowModal(true);
      setMessage(msg);
      setIsSuccess(false);
      setLoaded(true);
    }
  }

  const handleOk = () => {
    setIsShowModal(false);
  }

  const handleCancel = () => {
    setIsShowModal(false);
  }

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
      {
          (!loaded) &&
            <Spin size="large" fullscreen/>
        }
        <Row gutter={20}>
          <Title
            level={4}
            style={{
              color: '#393874',
              width: '100%',
              textAlign: 'center',
              textTransform: 'uppercase',
              marginBottom: 20,
            }}
          >
            ({auction?.code}) дугаартай дуудлага худалдаанд оролцох
          </Title>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Card style={{ marginBottom: 20 }}>
              <CheckCircleOutlined style={{ color: '#1AA752' }} />
              <Text
                style={{
                  marginLeft: 8,
                  color: '#171441',
                  fontWeight: 600,
                }}
              >
                Дуудлага худалдааны мэдээлэл
              </Text>
              {isEntityApproved? (
                <>
                  <Divider style={{ marginTop: 10, borderColor: '#1AA752' }} />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: '#1AA752', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <Text style={{ color: '#1AA752', fontWeight: 400 }}>
                          Таны бүртгэл баталгаажсан байна
                        </Text>
                      }
                    />
                  </>
              ): (
                <>
                  <Divider style={{ marginTop: 10, borderColor: 'red' }} />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: 'red', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <Text style={{ color: 'red', fontWeight: 400 }}>
                          Таны бүртгэл баталгаажаагүй байна. Та хувийн мэдээлэл хэсгээр орж мэдээллээ баталгаажуулна уу.
                        </Text>
                      }
                    />
                </>
              )}

              {isParticipantApproved? (
                <>
                  <Divider style={{ marginTop: 10, borderColor: '#1AA752' }} />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: '#1AA752', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <Text style={{ color: '#1AA752', fontWeight: 400 }}>
                          Таны оролцох хүсэлт баталгаажсан байна. Дуудлага худалдаа явагдах өдөр <strong>{auction.start_date}</strong>
                        </Text>
                      }
                    />
                  </>
              ): (
                <>
                  <Divider style={{ marginTop: 10, borderColor: 'red' }} />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: 'red', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <Text style={{ color: 'red', fontWeight: 400 }}>
                          Таны оролцох хүсэлт баталгаажаагүй байна. Та баталгаажуулах мэдээллээ шинээр илгээх, засварлах боломжтой.
                        </Text>
                      }
                    />
                </>
              )}
              
            </Card>            
            <Row gutter={20}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              {(isEntityApproved && !isParticipantApproved) && (              
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Хураамжын мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <Form
                    layout={'horizontal'}
                    form={feeForm}
                    {...requestFormLayout}
                    style={{
                      maxWidth: '100%',
                    }}
                  >
                    <Form.Item
                      name="amount"
                      labelAlign="left"
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Тушаах дүн
                        </Text>
                      }
                    >
                      <Text style={{ color: '#000000' }}>{SmartHelper.formatCurrency(auction.fee)}₮</Text>
                    </Form.Item>
                    <Form.Item
                      name="description"
                      labelAlign="left"
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Хураамж тушаах дансны мэдээлэл
                        </Text>
                      }
                    >
                      <Text style={{ color: '#000000' }}>{auction.account_huraamj}</Text>
                    </Form.Item>
                    <Form.Item
                      name="description"
                      labelAlign="left"
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Гүйлгээний утга
                        </Text>
                      }
                    >
                      <Text style={{ color: '#000000' }}>
                        ДХ-{auction.code}-{auth.register}
                      </Text>
                    </Form.Item>
                    <Form.Item
                      name="fee_date"
                      labelAlign="left"
                      style={{ textAlign: 'left' }}
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Гүйлгээ хийсэн огноо
                        </Text>
                      }
                    >
                      <ConfigProvider locale={mnMN}>
                        <DatePicker onChange={handleFeePayment} value={feeDate}/>
                      </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                      name="fee_file"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      noStyle
                      wrapperCol={{ span: 24 }}                      
                    >
                      <Dragger
                        name="files"
                        multiple={false}
                        maxCount={1}
                        accept='.jpg, .png'
                        beforeUpload={handleFeeUpload}                        
                        style={{
                          backgroundColor: 'rgba(227, 231, 239, 0.5)',
                          height: 160,
                        }}
                        
                      >
                        {feeBase64Image ? (
                        <img src={feeBase64Image} alt="Uploaded" style={{ maxWidth: '100%' }} />
                        ) : (
                          <div className="ant-upload-drag-icon">
                          <Image                            
                            src={defaultFeeFile}
                            width={160}
                            height={160}
                            alt=""
                            preview={false}
                            style={{ marginBottom: 8 }}
                          />
                        </div>        
                        )}
                                       
                        <p
                          className="ant-upload-text"
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: '#393874',
                          }}
                        >
                          Энд дарж файлаа сонгоно уу
                        </p>
                        <p className="ant-upload-hint">
                          Хураамж тушаасан баримтын зураг (jpg, png формат байна)
                        </p>
                      </Dragger>
                    </Form.Item>
                  </Form>
                </Card>
              )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              {(isEntityApproved && !isParticipantApproved) && (              
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Дэнчингийн мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <Form
                    layout={'horizontal'}
                    form={pawnForm}
                    {...requestFormLayout}
                    style={{
                      maxWidth: '100%',
                    }}
                  >
                    <Form.Item
                      name="amount"
                      labelAlign="left"
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Тушаах дүн
                        </Text>
                      }
                    >
                      <Text style={{ color: '#000000' }}>{SmartHelper.formatCurrency(auction.pawn)}₮</Text>
                    </Form.Item>
                    <Form.Item
                      name="description"
                      labelAlign="left"
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Дэнчин тушаах дансны мэдээлэл
                        </Text>
                      }
                    >
                      <Text style={{ color: '#000000' }}>{auction.account_denchin}</Text>
                    </Form.Item>
                    <Form.Item
                      name="description"
                      labelAlign="left"
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Гүйлгээний утга
                        </Text>
                      }
                    >
                      <Text style={{ color: '#000000' }}>
                        ДХ-{auction.code}-{auth.register}
                      </Text>
                    </Form.Item>
                    <Form.Item
                      name="pawn_date"
                      labelAlign="left"
                      style={{ textAlign: 'left' }}                      
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Гүйлгээ хийсэн огноо
                        </Text>
                      }
                    >
                      <ConfigProvider locale={mnMN}>
                        <DatePicker onChange={handlePawnPayment} value={pawnDate}/>
                      </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                      name="pawn_refund_account"
                      labelAlign="left"
                      style={{ textAlign: 'left' }}                      
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Дэнчин буцаах дансны дугаар
                        </Text>
                      }
                    >
                      <Input onChange={(e) => {setPawnRefundAccount(e.target.value)}} placeholder='Банкны нэр, дансны дугаар, дансны нэр'/>
                    </Form.Item>
                    <Form.Item
                      name="pawn_file"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      noStyle
                      wrapperCol={{ span: 24 }}                      
                    >
                      <Dragger
                        name="files"
                        multiple={false}
                        maxCount={1}
                        accept='.jpg, .png'
                        beforeUpload={handlePawnUpload}                        
                        style={{
                          backgroundColor: 'rgba(227, 231, 239, 0.5)',
                          height: 160,
                        }}
                      >
                        {pawnBase64Image ? (
                        <img src={pawnBase64Image} alt="Uploaded" style={{ maxWidth: '100%' }} />
                        ) : (
                          <div className="ant-upload-drag-icon">
                          <Image                            
                            src={defaultPawnFile}
                            width={160}
                            height={160}
                            alt=""
                            preview={false}
                            style={{ marginBottom: 8 }}
                          />
                        </div>        
                        )}
                                       
                        <p
                          className="ant-upload-text"
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: '#393874',
                          }}
                        >
                          Энд дарж файлаа сонгоно уу
                        </p>
                        <p className="ant-upload-hint">
                          Дэнчин тушаасан баримтын зураг (jpg, png формат байна)
                        </p>
                      </Dragger>
                    </Form.Item>
                  </Form>
                </Card>
              )}
              </Col>
            </Row>
            {(isEntityApproved && !isParticipantApproved) && (              
              <Card style={{ marginBottom: 20 }}>
                <Divider style={{ marginTop: 10 }} />
                <Form
                  layout={'horizontal'}
                  {...requestFormLayout}
                  style={{
                    maxWidth: '100%',
                  }}
                >
                  <Form.Item
                    wrapperCol={{ span: 24 }}
                    style={{ marginTop: 20 }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ backgroundColor: '#1AA752', width: 185 }}
                        onClick={() => {sendRequest()}}
                      >
                        Хүсэлт илгээх
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Card>
            )}
            <div className="hide-on-md-or-less" style={{ marginTop: 100 }}>
              <FooterLayout />
            </div>
          </Col>
        </Row>
        <Modal
            title="Анхааруулга"
            open={isShowModal}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="ok" type="primary" onClick={handleOk}>
                Хаах
              </Button>,
            ]}
          >
          <div style={{color: isSuccess? 'green': 'red'}} dangerouslySetInnerHTML={{ __html: message }} />
        </Modal>
      </Content>
    </Layout>
  )
}

export default AuctionJoin
