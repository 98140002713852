import React, { createContext, useState, ReactNode } from 'react';

interface LayoutContextType {
  openDAN: boolean;
  setOpenDAN: React.Dispatch<React.SetStateAction<boolean>>;
}

const LayoutContext = createContext<LayoutContextType>({
  openDAN: false,
  setOpenDAN: () => {},
});

interface LayoutProviderProps {
  children: ReactNode;
}

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  const [openDAN, setOpenDAN] = useState<boolean>(false);

  return (
    <LayoutContext.Provider value={{ openDAN, setOpenDAN }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContext;