import React, { PropsWithChildren } from 'react'
import { Layout, Flex, Image } from 'antd'

const { Header, Footer } = Layout

const CoverLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const isMobile = window.innerWidth <= 768
  return (
    <Layout>
      <Header
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'transparent',
        }}
      >
        <Flex
          justify={'space-between'}
          align="center"
          wrap="wrap"
          style={{ width: '100%', marginTop: isMobile ? 0 : 45 }}
        >
          <Image
            src="/images/logo-primary.png"
            width={isMobile ? 122 : 244}
            height={isMobile ? 35 : 70}
            alt="Газар зохион байгуулалт, геодиз, зураг зүйн ерөнхий газар"
            preview={false}
          />
            <Image
              src="/images/logo-secondary1.png"
              width={isMobile ? 120 : 280}
              height={isMobile ? 22 : 43}
              alt="Газрын биржийн цахим систем"
              preview={false}
            />
           
        </Flex>
      </Header>
      {children}
      <Footer
        style={{
          textAlign: 'center',
          position: 'absolute',
          bottom: 0,
          height: isMobile ? 'auto' : 65,
          width: '100%',
          backgroundColor: 'rgba(3,16,139,.5)',
          zIndex: 4,
          color: 'white',
        }}
      >
        © {new Date().getFullYear()} www.mle.mn Бүх эрх хуулиар хамгаалагдсан.
        Smart Data LLC бүтээв.
      </Footer>
    </Layout>
  )
}

export default CoverLayout
