import React, { useEffect, useState } from 'react'
import {
  Layout,
  Button,
  Typography,
  Flex,
  Image,
  Card,
  Space,
  Form,
  Input,
  Checkbox,
  Select,
  Collapse,
  Divider,
  ConfigProvider,
  DatePicker,
  Spin,
  Modal,
  Descriptions,
  Tag,
  Table,
  Empty,
} from 'antd'
import {
  DeleteOutlined,
  SearchOutlined,
  InfoCircleOutlined,
  MailOutlined,
} from '@ant-design/icons'
import type { CollapseProps, GetProp } from 'antd'
import MainMap from './../../components/MainMap'

import * as WEBSERVICES from '../../config/WebServices'
import SmartHelper from 'helper/SmartHelper'

import mnMN from 'antd/lib/locale/mn_MN'
import dayjs from 'dayjs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TableProps } from 'antd/lib'

dayjs.locale('mn')

const { Content } = Layout
const { Text, Link } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

const MapPage: React.FC = () => {
  const [form] = Form.useForm()
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false)
  const [results, setResults] = useState([] as any)
  const [sources, setSources] = useState([] as any)
  const [landUses, setLandUses] = useState([] as any)
  const [statuses, setStatuses] = useState([] as any)
  const [layers, setLayers] = useState([] as any)
  const [items, setItems] = useState([] as any)
  const [selectedLayers, setSelectedLayers] = useState([] as any)
  const [searchYearOptions, setSearchYearOptions] = useState([] as any)
  
  const [feeZoneGet, setFeeZoneGet] = useState<{
    au1: Number;
    au2: Number;
    code: Number;
  } | null>(null);  

  const [baseMap, setBaseMap] = useState("voyager")

  const [selectedSearchType, setSelectedSearchType] = useState(searchParams?.get("type")? searchParams?.get("type"): "auction")
  const [selectedSearchYear, setSelectedSearchYear] = useState(new Date().getFullYear())
  const [selectedSearchHasProperty, setSelectedSearchHasProperty] = useState(false as any)
  const [selectedSearchKeyword, setSelectedSearchKeyword] = useState(null as any)
  const [selectedSearchParcel, setSelectedSearchParcel] = useState(null as any)
  const [selectedSearchStatus, setSelectedSearchStatus] = useState(null as any)

  const [shouldSearchMap, setShouldSearchMap] = useState(true);
  const [shouldCallFirstTime, setShouldCallFirstTime] = useState(true);

  const [isShowModal, setIsShowModal] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [title, setTitle] = useState("Дуудлага худалданы мэдээлэл");
  const [message, setMessage] = useState<Element | any>("")
  const [feeInfoMessage, setFeeInfoMessage] = useState<Element | any>("")

  const descriptionItemLabelStyle = {
    flex: 4,
    fontSize: 12,
    fontWeight: 700,
    color: '#171441',
    paddingLeft: 20,
  }
  const descriptionItemContentStyle = {
    flex: 6,
    fontSize: 12,
    fontWeight: 600,
    color: '#393874',
  }
  const largeDescriptionItemLabelStyle = {
    flex: 5,
    fontSize: 12,
    fontWeight: 700,
    color: '#171441',
    paddingLeft: 20,
  }
  const largeDescriptionItemContentStyle = {
    flex: 5,
    fontSize: 12,
    fontWeight: 600,
    color: '#393874',
  }
  const priceTagStyle = {
    fontSize: 12,
    fontWeight: 700,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px 0px 10px',
  }

  useEffect(() => {
    async function fetchData() {
      fetchAuctionFilter()
    }

    if (shouldSearchMap) {
      searchMap();
      setShouldSearchMap(false);
      fetchUrbanLayers();
    }

    if(shouldCallFirstTime) {
      fetchData().then(() => {
        setLoaded(true);
        setShouldCallFirstTime(false);

        let searchYearOptions = [];

        for (let i = new Date().getFullYear(); i >= (new Date().getFullYear()) - 3; i--) {
          searchYearOptions.push({ label: i.toString(), value: i })
        }

        setSearchYearOptions(searchYearOptions);
      })
    }    

    setFeeZoneGet({
      au1: SmartHelper.getSession("au1"),
      au2: SmartHelper.getSession("au2"),
      code: form.getFieldValue('field_fee_zone') === undefined ? [2] : form.getFieldValue('field_fee_zone'),
    });


    
  }, [shouldSearchMap])

  const numberWithCommas = (price: any) => {
    price = price.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(price))
      price = price.replace(pattern, "$1,$2");
    return price;
  }

  type ColumnsType<T extends object> = TableProps<T>['columns'];

  interface DataType {
    key: number;
    feeZone: string;
    confidence: string;
    payment: string;
    payment_amount: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Төлбөрийн бүс',
      dataIndex: 'feeZone',
      key: 'feeZone',
    },
    {
      title: 'Төлбөрийн хувь хэмжээ',
      dataIndex: 'confidence',
      key: 'confidence',
    },
    {
      title: 'Газрын төлбөр 1m2',
      dataIndex: 'payment',
      key: 'payment',
    },
    {
      title: 'Төлбөрийн дүн',
      dataIndex: 'payment_amount',
      key: 'payment_amount',
    },

  ];
   

  const fetchMapList = async(search:any="") => {    
    setLoaded(false);
    let url = WEBSERVICES.URL_MAP_DATA_FIND+"?au1="+SmartHelper.getSession("au1")+"&au2="+SmartHelper.getSession("au2")+"&au3="+SmartHelper.getSession("au3")+search;
    
    await fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
            if(responseJson && responseJson.status === true) {              
              setResults(responseJson.data);
            } else {
              setResults([]);  
            }            
        })
        .catch((error) => {
            setResults([]);
        })
        .finally(() => {
          setLoaded(true);
        });     
  }


  const fetchUrbanLayers = async () => {
    let url = WEBSERVICES.URL_URBAN_PUBLIC_LAYERS;

    await fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
          if(responseJson && responseJson.status === true) {
            setLayers(responseJson.data);
                        
            let parentItems: any[] = [];

            responseJson.data.map((item: any, index: any) => {

              let options: any[] = [];              

              item.children.map((childItem: any, ind: any) => {               
                if(childItem.children && childItem.children.length > 0) {
                  childItem.children.map((childItem1: any, inde: any) => {
                    options = [...options, { label: childItem1.name, value: childItem1.id }]
                  })
                } else {
                  options = [...options, { label: childItem.name, value: childItem.id }]
                }
              })
                            
              let parent = {
                key: item.id,
                label: item.name,
                children: (
                  <>
                    <Checkbox.Group
                      options={options}                        
                      onChange={values => onChangeLayers(values, item.id)}
                      className="map-form-checkbox-group"
                    />
                  </>
                ),
              };

              parentItems = [...parentItems, parent];
            })

            let menuItems: CollapseProps['items'] = parentItems;

            setItems(menuItems);
          } else {
            setLayers([]);  
          }
        })
        .catch((error) => {
          setLayers([]); 
        });     
  }
  
  const searchMap = () => {
    let search = "";
    let searchAu = "";

    search += (selectedSearchType && typeof selectedSearchType !== undefined)?"&type="+selectedSearchType:"";
    search += (selectedSearchYear && typeof selectedSearchYear !== undefined)?"&year="+selectedSearchYear:"";
    search += (typeof selectedSearchHasProperty !== undefined)?"&has_property="+selectedSearchHasProperty:"";
    search += (selectedSearchKeyword && typeof selectedSearchKeyword !== undefined)?"&keyword="+selectedSearchKeyword:"";
    search += (selectedSearchParcel && typeof selectedSearchParcel !== undefined) ? "&parcel=" + selectedSearchParcel : "";    
    search += (selectedSearchStatus && typeof selectedSearchStatus !== undefined) ? "&status=" + selectedSearchStatus : "";    

    fetchMapList(search);

    setFeeZoneGet({
      au1: SmartHelper.getSession("au1"),
      au2: SmartHelper.getSession("au2"),
      code: form.getFieldValue('field_fee_zone') === undefined ? [2] : form.getFieldValue('field_fee_zone'),
    });   

  }
  
  const handleOk = () => {
    setIsShowModal(false);
  }

  const handleCancel = () => {
    setIsShowModal(false);
  }

  const onChangeLayers = (checkedValues: any, parentId: any) => {  
    
    let au2 = SmartHelper.getSession("au2");

    if(au2) {
      setSelectedLayers((prevValues: any) => {      

        let temp: any[] = prevValues;
        temp = {...temp, [parentId]: checkedValues};
  
        return temp;
      });
    } else {
      setTitle("Анхааруулга")
      setMessage("Давхаргыг харахын тулд заавал дээд талаас Сум/Дүүргийг сонгон хайсан байх шаардлагатай");
      setIsShowModal(true);
      setIsSuccess(false);
    }
  }

  const navigateDetail = (id: any) => {
    navigate(`/auction/detail/${id}`);
    navigate(0);
  }

  const navigateProjectDetail = (id: any) => {
    navigate(`/project/detail/${id}`);
    navigate(0);
  }

  const showAuctionModal = (auction: any) => {

    const item = auction.getProperties();
    
    let msg = <Card style={{ marginBottom: 20 }}>
      <Descriptions column={1} className="auction-table">
        <Descriptions.Item
          label="Код"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item.code}
        </Descriptions.Item>
        <Descriptions.Item
          label="Газрын төрөл"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {(item?.land?.has_property === null || item?.land?.has_property === false) &&
            <>Үл хөдлөх хөрөнгөгүй газар</>
          }

          {item?.land?.has_property === true &&
            <>Үл хөдлөх хөрөнгөтэй газар</>
          }
        </Descriptions.Item>
        <Descriptions.Item
          label="Анхны үнэ"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          <Tag color="#1AA752" style={priceTagStyle}>
            {SmartHelper.formatCurrency(item.auction_price)} ₮
          </Tag>
        </Descriptions.Item>
        {/*<Descriptions.Item
            label="Сүүлийн үнэ"
            labelStyle={descriptionItemLabelStyle}
            contentStyle={descriptionItemContentStyle}
            style={{
              paddingBottom: 10,
            }}
          >
            <Tag color="#1AA752" style={priceTagStyle}>
              1,908,000 ₮
            </Tag>
          </Descriptions.Item>*/}
        <Descriptions.Item
          label="Явагдах хэлбэр"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {(item?.is_auction_online === null || item?.is_auction_online === true) &&
            <>Цахимаар</>
          }

          {item?.is_auction_online === false &&
            <>Танхимаар</>
          }
        </Descriptions.Item>
        <Descriptions.Item
          label="Хаанаас зарласан"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item?.source?.name}
        </Descriptions.Item>
        <Descriptions.Item
          label="Газрын хэмжээ"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item?.land?.area_m2} м2
        </Descriptions.Item>
        <Descriptions.Item
          label="Газрын зориулалт"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item?.land?.land_use_type?.description}
        </Descriptions.Item>
        <Descriptions.Item
          label="Газар ашиглалтын хэлбэр"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          Эзэмших
        </Descriptions.Item>
        <Descriptions.Item
          label="Оролцогч бүртгэх хугацаа"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item.start_reg_date} / {item.end_reg_date}
        </Descriptions.Item>
        <Descriptions.Item
          label="Дуудлага худалдаа явагдах хугацаа"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item.start_date} / {item.end_date}
        </Descriptions.Item>
        <Descriptions.Item
          label="Бусад олон нийтийн хэрэгслээр зарласан талаар"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item.other_announce}
        </Descriptions.Item>
        <Descriptions.Item
          label="ДХ явагдах газрын хаяг"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {(item?.is_auction_online === null || item?.is_auction_online === true) &&
            <>Цахимаар</>
          }

          {item?.is_auction_online === false &&
            item.auction_address_detail
          }
        </Descriptions.Item>
        <Descriptions.Item
          label="Тайлбар"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item.auction_detail}
        </Descriptions.Item>
      </Descriptions>
      <Space size="middle" wrap align='end'>
        <Button type="primary"
          onClick={() => { navigateDetail(item.id) }}
        >
          Дэлгэрэнгүй
        </Button>
      </Space>
    </Card>; 

    const feeZonePaymentInfoFetch = async () => {

      setLoaded(false);

      let feeUrl = WEBSERVICES.URL_FEE_ZONE_INFO + item.land.parcel;

      await fetch(feeUrl)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson && responseJson.status === true) {
            var resFeeInfo = responseJson.data?.[0]           

            const data: DataType[] = resFeeInfo !== null ? [
              {
                key: 1,
                feeZone: `${resFeeInfo?.zone_name} | ${resFeeInfo?.zone_no}`,
                confidence: `${resFeeInfo?.confidence_percent}₮`,
                payment: `${resFeeInfo?.base_fee_per_m2}₮`,
                payment_amount: `${numberWithCommas(parseInt(resFeeInfo?.payment))}₮`,
              },
            ] : [];            

            let feeInfo = <Card title="Үнэлгээний мэдээлэл" style={{ marginBottom: 20 }}>
              <Table pagination={false} columns={columns} dataSource={data} 
              locale={{
                emptyText: <Empty description="Бичлэг байхгүй байна" />,
              }}/>
            </Card>;

            setFeeInfoMessage(feeInfo);
          }
        })
        .finally(() => {
          setLoaded(true);
        });
    }
    //feeZonePaymentInfoFetch();
  
    setTitle("Дуудлага худалдааны мэдээлэл");
    setMessage(msg);
    setIsShowModal(true);
    setIsSuccess(true);
  }

  const showProjectModal = (auction: any) => {
    const item = auction.getProperties();

    let msg =
    <Card style={{ marginBottom: 20 }}>
      <Descriptions column={1} className="auction-table">
        <Descriptions.Item
          label="Газрын төрөл"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {(item?.land?.has_property === null ||
            item?.land?.has_property === false) && (
            <>Үл хөдлөх хөрөнгөгүй газар</>
          )}

          {item?.land?.has_property === true && (
            <>Үл хөдлөх хөрөнгөтэй газар</>
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label="Гэрчилгээ үнэ"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          <Tag color="#1AA752" style={priceTagStyle}>
            {SmartHelper.formatCurrency(item.certificate_price)}{' '}
            ₮
          </Tag>
        </Descriptions.Item>
        {/*<Descriptions.Item
          label="Сүүлийн үнэ"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          <Tag color="#1AA752" style={priceTagStyle}>
            1,908,000 ₮
          </Tag>
        </Descriptions.Item>*/}
        <Descriptions.Item
          label="Бүртгэл явагдах хэлбэр"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {(item?.is_project_online === null ||
            item?.is_project_online === true) && <>Цахимаар</>}

          {item?.is_project_online === false && (
            <>Уламжлалтаар</>
          )}
        </Descriptions.Item>
        {/*<Descriptions.Item
          label="Хаагдсан байдал"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          <Tag color="#0654DE" style={priceTagStyle}>
            Үнэ төлөөгүй
          </Tag>
        </Descriptions.Item>*/}
        <Descriptions.Item
          label="Газрын төрөл"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item?.land_type?.name}
        </Descriptions.Item>
        <Descriptions.Item
          label="Хөрөнгө оруулалтын хэлбэр"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item?.invest?.name}
        </Descriptions.Item>
        <Descriptions.Item
          label="Хэрэгжих хугацаа"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item?.project_duration}
        </Descriptions.Item>
        <Descriptions.Item
          label="Газрын хэмжээ"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item?.land?.area_m2} м2
        </Descriptions.Item>
        <Descriptions.Item
          label="Газрын зориулалт"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item?.land?.land_use_type?.description}
        </Descriptions.Item>
        <Descriptions.Item
          label="Газар ашиглалтын хэлбэр"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {SmartHelper.getRightType(item?.land?.land_right_type)}
        </Descriptions.Item>
        <Descriptions.Item
          label="Төсөл шалгаруулалтын бүртгэлийн хугацаа"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item.start_date} / {item.end_date}
        </Descriptions.Item>
        <Descriptions.Item
          label="Бусад олон нийтийн хэрэгслээр зарласан талаар"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item.other_announce}
        </Descriptions.Item>
        <Descriptions.Item
          label="Төсөл бүртгэх газрын хаяг"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {(item?.is_project_online === null ||
            item?.is_project_online === true) && <>Цахимаар</>}

          {item?.is_project_online === false &&
            item.project_address_detail}
        </Descriptions.Item>
        <Descriptions.Item
          label="Тайлбар"
          labelStyle={descriptionItemLabelStyle}
          contentStyle={descriptionItemContentStyle}
          style={{
            paddingBottom: 10,
          }}
        >
          {item.project_detail}
        </Descriptions.Item>
      </Descriptions>
      <Space size="middle" wrap align='end'>
            <Button type="primary"
              onClick={() => {navigateProjectDetail(item.id)}}
            >
              Дэлгэрэнгүй
            </Button>
        </Space>
    </Card>;

    const feeZonePaymentInfoFetch = async () => {

      setLoaded(false);

      let feeUrl = WEBSERVICES.URL_FEE_ZONE_INFO + "?parcel_id=" + item.land.parcel;

      await fetch(feeUrl)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson && responseJson.status === true && responseJson.paymentCalc !== null) {
            var paymentCalc = responseJson.paymentCalc
            const data: DataType[] = paymentCalc !== null ? [
              {
                key: 1,
                feeZone: `${paymentCalc?.level_type} | ${paymentCalc?.level_name} | ${paymentCalc?.level_no}`,
                confidence: `${numberWithCommas(parseInt(paymentCalc?.base_price))}₮`,
                payment: paymentCalc?.confidence,
                payment_amount: `${numberWithCommas(paymentCalc?.auction_amount)}₮`,
              },
            ] : []; 

            let feeInfo = <Card title="Үнэлгээний мэдээлэл" style={{ marginBottom: 20 }}>
              <Table pagination={false} columns={columns} dataSource={data} 
              locale={{
                emptyText: <Empty description="Бичлэг байхгүй байна" />,
              }}/>
            </Card>;

            setFeeInfoMessage(feeInfo);
          }
        })
        .finally(() => {
          setLoaded(true);
        });
    }
    //feeZonePaymentInfoFetch();

    setTitle("Төслийн мэдээлэл");
    setMessage(msg);
    setIsShowModal(true);
    setIsSuccess(true);
  }

  const findSearchStatus = (value: any) => {
    setSelectedSearchType(value);
    if(value === "auction") {      
      fetchAuctionFilter();
    } else {
      fetchProjectFilter();
    }
  }

  const fetchAuctionFilter = async () => {
    let url = WEBSERVICES.URL_AUCTION_FILTER +
    '?au1=' +
    SmartHelper.getSession('au1') +
    '&au2=' +
    SmartHelper.getSession('au2') +
    '&au3=' +
    SmartHelper.getSession('au3')

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setStatuses(responseJson.statuses)
        } else {
          setStatuses([])
        }
      })
      .catch((error) => {
        setStatuses([])
      })
  }

  const fetchProjectFilter = async () => {
      let url = WEBSERVICES.URL_PROJECT_FILTER;
          
      await fetch(url)
          .then((response) => response.json())
          .then((responseJson) => {
            if(responseJson && responseJson.status === true) {
              setStatuses(responseJson.statuses);
            } else {
              setStatuses([]);  
            }
          })
          .catch((error) => {
            setStatuses([]);  
          });     
    }

  const searchTypeOptions = [
    { label: 'Дуудлага худалдаа', value: 'auction' },
    { label: 'Төсөл сонгон шалгаруулалт', value: 'project' },
  ]  

  const searchHasPropertyOptions = [
    { label: 'Үл хөдлөх хөрөнгөгүй газар', value: false },
    { label: 'Үл хөдлөх хөрөнгө бүхий газар', value: true },
  ]
  
  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        {
          (!loaded) &&
            <Spin size="large" fullscreen/>
        }
        <div
          className="main-map-wrapper"
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <MainMap results={results} searchType={selectedSearchType} showAuctionModal={showAuctionModal} showProjectModal={showProjectModal} baseMap={baseMap} feeZone={feeZoneGet} layers={selectedLayers}/>

          <div className="main-map-filter">
            <Card style={{ borderRadius: 5, marginBottom: 15 }}>
              <Image
                src="/images/map-logo.png"
                width={207}
                height={41}
                alt=""
                preview={false}
              />
              <Form
                layout={'vertical'}
                form={form}
                initialValues={{
                  search_type: selectedSearchType,
                  search_year: new Date().getFullYear(),
                  search_has_property: false,
                  search_keyword: null,
                  // search_au1: selectedAu1Get
                }}
                style={{ maxWidth: '100%', marginTop: 20 }}
              >
                <Form.Item name="field_number">
                  <Input
                    placeholder="Нэгж талбарын дугаар"
                    style={{ borderRadius: 20 }}
                    onChange={(e) => {setSelectedSearchParcel(e.target.value)}}
                  />
                </Form.Item>
                <Form.Item name="search_type" className="map-search-form">
                  <Select
                    placeholder="Хайлтын төрөл"                    
                    value={selectedSearchType}
                    onChange={(value) => { findSearchStatus(value)}}
                  >
                    {searchTypeOptions.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item.value}>{item.label}</Option>
                      )
                    })}
                    
                  </Select>
                </Form.Item>
                <Form.Item name="search_status" className="map-search-form">
                  <Select
                    placeholder="Төлөв"                    
                    value={selectedSearchStatus}
                    onChange={(value) => { setSelectedSearchStatus(value)}}
                  >
                    {statuses.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item.id}>{item.name}</Option>
                      )
                    })}
                    
                  </Select>
                </Form.Item>
                <Form.Item name="search_year" className="map-search-form">
                  <Select
                    placeholder="Он"                    
                    value={selectedSearchYear}
                    onChange={(value) => {setSelectedSearchYear(value)}}
                  >
                    {searchYearOptions.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item.value}>{item.label}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item name="search_has_property" className="map-search-form">
                  <Select 
                    placeholder="Газрын төрөл" 
                    value={selectedSearchHasProperty}
                    onChange={(value) => {setSelectedSearchHasProperty(value)}}
                    >
                    {searchHasPropertyOptions.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item.value}>{item.label}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="search_keyword"
                  className="map-search-form"
                >
                  <Input placeholder="Хайх утга" onChange={(e) => {setSelectedSearchKeyword(e.target.value)}}></Input>
                </Form.Item>  
                
                <Collapse
                  accordion
                  items={items}
                  className="map-collapse"
                  bordered={false}
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <Image
                        src="/images/collapse-expand.png"
                        width={6}
                        height={7}
                        alt=""
                        preview={false}
                        style={{ transform: 'rotate(90deg)' }}
                      />
                    ) : (
                      <Image
                        src="/images/collapse-expand.png"
                        width={6}
                        height={7}
                        alt=""
                        preview={false}
                      />
                    )
                  }
                />
              </Form>
              <Form.Item style={{ marginTop: 10 }}>
                <Flex justify="space-between" vertical gap={10}>
                  <Button icon={<SearchOutlined />}
                    onClick={() => {searchMap()}}
                    type="primary"
                    style={{ width: 250 }}>
                    Хайх
                  </Button>
                </Flex>
              </Form.Item>
            </Card>
            <Card
              style={{ borderRadius: 5 }}
              styles={{ body: { padding: 10 } }}
            >
              <Card
                style={{ borderRadius: 5, background: '#EFFAFC' }}
                styles={{ body: { padding: 10 } }}
              >
                <Text
                  style={{
                    fontWeight: 600,
                    display: 'block',
                    marginBottom: 10,
                  }}
                >
                  СУУРЬ ЗУРАГ
                </Text>
                <Space wrap align="start" size={10}>
                  <Flex vertical style={{ width: 77, cursor: 'pointer' }} onClick={() => {setBaseMap('voyager')}}>
                    <Image
                      src="/images/map/5.png"
                      width={77}
                      height={50}
                      alt=""
                      preview={false}
                      style={{
                        minWidth: '100%',
                        objectFit: 'cover',
                        borderRadius: 5,
                      }}
                    />
                    <Link                      
                      style={{
                        fontWeight: 600,
                        fontSize: 11,
                        textAlign: 'center',
                        color: '#393874',
                        lineHeight: '12px',
                        marginTop: 5,
                      }}
                    >
                      Voyager Map
                    </Link>
                  </Flex>
                  <Flex vertical style={{ width: 77, cursor: 'pointer' }} onClick={() => {setBaseMap('openstreet')}}>
                    <Image
                      src="/images/map/1.jpg"
                      width={77}
                      height={50}
                      alt=""
                      preview={false}
                      style={{
                        minWidth: '100%',
                        objectFit: 'cover',
                        borderRadius: 5,
                      }}
                    />
                    <Link                      
                      style={{
                        fontWeight: 600,
                        fontSize: 11,
                        textAlign: 'center',
                        color: '#393874',
                        lineHeight: '12px',
                        marginTop: 5,
                      }}
                    >
                      Open Street Map
                    </Link>
                  </Flex>
                  <Flex vertical style={{ width: 77, cursor: 'pointer' }} onClick={() => {setBaseMap('google')}}>
                    <Image
                      src="/images/map/2.jpg"
                      width={77}
                      height={50}
                      alt=""
                      preview={false}
                      style={{
                        minWidth: '100%',
                        objectFit: 'cover',
                        borderRadius: 5,
                      }}
                    />
                    <Link                      
                      style={{
                        fontWeight: 600,
                        fontSize: 11,
                        textAlign: 'center',
                        color: '#393874',
                        lineHeight: '12px',
                        marginTop: 5,
                      }}
                    >
                      Google Map
                    </Link>
                  </Flex>
                  <Flex vertical style={{ width: 77, cursor: 'pointer' }} onClick={() => {setBaseMap('topo')}}>
                    <Image
                      src="/images/map/3.jpg"
                      width={77}
                      height={50}
                      alt=""
                      preview={false}
                      style={{
                        minWidth: '100%',
                        objectFit: 'cover',
                        borderRadius: 5,
                      }}
                    />
                    <Link
                      style={{
                        fontWeight: 600,
                        fontSize: 11,
                        textAlign: 'center',
                        color: '#393874',
                        lineHeight: '12px',
                        marginTop: 5,
                      }}
                    >
                      Topo Map
                    </Link>
                  </Flex>
                  {/*<Flex vertical style={{ width: 77 }}>
                    <Image
                      src="/images/about-1.jpg"
                      width={77}
                      height={50}
                      alt=""
                      preview={false}
                      style={{
                        minWidth: '100%',
                        objectFit: 'cover',
                        borderRadius: 5,
                      }}
                    />
                    <Link
                      onClick={() => console.log('clicked')}
                      style={{
                        fontWeight: 600,
                        fontSize: 11,
                        textAlign: 'center',
                        color: '#393874',
                        lineHeight: '12px',
                        marginTop: 5,
                      }}
                    >
                      ТУСГАЙ ХАМГААЛАЛТАЙ ГАЗАР НУТАГ
                    </Link>
                  </Flex>
                  <Flex vertical style={{ width: 77 }}>
                    <Image
                      src="/images/about-5.jpg"
                      width={77}
                      height={50}
                      alt=""
                      preview={false}
                      style={{
                        minWidth: '100%',
                        objectFit: 'cover',
                        borderRadius: 5,
                      }}
                    />
                    <Link
                      onClick={() => console.log('clicked')}
                      style={{
                        fontWeight: 600,
                        fontSize: 11,
                        textAlign: 'center',
                        color: '#393874',
                        lineHeight: '12px',
                        marginTop: 5,
                      }}
                    >
                      БАЙРЗҮЙН СУУРЬ ЗУРАГ /ОЙ, НУУР/
                    </Link>
                    </Flex>*/}
                </Space>
              </Card>
            </Card>
          </div>
        </div>
        <Modal
          width={900}
            title={title}
            open={isShowModal}
            centered
            onOk={handleOk}
            onCancel={handleCancel}            
            footer={null}
          >
          {message}
          {feeInfoMessage}
        </Modal>
      </Content>
    </Layout>
  )
}

export default MapPage
