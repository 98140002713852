import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Button, Steps, Card, Form, Input, Select, Space, Typography, Image, Divider, message, Modal,Flex, Upload, Table, Result, Spin, InputNumber, Empty ,ConfigProvider, App } from 'antd';
import dayjs from 'dayjs';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import 'dayjs/locale/mn';
import MapView from './../../../components/SalesMapView';
import * as WEBSERVICES from '../../../config/WebServices'
import * as CONFIGS from '../../../config/Configs'
import SmartHelper from 'helper/SmartHelper'
import { useParams , useNavigate } from 'react-router-dom'
dayjs.locale('mn');

const { Option } = Select;
const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;
const { Step } = Steps;

// Define the table data and columns

interface LandItem {
  parcel: string;
  right_type_desc: string;
  landuse_desc: string;
  au1_name: string;
  au2_name: string;
}

interface LandType {
  key: number;
  parcel: string;
  type: string;
  purpose: string;
  address: string;
}

const SalesNew: React.FC = () => {
  const [current, setCurrent] = useState(0);
  const [land, setLand] = useState<LandType[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedRow, setSelectedRow] = useState<LandType | null>(null);
  const [mainType, setMainType] = useState<'sell' | 'rent' | null>(null);
  const [formType, setFormType] = useState<'realState' | 'noRealState' | null>(null);

  const [mainForm] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {parcelNum: paramParcelNum} = useParams<{ parcelNum: string }>();
  const [parcelNum, setParcelNum] = useState(paramParcelNum);
  const [landInfo, setLandInfo] = useState([] as any)
  const [isShowModal, setIsShowModal] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)  
  const [entity, setEntity] = useState([] as any)
  const entityId = SmartHelper.getEntity().id;
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      await fetchLandList();
    }

    fetchData();
  }, []);


  useEffect(() => {
    if (parcelNum) {
      fetchLandInfoList(parcelNum);
    }
  }, [parcelNum]);

  useEffect(() => {
    
  }, [mainType, formType]);
  
  //steps
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const onReset = () => {
    mainForm.resetFields()
  }

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  //modal
  const showModal = () => {
    setIsModalOpen(true)
  }

  const navigateToSales = () => {
    navigate(`/sales`)
    navigate(0);
  }

  const handleOk = () => {
    setIsModalOpen(false)
    navigateToSales();
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleClick = async () => {
    try {
      if (!mainType) {
        message.error('Худалдах эсвэл Түрээслэх төрлийг сонгоно уу!');
        return;
      }
  
      if (!formType) {
        message.error('Үл хөдлөх хөрөнгийн төрлийг сонгоно уу!');
        return;
      }

      const formValues = await mainForm.validateFields();
      const images = mainForm.getFieldValue('images');
      formValues.images = images;
      showModal();
      onFinish(formValues);
      sendSales(formValues);
    } catch (error) {
      message.error('Мэдээллээ бүрэн бөглөнө үү!');
      console.error("Form validation failed:", error);
    }
  };

  const showAdditionalButtons = mainType === 'sell' || mainType === 'rent';
  const showAdditionalForm = formType === 'realState' || formType === 'noRealState';
  // console.log(mainType);
  // console.log(formType);
  const fetchLandList = async () => {
      setLoaded(false);
      let url = WEBSERVICES.URL_USER_LAND_LIST + '?' + 'register_number' + '=' + SmartHelper.getAuth().register;

      try {
          const response = await fetch(url);
          const responseJson = await response.json();
          const formattedData = responseJson.data.map((item: LandItem, index: number) => ({
            key: index + 1,
            numbers: index + 1,
            parcel: item.parcel,
            type: item.right_type_desc,
            purpose: item.landuse_desc,
            address: `${item.au1_name}, ${item.au2_name}`,
          }));
          setLand(formattedData);
      } catch (error) {
          setLand([]);
      } finally {
          setLoaded(true);
      }
  };

  const fetchLandInfoList = async (parcelNum: string) => {
    setLoaded(false);
    const url = `${WEBSERVICES.URL_USER_LAND_INFO_LIST}?id=${parcelNum}`;
  
    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setLandInfo(responseJson.data);
        } else {
          setLandInfo([]);
        }
      })
      .catch((error) => {
        setLandInfo([]);
      })
      .finally(() => {
        setLoaded(true);
      });
  };
  // console.log(land);
  
  const showMessage = (msg: string, isError: boolean) => {
    setIsShowModal(true);
    
    setIsSuccess(!isError);
  }

  const sendSales = async (values: any) => {
    setLoaded(false);
    
    const dataToSend = {...values, main_type: mainType, form_type: formType};    
    
    try {
      const response = await fetch(WEBSERVICES.URL_SALES_STORE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          entity_id: entity.id,
          data: dataToSend,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
      }
  
      const result = await response.json();
      if (result.status === true) {
        const msg = '<p>Амжилттай таны хүсэлтийг илгээлээ. Та хугацаа дуусахаас өмнө мэдээллийг ахин илгээх боломжтой.</p>';
        showMessage(msg, false);

      } else {
        throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred.';
      const msg = `<p>${errorMessage}</p>`;
      showMessage(msg, true);
    } finally {
      setLoaded(true);
    }
  };
  
  const handleRowSelection = (selectedKeys: React.Key[]) => {
    const selectedItem = land.find(item => item.key === selectedKeys[0]);
    if (selectedItem) {
      setSelectedRow(selectedItem);
      setParcelNum(selectedItem.parcel);
    }
  };
  
  const columns = [
    { title: '#', dataIndex: 'numbers', key: 'numbers' },
    { title: 'Нэгж талбарын дугаар', dataIndex: 'parcel', key: 'parcel' },
    { title: 'Эрхийн төрөл', dataIndex: 'type', key: 'type' },
    { title: 'Зориулалт', dataIndex: 'purpose', key: 'purpose' },
    { title: 'Байршил', dataIndex: 'address', key: 'address' },
  ];

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },  
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  // console.log(entity);
  const steps = [
    {
      title: 'Миний газрууд',
      content: (
        <Table
          rowSelection={{
            type: "radio",
            onChange: handleRowSelection,
          }}
          columns={columns}
          dataSource={land}
          locale={{
            emptyText: <Empty description="Бичлэг байхгүй байна" />,
          }}
        />
      ),
    }, 
    {
      title: 'Худалдаа, түрээсийн зар нэмэх',
      content: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <Form
            layout="horizontal"
            {...formItemLayout}
            form={mainForm}
            onFinish={onFinish}
            labelWrap
            initialValues={{
              main_type: mainType ?? '',
              form_type: formType ?? '', 
              entity_id: entityId,
              properties_type: '',
              sale_price: '',
              rent_price: '',              
              contact_phone: '',
              ads_title: '',
              description: '',
              parcel: landInfo[0]?.parcel_id || '',
              au1: landInfo[0]?.au1_name || '',
              au1_code: landInfo[0]?.au1_code || '',
              au2: landInfo[0]?.au2_name || '',
              au2_code: landInfo[0]?.au2_code || '',
              au3: landInfo[0]?.au3_name || '',
              au3_code: landInfo[0]?.au3_code || '',
              land_address: `${landInfo[0]?.au1_name || ' '}, ${landInfo[0]?.au2_name || ' '}, ${landInfo[0]?.au3_name || ''}, ${landInfo[0]?.address_streetname || ''}, ${landInfo[0]?.address_khashaa || ''}`,
              land_use: landInfo[0]?.description || '',
              land_use_code: landInfo[0]?.landuse || '',
              land_area: landInfo[0]?.area_m2 || '',
              land_right_type: landInfo[0]?.dataapp?.data[0]?.right_desc || '',
              real_state_area: '',
              room: '',
              floor: '',
              window: '',
              geojson: landInfo[0]?.geojson || '',
              youtube_url: '',
              images: '',
            }}
            style={{ maxWidth: 928, marginTop: 60}}
          >
            <Row gutter={[20, 20]} style={{ marginBottom: 20 }}>
              <Col xs={24} sm={12}>
                <Button
                  onClick={() => setMainType('sell')}
                  shape="round"
                  icon={<Image src="/images/icon-house.png" width={58} height={23} preview={false} />}
                  block
                  style={{ 
                    fontSize: 20, 
                    fontWeight: 700, 
                    color: '#0654DE', 
                    borderColor: mainType === 'sell' ? '#FF1D1D' : '#0654DE', 
                    height: 113, 
                    borderRadius: '8px',
                    textTransform: 'uppercase' 
                  }}
                >
                  Худалдах зар
                </Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button
                  onClick={() => setMainType('rent')}
                  shape="round"
                  icon={<Image src="/images/icon-key.png" width={40} height={43} preview={false} />}
                  block
                  style={{ 
                    fontSize: 20, 
                    fontWeight: 700, 
                    color: '#0654DE', 
                    borderColor: mainType === 'rent' ? '#FF1D1D' : '#0654DE', 
                    height: 113, 
                    borderRadius: 5, 
                    textTransform: 'uppercase' 
                  }}
                >
                  Түрээслэх зар
                </Button>
              </Col>
                
              {showAdditionalButtons && (
                <>
                  <Col xs={24} sm={12}>
                    <Button
                      onClick={() => setFormType('realState')}
                      shape="round"
                      block
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        color: '#0654DE',
                        borderColor: formType === 'realState' ? '#FF1D1D' : '#0654DE',
                        height: 113,
                        borderRadius: 5,
                        textTransform: 'uppercase',
                      }}
                    >
                      Үл хөдлөх хөрөнгө бүхий
                    </Button>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Button
                      onClick={() => setFormType('noRealState')}
                      shape="round"
                      block
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        color: '#0654DE',
                        borderColor: formType === 'noRealState' ? '#FF1D1D' : '#0654DE',
                        height: 113,
                        borderRadius: 10,
                        textTransform: 'uppercase',
                      }}
                    >
                      Үл хөдлөх хөрөнгөгүй
                    </Button>
                  </Col>
                </>
              )}
            </Row>
            {showAdditionalForm && (
            <>
              <Image
                src="/images/icon-folder-red.png"
                width={13}
                height={13}
                alt=""
                preview={false}
                style={{ marginBottom: 4 }}
              />
              {mainType == 'sell' && (
                <Text
                  style={{
                    marginLeft: 8,
                    color: '#FF1D1D',
                    fontWeight: 600,
                  }}
                >
                  Худалдах газрын ерөнхий мэдээлэл
                </Text>
              )}
              {mainType == 'rent' && (
                <Text
                  style={{
                    marginLeft: 8,
                    color: '#FF1D1D',
                    fontWeight: 600,
                  }}
                >
                  Түрээслэх газрын ерөнхий мэдээлэл
                </Text>
              )}
              <Divider
                style={{
                  marginTop: 10,
                  borderBlockStartColor: '#FF1D1D',
                }}
              />
              {mainType == 'sell' && (
                <Form.Item
                  label={
                    <Text style={{ fontWeight: 700, color: '#171441' }}>
                      Нийт худалдах үнэ
                    </Text>
                  }
                  name="sale_price"
                  colon={false}
                  labelAlign="left"
                  rules={[
                    { 
                      required: mainType === 'sell', 
                      message: 'Худалдах үнийг оруулна уу!' 
                    }
                  ]}
                >
                  <InputNumber suffix="₮" style={{width: '100%'}}/>
                </Form.Item>
              )}
              {mainType == 'rent' && (
                <Form.Item
                  label={
                    <Text style={{ fontWeight: 700, color: '#171441' }}>
                      1 сарын түрээсийн үнэ
                    </Text>
                  }
                  name="rent_price"
                  colon={false}
                  labelAlign="left"
                  rules={[
                    { 
                      required: mainType === 'rent', 
                      message: 'Түрээслэх үнийг оруулна уу!' 
                    }
                  ]}
                >
                  <InputNumber suffix="₮" style={{width: '100%'}}/>
                </Form.Item>
              )}
              <Form.Item
                label={
                  <Text style={{ fontWeight: 700, color: '#171441' }}>
                    Холбоо барих утасны дугаар
                  </Text>
                }
                name="contact_phone"
                colon={false}
                labelAlign="left"
                rules={[
                  { required: true, message: 'Холбоо барих дугаараа оруулна уу!' }
                ]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>              
              <Form.Item
                label={
                  <Text style={{ fontWeight: 700, color: '#171441' }}>
                    Зарын гарчиг
                  </Text>
                }
                name="ads_title"
                colon={false}
                labelAlign="left"
                rules={[
                  { required: true, message: 'Зарын гарчгаа оруулна уу!' }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <Text style={{ fontWeight: 700, color: '#171441' }}>
                    Дэлгэрэнгүй мэдээлэл
                  </Text>
                }
                name="description"
                colon={false}
                labelAlign="left"
                style={{ marginBottom: 40 }}
              >
                <Input.TextArea rows={4} />
              </Form.Item>

              <Image
                src="/images/icon-folder-red.png"
                width={13}
                height={13}
                alt=""
                preview={false}
                style={{ marginBottom: 4 }}
              />
              <Text
                style={{
                  marginLeft: 8,
                  color: '#FF1D1D',
                  fontWeight: 600,
                }}
              >
                Хаяг байршилын мэдээлэл
              </Text>
              <Divider
                style={{
                  marginTop: 10,
                  borderBlockStartColor: '#FF1D1D',
                }}
              />
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={14} xxl={16}>
                  <MapView />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={8}>
                  <Card
                    style={{
                      padding: '14px 0',
                      backgroundColor: 'rgba(227,231,239, .5)',
                      boxShadow: 'none',
                    }}
                  >
                    <Form.Item
                      label={
                        <Text style={{ fontWeight: 600, color: '#171441' }}>
                          Нэгж талбарын дугаар
                        </Text>
                      }
                      colon={false}
                      labelAlign="left"
                      name="parcel"
                    >                    
                      <Input readOnly />
                    </Form.Item>
                    <Form.Item
                      label={
                        <Text style={{ fontWeight: 600, color: '#171441' }}>
                          Аймаг
                        </Text>
                      }
                      colon={false}
                      name="au1"
                      labelAlign="left"
                    >
                      <Input readOnly />
                    </Form.Item>
                    <Form.Item
                      label={
                        <Text style={{ fontWeight: 600, color: '#171441' }}>
                          Хэрэглэгчийн ID
                        </Text>
                      }
                      colon={false}
                      name="entity_id"
                      labelAlign="left"
                      hidden
                    >
                      <Input readOnly />
                    </Form.Item>
                    <Form.Item
                      label={
                        <Text style={{ fontWeight: 600, color: '#171441' }}>
                          Аймаг код
                        </Text>
                      }
                      colon={false}
                      name="au1_code"
                      labelAlign="left"
                      hidden
                    >
                      <Input readOnly />
                    </Form.Item>
                    <Form.Item
                      label={
                        <Text style={{ fontWeight: 600, color: '#171441' }}>
                          Сум код
                        </Text>
                      }
                      colon={false}
                      name="au2_code"
                      labelAlign="left"
                      hidden
                    >
                      <Input readOnly />
                    </Form.Item>
                    <Form.Item
                      label={
                        <Text style={{ fontWeight: 600, color: '#171441' }}>
                          Баг код
                        </Text>
                      }
                      colon={false}
                      name="au3_code"
                      labelAlign="left"
                      hidden
                    >
                      <Input readOnly />
                    </Form.Item>
                    <Form.Item
                      label={
                        <Text style={{ fontWeight: 600, color: '#171441' }}>
                          Сум
                        </Text>
                      }
                      name="au2"
                      colon={false}
                      labelAlign="left"
                    >
                      <Input readOnly />
                    </Form.Item>
                    <Form.Item
                      label={
                        <Text style={{ fontWeight: 600, color: '#171441' }}>
                          Баг
                        </Text>
                      }
                      name="au3"
                      colon={false}
                      labelAlign="left"
                    >
                      <Input readOnly />
                    </Form.Item>
                    <Form.Item
                      label={
                        <Text style={{ fontWeight: 600, color: '#171441' }}>
                          Хаяг
                        </Text>
                      }
                      colon={false}
                      name="land_address"
                      labelAlign="left"
                    >
                      <Input.TextArea rows={3} readOnly/>
                    </Form.Item>
                    <Form.Item
                      label={
                        <Text style={{ fontWeight: 600, color: '#171441' }}>
                          Geojson
                        </Text>
                      }
                      colon={false}
                      name="geojson"
                      labelAlign="left"
                      hidden
                    >
                      <Input readOnly />
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
              <div style={{ marginTop: 60 }}>
                <Image
                  src="/images/icon-folder-red.png"
                  width={13}
                  height={13}
                  alt=""
                  preview={false}
                  style={{ marginBottom: 4 }}
                />
                <Text
                  style={{
                    marginLeft: 8,
                    color: '#FF1D1D',
                    fontWeight: 600,
                  }}
                >
                  Газрын мэдээлэл
                </Text>
                <Divider
                  style={{
                    marginTop: 10,
                    borderBlockStartColor: '#FF1D1D',
                  }}
                />
              </div>            
              <Form.Item
                label={
                  <Text style={{ fontWeight: 600, color: '#171441' }}>
                    Газрын хэмжээ /м2/
                  </Text>
                }
                colon={false}
                name="land_area"
                labelAlign="left"
              >
                <Input suffix="₮" readOnly />
              </Form.Item>
              <Form.Item
                label={
                  <Text style={{ fontWeight: 600, color: '#171441' }}>
                    Газрын зориулалт
                  </Text>
                }
                colon={false}
                name="land_use"
                labelAlign="left"
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item
                label={
                  <Text style={{ fontWeight: 600, color: '#171441' }}>
                    Газрын зориулалт
                  </Text>
                }
                colon={false}
                name="land_use_code"
                labelAlign="left"
                hidden
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item
                label={
                  <Text style={{ fontWeight: 600, color: '#171441' }}>
                    Эзэмших хэлбэр
                  </Text>
                }
                colon={false}
                name="land_right_type"
                labelAlign="left"
              >
                <Input readOnly />
              </Form.Item>
              {formType == 'realState' && (
                <>
                  <div style={{ marginTop: 60 }}>
                    <Image
                      src="/images/icon-folder-red.png"
                      width={13}
                      height={13}
                      alt=""
                      preview={false}
                      style={{ marginBottom: 4 }}
                    />
                    <Text
                      style={{
                        marginLeft: 8,
                        color: '#FF1D1D',
                        fontWeight: 600,
                      }}
                    >
                      Үл хөдлөх хөрөнгийн мэдээлэл
                    </Text>
                    <Divider
                      style={{
                        marginTop: 10,
                        borderBlockStartColor: '#FF1D1D',
                      }}
                    />
                  </div>
                  <Form.Item
                    label={
                      <Text style={{ fontWeight: 700, color: '#171441' }}>
                        Үл хөдлөх хөрөнгийн төрөл
                      </Text>
                    }
                    colon={false}
                    name="properties_type"
                    labelAlign="left"
                  >
                    <Select allowClear>
                      <Option value="1">Үйлдвэрлэлийн барилга</Option>
                      <Option value="2">Худалдаа үйлчилгээний барилга</Option>
                      <Option value="3">Оффисын барилга</Option>
                      <Option value="4">Амины сууц, хаус, таун хаус, зуслан</Option>
                      <Option value="5">Агуулах, склад, нийтийн байр г.м бусад</Option>
                      <Option value="6">Хашаа байшин</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={
                      <Text style={{ fontWeight: 700, color: '#171441' }}>
                        Үл хөдлөх хөрөнгийн талбай
                      </Text>
                    }
                    name="real_state_area"
                    colon={false}
                    labelAlign="left"
                  >
                    <InputNumber style={{
                      width: '100%',
                    }}/>
                  </Form.Item>
                  <Form.Item
                    label={
                      <Text style={{ fontWeight: 700, color: '#171441' }}>
                        Өрөөний тоо
                      </Text>
                    }
                    colon={false}
                    name="room"
                    labelAlign="left"
                  >
                    <InputNumber style={{
                      width: '100%',
                    }}/>
                  </Form.Item>
                  <Form.Item
                    label={
                      <Text style={{ fontWeight: 700, color: '#171441' }}>
                        Хэдэн давхарт
                      </Text>
                    }
                    name="floor"
                    colon={false}
                    labelAlign="left"
                  >
                    <InputNumber style={{
                      width: '100%',
                    }}/>
                  </Form.Item>
                  <Form.Item
                    label={
                      <Text style={{ fontWeight: 700, color: '#171441' }}>
                        Цонхны тоо
                      </Text>
                    }
                    name="window"
                    colon={false}
                    labelAlign="left"
                  >
                    <InputNumber style={{
                      width: '100%',
                    }}/>
                  </Form.Item>
                </>
              )}
              <div style={{ marginTop: 60 }}>
                <Image
                  src="/images/icon-folder-red.png"
                  width={13}
                  height={13}
                  alt=""
                  preview={false}
                  style={{ marginBottom: 4 }}
                />
                <Text
                  style={{
                    marginLeft: 8,
                    color: '#FF1D1D',
                    fontWeight: 600,
                  }}
                >
                  Зарын хавсралт файлууд
                </Text>
                <Divider
                  style={{
                    marginTop: 10,
                    borderBlockStartColor: '#FF1D1D',
                  }}
                />
              </div>
              <Form.Item
                label={
                  <Text style={{ fontWeight: 700, color: '#171441' }}>
                    Зураг
                  </Text>
                }
                extra="jpg/png төрлийн 5mb-аас ихгүй хэмжээтэй, зартай холбоотой зураг сонгоно уу."
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  return e && e.fileList;
                }}
                colon={false}
                labelAlign="left"
              >
                <Upload
                  name="images"
                  action={`${CONFIGS.URL_SALES_ROOT_IMAGE}`}
                  listType="picture"
                  beforeUpload={(file, fileList) => {
                    const isJpgOrPng = file.type && (file.type === 'image/jpeg' || file.type === 'image/png');
                    if (!isJpgOrPng) {
                      message.error('Зөвхөн JPG эсвэл PNG файлуудыг байршуулах боломжтой.');
                      return Upload.LIST_IGNORE;
                    }
                  
                    if (fileList.length >= 10) {
                      message.error('Зураг байршуулах дээд хязгаар 10 байна.');
                      return Upload.LIST_IGNORE;
                    }
                  
                    return true;
                  }}
                  onChange={({ file, fileList }) => {
                    if (file.status === 'done') {
                      message.success(`${file.response[0]} файл амжилттай байршлаа`);
                    
                      mainForm.setFieldsValue({
                        images: fileList.map(f => f.response[0]),
                      });
                    } else if (file.status === 'error') {
                      message.error(`${file.name} файл байршуулахад алдаа гарлаа.`);
                    }
                  }}
                  onRemove={(file) => {
                    fetch(`${CONFIGS.URL_SALES_ROOT_REMOVE_IMAGE}`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({ filename: file.response[0] }),
                    })
                    .then(response => response.json())
                    .then(data => {
                      if (data.success) {
                        message.success(`${file.name} файл амжилттай устгагдлаа.`);

                        const updatedFileList = mainForm.getFieldValue('images').filter((img: string) => img !== file.response[0]);
                        mainForm.setFieldsValue({
                          images: updatedFileList,
                        });
                      } else {
                        message.error(`${file.name} файл устгахад алдаа гарлаа.`);
                      }
                    })
                    .catch(() => {
                      message.error(`${file.name} файл устгахад алдаа гарлаа.`);
                    });
                  }}
                >
                  <Button icon={<UploadOutlined />}>Зураг сонгох</Button>
                </Upload>
              </Form.Item>
              <Form.Item
                label={
                  <Text style={{ fontWeight: 700, color: '#171441' }}>
                    Youtube видео холбоос оруулах
                  </Text>
                }
                name="youtube_url"
                extra="Видеогоо youtube дээр байршуулсаны дараа хаягийн хэсгийн = тэмдгээс хойшхи үсэг тоо холилдсон видеоны код хэсгийг хуулж тавина. Жишээ нь M32gucN6hQY"
                colon={false}
                labelAlign="left"
              >
                <Input addonBefore="https://www.youtube.com/watch?v="/>
              </Form.Item>
              {/* <Form.Item>
                <Flex
                  justify="flex-start"
                  gap={20}
                  style={{ marginTop: 40 }}
                >
                  <Button style={{ width: 140 }} onClick={onReset}>
                    Арилгах
                  </Button>
                </Flex>
              </Form.Item> */}
            </>
          )}
          </Form>
          <Modal
            open={isModalOpen}
            centered
            onCancel={handleCancel}
            footer={null}
          >
            <Result
              status="success"
              title={
                <Title level={3} style={{ textAlign: 'center' }}>
                  Таны зар амжилттай нэмэгдлээ
                </Title>
              }
              extra={
                <Button
                  type="primary"
                  style={{
                    backgroundColor: '#0654de',
                    width: 170,
                    marginBottom: 30,
                  }}
                  onClick={handleOk}
                >
                  Хаах
                </Button>
              }
            />
          </Modal>
        </div>
      ),
    },
  ];

  return (
    <ConfigProvider>
      <App>
        <Layout>
          {!loaded && (
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: 10
            }}>
              <Spin size="large" />
              <p style={{ marginTop: 20, fontSize: '16px', color: '#1890ff' }}>Өгөгдлийг татаж байна, түр хүлээнэ үү...</p>
            </div>
          )}
          <Content
            style={{
              padding: '0 50px',
              margin: '16px 0',
            }}
          >
            <Card
              style={{
                maxWidth: '1500px',
                margin: 'auto',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Title level={3} style={{
                color: '#FF0000',
                marginBottom: 5,
                marginTop: 0,
                textTransform: 'uppercase',
              }}>
                Зар нэмэх
              </Title>
              <Paragraph style={{ color: '#6E6B98', fontWeight: 500 }}>
                Худалдах зар, түрээслэх зар
              </Paragraph>          
              <Steps current={current} style={{ marginBottom: 30 }}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <div className="steps-content">{steps[current].content}</div>
              <div className="steps-action" style={{ marginTop: '24px', display: 'flex', justifyContent: 'space-between' }}>
                {current < steps.length - 1 && (
                  <Button type="primary" onClick={next}>
                    Дараах
                  </Button>
                )}
                {current > 0 && (
                  <Button style={{ margin: '0 8px' }} onClick={prev}>
                    Буцах
                  </Button>
                )}
                {current === steps.length - 1 && (
                <Button type="primary" htmlType="submit" onClick={handleClick}>
                  Зарыг илгээх
                </Button>
                )}
              </div>
            </Card>
          </Content>
        </Layout>
      </App>
    </ConfigProvider>
  );
};

export default SalesNew;
