import React, {useEffect, useState} from 'react'
import {
  Breadcrumb,
  Layout,
  Row,
  Col,
  Flex,
  Typography,
  Card,
  Space,
  Button,
  Alert,
  Statistic,
  List,
  Spin,
  Modal,
  Empty,
} from 'antd'
import {
  ArrowRightOutlined,
  SyncOutlined,
  WarningFilled,
} from '@ant-design/icons'
import AuctionItem from '../../../components/AuctionItem'
import FooterLayout from '../../../layouts/Footer'

import * as WEBSERVICES from '../../../config/WebServices'
import SmartHelper from 'helper/SmartHelper'
import { useNavigate } from 'react-router-dom'

const { Content } = Layout
const { Text, Title, Paragraph } = Typography
const { Meta } = Card
const { Countdown } = Statistic

const AttendAuction: React.FC = () => {
  const navigate = useNavigate()

  const [auctions, setAuctions] = useState([] as any)
  const [loaded, setLoaded] = useState(false)
  const [entity, setEntity] = useState([] as any)  
  const [isShowModal, setIsShowModal] = useState(false)
  const [caution, setCaution] = useState("")
  const [isShowTermsModal, setIsShowTermsModal] = useState(true)
  const [terms, setTerms] = useState("")
  const [timer, setTimer] = useState(null as any);
  const sessionTimeoutLimit = 15 * 60 * 1000; // 15 minutes
  const [deadline, setDeadline] = useState((Date.now() + sessionTimeoutLimit) as any);

  useEffect(() => {
    resetTimer();

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    async function fetchData() {
      let entity = SmartHelper.getEntity();
      if(entity != null) {
        setEntity(entity);
        await fetchAuctionParticipantList(entity.id)        
      }
      await fetchAuctionCaution();
    }

    fetchData().then(() => {
      setLoaded(true)
    })

    return () => {
      if (timer) clearTimeout(timer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
    };
  }, [])
  
  const fetchAuctionParticipantList = async (entityId: any) => {
    let url = WEBSERVICES.URL_AUCTION_PARTICIPANT_LIST+"?entity_id="+entityId;

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setAuctions(responseJson.data)
        } else {
          setAuctions([])
        }
      })
      .catch((error) => {
        setAuctions([])
      })
  }

  const fetchAuctionCaution = async () => {
    let url = WEBSERVICES.URL_CONFIG_AUCTION_CAUTION;

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setCaution(responseJson.caution)
          setTerms(responseJson.terms)
        } else {
          setCaution("")
          setTerms("")
        }
      })
      .catch((error) => {
        setCaution("")
        setTerms("")
      })
  }

  const handleLogout = () => {
    SmartHelper.clearSession()
    navigate('/')
    navigate(0)
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
    const newTimer = setTimeout(() => {
      
      handleLogout();      
    }, sessionTimeoutLimit);

    setTimer(newTimer);
    setDeadline(Date.now() + sessionTimeoutLimit)
  };

  const refreshAuctions = () => {
    window.location.reload();
  }

  const handleOk = () => {
    setIsShowModal(false);
  }

  const handleCancel = () => {
    setIsShowModal(false);
  }

  const handleTermsOk = () => {
    setIsShowTermsModal(false);
  }

  const handleTermsCancel = () => {
    setIsShowTermsModal(false);
  }

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        {!loaded && <Spin size="large" fullscreen />}
        <Row gutter={20}>
          <Col span={24}>
            <Flex
              style={{ marginTop: 40 }}
              justify="space-between"
              align="center"
              wrap="wrap"
            >
              <Meta
                title={
                  <Title
                    level={4}
                    style={{
                      color: '#393874',
                      marginBottom: 5,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    Таны оролцож буй дуудлага худалдаа
                  </Title>
                }
                description={
                  <Paragraph style={{ color: '#6E6B98', fontWeight: 500 }}>
                    Таны оролцохоор бүртгүүлсэн болон дуудлага худалдаа нь явагдаж байгаа дуудлага худалдаанууд
                  </Paragraph>
                }
              />
            </Flex>
            <Flex
              style={{ marginBottom: 20 }}
              justify="space-between"
              align="center"
              wrap="wrap"
            >
              <Breadcrumb
                separator={<ArrowRightOutlined />}
                items={[
                  {
                    title: 'Эхлэл',
                    href: '/',
                  },
                  {
                    title: 'Дуудлага худалдаа',
                    href: '/auction',
                  },
                  {
                    title: 'Оролцож буй',
                    className: 'breadcrumb-active',
                  },
                ]}
              />
              <Button type="primary" icon={<SyncOutlined />} onClick={() => {refreshAuctions()}}>
                Үнийн санал шинэчлэх
              </Button>
            </Flex>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
            <Alert
              style={{ backgroundColor: '#FFB5B5' }}
              message={
                <>
                  <WarningFilled style={{ color: '#FF0000', fontSize: 18 }} />
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                      color: '#000',
                      marginLeft: 8,
                    }}
                  >
                    САНАМЖ
                  </Text>
                </>
              }
              description={
                <>
                  <Paragraph
                    style={{ fontSize: 12, fontWeight: 500, color: '#000' }}
                  >
                    Улсын хэмжээнд явагдаж буй нийт цахим дуудлага худалдааг
                    үндсэн серверийн цаг / Улаанбаатар хотын цаг/- аар тооцон
                    зохион байгуулна. 60 секундийн хүлээлтийн горимд шилжсэн үед
                    секунд тоологдож дуусахыг хүлээлгүй даруй үнийн саналаа
                    илгээхийг зөвлөж байна.
                  </Paragraph>
                  <Button type="primary" style={{ backgroundColor: '#FF1D1D' }} onClick={() => {setIsShowModal(true)}}>
                    Дэлгэрэнгүй мэдээлэл
                  </Button>
                </>
              }
              type="error"
            />
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
            <Alert
              style={{ backgroundColor: '#ffda00' }}
              message={
                <>
                  <WarningFilled style={{ color: '#FF0000', fontSize: 18 }} />
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                      color: '#000',
                      marginLeft: 8,
                    }}
                  >
                    АНХААРУУЛГА
                  </Text>
                </>
              }
              description={
                <>
                  <Countdown title={
                    <Paragraph
                      style={{ fontSize: 12, fontWeight: 500, color: '#000' }}
                    >
                      15 минут идэвхгүй байхад системээс автоматаар гарна
                    </Paragraph>
                  }
                    value={deadline} style={{
                      fontSize: 12,
                      fontWeight: 500,
                      color: '#000',
                    }}/>
                </>
              }
              type="error"
            />
          </Col>
        </Row>
        <List
          style={{ marginTop: 40, marginBottom: 80 }}
          grid={{
            gutter: 20,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          dataSource={auctions}
          pagination={{ position: 'bottom', align: 'start' }}
          locale={{
            emptyText: <Empty description="Бичлэг байхгүй байна" />,
          }}
          renderItem={(item, index) => (
            <List.Item key={index} >
              <AuctionItem item={item}/>
            </List.Item>
          )}
        />
        <FooterLayout isFull />
        <Modal
          title="Дуудлага худалдааны санамж"
          open={isShowModal}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="ok" type="primary" onClick={handleOk}>
              Хаах
            </Button>,
          ]}
        >
          <div
            dangerouslySetInnerHTML={{ __html: caution }}
          />
        </Modal>

        <Modal
          title="Дуудлага худалдааны санамж"
          open={isShowTermsModal}
          onOk={handleTermsOk}
          onCancel={handleTermsCancel}
          footer={[
            <Button key="ok" type="primary" onClick={handleTermsOk}>
              Мэдэгдлийг хаах
            </Button>,
          ]}
        >
          <div
            dangerouslySetInnerHTML={{ __html: terms }}
          />
        </Modal>
      </Content>
    </Layout>
  )
}

export default AttendAuction
