import React, { useEffect, useRef, useState } from 'react'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import TileWMS from 'ol/source/TileWMS'
import OSM from 'ol/source/OSM'
import 'ol/ol.css'
import { getCenter } from 'ol/extent'
import GeoJSON from 'ol/format/GeoJSON'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import { Fill, Icon, Stroke, Style, Text } from 'ol/style'
import { Feature, Overlay } from 'ol'
import { Point } from 'ol/geom'
import { fromLonLat } from 'ol/proj'
import { XYZ } from 'ol/source'
import * as WEBSERVICES from '../../config/WebServices'
import SmartHelper from 'helper/SmartHelper'
import BaseLayer from 'ol/layer/Base'

const MainMap = (props: any) => {  

  const [loaded, setLoaded] = useState(false)
  const mapRef = useRef<Map | null>(null);
  let baseMap = props.baseMap; 

  let urbanLayer: BaseLayer | null = null; 
  var baseLayer: BaseLayer | null = null;

  useEffect(() => {
    const imageExtent = [9218917.1074,4799022.3839,13873626.3819,7178965.6965];
       
    var view = new View({
        center: getCenter(imageExtent),
        //center: ol.proj.fromLonLat([107.15968151256523, 47.91619699047089]),
        zoom: 6,
        minZoom: 3,
        maxZoom: 20,
    });
    
    baseLayer = getBaseLayer(baseMap);    

    const map = new Map({
      target: 'main-map',
      layers: [baseLayer],
      view: view,
    })
   
    map.on('pointermove', function (event: any) {
      const feature = map.forEachFeatureAtPixel(event.pixel, function (feature: any) {
        return feature;
      });
      const attr = feature?.getProperties();
      const mapElement = map.getTargetElement();
      if(attr?.type == "auction") {
        mapElement.style.cursor = feature ? 'pointer' : ''; // Set hand icon if feature is present, otherwise reset cursor
      } else {
        mapElement.style.cursor = '';
      }
    });


    map.on('click', function (event: any) {
      map.forEachFeatureAtPixel(event.pixel, function (feature: any) {
        const attr = feature.getProperties();
        
        if (attr.show) {
          if(attr.type === "auction") {
            showAuctionModal(feature);
          } else {
            showProjectModal(feature);
          }

          return true; 
        }
      });
    });

    mapRef.current = map;

    return () => {
      if(map) {
        map.setTarget(undefined)
      }
    }
  }, [])

  useEffect(() => {

    const map = mapRef.current;    

    let searchType = props.searchType;
    let results = props.results;
    let layers = props.layers;

    const feeZoneStyle = [
      {
        id: 2,
        borderColor: '#80cc00',
        fillColor: '#80cc0010',
        zIndex: 10,
        text: 'Хот суурин газар'
      },
      {
        id: 3,
        borderColor: '#a7dcfb',
        fillColor: '#a7dcfb10',
        zIndex: 11,
        text: 'Улаанбаатар'
      },
      {
        id: 21,
        borderColor: '#ccccff',
        fillColor: '#ccccff10',
        zIndex: 12,
        text: 'Гэр бүлийн хамтын хэрэгцээ'
      },
      {
        id: 22,
        borderColor: '#40E0D0',
        fillColor: '#40e0d010',
        zIndex: 13,
        text: 'Худалдаа, бүх төрлийн үйлчилгээ'
      },
      {
        id: 23,
        borderColor: '#3881ac',
        fillColor: '#3881ac10',
        zIndex: 14,
        text: 'ХАА-н үйлдвэрлэл'
      },
      {
        id: 24,
        borderColor: '#fda87f',
        fillColor: '#fda87f10',
        zIndex: 15,
        text: 'ХАА-гаас бусад үйлдвэрлэл',
        offsetY: 50
      }
    ] 
    if(map) {
      map.getLayers().forEach((layer: any) => {
        if (layer instanceof VectorLayer) {
          map.removeLayer(layer);
        }
      });
  
      if(baseLayer != null) {
        map.removeLayer(baseLayer);
      }

      baseLayer = getBaseLayer(baseMap);    

      map.addLayer(baseLayer);

      // const fetchAuctionData = async (item: any) => {
      //   console.log(item);
        
      //   let url = WEBSERVICES.URL_FEE_ZONE + `soum_code:${props.feeZone.au2};code:${item}`
      //   await fetch(url)
      //     .then((response) => response.json())
      //     .then((responseJson) => {
      //       let tempCoord = responseJson
      //       setFeeZoneGetCoord(tempCoord)
      //       // console.log(feeZoneGetCoord);
  
      //     })
      // }
      
      if (props.feeZone?.au2 || props.feeZone?.code) {     
  
        props.feeZone.code.map((item: any, index: any) => {
    
          var f_id = feeZoneStyle.findIndex(obj => obj.id === Number(item) )  
    
          async function fetchFeeZoneData() {
            let url;
            
            if (Number(item) === 1){
              url = WEBSERVICES.URL_FEE_ZONE + `soum_code:t;code:${item}`
    
            }else{
              url = WEBSERVICES.URL_FEE_ZONE + `soum_code:${props.feeZone.au2};code:${item}`
            }
            await fetch(url)
              .then((response) => response.json())
              .then((responseJson) => {
    
                for (let i = 0; i < responseJson.features.length; i++) {
    
                  var zoneNo = responseJson.features[i].properties.fz_name;
    
                  const format = new GeoJSON();
                  const vectorSources = new VectorSource();
                  const features: any = format.readFeatures(responseJson.features[i])
    
                  for (var a = 0; a < features.length; a++) {
                    features[a].getGeometry().transform('EPSG:4326', 'EPSG:3857');
                    vectorSources.addFeature(features[a]);
                  }
    
                  const vectorLayer = new VectorLayer({
                    source: vectorSources,
                    style: new Style({
                      stroke: new Stroke({
                        color: feeZoneStyle[f_id]?.borderColor,
                        width: 2
                      }),
                      // fill: new Fill({
                      //   color: feeZoneStyle[f_id]?.fillColor,
                      // }),
                      text: new Text({
                        text: `${zoneNo}`,
                        fill: new Fill({
                          color: '#a7dcfb'
                        }),
                      })
                    })
                  })
    
                  vectorLayer.setZIndex(10)
    
                  map?.addLayer(vectorLayer);
                }
              })
            }
    
            fetchFeeZoneData();
        })
      }
      
      if(urbanLayer != null) {
        map.removeLayer(urbanLayer);
      }
      
      if(layers) {
        var layerIds: any[] = [];
        
        Object.keys(layers).map(function(layerId){
          layers[layerId].map((item: any) => {
            layerIds.push(item);
          })
        });
        
        if(layerIds.length > 0) {
          urbanLayer = new TileLayer({          
              visible: true,
              source: new TileWMS({
                  url: 'https://urban.gov.mn/api/geoserver',
                  params: {
                      'FORMAT': 'image/png',
                      'VERSION': '1.1.0',
                      tiled: true,
                      //"STYLES": 'style_layer_data_admin',
                      "LAYERS": 'urban_gov:layer_data_map_new',
                      "exceptions": 'application/vnd.ogc.se_inimage',
                      "viewparams": 'layertypeid:' + escapeCommasSemiColons(layerIds.toString())+';au2:'+SmartHelper.getSession("au2"),
                  },
                  serverType: 'geoserver',
                  crossOrigin: 'anonymous',
                  projection: 'EPSG:4326',
              })
          });
  
          map.addLayer(urbanLayer);
        }
      }
      
      if(searchType == 'auction' && results) {      
        results.map((item: any, index: any) => {
          if(item.land.geojson) {
  
            const format = new GeoJSON();
            const vectorSources = new VectorSource();
            const features: any = format.readFeatures(item.land.geojson)          
            
  
            for(var i = 0; i < features.length; i++){
              features[i].getGeometry().transform('EPSG:4326', 'EPSG:3857');
              vectorSources.addFeature( features[i] );
            }
  
            const vectorLayer = new VectorLayer({
              source: vectorSources,
              style:  new Style({
                  stroke: new Stroke({
                    color: '#01949A',
                    width: 2
                  }),
                  fill: new Fill({
                    color: 'rgba(0, 0, 255, 0)'
                  }),
              })
            })
  
            vectorLayer.setZIndex(10)
  
            map.addLayer(vectorLayer);
  
            const iconStyle = new Style({
              image: new Icon({
                src: '/images/pin.png',
                scale: 0.5, 
                size: [64, 64]
              })
            });
  
            const markerVectorSource = new VectorSource();
            const markerVectorLayer = new VectorLayer({
              source: markerVectorSource,
            });
  
            var extend: any = vectorLayer.getSource()?.getExtent();
            var center = getCenter(extend);
            
            const iconFeature = new Feature({
              geometry: new Point(center),
              type: searchType,
              show: true,
              center: center,
              ...item,            
            });
  
            iconFeature.setStyle(iconStyle);
            markerVectorSource.addFeature(iconFeature);
  
            map.addLayer(markerVectorLayer);
          }
        })
      }
  
      if(searchType == 'project' && results) {      
        results.map((item: any, index: any) => {
          if(item.land.geojson) {
            const format = new GeoJSON();
            const vectorSources = new VectorSource();
            const features: any = format.readFeatures(item.land.geojson)
  
            for(var i = 0; i < features.length; i++){
              features[i].getGeometry().transform('EPSG:4326', 'EPSG:3857');
              vectorSources.addFeature( features[i] );
            }
  
            const vectorLayer = new VectorLayer({
              source: vectorSources,
              style:  new Style({
                  stroke: new Stroke({
                      color: '#01949A',
                      width: 2
                  }),
                  fill: new Fill({
                      color: 'rgba(0, 0, 255, 0)'
                  })
              })
            })
  
            vectorLayer.setZIndex(10)
  
            map.addLayer(vectorLayer);
  
            const iconStyle = new Style({
              image: new Icon({
                src: '/images/pin2.png',
                scale: 0.5, 
                size: [64, 64]
              })
            });
  
            const markerVectorSource = new VectorSource();
            const markerVectorLayer = new VectorLayer({
              source: markerVectorSource,
            });
  
            var extend: any = vectorLayer.getSource()?.getExtent();
            var center = getCenter(extend);
            
            const iconFeature = new Feature({
              geometry: new Point(center),
              type: searchType,
              show: true,
              center: center,
              ...item,            
            });
  
            iconFeature.setStyle(iconStyle);
            markerVectorSource.addFeature(iconFeature);
  
            map.addLayer(markerVectorLayer);
          }
        })
      }
    }
    
  }, [props.results, props.baseMap, props.layers]);


  const getBaseLayer = (baseMap: string) => {
    switch(baseMap) {
      case 'openstreet': 
        return new TileLayer({        
          source: new OSM(),
          visible: true
        })
      case 'voyager': 
        return new TileLayer({
          source: new XYZ({
            url: 'https://a.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}@2x.png'
          }),
          visible: true
        })
      case 'google': 
        return new TileLayer({
          source: new XYZ({
            url: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          }),
          visible: true
        })
      case 'topo': 
        return new TileLayer({
          source: new XYZ({
            url: 'https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png'
          }),
          visible: true
        })
      default: 
        return new TileLayer({
          source: new XYZ({
            url: 'https://a.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}@2x.png'
          }),
          visible: true
        })
    }
  }

  const escapeCommasSemiColons = (string: string) => {    
    if(string != "" && string != undefined) {
      var output = string.toString().replaceAll(",", "\\,");
      output = output.toString().replaceAll(";", "\\;");
    } else {
      output = '0';
    }
    return output;
  }

  const showAuctionModal = (item: any) => {
    props.showAuctionModal(item);
  };

  const showProjectModal = (item: any) => {
    props.showProjectModal(item);
  };
  
  
  return (    
    <div id="main-map" style={{ width: '100%', height: '100%' }} />      
  )
}

export default MainMap
