import React, { useEffect } from 'react'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import GeoJSON from 'ol/format/GeoJSON'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import {getCenter} from 'ol/extent'
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style'
import 'ol/ol.css'
interface SMapProps {
  item: any
  spacing?: number,
  url: any
}
const MapView = (props: any) => {
  useEffect(() => {
    const imageExtent = [9218917.1074,4799022.3839,13873626.3819,7178965.6965];

    var view = new View({
        center: getCenter(imageExtent),
        //center: ol.proj.fromLonLat([107.15968151256523, 47.91619699047089]),
        zoom: 6,
        minZoom: 3,
        maxZoom: 20,
    });

    const map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: view,
    })    

    if(typeof props.geoJson !== undefined && props.geoJson) {
      const format = new GeoJSON();
      const vectorSources = new VectorSource();
      const features: any = format.readFeatures(props.geoJson)

      for(var i = 0; i < features.length; i++){
        features[i].getGeometry().transform('EPSG:4326', 'EPSG:3857');
        vectorSources.addFeature( features[i] );
      }

      const vectorLayer = new VectorLayer({
        source: vectorSources,
        style:  new Style({
            stroke: new Stroke({
                color: '#01949A',
                width: 2
            }),
            fill: new Fill({
                color: 'rgba(0, 0, 255, 0)'
            })
        })
      })

      vectorLayer.setZIndex(10)

      map.addLayer(vectorLayer);

      var extend: any = vectorLayer.getSource()?.getExtent();
      var center = getCenter(extend);

      map.getView().animate({
          center: center,
          duration: 2000
        }, callback);

      function callback(complete: any) {
          const size: any = map.getSize();
          map.getView().fit(extend, size);
          map.getView().setZoom(18);
      }
    }

    return () => {
      map.setTarget(undefined)
    }
  }, [props])

  return <div id="map" style={{ width: '100%', height: '400px' }} />
}

export default MapView
