import React, { useEffect, useState } from 'react'
import {
  Layout,
  Row,
  Col,
  Divider,
  Card,
  Button,
  Form,
  Input,
  Flex,
  Typography,
  List,
  Breadcrumb,
  Pagination,
  Spin,
} from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'

import BlogItem from './../../components/BlogItem'
import FooterLayout from './../../layouts/Footer'

import * as WEBSERVICES from '../../config/WebServices'

import dayjs from 'dayjs'
import 'dayjs/locale/mn'
import { useNavigate } from 'react-router-dom'

dayjs.locale('mn')

const { Content } = Layout
const { Title } = Typography
const { Meta } = Card

const Blogs: React.FC = () => {
  const [form] = Form.useForm()
  const [news, setNews] = useState([] as any)
  const [loaded, setLoaded] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    async function fetchData() {
      await fetchNewsList(1);
    }

    fetchData().then(() => {
      setLoaded(true);
    })

  }, [])

  const onChangePagination = async (page: any) => {
    await fetchNewsList(page);
  }

  const onFinish = (values: any) => {
    console.log(values)
  }

  const onReset = () => {
    form.resetFields()
  }

  const fetchNewsList = async (page: any) => {
    let url = WEBSERVICES.URL_NEWS_PAGINATION+"?page="+page;
        
    await fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
            setNews(responseJson.data);
            
        })
        .catch((error) => {
            setNews([]);
        });     
        
  }

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        {!loaded && <Spin size="large" fullscreen />}
        <Row gutter={20}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Flex
              style={{ marginTop: 40 }}
              justify="space-between"
              align="center"
              wrap="wrap"
            >
              <Meta
                title={
                  <Title
                    level={4}
                    style={{
                      color: '#393874',
                      marginBottom: 5,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    мэдээ мэдээлэл
                  </Title>
                }
              />
            </Flex>
            <Breadcrumb
              separator={<ArrowRightOutlined />}
              items={[
                {
                  title: 'Эхлэл',
                  href: '/',
                },
                {
                  title: 'мэдээ мэдээлэл',
                  className: 'breadcrumb-active',
                },
              ]}
            />
            <Card style={{ marginTop: 10, marginBottom: 40 }}>
              <List
                grid={{
                  gutter: 20,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 3,
                  xl: 4,
                  xxl: 6,
                }}
                dataSource={news.data}
                renderItem={(item, index) => (
                  <List.Item>
                    <BlogItem key={index} item={item} />
                  </List.Item>
                )}
                style={{ marginBottom: 5 }}
              />
              <Pagination
                onChange={onChangePagination}
                total={news?.total}
                style={{ marginBottom: 20 }}
              />
            </Card>
            <FooterLayout isFull />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default Blogs
