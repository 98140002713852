import React from 'react'
import { Button } from 'antd'

const buttonStyles = {
  fontSize: 12,
  color: 'black', 
  width: 153,
  height: 40,
  background: 'linear-gradient(110.35deg, #FFFFFF 0.78%, #B0B0B0 100%)',
  boxShadow: 'none', 
  transition: 'background 0.3s ease, box-shadow 0.3s ease', 
}

const CoverButton: React.FC<React.ComponentProps<typeof Button>> = (props) => {
  return (
    <Button
      style={{ ...buttonStyles, ...props.style }}
      {...props}
      onMouseEnter={(e) => {
        const target = e.currentTarget as HTMLElement
        target.style.background = '#ffdb01'
        target.style.boxShadow = '0 0 10px 2px yellow' 
      }}
      onMouseLeave={(e) => {
        const target = e.currentTarget as HTMLElement
        target.style.background =
          'linear-gradient(110.35deg, #FFFFFF 0.78%, #B0B0B0 100%)'
        target.style.boxShadow = 'none'
      }}
    />
  )
}

export default CoverButton
