import React from 'react'
import {
  Card,
  Flex,
  Typography,
  Image,
  Divider,
  Space,
  Button,
  Tag,
} from 'antd'
import { BookOutlined } from '@ant-design/icons'

import moment from 'moment'
import * as CONFIGS from '../../config/Configs'

import { useNavigate, useParams } from 'react-router-dom'
import SmartHelper from 'helper/SmartHelper'
const { Text, Paragraph } = Typography
const { Meta } = Card

interface SCardProps {
  item: any
  spacing?: number,
  url: any
}
const SCard: React.FC<SCardProps> = ({ item, spacing, url }) => {
  const navigate = useNavigate()
  
  const getCoverImage = (images: any): string | any => {
    if(images && images.length > 0) {
      return CONFIGS.URL_AUCTION_ROOT+images[0].file_url;
    }

    return "";
  }

  const navigateToSalesDetail = (item: any) => {
    navigate(`/sales/detail/${item.id}`)
    navigate(0);
  }
  
  return (
    <Card
      cover={
        <div style={{ position: 'relative' }}>
          <Flex
            style={{
              position: 'absolute',
              top: 10,
              padding: '0 10px',
              width: '100%',
            }}
            justify="space-between"
          >
            <Tag
              style={{
                color: '#0654DE',
                fontWeight: 600,
                marginInlineEnd: 0,
                zIndex: 100,
              }}
            >
              #{item.code}
            </Tag>
            {item.ad_type && (
              <Tag
                color={item?.ad_type?.color}
                style={{ fontWeight: 600, marginInlineEnd: 0, zIndex: 100 }}
              >
                {item?.ad_type?.name}
              </Tag>
            )}
          </Flex>
          <Image
            height={180}
            width={'100%'}
            src={getCoverImage(item.documents)}
            fallback="/images/noimage.png"
          />
          <Flex
            justify="space-around"
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              backgroundColor: '#0654DE',
              height: 47,
              width: '100%',
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            <Space
              split={
                <Divider
                  type="vertical"
                  style={{
                    backgroundColor: '#FFDA00',
                    height: 25,
                  }}
                />
              }
            >
              {/* <Flex vertical align="center" justify="center">
                <Text
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    color: '#fff',
                    lineHeight: '18px',
                  }}
                >
                  {item?.square}м2
                </Text>
              </Flex> */}
              <Flex vertical align="center" justify="center">
                <Text
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    color: '#fff',
                    lineHeight: '18px',
                  }}
                >
                  {moment(item.created_at).format('YYYY-MM-DD')}
                </Text>
              </Flex>              
            </Space>
          </Flex>
        </div>
      }
      style={{
        boxShadow: '0px 4px 19px #D8DEE9',
        margin: spacing ? '0 10px 20px 10px' : 0,
      }}
      styles={{
        body: {
          padding: '20px 16px 40px 16px',
          position: 'relative',
        },
      }}
    >
      <Button
        style={{
          fontSize: 20,
          position: 'absolute',
          right: 20,
          top: 10,
          border: 'none',
          boxShadow: 'none',
          padding: 0,
          color: '#FFFFFF',
        }}
      >
        <BookOutlined />
      </Button>
      <Meta
        style={{ marginBottom: 10 }}
        avatar={
          <Image
            src="/images/icon-card-address.jpg"
            width={31}
            height={31}
            alt=""
            preview={false}
          />
        }
        title={
          <Text
            style={{
              color: '#6E6B98',
              fontSize: 12,
              fontWeight: 400,
              textTransform: 'capitalize',
              width: 150,
            }}
          >
            {item?.admin_unit_one?.name}, {item?.admin_unit_two?.name},{' '}
            {item?.admin_unit_three?.name}, {item?.address}
          </Text>
        }
      />
      <Meta
        style={{ marginBottom: 10 }}
        avatar={
          <Image
            src="/images/icon-card-flag.jpg"
            width={31}
            height={31}
            alt=""
            preview={false}
          />
        }
        title={
          <Paragraph
            ellipsis={{ rows: 2, expandable: false, tooltip: item.category }}
            style={{
              color: '#6E6B98',
              fontSize: 12,
              fontWeight: 400,
              textTransform: 'capitalize',
              whiteSpace: 'wrap',
              marginBottom: 0,
              lineHeight: '16px',
            }}
          >
            {item?.land?.land_type?.name}
          </Paragraph>
        }
      />
      <Meta
        style={{ marginBottom: 10 }}
        avatar={
          <Image
            src="/images/icon-card-square.jpg"
            width={31}
            height={31}
            alt=""
            preview={false}
          />
        }
        title={
          <Text
            style={{
              color: '#6E6B98',
              fontSize: 12,
              fontWeight: 400,
              textTransform: 'capitalize',
            }}
          >
            {item?.land?.area_m2}м2
          </Text>
        }
      />
      <Meta
        style={{ marginBottom: 20 }}
        avatar={
          <Image
            src="/images/icon-card-wallet.jpg"
            width={31}
            height={31}
            alt=""
            preview={false}
          />
        }
        title={
          <Text
            style={{
              color: '#0654DE',
              fontSize: 24,
              lineHeight: '30px',
              fontWeight: 700,
              textTransform: 'capitalize',
            }}
          >
            {item?.price
              ? SmartHelper.formatCurrency(item?.price)
              : '0.00'}
            ₮
          </Text>
        }
      />
      <Button
        style={{
          color: '#0654DE',
        }}
        block
        className={'auction-hover-border'}
        onClick={() => {
          navigateToSalesDetail(item)
        }}
      >
        Дэлгэрэнгүй
      </Button>
    </Card>
  )
}

export default SCard
