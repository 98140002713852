import ReactDOM from 'react-dom/client'
import { ConfigProvider } from 'antd'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
//Branch Test
root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#0654DE',
        fontFamily: 'Roboto',
        fontSize: 12,
        // borderRadius: 20,
      },
      components: {
        Layout: {
          headerBg: '#fff',
          headerHeight: 74,
          lightSiderBg: 'transparent',
          bodyBg: '#F4F5F8',
        },
        Button: {
          fontFamily: 'Roboto',
          fontSize: 12,
          defaultBorderColor: '#E7E7EE',
          fontWeight: 600,
        },
        Menu: {
          darkItemBg: 'transparent',
          darkItemSelectedBg: 'transparent',
          darkItemSelectedColor: '#FFDA00',
          itemBg: 'transparent',
          itemSelectedBg: 'transparent',
          itemSelectedColor: '#0654DE',
          iconSize: 16,
          itemHoverBg: 'transparent',
          itemHoverColor: '#181771',
        },
        Card: {
          borderRadiusLG: 5,
        },
        Tag: {
          defaultBg: '#fff',
          fontSize: 12,
        },
        Input: {
          addonBg: 'transparent',
        },
      },
    }}
  >
    <App />
  </ConfigProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
