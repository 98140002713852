export const IntroVideoData: any = [
  {
    cover: 'https://mle.mn/uploaded/images/2019/Jul/Screen Shot 2019-07-26 at 01.33.03.png',
    video_url: 'https://www.youtube.com/embed/JLvXAXP-aoQ',
    title:
      'Дуудлага худалдаанд оролцох хүсэлт илгээх видео заавар',
    description:
      'Монгол Улсын Засаг захиргаа, нутаг дэвсгэрийн нэгж, түүний удирдлагын тухай хуулийн шинэчилсэн найруулгын төсөлд иргэдээс санал авч байна',
    viewed: '90k',
    date: '2020.09.20',
  },
  {
    cover: 'https://mle.mn/uploaded/images/2019/Jul/Screen Shot 2019-07-26 at 01.31.11.png',
    video_url: 'https://www.youtube.com/embed/SYeLZK7yqzc',
    title: 'Системд имэйл хаягаараа нэвтрэх видео заавар',
    description:
      'Монгол Улсын Засаг захиргаа, нутаг дэвсгэрийн нэгж, түүний удирдлагын тухай хуулийн шинэчилсэн',
    viewed: '90k',
    date: '2020.09.20',
  },
  {
    cover: 'https://mle.mn/uploaded/images/2019/Jul/Screen Shot 2019-07-26 at 01.34.40.png',
    video_url: 'https://www.youtube.com/embed/ofbArMK00Bw',
    title: 'Үнийн санал өгөх видео заавар',
    description: 'Монгол Улсын Засаг захиргаа, нутаг дэвсгэрийн нэгж',
    viewed: '90k',
    date: '2020.09.20',
  },
  {
    cover: 'https://mle.mn/uploaded/images/2019/Jul/Screen Shot 2019-07-26 at 01.41.25.png',
    video_url: 'https://www.youtube.com/embed/oTL6RNHJGMk',
    title: 'Газрын биржийн цахим системд хэрэглэгчээр бүртгүүлэх',
    description: 'Монгол Улсын Засаг захиргаа, нутаг дэвсгэрийн',
    viewed: '90k',
    date: '2020.09.20',
  },
]
export const AuctionCardData: any = [
  {
    id: '2023-15432',
    status: 'Дууссан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category: 'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#0654DE',
    type: 'auction',
  },
  {
    id: '2023-15432',
    status: 'Дууссан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category: 'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#0654DE',
    type: 'auction',
  },
  {
    id: '2023-15432',
    status: 'Дууссан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category: 'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#0654DE',
    type: 'auction',
  },
  {
    id: '2023-15432',
    status: 'Дууссан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category:
      'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж, Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#0654DE',
    type: 'auction',
  },
  {
    id: '2023-15432',
    status: 'Дууссан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category: 'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#0654DE',
    type: 'auction',
  },
]

export const ProjectCardData: any = [
  {
    id: '2023-15432',
    status: 'Дууссан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category: 'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#1AA752',
    type: 'project-evaluation',
  },
  {
    id: '2023-15432',
    status: 'Дууссан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category: 'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#1AA752',
    type: 'project-evaluation',
  },
  {
    id: '2023-15432',
    status: 'Дууссан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category: 'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#1AA752',
    type: 'project-evaluation',
  },
  {
    id: '2023-15432',
    status: 'Дууссан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category:
      'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж, Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#1AA752',
    type: 'project-evaluation',
  },
  {
    id: '2023-15432',
    status: 'Дууссан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category: 'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#1AA752',
    type: 'project-evaluation',
  },
]

export const SalesCardData: any = [
  {
    id: '2023-15432',
    status: 'Зарагдсан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category: 'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#FF1D1D',
    type: 'trade-rent',
  },
  {
    id: '2023-15432',
    status: 'Зарагдсан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category: 'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#FF1D1D',
    type: 'trade-rent',
  },
  {
    id: '2023-15432',
    status: 'Зарагдсан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category: 'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#FF1D1D',
    type: 'trade-rent',
  },
  {
    id: '2023-15432',
    status: 'Зарагдсан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category:
      'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж, Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#FF1D1D',
    type: 'trade-rent',
  },
  {
    id: '2023-15432',
    status: 'Зарагдсан',
    cover: 'https://picsum.photos/200/180',
    start_date: '2024.04.22',
    start_time: '10:00',
    end_time: '16:00',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    square: '14632 м2',
    category: 'Уул уурхайн үйлдвэрлэлийн барилга, байгууламж',
    price: '23,411,200 ₮',
    themeColor: '#FF1D1D',
    type: 'trade-rent',
  },
]

export const AuctionAttendingItems: any = [
  {
    id: '2023-15432',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    item_border_color: '#FF1D1D',
    image_url: 'https://picsum.photos/337/200',
    floor_price: '4,100,200 ₮',
    max_price: '23,411,200 ₮',
    participant_count: 5,
    participants: [
      {
        user_id: 6128,
        date: '14:46:54',
        price: '4,100,200 ₮',
        color: '#4ED442',
      },
      {
        user_id: 6129,
        date: '15:46:54',
        price: '2,500,00 ₮',
        color: '#FF1D1D',
      },
    ],
  },
  {
    id: '2023-15432',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    item_border_color: '#4ED442',
    image_url: 'https://picsum.photos/337/200',
    floor_price: '4,100,200 ₮',
    max_price: '23,411,200 ₮',
    participant_count: 5,
    participants: [
      {
        user_id: 6228,
        date: '14:46:54',
        price: '4,100,200 ₮',
        color: '#4ED442',
      },
      {
        user_id: 6229,
        date: '15:46:54',
        price: '2,500,00 ₮',
        color: '#393874',
      },
      {
        user_id: 6230,
        date: '15:46:54',
        price: '2,500,00 ₮',
        color: '#393874',
      },
      {
        user_id: 6231,
        date: '15:46:54',
        price: '2,500,00 ₮',
        color: '#393874',
      },
      {
        user_id: 6232,
        date: '15:46:54',
        price: '2,500,00 ₮',
        color: '#FF1D1D',
      },
    ],
  },
]

export const AuctionPriceQuoteData: any = [
  {
    id: '2023-15432',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    quotes: [
      {
        id: 1,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 2,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 3,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 4,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 5,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 6,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 7,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
    ],
  },
  {
    id: '2023-15432',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    quotes: [
      {
        id: 1,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 2,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 3,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 4,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 5,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 6,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 7,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
    ],
  },
  {
    id: '2023-15432',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    quotes: [
      {
        id: 1,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 2,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 3,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 4,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 5,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 6,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 7,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
    ],
  },
  {
    id: '2023-15432',
    address: 'Хэнтий Бор-Өндөр 2-р баг Хэнтий Бор-Өндөр 2-р баг',
    quotes: [
      {
        id: 1,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 2,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 3,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 4,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 5,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 6,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
      {
        id: 7,
        user: 'Хэрэглэгч 8080',
        date: '2024.03.22 19:45:13',
        price: '4,500,000₮',
      },
    ],
  },
]

export const PriceInfoChartData = [
  {
    date: '2018/8/1',
    type: 'Дундаж үнэ',
    value: 4623,
  },
  {
    date: '2018/8/1',
    type: 'Үл хөдлөх хөрөнгө бүхий',
    value: 2208,
  },
  {
    date: '2018/8/1',
    type: 'Үл хөдлөх хөрөнгөггүй',
    value: 182,
  },
  {
    date: '2018/8/2',
    type: 'Дундаж үнэ',
    value: 6145,
  },
  {
    date: '2018/8/2',
    type: 'Үл хөдлөх хөрөнгө бүхий',
    value: 2016,
  },
  {
    date: '2018/8/2',
    type: 'Үл хөдлөх хөрөнгөггүй',
    value: 257,
  },
  {
    date: '2018/8/3',
    type: 'Дундаж үнэ',
    value: 508,
  },
  {
    date: '2018/8/3',
    type: 'Үл хөдлөх хөрөнгө бүхий',
    value: 2916,
  },
  {
    date: '2018/8/3',
    type: 'Үл хөдлөх хөрөнгөггүй',
    value: 289,
  },
  {
    date: '2018/8/4',
    type: 'Дундаж үнэ',
    value: 6268,
  },
  {
    date: '2018/8/4',
    type: 'Үл хөдлөх хөрөнгө бүхий',
    value: 4512,
  },
  {
    date: '2018/8/4',
    type: 'Үл хөдлөх хөрөнгөггүй',
    value: 428,
  },
  {
    date: '2018/8/5',
    type: 'Дундаж үнэ',
    value: 6411,
  },
  {
    date: '2018/8/5',
    type: 'Үл хөдлөх хөрөнгө бүхий',
    value: 8281,
  },
  {
    date: '2018/8/5',
    type: 'Үл хөдлөх хөрөнгөггүй',
    value: 619,
  },
  {
    date: '2018/8/6',
    type: 'Дундаж үнэ',
    value: 1890,
  },
  {
    date: '2018/8/6',
    type: 'Үл хөдлөх хөрөнгө бүхий',
    value: 2008,
  },
  {
    date: '2018/8/6',
    type: 'Үл хөдлөх хөрөнгөггүй',
    value: 87,
  },
  {
    date: '2018/8/7',
    type: 'Дундаж үнэ',
    value: 4251,
  },
  {
    date: '2018/8/7',
    type: 'Үл хөдлөх хөрөнгө бүхий',
    value: 1963,
  },
  {
    date: '2018/8/7',
    type: 'Үл хөдлөх хөрөнгөггүй',
    value: 706,
  },
  {
    date: '2018/8/8',
    type: 'Дундаж үнэ',
    value: 2978,
  },
  {
    date: '2018/8/8',
    type: 'Үл хөдлөх хөрөнгө бүхий',
    value: 2367,
  },
  {
    date: '2018/8/8',
    type: 'Үл хөдлөх хөрөнгөггүй',
    value: 387,
  },
  {
    date: '2018/8/9',
    type: 'Дундаж үнэ',
    value: 3880,
  },
  {
    date: '2018/8/9',
    type: 'Үл хөдлөх хөрөнгө бүхий',
    value: 2956,
  },
  {
    date: '2018/8/9',
    type: 'Үл хөдлөх хөрөнгөггүй',
    value: 488,
  },
  {
    date: '2018/8/10',
    type: 'Дундаж үнэ',
    value: 3606,
  },
  {
    date: '2018/8/10',
    type: 'Үл хөдлөх хөрөнгө бүхий',
    value: 678,
  },
  {
    date: '2018/8/10',
    type: 'Үл хөдлөх хөрөнгөггүй',
    value: 507,
  },
  {
    date: '2018/8/11',
    type: 'Дундаж үнэ',
    value: 4311,
  },
  {
    date: '2018/8/11',
    type: 'Үл хөдлөх хөрөнгө бүхий',
    value: 3188,
  },
  {
    date: '2018/8/11',
    type: 'Үл хөдлөх хөрөнгөггүй',
    value: 548,
  },
  {
    date: '2018/8/12',
    type: 'Дундаж үнэ',
    value: 4116,
  },
  {
    date: '2018/8/12',
    type: 'Үл хөдлөх хөрөнгө бүхий',
    value: 3491,
  },
  {
    date: '2018/8/12',
    type: 'Үл хөдлөх хөрөнгөггүй',
    value: 456,
  },
  {
    date: '2018/8/13',
    type: 'Дундаж үнэ',
    value: 6419,
  },
  {
    date: '2018/8/13',
    type: 'Үл хөдлөх хөрөнгө бүхий',
    value: 2852,
  },
  {
    date: '2018/8/13',
    type: 'Үл хөдлөх хөрөнгөггүй',
    value: 689,
  },
]
