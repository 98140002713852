import React, { useEffect, useState } from 'react'
import {
  Layout,
  Carousel,
  Typography,
  Flex,
  Button,
  Space,
  Image,
  Divider,
  Form,
  Input,
  Row,
  Col,
  Spin,
  Modal,
} from 'antd'
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  InfoCircleOutlined,
  MailOutlined,
} from '@ant-design/icons'
import MCard from './../../components/Card'
import PCard from './../../components/PCard'

import FooterLayout from './../../layouts/Footer'
import BlogItem from './../../components/BlogItem'
import Map from './map'

import * as WEBSERVICES from 'config/WebServices'
import SmartHelper from 'helper/SmartHelper'
import { useNavigate } from 'react-router-dom'

const { Content } = Layout
const { Title, Text, Link } = Typography

const contentStyle: React.CSSProperties = {
  margin: 0,
  textAlign: 'center',
  position: 'relative',
  padding: 40,
}

const HomePage: React.FC = () => {
  const [form] = Form.useForm()

  const [links, setLinks] = useState([] as any[])
  const [auctions, setAuctions] = useState([] as any[])
  const [projects, setProjects] = useState([] as any[])
  const [news, setNews] = useState([] as any[])
  const [loaded, setLoaded] = useState(false)
  const [statuses, setStatuses] = useState([] as any)  
  const [projectStatuses, setProjectStatuses] = useState([] as any)  

  const [isShowModal, setIsShowModal] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [message, setMessage] = useState("")

  const navigate = useNavigate()

  useEffect(() => {
    async function fetchData() {
      await fetchProvinceLinks()
      await fetchAuctionList()
      await fetchNewsList()
      await fetchAuctionFilter()
      await fetchProjectFilter()
      await fetchProjectList()
    }

    fetchData().then(() => {
      setLoaded(true)
    })
  }, [])

  const carouselCardSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <ArrowRightOutlined />,
    prevArrow: <ArrowLeftOutlined />,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  const carouselLinkSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <ArrowRightOutlined />,
    prevArrow: <ArrowLeftOutlined />,
    className: 'center',
    // centerMode: true,
    centerPadding: '60px',
    rows: 2,
    slidesPerRow: 2,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const searchByToday = () => {
    navigate(`/auction`, { state: { today: 1 } })
    navigate(0)
  }

  const searchByStatus = (statusId: any) => {
    navigate(`/auction`, { state: { statusId: statusId } })
    navigate(0)
  }

  const searchByProjectStatus = (statusId: any) => {
    navigate(`/project`, { state: { statusId: statusId } })
    navigate(0)
  }

  const fetchAuctionFilter = async () => {
    let url = WEBSERVICES.URL_AUCTION_FILTER +
    '?au1=' +
    SmartHelper.getSession('au1') +
    '&au2=' +
    SmartHelper.getSession('au2') +
    '&au3=' +
    SmartHelper.getSession('au3')

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setStatuses(responseJson.statuses)
        } else {
          setStatuses([])
        }
      })
      .catch((error) => {
        setStatuses([])
      })
  }

  const fetchProjectFilter = async () => {
    let url = WEBSERVICES.URL_PROJECT_FILTER +
    '?au1=' +
    SmartHelper.getSession('au1') +
    '&au2=' +
    SmartHelper.getSession('au2') +
    '&au3=' +
    SmartHelper.getSession('au3')

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setProjectStatuses(responseJson.statuses)
        } else {
          setProjectStatuses([])
        }
      })
      .catch((error) => {
        setStatuses([])
      })
  }

  const fetchProvinceLinks = async () => {
    let url = WEBSERVICES.URL_PROVINCE_LINKS

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        setLinks(responseJson)
      })
      .catch((error) => {
        setLinks([])
      })
  }

  const fetchNewsList = async () => {
    let url = WEBSERVICES.URL_LATEST_NEWS

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        setNews(responseJson)
      })
      .catch((error) => {
        setNews([])
      })
  }

  const fetchAuctionList = async () => {
    let url =
      WEBSERVICES.URL_AUCTION_LATEST_LIST +
      '?au1=' +
      SmartHelper.getSession('au1') +
      '&au2=' +
      SmartHelper.getSession('au2') +
      '&au3=' +
      SmartHelper.getSession('au3')

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setAuctions(responseJson.data)
        } else {
          setAuctions([])
        }
      })
      .catch((error) => {
        setAuctions([])
      })
  }

  const fetchProjectList = async () => {
    let url =
      WEBSERVICES.URL_PROJECT_LATEST_LIST +
      '?au1=' +
      SmartHelper.getSession('au1') +
      '&au2=' +
      SmartHelper.getSession('au2') +
      '&au3=' +
      SmartHelper.getSession('au3')

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setProjects(responseJson.data)
        } else {
          setProjects([])
        }
      })
      .catch((error) => {
        setProjects([])
      })
  }

  const onFinish = async (values: any) => {
    setLoaded(false);

    try {
      const response = await fetch(WEBSERVICES.URL_FEEDBACK_STORE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          full_name: values.full_name,
          email: values.email,
          phone: values.phone,
          message: values.message,
        })
      });

      if (!response.ok) {
        throw new Error('Санал хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
      }

      const result = await response.json();
      if(result.status === true) {
        let msg = '<p>Баярлалаа. Амжилттай таны саналыг илгээлээ.</p>';
        setIsShowModal(true);
        setMessage(msg);
        setIsSuccess(true);
        setLoaded(true);
        form.resetFields();
      } else {
        throw new Error('Санал хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
      }
    } catch (error) {
      let msg = `<p>${error}</p>`;
      setIsShowModal(true);
      setMessage(msg);
      setIsSuccess(false);
      setLoaded(true);
    }
  };  

  const handleOk = () => {
    setIsShowModal(false);
  }

  const handleCancel = () => {
    setIsShowModal(false);
  }


  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        {!loaded && <Spin size="large" fullscreen />}
        <Layout>
          <Content>
            <div
              style={{
                backgroundColor: '#fff',
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundImage: 'url(/images/cover-map-pattern.png)',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  width: '100%',
                  height: '100%',
                }}
              />
              <Map />
            </div>
          </Content>
        </Layout>
        <Layout>
          <Content style={{ marginTop: 40 }}>
            <Row gutter={20}>
              <Col xs={24} sm={24} md={16} lg={16} xl={18} xxl={19}>
                {/* Auction */}
                <div
                  style={{ position: 'relative', marginBottom: 70 }}
                  className="auction-card"
                >
                  <Title
                    level={4}
                    style={{
                      color: '#0654DE',
                      marginBottom: 30,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    Дуудлага худалдаа
                  </Title>
                  <Space style={{ marginBottom: 5 }} wrap>
                    <Button onClick={searchByToday}>Өнөөдөр</Button>
                    {statuses.map((item: any, index: any) => {
                      return (
                        <Button
                          key={index}
                          type="text"
                          style={{ color: item.color }}
                          onClick={() => {
                            searchByStatus(item.id)
                          }}
                        >
                          {item.name} - {item.total}
                        </Button>
                      )
                    })}
                  </Space>
                  <Carousel {...carouselCardSettings} style={{ marginTop: 20 }}>
                    {auctions.map((item, index) => (
                      <MCard
                        key={index}
                        item={item}
                        spacing={10}
                        url={'auction/detail'}
                      />
                    ))}
                  </Carousel>
                </div>
                {/* Project evaluation */}
                <div
                  style={{ position: 'relative', marginBottom: 70 }}
                  className="project-card"
                >
                  <Title
                    level={4}
                    style={{
                      color: '#1AA752',
                      marginBottom: 30,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    Төсөл сонгон шалгаруулалт
                  </Title>
                  {projectStatuses.map((item: any, index: any) => {
                      return (
                        <Button
                          key={index}
                          type="text"
                          style={{ color: item.color }}
                          onClick={() => {
                            searchByProjectStatus(item.id)
                          }}
                        >
                          {item.name} - {item.total}
                        </Button>
                      )
                    })}
                  
                  <Carousel {...carouselCardSettings} style={{ marginTop: 20 }}>
                    {projects.map((item: any, index: any) => (
                      <PCard
                        key={index}
                        item={item}
                        spacing={10}
                        url={'project/detail'}
                      />
                    ))}
                  </Carousel>
                </div>
                {/* Trade & Rent */}
                {/*<div style={{ position: 'relative', marginBottom: 70 }} className="trade-card">
                  <Title
                    level={4}
                    style={{
                      color: '#FF1D1D',
                      marginBottom: 30,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    Худалдах/Түрээслэх зар
                  </Title>
                  <Space style={{ marginBottom: 5 }} wrap>
                    <Button>Өнөөдөр</Button>
                    <Button type="text" style={{ color: '#0654DE' }}>
                      Худалдах зар - 0
                    </Button>
                    <Button type="text" style={{ color: '#0654DE' }}>
                      Түрээслэх зар - 0
                    </Button>
                  </Space>
                  <Carousel {...carouselCardSettings} style={{ marginTop: 20 }}>
                    {SalesCardData.map((item: any, index: any) => (
                      <MCard key={index} item={item} spacing={10} />
                    ))}
                  </Carousel>
                  </div>*/}
                {/* Links */}
                <div style={{ position: 'relative', marginBottom: 110 }}>
                  <Title
                    level={4}
                    style={{
                      color: '#0654DE',
                      marginBottom: 30,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    Холбоос
                  </Title>
                  <Carousel {...carouselLinkSettings} style={{ marginTop: 20 }}>
                    {links.map((item, index) => (
                      <a
                        key={index}
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div key={index}>
                          <div style={contentStyle}>
                            <Flex vertical align="center">
                              <Image
                                src={item.image}
                                height={97}
                                alt={item.title}
                                preview={false}
                              />
                              <Text
                                style={{
                                  fontSize: 12,
                                  fontWeight: 500,
                                  color: '#0654DE',
                                  marginTop: 12,
                                  height: 40,
                                }}
                              >
                                {item.title}
                              </Text>
                            </Flex>
                            <div
                              style={{
                                position: 'absolute',
                                right: 0,
                                top: '50%',
                                transform: 'translateY(-50%)',
                                height: 153,
                                width: 1,
                                backgroundColor: 'rgb(204 204 204 / 32%)',
                              }}
                            />
                            <div
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                height: 1,
                                width: '80%',
                                backgroundColor: 'rgb(204 204 204 / 32%)',
                              }}
                            />
                          </div>
                        </div>
                      </a>
                    ))}
                  </Carousel>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6} xxl={5}>
                <div
                  style={{
                    padding: '40px 20px',
                    background: '#fff',
                    borderRadius: 5,
                    boxShadow: '0px 4px 19px #D8DEE9',
                    marginBottom: 20,
                  }}
                >
                  <div>
                    <Flex align="center" justify="space-between">
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: '#393874',
                          textTransform: 'uppercase',
                        }}
                      >
                        <InfoCircleOutlined style={{ marginRight: 8 }} />
                        Мэдээ
                      </Text>
                      <Link
                        style={{ color: '#393874', fontSize: 10 }}
                        href="/blogs"
                      >
                        Бүгдийг унших
                      </Link>
                    </Flex>
                    <Divider style={{ margin: '10px 0 20px 0' }} />
                  </div>
                  {news.map((item, index) => (
                    <BlogItem key={index} item={item} />
                  ))}
                </div>
                <div
                  style={{
                    padding: '40px 20px',
                    background: '#fff',
                    borderRadius: 5,
                    boxShadow: '0px 4px 19px #D8DEE9',
                    marginBottom: 20,
                  }}
                >
                  <div>
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: '#393874',
                        textTransform: 'uppercase',
                      }}
                    >
                      <MailOutlined style={{ marginRight: 8 }} />
                      Санал хүсэлт
                    </Text>
                    <Divider style={{ margin: '10px 0 20px 0' }} />
                    <Form
                      layout={'vertical'}
                      form={form}
                      initialValues={{
                        full_name: '',
                        email: '',
                        phone: '',
                        message: '',
                      }}
                      style={{ maxWidth: '100%' }}
                      onFinish={onFinish}
                    >
                      <Form.Item label="Овог нэр" name="full_name"
                        rules={[
                          {
                            required: true,
                            message: 'Заавал оруулна',
                          },
                        ]}>
                        <Input placeholder='Жишээ: Э.Дорж'/>
                      </Form.Item>
                      <Form.Item label="Имэйл" name="email"
                        rules={[
                          {
                            required: true,
                            type: 'email',
                            message: 'Заавал оруулна. Имэйл оруулна.',
                          },
                        ]}>
                        <Input placeholder='Жишээ: dorj@gmail.com' />
                      </Form.Item>
                      <Form.Item label="Утас" name="phone"
                        rules={[
                          {
                            required: true,
                            message: 'Заавал оруулна',
                          },
                        ]}>
                        <Input placeholder='Жишээ: 9999xxxx' />
                      </Form.Item>
                      <Form.Item label="Санал хүсэлт" name="message"
                        rules={[
                          {
                            required: true,
                            message: 'Заавал оруулна',
                          },
                        ]}>
                        <Input.TextArea placeholder='Санал хүсэлтээ энд бичнэ үү...' />
                      </Form.Item>
                      <Form.Item>
                        <Flex justify="space-between">
                          <Button type="primary" htmlType='submit'>Илгээх</Button>
                          <Button danger onClick={() => form.resetFields()}>Арилгах</Button>
                        </Flex>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <FooterLayout isFull />
              </Col>
            </Row>
          </Content>
        </Layout>
        <Modal
            title="Анхааруулга"
            open={isShowModal}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="ok" type="primary" onClick={handleOk}>
                Хаах
              </Button>,
            ]}
          >
          <div style={{color: isSuccess? 'green': 'red'}} dangerouslySetInnerHTML={{ __html: message }} />
        </Modal>
      </Content>
    </Layout>
  )
}

export default HomePage
