import React, { useState, useEffect, useRef } from 'react'
import {
  Layout,
  Row,
  Col,
  Typography,
  Divider,
  Flex,
  Image,
  Card,
  Carousel,
  Space,
} from 'antd'
import ReactPlayer from 'react-player'

import FooterLayout from './../../layouts/Footer'

import * as WEBSERVICES from 'config/WebServices'

const { Content } = Layout
const { Text, Paragraph } = Typography

const IntroVideo: React.FC = () => {
  const [nav1, setNav1] = useState(undefined)
  const [nav2, setNav2] = useState(undefined)
  const [guides, setGuides] = useState([] as any[])
  const [loaded, setLoaded] = useState(false)

  let sliderRef1 = useRef(null)
  let sliderRef2 = useRef(null)

  useEffect(() => {
    if (sliderRef1.current) setNav1(sliderRef1.current)
    if (sliderRef2.current) setNav2(sliderRef2.current)

    async function fetchData() {
      await fetchAllGuides()
    }

    fetchData().then(() => {
      setLoaded(true)
    })

  }, [])


  const fetchAllGuides = async () => {
    let url = WEBSERVICES.URL_ALL_GUIDE

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        setGuides(responseJson)
      })
      .catch((error) => {
        setGuides([])
      })
  }

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div>
              <Text style={{ color: '#393874' }}>ТУСЛАМЖ</Text>
            </div>
            <Text
              style={{ color: '#393874', fontWeight: 600, marginBottom: 20 }}
            >
              Газрын цахим биржийн танилцуулга видео
            </Text>
            <Card style={{ marginTop: 20, boxShadow: '0px 4px 19px #D8DEE9' }}>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Carousel
                    asNavFor={nav2}
                    ref={(slider: any) => (sliderRef1.current = slider)}
                    dots={false}
                  >
                    {guides.map((item: any, index: any) => (
                      <ReactPlayer
                        key={index}
                        width={'100%'}
                        url={"https://www.youtube.com/watch?v="+item.youtube_url}
                      />
                    ))}
                  </Carousel>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <div>
                    <Carousel
                      asNavFor={nav1}
                      ref={(slider: any) => (sliderRef2.current = slider)}
                      slidesToShow={3}
                      slidesToScroll={1}
                      swipeToSlide={true}
                      focusOnSelect={true}
                      vertical={true}
                      dots={false}
                    >
                      {guides.map((item: any, index: any) => (
                        <div key={index} style={{ height: 112 }}>
                          <Space size={20}>
                            <Image
                              src={"http://img.youtube.com/vi/"+item.youtube_url+"/0.jpg" }
                              width={209}
                              height={112}
                              alt=""
                              preview={false}
                              style={{ marginBottom: 4, borderRadius: 5 }}
                            />
                            <div>
                              <Paragraph
                                ellipsis={{ rows: 2 }}
                                style={{
                                  fontWeight: 700,
                                  color: '#3D4960',
                                  marginBottom: 0,
                                }}
                              >
                                {item.title}
                              </Paragraph>
                              <Paragraph
                                style={{
                                  color: 'rgba(46,59,82,.7)',
                                  marginBottom: 0,
                                }}
                                ellipsis={{ rows: 2 }}
                              >
                                {item.description}
                              </Paragraph>
                              <Flex justify="space-between">
                                <Text>{item.viewed}</Text>
                                <Text>{item.date}</Text>
                              </Flex>
                            </div>
                          </Space>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </Col>
              </Row>
            </Card>
            <div style={{ marginTop: 60 }}>
              <FooterLayout isFull />
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default IntroVideo
