import { Anchor, Button, Flex, Spin } from "antd";
import type { FlexProps } from 'antd';
import React, { useState } from "react";
import { ContainerOutlined, FileProtectOutlined } from '@ant-design/icons';
import * as WEBSERVICES from '../../../config/WebServices'

const CertificateIndex = (props: any) => {
  const alignOptions = ['center'];
  const [alignItems, setAlignItems] = useState<FlexProps['align']>(alignOptions[0]);
  const [loaded, setLoaded] = useState(false)
  
  const ContractCertificatePrint = async (item: any) => {
    setLoaded(true)
    
    if(parseInt(props.active) === 1){
      window.location.href = WEBSERVICES.URL_A_CONT_CERTI_PRINT + `/${props.id}/${item}`;    
    }else if(parseInt(props.active) === 2){
      window.location.href = WEBSERVICES.URL_P_CONT_CERTI_PRINT + `/${props.id}/${item}`;    
    }

    if (item === 'contract') {
      setTimeout(() => {
        setLoaded(false)
      }, 3000);
    } else if (item === 'certificate') {
      setTimeout(() => {
        setLoaded(false)
      }, 1000 * 20);
    }

  }


  return (
    <>
      <Spin spinning={loaded} tip="Уншиж байна . . .">
        <Flex align={alignItems} gap={'middle'}>          
          <Button
              type="primary"
              style={{
                  color: '#fff',
                  width: '60%',
              }}
              block
              icon={<ContainerOutlined style={{ fontSize: '16px' }} />}
              onClick={() => {
                ContractCertificatePrint('contract')
              }}
          >
              Гэрээ хэвлэх
          </Button>
          <Button type="primary" 
            style={{
              color: '#fff',
              width: '60%',
              backgroundColor: '#34c38f'
            }}
            block
            icon={<FileProtectOutlined style={{ fontSize: '16px'}}/>}
            onClick={() => {
              ContractCertificatePrint('certificate')
            }}
          >
            Гэрчилгээ хэвлэх
          </Button>
        </Flex>
      </Spin>
    </>
  );
}

export default CertificateIndex;