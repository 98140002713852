import React, { useEffect, useState } from 'react'
import {
  Layout,
  Row,
  Col,
  Form,
  Typography,
  Divider,
  Flex,
  Image,
  Button,
  Input,
  Card,
  Space,
  InputNumber,
  Upload,
  Modal,
  Spin
} from 'antd'
import {
  CheckCircleOutlined,
  CheckCircleFilled,
} from '@ant-design/icons'

import FooterLayout from './../../layouts/Footer'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import * as CONFIGS from '../../config/Configs'
import * as WEBSERVICES from '../../config/WebServices'

import SmartHelper from 'helper/SmartHelper'

const { Content } = Layout
const { Meta } = Card
const { Text, Title, Paragraph } = Typography
const {Dragger} = Upload

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

const requestFormLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
}

interface ProfileProps {
  isProfile: boolean;
}

const Logged: React.FC<ProfileProps> = ({ isProfile }) => {
  const [form] = Form.useForm()
  const [requestForm] = Form.useForm()
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [loaded, setLoaded] = useState(false)
  
  const [isCitizen, setIsCitizen] = useState(location?.state?.isCitizen)
  const [registerNumber, setRegisterNumber] = useState(location?.state?.registerNumber)
  const [isApproved, setIsApproved] = useState(false)
  const [citizen, setCitizen] = useState({} as any)
  const [legal, setLegal] = useState({} as any)
  const [auth, setAuth] = useState({} as any)

  
  const [defaultFileUrl, setDefaultFileUrl] = useState("/images/icon-upload-multiple.jpg");
  const [base64Image, setBase64Image] = useState(null as any);

  const [defaultFileRearUrl, setDefaultFileRearUrl] = useState("/images/icon-upload-multiple.jpg");
  const [base64RearImage, setBase64RearImage] = useState(null as any);

  const [defaultFileLetterUrl, setDefaultFileLetterUrl] = useState("/images/icon-upload-multiple.jpg");
  const [base64LetterImage, setBase64LetterImage] = useState(null as any);

  const [defaultFileCertUrl, setDefaultFileCertUrl] = useState("/images/icon-upload-multiple.jpg");
  const [base64CertImage, setBase64CertImage] = useState(null as any);

  const [mobilePhone, setMobilePhone] = useState("" as any)
  const [homePhone, setHomePhone] = useState("" as any)
  const [email, setEmail] = useState("" as any)

  const [isShowModal, setIsShowModal] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [message, setMessage] = useState("")

  useEffect(() => {
    async function checkLogin() {      
      let entity = SmartHelper.getEntity();
      let auth = SmartHelper.getAuth();            
      setAuth(auth);
      
      await findEntityByRegister(auth.register).then(() => {        
        setIsCitizen(auth.loginType == 1? true: false);
        setLoaded(true);
      });
    }   
    checkLogin();

  }, [])

  const findEntityByRegister = async (registerNumber: any ) => {    
    let url = WEBSERVICES.URL_ENTITY_FIND+"?register="+registerNumber;
    
    let entity: any = null;
    await fetch(url)
      .then((response) => response.json())
      .then(async (responseJson) => {
        if(responseJson?.status === true) {
          entity = responseJson?.entity;                               

          if(parseInt(entity.login_type) == 1) {
            let localCitizen = {loginType: entity.login_type, firstname: entity.firstname, lastname: entity.lastname, register: entity.register_number, image: entity.profile_photo};
            setCitizen(localCitizen);
            SmartHelper.storeSession('auth', JSON.stringify(localCitizen));
            setIsCitizen(true);            
          } else {
            let localLegal = {loginType: entity.login_type, entityname: entity.entity_name, register: entity.register_number, firstname: entity.firstname, lastname: entity.lastname, image: entity.profile_photo};
            setLegal(localLegal);
            SmartHelper.storeSession('auth', JSON.stringify(localLegal));
            setIsCitizen(false);
          }
          

          setMobilePhone(parseInt(entity.phone_number));
          setHomePhone(parseInt(entity.home_number));
          setEmail(entity.email_address);            
          localStorage.setItem('sessionToken', responseJson.token);

          requestForm.setFieldValue("mobile_phone", parseInt(entity.phone_number));
          requestForm.setFieldValue("home_phone", parseInt(entity.home_number));
          requestForm.setFieldValue("email", entity.email_address);
          
          if(entity.file_url != null && entity.file_url != "")
          {
            setDefaultFileUrl(CONFIGS.URL_ENTITY_ROOT+entity.file_url)
            const base64 = await convertImageToBase64(CONFIGS.URL_ENTITY_ROOT_IMAGE+entity.file_url);            
            setBase64Image(base64);
          }

          if(entity.file_rear_url != null && entity.file_rear_url != "")
          {
            setDefaultFileRearUrl(CONFIGS.URL_ENTITY_ROOT+entity.file_rear_url)
            const base64 = await convertImageToBase64(CONFIGS.URL_ENTITY_ROOT_IMAGE+entity.file_rear_url);            
            setBase64RearImage(base64);
          }


          if(entity.company_letter_url != null && entity.company_letter_url != "")
          {
            setDefaultFileLetterUrl(CONFIGS.URL_ENTITY_ROOT+entity.company_letter_url)
            const base64 = await convertImageToBase64(CONFIGS.URL_ENTITY_ROOT_IMAGE+entity.company_letter_url);            
            setBase64LetterImage(base64);
          }

          if(entity.company_cert_url != null && entity.company_cert_url != "")
          {
            setDefaultFileCertUrl(CONFIGS.URL_ENTITY_ROOT+entity.company_cert_url)
            const base64 = await convertImageToBase64(CONFIGS.URL_ENTITY_ROOT_IMAGE+entity.company_cert_url);            
            setBase64CertImage(base64);
          }

          setIsApproved(entity.is_active === true? true: false);

          SmartHelper.storeSession('entity', JSON.stringify(entity));
        }
      })
      .catch((error) => {
        setIsApproved(false);
      })
  }


  const handleBeforeUpload = (file: any) => {
    if (file.type.startsWith('image/')) {      
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {      
        setBase64Image(reader.result);
      };
    } else {
      console.log('error');
    }
    return false;
  }

  const handleRearBeforeUpload = (file: any) => {
    if (file.type.startsWith('image/')) {      
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {      
        setBase64RearImage(reader.result);
      };
    } else {
      console.log('error');
    }
    return false;
  }

  const handleLetterBeforeUpload = (file: any) => {
    if (file.type.startsWith('image/')) {      
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {      
        setBase64LetterImage(reader.result);
      };
    } else {
      console.log('error');
    }
    return false;
  }

  const handleCertBeforeUpload = (file: any) => {
    if (file.type.startsWith('image/')) {      
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {      
        setBase64CertImage(reader.result);
      };
    } else {
      console.log('error');
    }
    return false;
  }

  const convertImageToBase64 = async (imageUrl: any): Promise<string | null> => {
    try {
      const response = await fetch(imageUrl, {
        mode: 'cors',
      });
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string);
        };

        reader.onerror = () => {
          reject(new Error('Failed to convert image to base64'));
        };
        
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      return null;
    }
  };

  const sendRequest = async() => {
    setLoaded(false);
    requestForm.validateFields()
      .then(async () => {
        try {
          
          const response = await fetch(WEBSERVICES.URL_ENTITY_STORE, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              login_type: auth.loginType,
              register: auth.register,
              firstname: auth.firstname,
              lastname: auth.lastname,
              entityname: isCitizen? "": auth.entityname,
              directorregnum: isCitizen? "": auth.directorregnum,
              mobilephone: mobilePhone,
              homephone: homePhone,
              is_citizen: isCitizen,
              email: email,
              image: base64Image,
              image_rear: base64RearImage,
              image_letter: base64LetterImage,
              image_cert: base64CertImage
            })
          });
    
          if (!response.ok) {
            throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
          }
    
          const result = await response.json();
          if(result.status === true) {
            let msg = '<p>Амжилттай таны хүсэлтийг илгээлээ. Таны мэдээллийг шалгаж баталгаажуулсанаар та дараа дараагийн үйл ажиллагаанд орж болно. Таны хүсэлтийг баталгаажуулах үед гар утсанд мессеж ирэх болно.</p>';
            setIsShowModal(true);
            setMessage(msg);
            setIsSuccess(true);
            
          } else {
            throw new Error('Хүсэлт илгээх явцад алдаа гарлаа. Та мэдээллээ шалгаад дахин оролдоно уу!');
          }
          setLoaded(true);
        } catch (error) {
          let msg = `<p>${error}</p>`;
          setIsShowModal(true);
          setMessage(msg);
          setIsSuccess(false);
          setLoaded(true);
        }
      })
      .catch((error) => {
        let msg = "";
        error.errorFields.map((field: any, index: any) => {
          msg += `<p>${field.errors}</p>`;
        })
        setIsShowModal(true);
        setMessage(msg);
        setIsSuccess(false);
        setLoaded(true);
      });
  }

  const handleOk = () => {
    setIsShowModal(false);
  }

  const handleCancel = () => {
    setIsShowModal(false);
  }

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
      {
          (!loaded) &&
            <Spin size="large" fullscreen/>
        }
        <Row gutter={20}>
          <Title
            level={4}
            style={{
              color: '#393874',
              width: '100%',
              textAlign: 'center',
              textTransform: 'uppercase',
              marginBottom: 20,
            }}
          >
            {isProfile? ("Хувийн мэдээлэл") : ("Нэвтрэлт")}
          </Title>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Card style={{ marginBottom: 20 }}>
              <CheckCircleOutlined style={{ color: '#1AA752' }} />
              <Text
                style={{
                  marginLeft: 8,
                  color: '#171441',
                  fontWeight: 600,
                }}
              >
                Нэвтрэлтийн мэдээлэл
              </Text>
              
              {isCitizen? (
                  <>
                    <Divider style={{ marginTop: 10, borderColor: '#1AA752' }} />
                      <Meta
                        avatar={
                          <div
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 6,
                              backgroundColor: '#F5F7F9',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CheckCircleFilled
                              style={{ color: '#1AA752', fontSize: 20 }}
                            />
                          </div>
                        }
                        description={
                          <Text style={{ color: '#1AA752', fontWeight: 400 }}>
                            <strong>{citizen?.register}</strong> регистрийн дугаартай <strong>{citizen?.lastname}</strong> овогтой <strong>{citizen?.firstname}</strong> иргэнээр амжилттай нэвтэрлээ.                             
                          </Text>
                        }
                      />
                  </>
                ) : (
                  <>
                  <Divider style={{ marginTop: 10, borderColor: '#1AA752' }} />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: '#1AA752', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <Text style={{ color: '#1AA752', fontWeight: 400 }}>                          
                          <strong>{legal?.register}</strong> регистрийн дугаартай <strong>{legal?.entityname}</strong> ААН-р амжилттай нэвтэрлээ. Хэрэглэгчийн мэдээлэл <strong>{legal?.lastname}</strong> овогтой <strong>{legal?.firstname}</strong>
                        </Text>
                      }
                    />
                  </>
                )
              }
              {isApproved? (
                <>
                  <Divider style={{ marginTop: 10, borderColor: '#1AA752' }} />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: '#1AA752', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <Text style={{ color: '#1AA752', fontWeight: 400 }}>
                          Таны бүртгэл баталгаажсан байна. Таньд амжилт хүсье.
                        </Text>
                      }
                    />
                  </>
              ): (
                <>
                  <Divider style={{ marginTop: 10, borderColor: 'red' }} />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: 'red', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <Text style={{ color: 'red', fontWeight: 400 }}>
                          Таны бүртгэл баталгаажаагүй байна. Та баталгаажуулах мэдээллээ шинээр илгээх, засварлах боломжтой.
                        </Text>
                      }
                    />
                </>
              )}
              
            </Card>   
                     
            {(isApproved == false || isProfile) && (       
            <div>
            <Row gutter={20}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Бүртгэл баталгаажуулах (Та заавал өөрийн бүртгэлийг бичиг баримтаар баталгаажуулах шаардлагатай)
                  </Text>
                  <Divider style={{ marginTop: 10 }} />                                
                  <Form
                    layout={'horizontal'}
                    form={requestForm}
                    {...requestFormLayout}
                    style={{
                      maxWidth: '100%',
                    }}
                  >
                    <Form.Item
                      name="mobile_phone"
                      labelAlign="right"           
                      initialValue={mobilePhone}         
                      rules={[
                        {
                          type: 'number',
                          message: 'Зөвхөн тоон утга оруулна уу',
                        },
                        {
                          required: true,
                          message: 'Гарт утас заавал оруулна',
                        }
                      ]}
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Гар утас
                        </Text>
                      }
                    >
                      <InputNumber value={mobilePhone} min={1} max={99999999} style={{width: 350}} onChange={value => {setMobilePhone(value)}}/>
                    </Form.Item>
                    <Form.Item
                      name="home_phone"
                      labelAlign="right"
                      initialValue={homePhone}     
                      rules={[
                        {
                          type: 'number',
                          message: 'Зөвхөн тоон утга оруулна уу',
                        },
                      ]}
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          Суурин утас
                        </Text>
                      }
                    >
                      <InputNumber value={homePhone} min={1} max={99999999}  style={{width: 350}} onChange={value => {setHomePhone(value)}}/>
                    </Form.Item>               
                    <Form.Item
                      name="email"
                      labelAlign="right"
                      style={{ textAlign: 'left' }}
                      initialValue={email}
                      rules={[
                        {
                          type: 'email',
                          message: 'И-мэйл хаяг буруу байна',
                        },
                        {
                          required: true,
                          message: 'И-мэйл заавал оруулна',
                        },
                      ]}
                      label={
                        <Text
                          style={{
                            color: '#171441',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          И-мэйл хаяг
                        </Text>
                      }
                    >
                      <Input value={email} style={{width: 350}} onChange={e => {setEmail(e.target.value)}}/>
                    </Form.Item>
                    <Form.Item
                      wrapperCol={{ span: 24 }}
                      style={{ marginTop: 20 }}
                    >
                      <Space>
                        <Button
                          type="primary"
                          style={{ backgroundColor: '#1AA752', width: 185 }}
                          onClick={sendRequest}
                        >
                          Хүсэлт илгээх
                        </Button>
                        <Button
                          type="primary"
                          htmlType="reset"
                          style={{ backgroundColor: '#FF1D1D', width: 150 }}
                        >
                          Арилгах
                        </Button>
                      </Space>
                    </Form.Item>
                  </Form>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Иргэнийн үнэмлэхний мэдээлэл заавал оруулна
                  </Text>
                  <Divider style={{ marginTop: 10 }} />                                
                  <Form
                    layout={'horizontal'}
                    form={requestForm}
                    {...requestFormLayout}
                    style={{
                      maxWidth: '100%',
                    }}
                  >
                    <Row gutter={20}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="file"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          noStyle
                          wrapperCol={{ span: 24 }}
                          rules={ 
                            base64Image == null
                              ? [
                              {
                                required: true,
                                message: 'Иргэнийн үнэмлэхний урд зураг заавал хавсаргана',
                              },
                            ]: []
                          }
                          label={
                            <Text
                              style={{
                                color: '#171441',
                                fontSize: 12,
                                fontWeight: 700,
                              }}
                            >
                              Зураг
                            </Text>
                          }
                        >
                          <Dragger
                            name="picture"
                            multiple={false}
                            maxCount={1}
                            accept='.jpg, .png'
                            beforeUpload={handleBeforeUpload}
                            style={{
                              backgroundColor: 'rgba(227, 231, 239, 0.5)',
                              height: 160,
                            }}
                          >
                            {base64Image ? (
                              <img src={base64Image} alt="Uploaded" style={{ maxWidth: '100%' }} />
                            ) : (
                              <div className="ant-upload-drag-icon">
                                <Image
                                  src={defaultFileUrl}
                                  width={40}
                                  height={40}
                                  alt=""
                                  preview={false}
                                  style={{ marginBottom: 8 }}
                                />
                              </div>
                            )}
                            <p
                              className="ant-upload-text"
                              style={{
                                fontSize: 16,
                                fontWeight: 500,
                                color: '#393874',
                              }}
                            >
                              Иргэний үнэмлэхний урд талын зураг
                            </p>
                            <p className="ant-upload-hint">
                              (jpg, png формат байна)
                            </p>
                          </Dragger>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      
                        <Form.Item
                          name="file"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          noStyle
                          wrapperCol={{ span: 24 }}
                          rules={
                            base64RearImage == null
                              ? [
                              {
                                required: true,
                                message: 'Иргэнийн үнэмлэхний ар талын зураг заавал хавсаргана',
                              },
                            ] : []
                        }
                          label={
                            <Text
                              style={{
                                color: '#171441',
                                fontSize: 12,
                                fontWeight: 700,
                              }}
                            >
                              Зураг
                            </Text>
                          }
                        >
                          <Dragger
                            name="picture"
                            multiple={false}
                            maxCount={1}
                            accept='.jpg, .png'
                            beforeUpload={handleRearBeforeUpload}
                            style={{
                              backgroundColor: 'rgba(227, 231, 239, 0.5)',
                              height: 160,
                            }}
                          >
                            {base64RearImage ? (
                              <img src={base64RearImage} alt="Uploaded" style={{ maxWidth: '100%' }} />
                            ) : (
                              <div className="ant-upload-drag-icon">
                                <Image
                                  src={defaultFileRearUrl}
                                  width={40}
                                  height={40}
                                  alt=""
                                  preview={false}
                                  style={{ marginBottom: 8 }}
                                />
                              </div>
                            )}
                            <p
                              className="ant-upload-text"
                              style={{
                                fontSize: 16,
                                fontWeight: 500,
                                color: '#393874',
                              }}
                            >
                              Иргэний үнэмлэхний ар талын зураг
                            </p>
                            <p className="ant-upload-hint">
                              (jpg, png формат байна)
                            </p>
                          </Dragger>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>
                {!isCitizen && (
                  <Card style={{ marginBottom: 20 }}>
                    <Image
                      src="/images/icon-folder.png"
                      width={13}
                      height={13}
                      alt=""
                      preview={false}
                      style={{ marginBottom: 4 }}
                    />
                    <Text
                      style={{
                        marginLeft: 8,
                        color: '#171441',
                        fontWeight: 600,
                      }}
                    >
                      Байгууллагын гэрчилгээний мэдээлэл заавал оруулна
                    </Text>
                    <Divider style={{ marginTop: 10 }} />                                
                    <Form
                      layout={'horizontal'}
                      form={requestForm}
                      {...requestFormLayout}
                      style={{
                        maxWidth: '100%',
                      }}
                    >
                      <Row gutter={20}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                          <Form.Item
                            name="file"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            noStyle
                            wrapperCol={{ span: 24 }}
                            rules={
                              base64LetterImage == null
                              ? [
                                  {
                                    required: true,
                                    message: 'Гэрчилгээний урд зураг заавал хавсаргана',
                                  },
                                ]: []
                            }
                            label={
                              <Text
                                style={{
                                  color: '#171441',
                                  fontSize: 12,
                                  fontWeight: 700,
                                }}
                              >
                                Зураг
                              </Text>
                            }
                          >
                            <Dragger
                              name="picture"
                              multiple={false}
                              maxCount={1}
                              accept='.jpg, .png'
                              beforeUpload={handleLetterBeforeUpload}
                              style={{
                                backgroundColor: 'rgba(227, 231, 239, 0.5)',
                                height: 160,
                              }}
                            >
                              {base64LetterImage ? (
                                <img src={base64LetterImage} alt="Uploaded" style={{ maxWidth: '100%' }} />
                              ) : (
                                <div className="ant-upload-drag-icon">
                                  <Image
                                    src={defaultFileLetterUrl}
                                    width={40}
                                    height={40}
                                    alt=""
                                    preview={false}
                                    style={{ marginBottom: 8 }}
                                  />
                                </div>
                              )}
                              <p
                                className="ant-upload-text"
                                style={{
                                  fontSize: 16,
                                  fontWeight: 500,
                                  color: '#393874',
                                }}
                              >
                                Гэрчилгээний урд талын зураг
                              </p>
                              <p className="ant-upload-hint">
                                (jpg, png формат байна)
                              </p>
                            </Dragger>
                          </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>                        
                            <Form.Item
                              name="file"
                              valuePropName="fileList"
                              getValueFromEvent={normFile}
                              noStyle
                              wrapperCol={{ span: 24 }}
                              rules={
                                base64CertImage == null
                                ? [
                                  {
                                    required: true,
                                    message: 'Гэрчилгээний ар талын зураг заавал хавсаргана',
                                  },
                                ] : []
                              }
                              label={
                                <Text
                                  style={{
                                    color: '#171441',
                                    fontSize: 12,
                                    fontWeight: 700,
                                  }}
                                >
                                  Зураг
                                </Text>
                              }
                            >
                              <Dragger
                                name="picture"
                                multiple={false}
                                maxCount={1}
                                accept='.jpg, .png'
                                beforeUpload={handleCertBeforeUpload}
                                style={{
                                  backgroundColor: 'rgba(227, 231, 239, 0.5)',
                                  height: 160,
                                }}
                              >
                                {base64CertImage ? (
                                  <img src={base64CertImage} alt="Uploaded" style={{ maxWidth: '100%' }} />
                                ) : (
                                  <div className="ant-upload-drag-icon">
                                    <Image
                                      src={defaultFileCertUrl}
                                      width={40}
                                      height={40}
                                      alt=""
                                      preview={false}
                                      style={{ marginBottom: 8 }}
                                    />
                                  </div>
                                )}
                                <p
                                  className="ant-upload-text"
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    color: '#393874',
                                  }}
                                >
                                  Гэрчилгээний ар талын зураг
                                </p>
                                <p className="ant-upload-hint">
                                  (jpg, png формат байна)
                                </p>
                              </Dragger>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Card>
                  )}
                </Col>
              </Row>
              </div>
            )}
            
            <div className="hide-on-md-or-less" style={{ marginTop: 100 }}>
              <FooterLayout />
            </div>
          </Col>
        </Row>
        <Modal
            title="Анхааруулга"
            open={isShowModal}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="ok" type="primary" onClick={handleOk}>
                Хаах
              </Button>,
            ]}
          >
          <div style={{color: isSuccess? 'green': 'red'}} dangerouslySetInnerHTML={{ __html: message }} />
        </Modal>
      </Content>
    </Layout>
  )
}

export default Logged
