import React, { useState, useEffect } from 'react'
import {
  Layout,
  Row,
  Col,
  Card,
  Form,
  Select,
  Typography,
  Divider,
  Flex,
  Image,
  Button,
  Table,
  Space,
  Empty,
} from 'antd'
import { Line } from '@ant-design/plots'
import { PriceInfoChartData } from './../../constants/dummy.constants'
import PriceMap from './PriceMap'
import FooterLayout from './../../layouts/Footer'
import * as WEBSERVICES from 'config/WebServices'

const { Content } = Layout
const { Option } = Select
const { Text } = Typography

const columns = [
  {
    title: '',
    dataIndex: 'title',
    key: 'title',
    render: (text: string) => (
      <Text style={{ fontWeight: 600, color: 'inherit' }}>{text}</Text>
    ),
  },
  {
    title: () => (
      <Text style={{ fontSize: 11, lineHeight: '11px' }}>Бүртгэгдсэн тоо</Text>
    ),
    dataIndex: 'count',
    key: 'count',
    render: (text: string) => (
      <Text style={{ fontWeight: 700, color: 'inherit', fontSize: 16 }}>
        {text}
      </Text>
    ),
  },
  {
    title: () => (
      <Text style={{ fontSize: 12, lineHeight: '12px' }}>
        1m<sup>2</sup> дундаж үнэ
      </Text>
    ),
    dataIndex: 'price',
    key: 'price',
    render: (text: string) => (
      <Text style={{ fontWeight: 700, color: 'inherit', fontSize: 16 }}>
        {text}
      </Text>
    ),
  },
]

const PriceInfo: React.FC = () => {
  const [data, setData] = useState<any>([])
  const [loaded, setLoaded] = useState(false)
  const [links, setLinks] = useState([] as any[])
  const [landUse, setLandUse] = useState([] as any[])
  const [source, setSource] = useState([] as any[])
  const [priceType, setPriceType] = useState([] as any[])
  const [landRightType, setLandRightType] = useState([] as any[])
  const [landUsePercentage, setLandUsePercentage] = useState([] as any[])
  const [selected, setSelected] = useState<string>('');

  useEffect(() => {
    async function fetchData() {
      await fetchPriceLandUseList()
      await fetchPriceSourceList()
      await fetchPriceTypeList()
      await fetchLandRightTypeList()
      await fetchPriceLandUsePercentageList()
    }

    fetchData().then(() => {
      setLoaded(true)
    })
    setData(PriceInfoChartData)
  }, []);

  useEffect(() => {
    if (selected) {
      fetchPriceLandUseList(selected);
    }
  }, [selected]);

  const handleSelect = (selectedValue: string) => {
    setSelected(selectedValue);
  };

  const fetchPriceLandUseList = async (au1?: string) => {
    let url = WEBSERVICES.URL_PRICE_LAND_USE_LIST;
    if (au1) {
      url += `?au1=${encodeURIComponent(au1)}`;
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseJson = await response.json();
      setLandUse(responseJson);
    } catch (error) {
      console.error('Error fetching land use data:', error);
      setLandUse([]);
    }
  };

  const fetchPriceSourceList = async () => {
    let url = WEBSERVICES.URL_PRICE_SOURCE_LIST

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        setSource(responseJson)
      })
      .catch((error) => {
        setSource([])
      })
  };

  const fetchPriceTypeList = async () => {
    let url = WEBSERVICES.URL_PRICE_TYPE_LIST

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        setPriceType(responseJson)
      })
      .catch((error) => {
        setPriceType([])
      })
  };

  const fetchLandRightTypeList = async () => {
    let url = WEBSERVICES.URL_PRICE_LAND_RIGHT_TYPE

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        setLandRightType(responseJson)
      })
      .catch((error) => {
        setLandRightType([])
      })
  };

  const fetchPriceLandUsePercentageList = async () => {
    let url = WEBSERVICES.URL_PRICE_LAND_USE_PERCENTAGE_LIST

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        setLandUsePercentage(responseJson)
      })
      .catch((error) => {
        setLandUsePercentage([])
      })
  };

  const config = {
    data,
    xField: 'date',
    yField: 'value',
    colorField: 'type',
    axis: {
      y: {
        labelFormatter: (v: any) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    scale: { color: { range: ['#FF1D1D', '#FFDA00', '#4ED442'] } },
    style: {
      lineWidth: 3,
      opacity: (data: any) => {
        if (data[0].type !== 'Дундаж үнэ') return 0.5
      },
    },
  }

  const onGenderChange = (value: string) => {
    console.log(value)
  }

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20, backgroundColor: '#eef1f2' }}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Card
              style={{ boxShadow: '4px 4px 24px rgba(139, 150, 180, 0.5)', backgroundColor: '#eef1f2' }}
            >
              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={16}
                  xxl={16}
                  style={{ paddingTop: 80 }}
                >
                  <PriceMap onSelect={handleSelect} />
                </Col>


                <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} >
                  <div style={{
                    padding: '15px',
                  }}>
                    <Table
                      rowKey={(record) => `id-${record.title}`}
                      pagination={false}
                      columns={columns}
                      dataSource={landUse}
                      bordered={false}
                      size="small"
                      scroll={{ y: 280 }}
                      onRow={(record) => ({
                        style: {
                          color: record.color,   whiteSpace: 'normal',
                          wordBreak: 'break-word',
                          textOverflow: 'ellipsis',
                        },

                      })}
                      className="auction-price-quote-item-table"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '450px',
                        borderRadius: '40px',
                        padding: '15px 25px',
                        backgroundColor: '#fff',
                        border: '5px solid rgb(255, 255, 255)',
                        boxShadow: 'rgba(133, 189, 215, 0.8784313725) 0px 30px 50px -20px',
                        margin: '10px',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        textOverflow: 'ellipsis',
                      }}
                      locale={{
                        emptyText: <Empty description="Бичлэг байхгүй байна" />,
                      }}
                    />
                    <Space
                      wrap
                      size={20}
                      style={{
                        maxWidth: '100%',
                        maxHeight: '300px',
                        borderRadius: '40px',
                        padding: '15px 25px',
                        backgroundColor: '#fff',
                        border: '10px solid rgb(255, 255, 255)',
                        boxShadow: 'rgba(133, 189, 215, 0.8784313725) 0px 30px 50px -20px',
                        margin: '10px',
                        overflowY: 'auto',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        textOverflow: 'ellipsis',

                      }}
                    >
                      {landUse.map((item, index) => (
                        <Button
                          key={index}
                          style={{
                            color: '#000000',
                            backgroundColor: 'transparent',
                            borderColor: item.color,
                            width: 170,
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            padding: '10px',
                            borderRadius: '10px',
                            textAlign: 'center',
                            border: `2px solid ${item.color}`,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 'auto',
                            minHeight: 50,
                          }}
                        >
                          {item.title}
                        </Button>

                      ))}
                    </Space>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          {/* <Col span={24}>
            <Card
              style={{ boxShadow: '4px 4px 24px rgba(139, 150, 180, 0.5)' }}
            >
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={16} xxl={16}>
                  <Line {...config} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                  <Card
                    style={{
                      borderColor: '#CAC8E1',
                      boxShadow: '0px 4px 19px #D8DEE9',
                      marginBottom: 20,
                    }}
                  >
                    <Text
                      style={{
                        marginLeft: 8,
                        color: '#393874',
                        fontWeight: 600,
                        textTransform: 'uppercase',
                      }}
                    >
                      Байршил
                    </Text>
                    <Divider
                      style={{
                        marginTop: 10,
                        borderBlockStartColor: '#E7E7EE',
                      }}
                    />
                    <Form className="price-info-graph-form">
                      <Form.Item
                        name="province"
                        label={
                          <Text style={{ fontWeight: 600, color: '#393874' }}>
                            Аймаг / Нийслэл
                          </Text>
                        }
                        colon={false}
                        labelAlign="left"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Select
                          placeholder="Сонгох"
                          onChange={onGenderChange}
                          allowClear
                        >
                          <Option value="male">цахимаар</Option>
                          <Option value="female">танхимаар</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="soum"
                        label={
                          <Text style={{ fontWeight: 600, color: '#393874' }}>
                            Сум / Дүүрэг
                          </Text>
                        }
                        colon={false}
                        labelAlign="left"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Select
                          placeholder="Сонгох"
                          onChange={onGenderChange}
                          allowClear
                        >
                          <Option value="male">цахимаар</Option>
                          <Option value="female">танхимаар</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="bag"
                        label={
                          <Text style={{ fontWeight: 600, color: '#393874' }}>
                            Баг / Хороо
                          </Text>
                        }
                        colon={false}
                        labelAlign="left"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Select
                          placeholder="Сонгох"
                          onChange={onGenderChange}
                          allowClear
                        >
                          <Option value="male">цахимаар</Option>
                          <Option value="female">танхимаар</Option>
                        </Select>
                      </Form.Item>
                    </Form>
                  </Card>
                  <Card
                    style={{
                      borderColor: '#CAC8E1',
                      boxShadow: '0px 4px 19px #D8DEE9',
                      marginBottom: 20,
                    }}
                  >
                    <Form className="price-info-graph-form">
                      <Form.Item
                        name="main_category"
                        label={
                          <Text style={{ fontWeight: 600, color: '#393874' }}>
                            Дээд төрөл
                          </Text>
                        }
                        colon={false}
                        labelAlign="left"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Select
                          placeholder="Сонгох"
                          onChange={onGenderChange}
                          allowClear
                        >
                          <Option value="male">цахимаар</Option>
                          <Option value="female">танхимаар</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="sub_category"
                        label={
                          <Text style={{ fontWeight: 600, color: '#393874' }}>
                            Төрөл
                          </Text>
                        }
                        colon={false}
                        labelAlign="left"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Select
                          placeholder="Сонгох"
                          onChange={onGenderChange}
                          allowClear
                        >
                          <Option value="male">цахимаар</Option>
                          <Option value="female">танхимаар</Option>
                        </Select>
                      </Form.Item>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col> */}
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
            <Card
              style={{
                boxShadow: '4px 4px 24px rgba(139, 150, 180, 0.5)',
                height: '100%',
              }}
            >
              <Text
                style={{
                  marginLeft: 8,
                  color: '#0654DE',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  display: 'block',
                }}
              >
                Үнийн мэдээллийг эзлэх хувь
              </Text>
              <Divider
                style={{
                  marginTop: 10,
                  borderBlockStartColor: '#E7E7EE',
                }}
              />
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: 'rgba(255,137,20,.1)',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-users.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Гэр, орон сууцны хашааны газар
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#FF891D',
                  }}
                >
                  {landUsePercentage.find(item => item.description === "Гэр, орон сууцны хашааны газар")?.count ?? '0'}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: 'rgba(255,29,29,.1)',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-wallet.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Үр тарианы усалгаагүй тариалан
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#EC1B23',
                  }}
                >
                  {landUsePercentage.find(item => item.description === "Үр тарианы усалгаагүй тариалан")?.count ?? '0'}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: '#DBE2F7',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-gear.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Боловсролын байгууллага
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#255ED1',
                  }}
                >
                  {landUsePercentage.find(item => item.description === "Боловсролын байгууллага")?.count ?? '0'}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: 'rgba(78,212,66,.1)',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-flag.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Жимс, жимсгэний усалгаатай тариалан
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#27C74A',
                  }}
                >
                  {landUsePercentage.find(item => item.description === "Жимс, жимсгэний усалгаатай тариалан")?.count ?? '0'}
                </Text>
              </Flex>
              {/* <Button
                type="primary"
                style={{
                  backgroundColor: '#0654DE',
                  borderRadius: 30,
                  marginTop: 12,
                }}
                block
              >
                ДЭЛГЭРЭНГҮЙ
              </Button> */}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
            <Card
              style={{
                boxShadow: '4px 4px 24px rgba(139, 150, 180, 0.5)',
                height: '100%',
              }}
            >
              <Text
                style={{
                  marginLeft: 8,
                  color: '#27C74A',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  display: 'block',
                }}
              >
                Үнийн мэдээллийн төрөл
              </Text>
              <Divider
                style={{
                  marginTop: 10,
                  borderBlockStartColor: '#E7E7EE',
                }}
              />
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: 'rgba(255,137,20,.1)',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-user-valid.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Худалдагдсан үнэ
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#FF891D',
                  }}
                >
                  {priceType.find(item => item.name === "Худалдагдсан үнэ")?.count ?? '0'}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: 'rgba(255,29,29,.1)',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-wallet.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Үнэлэгдсэн үнэ
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#EC1B23',
                  }}
                >
                  {priceType.find(item => item.name === "Үнэлэгдсэн үнэ")?.count ?? '0'}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: '#DBE2F7',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-home.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Санал болгосон үнэ
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#255ED1',
                  }}
                >
                  {priceType.find(item => item.name === "Санал болгосон үнэ")?.count ?? '0'}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: 'rgba(78,212,66,.1)',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-bulb.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Түрээсийн үнэ
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#27C74A',
                  }}
                >
                  {priceType.find(item => item.name === "Түрээсийн үнэ")?.count ?? '0'}
                </Text>
              </Flex>
              {/* <Button
                type="primary"
                style={{
                  backgroundColor: '#27C74A',
                  borderRadius: 30,
                  marginTop: 12,
                }}
                block
              >
                ДЭЛГЭРЭНГҮЙ
              </Button> */}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
            <Card
              style={{
                boxShadow: '4px 4px 24px rgba(139, 150, 180, 0.5)',
                height: '100%',
              }}
            >
              <Text
                style={{
                  marginLeft: 8,
                  color: '#FF8B00',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  display: 'block',
                }}
              >
                Үнийн мэдээллийн эх сурвалж
              </Text>
              <Divider
                style={{
                  marginTop: 10,
                  borderBlockStartColor: '#E7E7EE',
                }}
              />
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: 'rgba(255,137,20,.1)',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-user.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Худалдагч
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#FF891D',
                  }}
                >
                  {source.find(item => item.source_name === "Худалдагч")?.count_source ?? '0'}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: 'rgba(255,29,29,.1)',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-user-plus-red.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Худалдан авагч
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#EC1B23',
                  }}
                >
                  {source.find(item => item.source_name === "Худалдан авагч")?.count_source ?? '0'}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: '#DBE2F7',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-edit.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Түрээслэгч
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#255ED1',
                  }}
                >
                  {source.find(item => item.source_name === "Түрээслэгч")?.count_source ?? '0'}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: 'rgba(78,212,66,.1)',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-comment.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Түрээслүүлэгч
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#27C74A',
                  }}
                >
                  {source.find(item => item.source_name === "Түрээслүүлэгч")?.count_source ?? '0'}
                </Text>
              </Flex>
              {/* <Button
                type="primary"
                style={{
                  backgroundColor: '#FF8B00',
                  borderRadius: 30,
                  marginTop: 12,
                }}
                block
              >
                ДЭЛГЭРЭНГҮЙ
              </Button> */}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
            <Card
              style={{
                boxShadow: '4px 4px 24px rgba(139, 150, 180, 0.5)',
                height: '100%',
              }}
            >
              <Text
                style={{
                  marginLeft: 8,
                  color: '#05C2F9',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  display: 'block',
                }}
              >
                Үнийн мэдээллийн эрхийн төрөл
              </Text>
              <Divider
                style={{
                  marginTop: 10,
                  borderBlockStartColor: '#E7E7EE',
                }}
              />
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: 'rgba(255,137,20,.1)',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-user-plus.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Өмчлөх
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#FF891D',
                  }}
                >
                  {landRightType.find(item => item.land_right_type === 3)?.count ?? '0'}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: 'rgba(255,29,29,.1)',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-user-red.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Эзэмших
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#EC1B23',
                  }}
                >
                  {landRightType.find(item => item.land_right_type === 2)?.count ?? '0'}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: '#DBE2F7',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-star.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Ашиглах
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#255ED1',
                  }}
                >
                  {landRightType.find(item => item.land_right_type === 1)?.count ?? '0'}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="space-between"
                style={{
                  backgroundColor: 'rgba(78,212,66,.1)',
                  borderRadius: 5,
                  padding: '8px 13px',
                  marginBottom: 20,
                }}
              >
                <Flex align="center" wrap="wrap" gap={'small'}>
                  <Image
                    src="/images/price-icon-comment.jpg"
                    width={40}
                    height={40}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4, borderRadius: 6 }}
                  />
                  <Text
                    style={{
                      color: '#272B3E',
                      fontWeight: 700,
                    }}
                  >
                    Төрийн өмч
                  </Text>
                </Flex>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: '#27C74A',
                  }}
                >
                  {landRightType.find(item => item.land_right_type === 4)?.count ?? '0'}
                </Text>
              </Flex>
              {/* <Button
                type="primary"
                style={{
                  backgroundColor: '#05C2F9',
                  marginTop: 10,
                  borderRadius: 30,
                }}
                block
              >
                ДЭЛГЭРЭНГҮЙ
              </Button> */}
            </Card>
          </Col>
          <FooterLayout isFull />
        </Row>
      </Content>
    </Layout>
  )
}

export default PriceInfo
