export const URL_ENTITY_ROOT = "https://admin.mle.mn/storage/entity/";
export const URL_ENTITY_ROOT_IMAGE = "https://admin.mle.mn/api/image/storage/entity/";

export const URL_PAYMENT_ROOT = "https://admin.mle.mn/storage";
export const URL_PAYMENT_ROOT_IMAGE = "https://admin.mle.mn/api/image/storage";

export const URL_AUCTION_ROOT = "https://admin.mle.mn//storage";
export const URL_PROJECT_ROOT = "https://admin.mle.mn/storage";
export const URL_NEWS_ROOT = "https://admin.mle.mn/storage/news/";
export const URL_SALES_ROOT = "https://admin.mle.mn/storage/sales/";
export const URL_SALES_ROOT_IMAGE = "https://admin.mle.mn/api/upload/images/sales";
export const URL_SALES_ROOT_REMOVE_IMAGE = "https://admin.mle.mn/api/remove/images/sales";


export const XYP_DAN_REDIRECT_URL = "https://sso.gov.mn/oauth2/authorize";
export const XYP_DAN_ACCESS_GRANT = "https://sso.gov.mn/oauth2/token";
export const XYP_CLIENT_SECRET = "YWIwZGUyZTIwYjYwYzFmY2YwYjlmN2EyNzcyZDcwOTA5MzkxNDFjOWIyMTkwMTc1NWFiNmVlOGNlZjUyNmRhODIyNzMzYzMwODQwYmNhNmQ0OTM2MDE3NjQwNWFmZGI4MDQzZWRlZTM1OTlkYjUzMDkwMDQ4ODEzNGE0ZDZjYjU=";
export const XYP_CLIENT_ID = "2a9f7b32e0f4348e27cf4551-d2621cd7edac292b4bb35e9b156d23c1";
export const XYP_REDIRECT_URL = "https://www.mle.mn/authorized";
export const XYP_SCOPE = [{"services": ["WS100101_getCitizenIDCardInfo"],"wsdl": "https://xyp.gov.mn/citizen-1.3.0/ws?WSDL"}];
export const XYP_SCOPE_LEGAL = [{"services": ["WS100307_getLegalEntityInfoWithRegnum"],"wsdl": "https://xyp.gov.mn/legal-entity-1.3.0/ws?WSDL"}];
