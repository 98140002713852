
export const URL_LOCATION_AIMAG_CITY_ALL = "https://api.mle.mn/aimag/city";
export const URL_LOCATION_SOUM_DISTRICT_BY_AIMAG_CITY = "https://api.mle.mn/soum/district/by/aimagcity";
export const URL_LOCATION_BAG_KHOROO_BY_SOUM_DISTRICT = "https://api.mle.mn/bag/khoroo/by/soumdistrict";

export const URL_PROVINCE_LINKS = "https://api.mle.mn/province/links";
export const URL_LATEST_NEWS = "https://api.mle.mn/latest/news";
export const URL_NEWS_PAGINATION = "https://api.mle.mn/news/pagination";
export const URL_NEWS_DETAIL = "https://api.mle.mn/news/find/";
export const URL_ALL_GUIDE = "https://api.mle.mn/all/guide";


export const URL_AUCTION_LATEST_LIST = "https://api.mle.mn/auction/latest/list";
export const URL_AUCTION_DETAIL = "https://api.mle.mn/auction/detail/";
export const URL_AUCTION_PAGINATION_LIST = "https://api.mle.mn/auction/pagination/list";
export const URL_AUCTION_FILTER = "https://api.mle.mn/auction/filter";
export const URL_AUCTION_PARTICIPANT = "https://api.mle.mn/auction/participant";
export const URL_AUCTION_PARTICIPANT_STORE = "https://api.mle.mn/auction/participant/store";
export const URL_AUCTION_PARTICIPANT_LIST = "https://api.mle.mn/auction/participant/list";
export const URL_AUCTION_TODAY = "https://api.mle.mn/auction/today";
export const URL_AUCTION_BIDS = "https://api.mle.mn/auction/bids";
export const URL_AUCTION_BID_STORE = "https://api.mle.mn/auction/bidding/store/";
export const URL_AUCTION_UPDATE_TIME = "https://api.mle.mn/auction/bidding/time/";

export const URL_AUCTION_STATISTIC = "https://api.mle.mn/auction/statistic";


export const URL_CONFIG_FOOTER_DATA = "https://api.mle.mn/config/footer/data";
export const URL_CONFIG_AUCTION_CAUTION = "https://api.mle.mn/config/auction/caution";
export const URL_CONFIG_AUCTION_TERMS = "https://api.mle.mn/config/auction/terms";

export const URL_MAP_DATA_FIND = "https://api.mle.mn/map/data/find";

export const URL_ENTITY_LOGIN_DAN = "https://api.mle.mn/auth/dan/check";
export const URL_ENTITY_FIND = "https://api.mle.mn/entity/find";
export const URL_ENTITY_STORE = "https://api.mle.mn/entity/store";

export const URL_PROJECT_LATEST_LIST = "https://api.mle.mn/project/latest/list";
export const URL_PROJECT_DETAIL = "https://api.mle.mn/project/detail/";
export const URL_PROJECT_PAGINATION_LIST = "https://api.mle.mn/project/pagination/list";
export const URL_PROJECT_FILTER = "https://api.mle.mn/project/filter";
export const URL_PROJECT_PARTICIPANT = "https://api.mle.mn/project/participant";
export const URL_PROJECT_PARTICIPANT_STORE = "https://api.mle.mn/project/participant/store";
export const URL_PROJECT_DOCUMENT_STORE = "https://api.mle.mn/project/document/store";
export const URL_PROJECT_PARTICIPANT_DOCUMENT_FIND = "https://api.mle.mn/project/participant/document/find";
export const URL_PROJECT_PARTICIPANT_DOCUMENT_DELETE = "https://api.mle.mn/project/participant/document/delete";

export const URL_PRICE_LAND_USE_LIST= "https://api.mle.mn/price/land/use/list";
export const URL_PRICE_LAND_RIGHT_TYPE= "https://api.mle.mn/price/land/right/type";
export const URL_PRICE_SOURCE_LIST= "https://api.mle.mn/price/source/list";
export const URL_PRICE_TYPE_LIST= "https://api.mle.mn/price/type/list";
export const URL_PRICE_LAND_USE_PERCENTAGE_LIST= "https://api.mle.mn/price/land/use/percentage/list";

export const URL_FEE_ZONE = "https://geoserver.egazar.gov.mn/geoserver/wfs?service=WFS&version=2.0.0&request=GetFeature&typeName=geoware:mle_fee_zone&outputFormat=application/json&viewparams=";

export const URL_FEE_ZONE_INFO = "https://api.mle.mn/fee/zone/payment/info/";
export const URL_A_P_WINNER_DETAIL = "https://api.mle.mn/winner/detail/information?";
export const URL_P_CONT_CERTI_PRINT = "https://api.mle.mn/project/contract/certificate/print";
export const URL_A_CONT_CERTI_PRINT = "https://api.mle.mn/auction/contract/certificate/print";
export const URL_WINNER_CONTRACT_CONFIRM = "https://api.mle.mn/winner/contract/confirm?";
export const URL_A_P_LAND_STATUS = "https://api.mle.mn/land/status/";
export const URL_AUCTION_NOTE_SHOW = "https://api.mle.mn/auction/notes/show/";
export const URL_PROJECT_NOTE_SHOW = "https://api.mle.mn/project/notes/show/";
export const URL_AUCTION_WINNER_PAY_ATTACH = "https://api.mle.mn/auction/winner/pay/attach";
export const URL_PROJECT_WINNER_PAY_ATTACH = "https://api.mle.mn/project/winner/pay/attach";

export const URL_SALES_LATEST_LIST = "https://api.mle.mn/sales/latest/list";
export const URL_SALES_DETAIL = "https://api.mle.mn/sales/detail/";
export const URL_SALES_FILTER = "https://api.mle.mn/sales/filter";
export const URL_SALES_PAGINATION_LIST = "https://api.mle.mn/sales/pagination/list";
export const URL_USER_LAND_LIST = "https://api.mle.mn/user/land/list";
export const URL_USER_LAND_INFO_LIST = "https://api.mle.mn/sales/parcel/by/id";
export const URL_SALES_FAVORITE_INFO = "https://api.mle.mn/sales/favorite/info";
export const URL_SALES_STORE = "https://api.mle.mn/sales/store";
export const URL_SALES_DELETE = "https://api.mle.mn/sales/delete";
export const URL_SALES_FAVORITE_LIST = "https://api.mle.mn/sales/favorite/list";
export const URL_SALES_FAVORITE_STORE = "https://api.mle.mn/sales/favorite/store";
export const URL_SALES_FAVORITE_DELETE = "https://api.mle.mn/sales/favorite/delete";

export const URL_FEEDBACK_STORE = "https://api.mle.mn/feedback/store";

export const URL_URBAN_PUBLIC_LAYERS = "https://admin.urban.gov.mn/api/layer/type/children/public?is_external=0";