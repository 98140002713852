import React, { useState } from 'react';

interface PriceMapProps {
  onSelect: (selected: string) => void;
}

const PriceMap: React.FC<PriceMapProps> = ({ onSelect }) => {
  const [selected, setSelected] = useState('');

  const select = (e: any) => {
    const titleElement = e.target.closest('g').querySelector('.map-title');
    
    if (titleElement) {
      const province = titleElement.innerHTML;
      const code = titleElement.getAttribute("data-code");

      // console.log(`Province: ${province}, Code: ${code}`); // debug

      setSelected(province);
      onSelect(code);
    }
  };
  
  return (
    <div className="map-card">
      <div className="map-svg mongolia">
      <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 1200 600"
          >
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Төв' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 1070.76 342.216 L 1082.99 359.503 L 1089.87 387.107 L 1084.06 400.692 L 1073.61 408.533 L 1064.47 409.403 L 1058.9 418.608 L 1059.96 425.651 L 1065.3 430.145 L 1066.02 432.505 L 1077.05 454.165 L 1082.04 458.843 L 1077.77 467.039 L 1069.34 471.128 L 1055.69 469.709 L 1046.2 472.376 L 1044.66 481.948 L 1027.21 491.038 L 1018.31 491.601 L 1007.27 483.248 L 983.774 482.4 L 970.363 473.908 L 965.141 475.155 L 955.41 489.684 L 939.626 481.552 L 927.995 492.841 L 918.976 484.887 L 918.026 476.062 L 912.567 467.493 L 916.721 464.594 L 916.958 459.584 L 910.55 451.424 L 910.312 440.145 L 906.515 434.057 L 923.367 423.921 L 932.03 423.46 L 929.894 407.953 L 918.501 405.515 L 914.585 396.967 L 923.011 384.125 L 923.367 370.166 L 943.067 376.802 L 959.089 369.166 L 957.19 362.865 L 959.682 360.978 L 971.668 356.787 L 978.196 359.621 L 978.314 367.754 L 984.011 366.753 L 998.846 375.511 L 1018.78 370.636 L 1024.01 373.985 L 1038.48 369.401 L 1041.57 358.5 L 1034.21 350.577 L 1039.55 346.074 L 1048.1 345.065 L 1054.74 348.741 L 1062.58 343.166 Z M 1009.41 395.219 L 990.894 390.67 L 996.947 418.897 L 1033.5 424.036 L 1035.16 408.939 L 1026.85 405.515 L 1018.31 392.829 Z"
              />
              <text
                transform="matrix(1 0 0 1 665 285)"
                className="map-title"
                data-code="041"
              >
                Төв
              </text>
              <circle cx="675" cy="295" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 665 285)"
                className="map-title-active"
              >
                Төв
              </text>
              <image
                x="625"
                y="245"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Өмнөговь' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 1040.74 591.121 L 1039.31 601.023 L 1050.59 632.419 L 1053.44 662.486 L 1053.32 696.478 L 1006.08 704.104 L 948.17 731.043 L 941.406 738.326 L 922.536 735.363 L 922.417 724.734 L 892.511 730.261 L 869.725 718.099 L 831.392 709.143 L 820.355 695.372 L 812.641 691.524 L 756.981 684.554 L 742.384 688.516 L 738.586 658.17 L 737.399 611.597 L 749.504 608.727 L 768.374 626.877 L 800.18 626.608 L 834.715 609.864 L 866.995 609.539 L 864.503 599.339 L 875.54 575.932 L 890.493 565.013 L 895.478 555.873 L 907.701 556.094 L 908.057 569.572 L 911.38 574.563 L 958.377 581.35 L 968.939 601.729 L 977.602 607.047 L 995.167 604.443 L 1024.95 591.938 Z"
              />
              <text
                transform="matrix(1 0 0 1 575 500)"
                className="map-title"
                data-code="046"
              >
                Өмнөговь
              </text>
              <circle cx="600" cy="510" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 575 500)"
                className="map-title-active"
              >
                Өмнөговь
              </text>
              <image
                x="550"
                y="460"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Архангай' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 817.388 349.215 L 829.256 361.745 L 834.952 359.857 L 838.631 377.447 L 843.734 386.522 L 855.246 392.77 L 859.993 400.576 L 861.654 410.157 L 867.588 413.517 L 870.318 420.111 L 874.709 421.439 L 891.443 441.12 L 892.036 445.933 L 888.12 448.394 L 874.116 448.336 L 868.182 440.489 L 854.415 436.011 L 857.145 443.928 L 850.143 454.108 L 848.956 460.951 L 840.055 456.562 L 822.847 464.821 L 803.028 469.311 L 799.824 466.641 L 782.615 468.062 L 774.427 459.413 L 771.222 449.48 L 751.641 453.766 L 750.098 437.562 L 744.283 430.721 L 743.57 423.402 L 729.685 432.505 L 719.954 433.482 L 711.765 428.072 L 708.798 419.822 L 697.049 424.094 L 688.979 421.555 L 690.759 412.822 L 688.504 404.702 L 690.64 400.343 L 703.22 398.364 L 708.323 392.304 L 723.039 386.172 L 728.261 390.495 L 732.533 387.048 L 744.639 387.457 L 748.436 378.209 L 762.203 382.662 L 763.864 377.564 L 773.24 373.926 L 769.205 369.695 L 769.561 360.683 L 777.393 352.294 L 771.816 345.54 L 778.106 344.828 L 782.734 337.105 L 805.876 350.577 Z"
              />
              <text
                transform="matrix(1 0 0 1 470 255)"
                className="map-title"
                data-code="065"
              >
                Архангай
              </text>
              <circle cx="490" cy="265" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 470 255)"
                className="map-title-active"
              >
                Архангай
              </text>
              <image
                x="440"
                y="215"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Баян-Өлгий' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 410.918 325.061 L 412.698 330.97 L 420.649 332.401 L 427.532 349.926 L 435.84 357.614 L 437.145 365.988 L 427.889 365.929 L 412.816 372.575 L 418.632 380.964 L 416.614 387.691 L 418.276 397.782 L 423.023 403.134 L 431.211 423.806 L 433.229 438.366 L 441.655 449.881 L 439.756 460.837 L 457.202 476.345 L 456.015 479.516 L 443.673 478.61 L 439.756 483.192 L 434.297 477.874 L 424.922 476.232 L 420.056 462.488 L 413.766 458.672 L 404.746 442.725 L 405.458 438.94 L 400.118 423.633 L 390.505 418.319 L 389.081 411.49 L 378.281 414.56 L 370.567 403.192 L 352.291 405.283 L 347.544 399.005 L 335.676 393.937 L 332.234 383.13 L 313.009 374.278 L 311.585 371.635 L 315.857 365.576 L 303.989 356.551 L 308.974 350.992 L 306.838 339.84 L 318.943 333.95 L 317.875 329.061 L 320.129 324.105 L 336.388 321.354 L 345.764 324.583 L 346.476 318.6 L 349.68 323.447 L 356.445 320.815 L 358.581 313.325 L 364.277 316.323 L 376.501 308.4 L 373.297 301.479 L 375.196 296.593 L 390.268 293.875 L 396.914 311.224 L 399.169 317.402 Z"
              />
              <text
                transform="matrix(1 0 0 1 35 190)"
                className="map-title"
                data-code="083"
              >
                Баян-Өлгий
              </text>
              <circle cx="70" cy="195" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 35 190)"
                className="map-title-active"
              >
                Баян-Өлгий
              </text>
              <image
                x="20"
                y="145"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Булган' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 916.839 276.721 L 926.69 291.881 L 923.011 293.392 L 923.248 299.55 L 930.962 310.383 L 928.707 312.665 L 923.129 343.285 L 907.701 342.395 L 903.904 349.985 L 915.059 353.181 L 923.96 360.978 L 923.604 381.666 L 914.585 396.967 L 918.501 405.515 L 929.894 407.953 L 932.03 423.46 L 923.367 423.921 L 909.719 430.951 L 906.515 434.057 L 910.312 440.145 L 909.363 443.355 L 892.036 445.933 L 891.443 441.12 L 874.709 421.439 L 861.654 410.157 L 859.993 400.576 L 855.246 392.77 L 843.734 386.522 L 838.631 377.447 L 834.952 359.857 L 829.256 361.745 L 817.863 351.288 L 816.676 342.81 L 819.999 336.034 L 829.374 328.166 L 842.903 323.626 L 849.312 313.385 L 859.044 310.083 L 835.189 297.861 L 835.071 292.122 L 848.6 271.974 L 852.991 269.415 L 864.384 274.957 L 874.472 275.322 L 877.201 281.035 L 891.68 284.857 L 895.952 281.703 L 908.413 283.887 Z"
              />
              <text
                transform="matrix(1 0 0 1 555 200)"
                className="map-title"
                data-code="063"
              >
                Булган
              </text>
              <circle cx="575" cy="210" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 555 200)"
                className="map-title-active"
              >
                Булган
              </text>
              <image
                x="525"
                y="160"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Дорнод' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 1368.05 497.231 L 1355.59 479.912 L 1316.07 482.683 L 1306.46 486.863 L 1298.74 474.078 L 1289.61 468.232 L 1290.55 451.595 L 1288.89 448.794 L 1265.63 431.699 L 1234.06 422.998 L 1224.45 426.92 L 1224.45 415.312 L 1218.87 400.634 L 1218.99 378.737 L 1206.89 352.057 L 1206.65 328.882 L 1203.68 324.344 L 1216.5 319.019 L 1226.11 321.354 L 1237.51 315.904 L 1242.73 303.708 L 1257.33 291.276 L 1280.23 277.633 L 1288.54 278.484 L 1300.64 278.788 L 1324.02 298.645 L 1336.13 299.429 L 1354.64 291.578 L 1367.81 296.895 L 1371.85 302.503 L 1347.99 356.492 L 1347.64 362.276 L 1339.21 375.511 L 1339.21 391.487 L 1328.65 397.607 L 1330.91 409.867 L 1341.23 421.093 L 1343.48 422.248 L 1355.59 412.417 L 1376.95 411.722 L 1396.77 424.036 L 1411.84 405.283 L 1437.83 405.457 L 1448.75 418.319 L 1460.38 423.46 L 1462.28 430.836 L 1468.69 433.597 L 1467.74 436.93 L 1483.17 450.852 L 1485.66 459.869 L 1490.41 464.992 L 1490.64 475.212 L 1482.34 481.496 L 1459.55 477.761 L 1451.6 472.149 L 1446.97 476.911 L 1430.36 474.418 L 1412.91 485.339 L 1408.28 485.565 L 1404.48 480.874 L 1398.08 482.57 L 1395.94 494.024 L 1376.71 492.503 Z"
              />
              <text
                transform="matrix(1 0 0 1 970 230)"
                className="map-title"
                data-code="021"
              >
                Дорнод
              </text>
              <circle cx="990" cy="240" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 970 230)"
                className="map-title-active"
              >
                Дорнод
              </text>
              <image
                x="940"
                y="190"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Сүхбаатар' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 1224.45 426.92 L 1234.06 422.998 L 1265.63 431.699 L 1288.89 448.794 L 1290.55 451.595 L 1289.61 468.232 L 1298.74 474.078 L 1306.46 486.863 L 1316.07 482.683 L 1355.59 479.912 L 1368.05 497.231 L 1354.52 517.97 L 1355.95 523.494 L 1351.91 529.286 L 1346.69 530.288 L 1333.28 542.443 L 1292.57 545.544 L 1288.3 555.211 L 1276.08 567.705 L 1259.34 578.943 L 1226.71 572.754 L 1214.25 562.263 L 1198.46 561.162 L 1197.99 550.851 L 1190.03 539.118 L 1190.63 523.438 L 1185.05 507.955 L 1170.69 500.829 L 1173.89 492.277 L 1172 478.893 L 1179.47 463.797 L 1182.08 448.394 L 1187.66 440.489 L 1192.65 437.562 L 1202.38 438.767 L 1202.14 426.631 L 1206.89 421.266 L 1224.45 415.312 L 1224.81 420.342 Z"
              />
              <text
                transform="matrix(1 0 0 1 925 340)"
                className="map-title"
                data-code="022"
              >
                Сүхбаатар
              </text>
              <circle cx="950" cy="350" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 925 340)"
                className="map-title-active"
              >
                Сүхбаатар
              </text>
              <image
                x="900"
                y="300"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Хэнтий' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 1206.89 352.057 L 1218.99 378.737 L 1218.87 400.634 L 1224.45 415.312 L 1203.92 423.46 L 1201.67 428.59 L 1202.38 438.767 L 1192.05 437.734 L 1183.98 444.845 L 1179.47 463.797 L 1172 478.893 L 1173.89 492.277 L 1170.69 500.829 L 1161.2 509.132 L 1156.57 508.179 L 1145.65 500.324 L 1130.93 479.289 L 1126.9 479.968 L 1127.85 487.541 L 1124.88 496.556 L 1115.74 503.076 L 1102.81 503.581 L 1089.63 490.136 L 1088.45 484.096 L 1092.48 474.531 L 1092.13 467.323 L 1088.45 468.857 L 1086.55 460.894 L 1081.68 458.33 L 1077.05 454.165 L 1066.14 431.814 L 1060.08 426.343 L 1058.66 418.608 L 1061.86 413.344 L 1064.36 409.925 L 1068.51 409.287 L 1084.06 400.692 L 1089.87 387.107 L 1082.99 359.503 L 1070.76 342.216 L 1072.54 330.851 L 1099.01 330.076 L 1132 340.078 L 1139 335.557 L 1150.63 341.622 L 1174.61 328.644 L 1203.68 324.344 L 1206.53 329.479 Z"
              />
              <text
                transform="matrix(1 0 0 1 825 250)"
                className="map-title"
                data-code="023"
              >
                Хэнтий
              </text>
              <circle cx="845" cy="260" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 825 250)"
                className="map-title-active"
              >
                Хэнтий
              </text>
              <image
                x="795"
                y="210"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Дорноговь' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 1102.81 503.581 L 1115.74 503.076 L 1124.88 496.556 L 1127.85 487.541 L 1126.9 479.968 L 1128.8 479.12 L 1132.83 480.817 L 1145.65 500.324 L 1156.57 508.179 L 1161.2 509.132 L 1170.69 500.829 L 1185.05 507.955 L 1190.63 523.438 L 1190.03 539.118 L 1197.99 550.851 L 1198.46 561.162 L 1189.44 567.485 L 1176.74 599.61 L 1185.53 614.086 L 1193.12 620.569 L 1197.04 631.559 L 1189.92 634.677 L 1183.15 642.831 L 1161.2 652.354 L 1140.31 679.952 L 1127.49 686.509 L 1098.77 697.004 L 1053.32 696.478 L 1053.44 662.486 L 1050.59 632.419 L 1039.2 598.523 L 1046.08 575.823 L 1062.81 573.466 L 1074.44 562.868 L 1070.17 532.289 L 1073.38 516.685 L 1067.32 517.523 L 1062.46 514.449 L 1057.23 508.404 L 1056.4 500.717 L 1059.49 494.024 L 1054.27 482.514 L 1058.78 478.78 L 1052.25 476.062 L 1049.88 471.696 L 1057 469.482 L 1069.22 471.355 L 1077.77 467.039 L 1082.04 458.843 L 1086.55 460.894 L 1088.21 468.857 L 1090.94 466.357 L 1092.72 470.277 L 1088.92 486.976 L 1097.35 499.087 Z"
              />
              <text
                transform="matrix(1 0 0 1 795 420)"
                className="map-title"
                data-code="044"
              >
                Дорноговь
              </text>
              <circle cx="820" cy="430" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 795 420)"
                className="map-title-active"
              >
                Дорноговь
              </text>
              <image
                x="770"
                y="380"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Говьсүмбэр' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 1073.26 516.909 L 1063.05 515.903 L 1057.23 509.188 L 1055.34 500.267 L 1059.02 494.024 L 1056.64 487.089 L 1055.34 485.282 L 1053.91 483.474 L 1058.42 479.006 L 1053.2 475.552 L 1050.11 471.923 L 1057.47 469.709 L 1069.34 471.128 L 1079.55 466.016 L 1082.16 459.356 L 1086.43 461.407 L 1088.21 468.062 L 1092.13 469.084 L 1087.26 491.376 L 1076.1 502.907 Z"
              />
              <text
                transform="matrix(1 0 0 1 725 310)"
                className="map-title"
                data-code="042"
              >
                Говьсүмбэр
              </text>
              <circle cx="770" cy="340" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 725 310)"
                className="map-title-active"
              >
                Говьсүмбэр
              </text>
              <image
                x="720"
                y="290"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Дундговь' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 1040.74 591.121 L 1024.95 591.938 L 995.167 604.443 L 977.602 607.047 L 968.939 601.729 L 958.377 581.35 L 911.38 574.563 L 908.057 569.572 L 908.413 557.196 L 895.478 555.873 L 897.02 548.309 L 889.544 535.011 L 894.528 528.452 L 906.159 526.67 L 910.668 506.554 L 919.45 504.535 L 924.316 492.784 L 927.995 492.841 L 939.626 481.552 L 955.41 489.684 L 965.141 475.155 L 970.363 473.908 L 983.774 482.4 L 1007.27 483.248 L 1018.31 491.601 L 1027.21 491.038 L 1043.94 482.627 L 1046.2 472.376 L 1049.88 471.696 L 1052.25 476.062 L 1058.66 478.78 L 1053.91 483.248 L 1060.08 495.825 L 1056.4 500.267 L 1057 508.572 L 1062.1 514.058 L 1068.39 516.685 L 1073.26 516.909 L 1070.17 532.289 L 1074.44 563.968 L 1062.81 573.466 L 1046.08 575.823 L 1043.82 587.194 Z"
              />
              <text
                transform="matrix(1 0 0 1 660 390)"
                className="map-title"
                data-code="048"
              >
                Дундговь
              </text>
              <circle cx="685" cy="400" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 660 390)"
                className="map-title-active"
              >
                Дундговь
              </text>
              <image
                x="635"
                y="350"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Завхан' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 651.239 308.761 L 641.508 318.301 L 641.983 327.987 L 651.477 336.45 L 665.362 337.818 L 679.485 347.497 L 689.454 345.837 L 703.22 349.985 L 709.985 341.622 L 722.446 341.86 L 725.175 358.086 L 720.072 370.871 L 723.039 386.172 L 708.323 392.304 L 703.22 398.364 L 689.454 401.855 L 690.759 412.822 L 688.979 421.555 L 697.049 424.094 L 696.693 428.014 L 688.267 446.563 L 677.348 450.052 L 669.278 480.76 L 660.259 477.138 L 654.562 481.722 L 645.187 480.534 L 639.609 465.276 L 633.913 462.716 L 622.994 465.618 L 615.399 445.532 L 589.29 428.302 L 575.998 428.936 L 570.776 422.709 L 553.805 422.074 L 540.395 417.105 L 516.185 425.132 L 507.996 412.185 L 505.148 399.994 L 515.354 390.203 L 517.134 376.978 L 524.492 368.401 L 539.801 360.27 L 564.249 366.753 L 591.189 357.142 L 597.36 328.763 L 588.934 314.945 L 588.815 299.429 L 602.463 291.215 L 605.905 295.567 L 610.889 292.485 L 621.57 298.766 L 627.385 296.835 L 630.352 300.575 L 634.743 297.318 L 644.594 298.886 Z"
              />
              <text
                transform="matrix(1 0 0 1 300 220)"
                className="map-title"
                data-code="081"
              >
                Завхан
              </text>
              <circle cx="320" cy="230" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 300 220)"
                className="map-title-active"
              >
                Завхан
              </text>
              <image
                x="270"
                y="180"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Өвөрхангай' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 892.036 445.933 L 909.363 443.355 L 910.55 451.424 L 916.958 459.584 L 916.721 464.594 L 912.567 467.493 L 918.026 476.062 L 918.976 484.887 L 926.215 490.699 L 923.485 498.637 L 919.45 504.535 L 910.668 506.554 L 906.159 526.67 L 895.359 528.006 L 890.137 533.123 L 890.374 539.728 L 897.02 548.309 L 895.24 556.7 L 890.493 565.013 L 875.54 575.932 L 864.503 599.339 L 866.995 609.539 L 834.715 609.864 L 821.067 614.573 L 811.573 581.295 L 814.184 573.466 L 800.773 565.177 L 798.518 557.802 L 797.331 544.16 L 803.147 534.845 L 804.215 512.715 L 811.691 505.04 L 809.911 495.544 L 813.946 488.951 L 812.403 481.27 L 803.028 469.311 L 822.847 464.821 L 840.055 456.562 L 848.956 460.951 L 850.143 454.108 L 857.145 443.928 L 854.415 436.011 L 868.182 440.489 L 874.116 448.336 L 886.458 448.851 Z"
              />
              <text
                transform="matrix(1 0 0 1 530 360)"
                className="map-title"
                data-code="062"
              >
                Өвөрхангай
              </text>
              <circle cx="560" cy="370" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 530 360)"
                className="map-title-active"
              >
                Өвөрхангай
              </text>

              <image
                x="510"
                y="320"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Сэлэнгэ' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 1072.54 330.851 L 1070.76 342.216 L 1062.58 343.166 L 1054.74 348.741 L 1048.1 345.065 L 1039.55 346.074 L 1034.21 350.577 L 1041.57 358.5 L 1038.48 369.401 L 1024.01 373.985 L 1018.78 370.636 L 998.846 375.511 L 984.011 366.753 L 978.314 367.754 L 978.196 359.621 L 971.668 356.787 L 959.682 360.978 L 957.19 362.865 L 959.089 369.166 L 943.067 376.802 L 923.367 370.166 L 923.96 360.978 L 917.908 354.896 L 925.028 348.563 L 923.129 343.285 L 928.707 312.665 L 930.962 310.383 L 923.248 299.55 L 923.011 293.392 L 926.69 291.881 L 916.839 276.721 L 952.917 264.9 L 977.128 269.354 L 985.91 275.566 L 1001.93 273.557 L 1013.68 281.096 L 1021.63 292.062 L 1049.52 296.231 L 1048.81 311.284 Z M 990.894 338.71 L 999.914 344.294 L 1007.15 341.801 L 1006.92 321.533 L 996.353 314.945 L 991.25 319.558 L 979.145 314.285 L 982.943 305.694 L 982.705 302.503 L 979.264 303.768 L 971.55 313.265 L 973.923 322.73 L 970.6 341.801 L 978.314 343.819 Z"
              />
              <text
                transform="matrix(1 0 0 1 630 150)"
                className="map-title"
                data-code="043"
              >
                Сэлэнгэ
              </text>
              <circle cx="660" cy="160" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 630 150)"
                className="map-title-active"
              >
                Сэлэнгэ
              </text>
              <image
                x="610"
                y="110"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Увс' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 588.934 314.945 L 597.36 328.763 L 593.681 339.959 L 592.494 354.541 L 575.405 364.692 L 564.249 366.753 L 539.801 360.27 L 524.492 368.401 L 514.998 382.896 L 500.875 368.107 L 489.601 373.867 L 468.951 371.341 L 464.322 362.689 L 460.525 362.571 L 456.015 350.696 L 448.064 353.595 L 437.145 365.988 L 435.246 356.314 L 427.532 349.926 L 420.649 332.401 L 412.698 330.97 L 410.918 325.061 L 398.575 316.623 L 387.657 289.461 L 404.984 280.063 L 412.698 279.942 L 414.241 275.566 L 424.803 267.89 L 439.638 265.815 L 451.98 252.843 L 467.052 252.536 L 473.579 242.395 L 483.786 252.659 L 495.179 246.518 L 498.502 256.398 L 501.943 257.806 L 543.718 259.765 L 548.583 280.731 L 557.247 291.276 L 568.996 289.763 L 574.693 295.506 L 588.34 297.318 Z"
              />
              <text
                transform="matrix(1 0 0 1 165 145)"
                className="map-title"
                data-code="085"
              >
                Увс
              </text>
              <circle cx="180" cy="155" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 165 145)"
                className="map-title-active"
              >
                Увс
              </text>
              <image
                x="130"
                y="105"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Ховд' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 464.322 362.689 L 468.951 371.341 L 489.601 373.867 L 500.875 368.107 L 516.541 384.242 L 514.167 391.954 L 505.148 399.994 L 507.996 412.185 L 518.083 432.044 L 536.241 446.334 L 538.14 455.307 L 544.786 466.811 L 543.48 471.242 L 526.984 481.835 L 517.846 482.57 L 521.406 494.474 L 513.099 512.491 L 513.218 517.132 L 502.418 528.841 L 500.994 565.452 L 469.307 565.177 L 462.78 561.822 L 444.029 561.987 L 440.468 558.078 L 433.466 558.959 L 426.464 554.329 L 419.7 555.983 L 415.19 543.662 L 411.511 539.894 L 413.647 526.224 L 424.447 511.932 L 420.531 497.006 L 425.99 483.644 L 424.922 476.232 L 434.297 477.874 L 439.756 483.192 L 442.961 478.78 L 455.303 479.799 L 457.202 476.345 L 439.756 460.837 L 441.655 449.881 L 433.229 438.366 L 431.211 423.806 L 423.023 403.134 L 418.513 398.714 L 416.614 387.691 L 418.632 380.964 L 412.935 371.929 L 438.807 365.281 L 453.285 350.636 L 457.439 351.879 L 461.237 362.983 Z"
              />
              <text
                transform="matrix(1 0 0 1 165 280)"
                className="map-title"
                data-code="084"
              >
                Ховд
              </text>
              <circle cx="185" cy="290" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 165 280)"
                className="map-title-active"
              >
                Ховд
              </text>
              <image
                x="135"
                y="240"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Хөвсгөл' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 852.991 269.415 L 845.514 275.14 L 834.121 296.412 L 859.044 310.083 L 849.312 313.385 L 842.903 323.626 L 829.374 328.166 L 819.999 336.034 L 816.676 342.81 L 817.388 349.215 L 805.876 350.577 L 782.734 337.105 L 778.106 344.828 L 771.816 345.54 L 777.393 352.294 L 769.561 360.683 L 769.205 369.695 L 773.24 373.926 L 763.864 377.564 L 762.203 382.662 L 747.605 378.444 L 744.639 387.457 L 732.533 387.048 L 728.261 390.495 L 723.039 386.172 L 720.191 372.517 L 725.175 357.319 L 721.496 340.434 L 709.985 341.622 L 703.22 349.985 L 689.454 345.837 L 679.485 347.497 L 665.362 337.818 L 651.477 336.45 L 641.033 326.674 L 641.508 318.301 L 651.239 308.761 L 648.866 303.527 L 656.817 308.1 L 665.718 302.563 L 666.549 297.197 L 676.992 296.412 L 686.487 288.734 L 692.302 276.052 L 693.37 262.456 L 683.994 256.398 L 680.553 247.563 L 681.977 243.38 L 675.45 234.317 L 679.959 221.752 L 679.722 215.665 L 684.469 206.883 L 690.759 206.384 L 694.794 191.932 L 706.662 187.223 L 714.138 168.748 L 716.749 167.355 L 731.465 179.042 L 745.588 182.254 L 753.302 190.112 L 775.257 191.43 L 806.113 207.881 L 819.999 207.881 L 836.97 215.354 L 835.545 228.692 L 838.631 235.614 L 838.156 246.149 L 842.31 250.818 L 840.53 258.296 Z"
              />
              <text
                transform="matrix(1 0 0 1 425 115)"
                className="map-title"
                data-code="067"
              >
                Хөвсгөл
              </text>
              <circle cx="450" cy="125" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 425 115)"
                className="map-title-active"
              >
                Хөвсгөл
              </text>
              <image
                x="400"
                y="75"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Улаанбаатар' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 1009.41 395.219 L 1018.31 392.829 L 1026.85 405.515 L 1035.16 408.939 L 1033.5 424.036 L 996.947 418.897 L 990.894 390.67 Z"
              />
              <text
                transform="matrix(1 0 0 1 675 240)"
                className="map-title"
                data-code="011"
              >
                Улаанбаатар
              </text>
              <circle cx="710" cy="260" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 675 240)"
                className="map-title-active"
              >
                Улаанбаатар
              </text>
              <image
                x="680"
                y="225"
                href={'/images/home-map-hover.png'}
                width={60}
                height={60}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Дархан-Уул' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 996.353 314.945 L 1005.97 319.798 L 1007.15 341.801 L 999.914 344.294 L 990.894 338.71 L 980.095 343.76 L 970.6 341.801 L 973.923 322.73 L 971.55 313.265 L 974.042 309.662 L 982.705 302.503 L 979.145 314.285 L 990.063 319.379 Z"
              />
              <text
                transform="matrix(1 0 0 1 690 160)"
                className="map-title"
                data-code="045"
              >
                Дархан-Уул
              </text>
              <circle cx="690" cy="180" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 690 160)"
                className="map-title-active"
              >
                Дархан-Уул
              </text>
              <image
                x="660"
                y="150"
                href={'/images/home-map-hover.png'}
                width={60}
                height={60}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Орхон' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 923.129 343.285 L 924.672 349.393 L 917.908 354.896 L 903.904 349.985 L 907.701 342.395 Z"
              />
              <text
                transform="matrix(1 0 0 1 590 180)"
                className="map-title"
                data-code="061"
              >
                Орхон
              </text>
              <circle cx="615" cy="198" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 590 180)"
                className="map-title-active"
              >
                Орхон
              </text>
              <image
                x="585"
                y="168"
                href={'/images/home-map-hover.png'}
                width={60}
                height={60}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Говь-Алтай' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 553.805 422.074 L 567.572 421.728 L 575.998 428.936 L 593.681 430.721 L 615.399 445.532 L 622.994 465.618 L 633.913 462.716 L 639.609 465.276 L 645.187 480.534 L 654.562 481.722 L 660.259 477.138 L 669.278 480.76 L 671.177 495.712 L 681.858 507.731 L 688.148 510.252 L 696.456 521.821 L 699.66 546.927 L 695.387 561.602 L 690.878 568.529 L 690.047 589.704 L 680.434 598.36 L 680.434 637.898 L 675.094 654.223 L 677.111 682.227 L 652.782 678.999 L 622.045 682.174 L 620.502 672.85 L 604.125 655.824 L 591.663 619.597 L 583.712 616.573 L 586.56 603.032 L 573.268 604.335 L 561.638 600.154 L 547.515 591.066 L 541.7 583.372 L 532.325 582.717 L 523.542 571.383 L 500.994 565.452 L 502.418 528.841 L 513.218 517.132 L 513.099 512.491 L 521.406 494.474 L 517.846 482.57 L 526.984 481.835 L 544.786 468.8 L 536.241 446.334 L 518.083 432.044 L 516.185 425.132 L 539.089 417.047 Z"
              />
              <text
                transform="matrix(1 0 0 1 250 340)"
                className="map-title"
                data-code="082"
              >
                Говь-Алтай
              </text>
              <circle cx="275" cy="350" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 250 340)"
                className="map-title-active"
              >
                Говь-Алтай
              </text>
              <image
                x="225"
                y="300"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
            <g
              onClick={(e) => select(e)}
              className={`${selected === 'Баянхонгор' ? 'selected' : ''}`}
            >
              <path transform="translate(-300, -150)"
                className="map-icon"
                d="M 782.615 468.062 L 800.654 466.982 L 812.403 481.27 L 813.946 488.951 L 809.911 495.544 L 811.691 505.04 L 804.215 512.715 L 803.147 534.845 L 797.331 545.156 L 800.061 564.298 L 814.184 573.466 L 811.573 581.295 L 821.067 614.573 L 801.01 626.5 L 768.374 626.877 L 749.504 608.727 L 737.399 611.597 L 738.586 658.17 L 742.384 688.516 L 736.924 690.205 L 677.111 682.227 L 675.094 655.824 L 680.434 637.898 L 680.671 597.218 L 690.64 588.122 L 690.878 568.529 L 695.387 561.602 L 700.253 542.111 L 696.456 521.821 L 688.148 510.252 L 681.858 507.731 L 670.821 495.093 L 669.397 476.628 L 677.942 448.966 L 688.267 446.563 L 699.422 421.901 L 708.798 419.822 L 711.765 428.072 L 719.954 433.482 L 729.685 432.505 L 743.57 423.402 L 744.283 430.721 L 750.098 437.562 L 751.641 453.766 L 771.222 449.48 L 774.427 459.413 Z"
              />
              <text
                transform="matrix(1 0 0 1 415 380)"
                className="map-title"
                data-code="064"
              >
                Баянхонгор
              </text>
              <circle cx="450" cy="390" fill="#FFDA00" r="3"></circle>
              <text
                transform="matrix(1 0 0 1 415 380)"
                className="map-title-active"
              >
                Баянхонгор
              </text>
              <image
                x="400"
                y="340"
                href={'/images/home-map-hover.png'}
                width={100}
                height={100}
              />
            </g>
          </svg>
      </div>
    </div>
  )
}

export default PriceMap
