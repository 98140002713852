import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Breadcrumb,
  Layout,
  Row,
  Col,
  Flex,
  Typography,
  Card,
  Space,
  Button,
  Divider,
  Image,
  Descriptions,
  Tag,
  Form,
  Spin,
  Modal,
  Table,
  Empty,
} from 'antd'
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  UserAddOutlined,
} from '@ant-design/icons'
import MapView from './../../../components/MapView'
import FooterLayout from './../../../layouts/Footer'

import * as WEBSERVICES from '../../../config/WebServices'
import * as CONFIGS from '../../../config/Configs'

import mnMN from 'antd/lib/locale/mn_MN'
import dayjs from 'dayjs'
import 'dayjs/locale/mn'
import SmartHelper from 'helper/SmartHelper'
import GeoJSON from 'ol/format/GeoJSON'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import { getCenter } from 'ol/extent'
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer'
import PanoramaImage from '../../../components/Panorama/Index'
import LandStatus from 'components/LandStatus'
import { TableProps } from 'antd/lib'

import LayoutContext from '../../../LayoutContext';

dayjs.locale('mn')

const { Content } = Layout
const { Text, Title, Link } = Typography
const { Meta } = Card

const descriptionItemLabelStyle = {
  flex: 4,
  fontSize: 12,
  fontWeight: 700,
  color: '#171441',
  paddingLeft: 20,
}
const descriptionItemContentStyle = {
  flex: 6,
  fontSize: 12,
  fontWeight: 600,
  color: '#393874',
}
const largeDescriptionItemLabelStyle = {
  flex: 5,
  fontSize: 12,
  fontWeight: 700,
  color: '#171441',
  paddingLeft: 20,
}
const largeDescriptionItemContentStyle = {
  flex: 5,
  fontSize: 12,
  fontWeight: 600,
  color: '#393874',
}
const priceTagStyle = {
  fontSize: 12,
  fontWeight: 700,
  height: 28,
  display: 'flex',
  alignItems: 'center',
  padding: '0px 20px 0px 10px',
}

const ProjectDetail: React.FC = () => {
  const { setOpenDAN } = useContext(LayoutContext);

  const navigate = useNavigate()
  const { id: detailId } = useParams()
  
  const [isAuth, setIsAuth] = useState(false)  
  const [auth, setAuth] = useState([] as any)  
  const [entity, setEntity] = useState([] as any)  

  const [project, setProject] = useState({} as any)
  const [participant, setParticipant] = useState(null)
  const [feeZone, setFeeZone] = useState({} as any)
  const [parcelDetail, setParcelDetail] = useState({} as any)
  const [loaded, setLoaded] = useState(false)

  const [isProjectStart, setIsProjectStart] = useState(false)
  const [isProjectEnd, setIsProjectEnd] = useState(false)

  const [isShowModal, setIsShowModal] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [message, setMessage] = useState("")

  const [userLocation, setUserLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);  
  const [errorLoc, setError] = useState<string | null>(null);

  const [panoImg, setPanoImg] = useState("");

  const numberWithCommas = (price: any) => {
    price = price.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(price))
      price = price.replace(pattern, "$1,$2");
    return price;
  }

  type ColumnsType<T extends object> = TableProps<T>['columns'];

  interface DataType {
    key: number;
    feeZone: string;
    confidence: string;
    payment: string;
    payment_amount: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Төлбөрийн бүс',
      dataIndex: 'feeZone',
      key: 'feeZone',
    },
    {
      title: 'Төлбөрийн хувь хэмжээ',
      dataIndex: 'confidence',
      key: 'confidence',
    },
    {
      title: 'Газрын төлбөр 1m2',
      dataIndex: 'payment',
      key: 'payment',
    },
    {
      title: 'Төлбөрийн дүн',
      dataIndex: 'payment_amount',
      key: 'payment_amount',
    },

  ];

  useEffect(() => {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },(err) => {
          switch (err.code) {
            case err.PERMISSION_DENIED:
              setError('User denied the request for Geolocation.');
              break;
            case err.POSITION_UNAVAILABLE:
              setError('Location information is unavailable.');
              break;
            case err.TIMEOUT:
              setError('The request to get user location timed out.');
              break;
            default:
              setError('An unknown error occurred.');
              break;
          }
        },{
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      console.log("Хөтөч дэмжихгүй байна");
    }

    const fetchProjectData = async (entity: any) => {
      let url = WEBSERVICES.URL_PROJECT_DETAIL+detailId;

      await fetch(url)
          .then((response) => response.json())
          .then(async (responseJson) => {
            if(responseJson && responseJson.status === true) {
              let project = responseJson.data;      
              setIsProjectStart(responseJson.isProjectStart);
              setIsProjectEnd(responseJson.isProjectEnd);

              responseJson.data.image360 === null ? setPanoImg(`https://admin.mle.mn/image360/default.jpg`) : setPanoImg(`https://admin.mle.mn/image360/project/${responseJson.data.image360}`);

              setFeeZone(responseJson.feeZoneInfo.data?.[0])
              
              setProject(project);
              setParcelDetail(responseJson.parcelDetail);
              if(entity) {
                await findParticipant(project.id, entity.id);
              }
            } else {
              setProject({});
              setParcelDetail({});
            }
          })
          .catch((error) => {
              setProject({});
              setParcelDetail({});
          });       
    }

    setIsAuth(SmartHelper.getSession('isAuth') as boolean | false);
    setAuth(SmartHelper.getAuth());    
    let entity = SmartHelper.getEntity()
    setEntity(entity);

    fetchProjectData(entity).then(() => {
      setLoaded(true);
    });
  }, [detailId])  

  const data: DataType[] = feeZone !== undefined ? [
    {
      key: 1,
      feeZone: `${feeZone?.zone_name} | ${feeZone?.zone_no}`,
      confidence: `${feeZone?.confidence_percent}₮`,
      payment: `${feeZone?.base_fee_per_m2}₮`,
      payment_amount: `${numberWithCommas(parseInt(feeZone?.payment))}₮`,
    },
  ] : []; 

  const findParticipant = async (projectId: any, entityId: any ) => {
    let url = WEBSERVICES.URL_PROJECT_PARTICIPANT+"?project_id="+projectId+"&entity_id="+entityId;
    
    let participant: any = null;
    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson?.status === true) {            
          participant = responseJson?.data;     

          setParticipant(participant);
        }
      })
      .catch((error) => {

      })
  }

  const RenderComponentImage = (props: any) => {
    return (
      <div
        style={{
          marginTop: 24,
          marginRight: 10,
          display: 'inline-block',
        }}
      >
        
        <Image
          width={100}
          height={100}
          style={{ objectFit: 'cover' }}
          src={CONFIGS.URL_PROJECT_ROOT+props.item.file_url}
          fallback="/images/noimage.png"
        />
      </div>
    );
  };

  const getGoogleMapUrl = (geoJson: any) => {
    if(geoJson && typeof geoJson !== undefined) {
      const format = new GeoJSON();
      
      const features: any = format.readFeatures(geoJson)

      const vectorSources = new VectorSource({
        features: features
      });

      const vectorLayer = new VectorLayer({
        source: vectorSources,
      })

      var extend: any = vectorLayer.getSource()?.getExtent();
      var center = getCenter(extend);

      var userLoc = userLocation;

      // https://www.google.com/maps/dir/47.9133696,106.9351907/47.7075694,108.4104767
      

      return `https://www.google.com/maps/dir/${userLoc?.latitude},${userLoc?.longitude}/${center[1]},${center[0]}`;
      // return `https://www.google.com/maps/search/?api=1&query=${center[1]},${center[0]}`;
    }

    return "#";
  }

  const joinProject = (isReadOnly: boolean) => {    
    if(isAuth) {
      if(entity && entity.is_active === false) {
        setMessage("<p>Таны бүртгэлийн мэдээллийг баталгаажуулаагүй байна. Бүртгэл баталгаажих хүртэл хүлээнэ <p>");
        setIsSuccess(false);
        setIsShowModal(true);
      } else {
        if(isReadOnly) {
          navigate(`/project/show/${detailId}`);  
        } else {
          navigate(`/project/join/${detailId}`);  
        }
        
        navigate(0);
      }
    } else {
      setMessage("<p>Та дуудлага худалдаанд оролцохын тулд нэвтэрсэн байх шаардлагатай<p>");
      setIsSuccess(false);
      setIsShowModal(true);
    }    
  }

  const handleOk = () => {
    setIsShowModal(false);
  }

  const handleCancel = () => {
    setIsShowModal(false);
  }

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        {!loaded && <Spin size="large" fullscreen />}
        <Row gutter={20}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Flex
              style={{ marginTop: 40, marginBottom: 20 }}
              justify="space-between"
              align="center"
              wrap="wrap"
            >
              <Meta
                style={{ marginBottom: 20 }}
                title={
                  <Title
                    level={4}
                    style={{
                      color: '#393874',
                      marginBottom: 5,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    {project.code} - {project.admin_unit_one?.name}{' '}
                    {project.admin_unit_two?.name}{' '}
                    {project.admin_unit_three?.name}
                  </Title>
                }
                description={
                  <Breadcrumb
                    separator={<ArrowRightOutlined />}
                    items={[
                      {
                        title: 'Эхлэл',
                        href: '/',
                      },
                      {
                        title: 'Төсөл шалгаруулалт',
                        href: '/project',
                      },
                      {
                        title: 'Төсөл шалгаруулалт дэлгэрэнгүй',
                        className: 'breadcrumb-active',
                      },
                    ]}
                  />
                }
              />
              <Space size="middle" wrap>
                {(isAuth && isProjectStart === true && isProjectEnd === false) && (
                  <Button
                    type="primary"
                    onClick={() => {
                      joinProject(false)
                    }}
                  >
                    Төсөл сонгон шалгаруулалтанд бүртгүүлэх
                  </Button>
                )}

                {(isProjectEnd === true && participant !== null) && (
                  <Button
                    type="primary"
                    onClick={() => {
                      joinProject(true)
                    }}
                  >
                    Төсөлд илгээсэн материалаа харах
                  </Button>
                )}
                <Button
                  style={{ borderColor: '#FF1D1D', color: '#FF1D1D' }}
                  onClick={() => {
                    window.open(
                      getGoogleMapUrl(project?.land?.geojson),
                      '_blank',
                      'noreferrer'
                    )
                  }}
                >
                  Замчлах
                </Button>
              </Space>
            </Flex>
            <Row gutter={20}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                {isProjectStart === false && (
                  <>
                    <Card style={{ marginBottom: 20 }}>
                      <CheckCircleOutlined style={{ color: '#1AA752' }} />
                      <Text
                        style={{
                          marginLeft: 8,
                          color: '#171441',
                          fontWeight: 600,
                        }}
                      >
                        Оролцох хүсэлт
                      </Text>
                      <Divider
                        style={{ marginTop: 10, borderColor: '#1AA752' }}
                      />
                      <Meta
                        avatar={
                          <div
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 6,
                              backgroundColor: '#F5F7F9',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CheckCircleFilled
                              style={{ color: '#FF0000', fontSize: 20 }}
                            />
                          </div>
                        }
                        description={
                          <>
                            <Text
                              style={{
                                color: '#171441',
                                fontWeight: 600,
                                display: 'block',
                              }}
                            >
                              Төсөл сонгон шалгаруулалтанд оролцогч бүртгэж
                              эхлээгүй байна. 
                            </Text>
                            <Text style={{ color: '#FF0000', fontWeight: 600 }}>
                              Оролцогч бүртгэж эхлэх огноо:{' '}
                              {project.start_date}
                            </Text>
                          </>
                        }
                      />
                    </Card>
                  </>
                )}
                {(isProjectStart === true && isProjectEnd === false) && (
                  <>
                    <Card style={{ marginBottom: 20 }}>
                      <CheckCircleOutlined style={{ color: '#1AA752' }} />
                      <Text
                        style={{
                          marginLeft: 8,
                          color: '#171441',
                          fontWeight: 600,
                        }}
                      >
                        Оролцох хүсэлт
                      </Text>
                      <Divider
                        style={{ marginTop: 10, borderColor: '#1AA752' }}
                      />
                      <Meta
                        avatar={
                          <div
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 6,
                              backgroundColor: '#F5F7F9',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CheckCircleFilled
                              style={{ color: '#1AA752', fontSize: 20 }}
                            />
                          </div>
                        }
                        description={
                          <>
                            <Text style={{ color: '#1AA752', fontWeight: 600, display: 'block' }}>
                              Төсөл сонгон шалгаруулалтанд оролцогч бүртгэж байна.
                            </Text>
                            <Text style={{ color: '#1AA752', fontWeight: 600 }}>
                              Оролцогч бүртгэж дуусах огноо: {project.end_date}
                            </Text>
                          </>
                        }
                      />
                      {!isAuth && (
                        <>
                          <Divider
                            style={{ marginTop: 10, borderColor: '#0654DE' }}
                          />
                          <Meta
                            className="auction-detail-request-meta"
                            style={{
                              backgroundColor: 'rgba(6, 84, 222, 0.1)',
                              borderRadius: 6,
                              padding: 20,
                              marginBottom: 20,
                              position: 'relative',
                            }}
                            avatar={
                              <div
                                style={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: 6,
                                  backgroundColor: '#fff',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <UserAddOutlined
                                  style={{ color: '#393874', fontSize: 20 }}
                                />
                              </div>
                            }
                            description={
                              <>
                                <Text
                                  style={{
                                    color: '#000000',
                                    fontWeight: 600,
                                    display: 'block',
                                    marginBottom: 14,
                                  }}
                                >
                                  Төсөл сонгон шалгаруулалт оролцохыг хүсвэл
                                  бүртгэлтэй хэрэглэгчээр нэвтэрч орно уу
                                </Text>
                                <Button type="primary" style={{ width: 150 }} onClick={() => {setOpenDAN(true)}}>
                                  Нэвтрэх
                                </Button>
                              </>
                            }
                          />
                        </>
                      )}
                    </Card>
                  </>
                )}                
                {isProjectEnd === true && (
                  <Card style={{ marginBottom: 20 }}>
                    <CheckCircleOutlined style={{ color: '#1AA752' }} />
                    <Text
                      style={{
                        marginLeft: 8,
                        color: '#171441',
                        fontWeight: 600,
                      }}
                    >
                      Оролцох хүсэлт
                    </Text>
                    <Divider
                      style={{ marginTop: 10, borderColor: '#FF0000' }}
                    />
                    <Meta
                      avatar={
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 6,
                            backgroundColor: '#F5F7F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleFilled
                            style={{ color: '#FF0000', fontSize: 20 }}
                          />
                        </div>
                      }
                      description={
                        <>
                          <Text
                            style={{
                              color: '#171441',
                              fontWeight: 600,
                              display: 'block',
                            }}
                          >
                            Төсөл сонгон шалгаруулалтын бүртгэл явагдаж дууссан
                            байна
                          </Text>
                          <Text style={{ color: '#FF0000', fontWeight: 600 }}>
                            Уг төсөл сонгон шалгаруулалтын бүртгэл:{' '}
                            {project.end_date} -д дууссан байна
                          </Text>
                        </>
                      }
                    />
                  </Card>
                )}

                <Card style={{ marginBottom: 20 }}>
                  <Flex
                    style={{ position: 'relative' }}
                    justify="space-between"
                  >
                    <div style={{ width: '90%' }}>
                      <Image
                        src="/images/icon-map.png"
                        width={13}
                        height={13}
                        alt=""
                        preview={false}
                        style={{ marginBottom: 4 }}
                      />
                      <Text
                        style={{
                          marginLeft: 8,
                          color: '#171441',
                          fontWeight: 600,
                        }}
                      >
                        Газрын фото зураг
                      </Text>
                      <Divider style={{ marginTop: 10 }} />
                    </div>
                    {/*<Button
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 6,
                        background: '#F5F7F9',
                      }}
                      icon={<DownloadOutlined />}
                    ></Button>*/}
                  </Flex>

                  <Image.PreviewGroup>
                    {project.documents?.map((item: any, index: any) => {
                      if (index !== 0) return null

                      return (
                        <Image
                          key={index}
                          width={'100%'}
                          height={293}
                          style={{ objectFit: 'cover' }}
                          src={CONFIGS.URL_PROJECT_ROOT + item.file_url}
                          fallback="/images/noimage.png"
                        />
                      )
                    })}
                    {project.documents?.map((item: any, index: any) => {
                      if (index === 0) return null
                      return <RenderComponentImage item={item} key={index} />
                    })}
                  </Image.PreviewGroup>
                </Card>
                <Card style={{ marginBottom: 20, border: '2px solid #FF1D1D' }}>
                  <Flex justify="space-between">
                    <Link
                      href={getGoogleMapUrl(project?.land?.geojson)}
                      target="_blank"
                      style={{
                        marginLeft: 8,
                        fontWeight: 600,
                        textDecoration: 'underline',
                      }}
                    >
                      Байршил Google Map дээр харах
                    </Link>
                  </Flex>
                  <Divider style={{ marginTop: 10 }} />
                  <MapView geoJson={project?.land?.geojson} />
                </Card>

                <Card style={{ marginBottom: 20 }}>
                  <Flex
                    style={{ position: 'relative' }}
                    justify="space-between"
                  >
                    <div style={{ width: '100%' }}>
                      <Image
                        src="/images/icon-map.png"
                        width={13}
                        height={13}
                        alt=""
                        preview={false}
                        style={{ marginBottom: 4 }}
                      />
                      <Text
                        style={{
                          marginLeft: 8,
                          color: '#171441',
                          fontWeight: 600,
                        }}
                      >
                        360<sup>o</sup> зураг
                      </Text>
                      <Divider style={{ marginTop: 10 }} />
                      {/* <PanoramaImage panoImg={panoImg}/> */}
                    </div>
    
                  </Flex>
                </Card>


                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-visa-card.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Хураамжын мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <Descriptions column={1} className="auction-table">
                    <Descriptions.Item
                      label="Хураамж"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Text style={{ color: '#FF0000' }}>
                        {SmartHelper.formatCurrency(project.fee)}₮
                      </Text>
                    </Descriptions.Item>
                    {/*<Descriptions.Item
                      label="Нийт"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Tag color="#1AA752" style={priceTagStyle}>
                        2,697,500 ₮
                      </Tag>
                    </Descriptions.Item>*/}
                  </Descriptions>
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-phone.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Холбоо барих мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <Meta
                    style={{ marginBottom: 10 }}
                    avatar={
                      <Image
                        src="/images/Icon-contact-home.jpg"
                        width={31}
                        height={31}
                        alt=""
                        preview={false}
                      />
                    }
                    title={
                      <Text
                        style={{
                          color: '#000000',
                          fontSize: 14,
                          fontWeight: 600,
                          width: 150,
                        }}
                      >
                        Зар оруулсан байгуулга
                      </Text>
                    }
                    description={
                      <Text
                        style={{
                          color: '#000000',
                          fontSize: 12,
                          fontWeight: 400,
                          width: 150,
                        }}
                      >
                        {project?.department?.organization?.name}
                      </Text>
                    }
                  />
                  <Meta
                    style={{ marginBottom: 10 }}
                    avatar={
                      <Image
                        src="/images/Icon-contact-phone.jpg"
                        width={31}
                        height={31}
                        alt=""
                        preview={false}
                      />
                    }
                    title={
                      <Text
                        style={{
                          color: '#000000',
                          fontSize: 14,
                          fontWeight: 600,
                          width: 150,
                        }}
                      >
                        Холбоо барих утас:
                      </Text>
                    }
                    description={
                      <Text
                        style={{
                          color: '#000000',
                          fontSize: 12,
                          fontWeight: 400,
                          width: 150,
                        }}
                      >
                        {project?.contact_phone}
                      </Text>
                    }
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Газрын ерөнхий мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <Descriptions column={1} className="auction-table">
                    <Descriptions.Item
                      label="Газрын төрөл"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {(project?.land?.has_property === null ||
                        project?.land?.has_property === false) && (
                        <>Үл хөдлөх хөрөнгөгүй газар</>
                      )}

                      {project?.land?.has_property === true && (
                        <>Үл хөдлөх хөрөнгөтэй газар</>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Гэрчилгээ үнэ"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Tag color="#1AA752" style={priceTagStyle}>
                        {SmartHelper.formatCurrency(project.certificate_price)}{' '}
                        ₮
                      </Tag>
                    </Descriptions.Item>
                    {/*<Descriptions.Item
                      label="Сүүлийн үнэ"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Tag color="#1AA752" style={priceTagStyle}>
                        1,908,000 ₮
                      </Tag>
                    </Descriptions.Item>*/}
                    <Descriptions.Item
                      label="Бүртгэл явагдах хэлбэр"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {(project?.is_project_online === null ||
                        project?.is_project_online === true) && <>Цахимаар</>}

                      {project?.is_project_online === false && (
                        <>Уламжлалтаар</>
                      )}
                    </Descriptions.Item>
                    {/*<Descriptions.Item
                      label="Хаагдсан байдал"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <Tag color="#0654DE" style={priceTagStyle}>
                        Үнэ төлөөгүй
                      </Tag>
                    </Descriptions.Item>*/}
                    <Descriptions.Item
                      label="Газрын төрөл"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {project?.land_type?.name}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Хөрөнгө оруулалтын хэлбэр"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {project?.invest?.name}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Шаардлагатай зөвшөөрөл"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
             

                    {project?.permissions?.map(
                      (item: any, index: any) => {
                        
                        return <div key={index}> 
                          {index+1}. {item.permission_name}.<br/>
                          </div>
                      }
                    )}
                     
                    </Descriptions.Item>

                    <Descriptions.Item
                      label="Төсөлд тавигдах шаардлага"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >


                      {project?.requirements?.map(
                        (item: any, index: any) => {

                          return <div key={index}>
                            {index + 1}. {item.requirements}.<br />
                          </div>
                        }
                      )}

                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Хэрэгжих хугацаа"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {project?.project_duration}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Газрын хэмжээ"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {project?.land?.area_m2} м2
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Газрын зориулалт"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {project?.land?.land_use_type?.description}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Газар ашиглалтын хэлбэр"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {SmartHelper.getRightType(Number(project?.land?.land_right_type))}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Төсөл шалгаруулалтын бүртгэлийн хугацаа"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {project.start_date} / {project.end_date}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Бусад олон нийтийн хэрэгслээр зарласан талаар"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {project.other_announce}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Төсөл бүртгэх газрын хаяг"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {(project?.is_project_online === null ||
                        project?.is_project_online === true) && <>Цахимаар</>}

                      {project?.is_project_online === false &&
                        project.project_address_detail}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Тайлбар"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {project.project_detail}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Нэгж талбарын мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />

                  <Descriptions column={1} className="auction-table">
                    <Descriptions.Item
                      label="Нэгж талбарын дугаар"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {parcelDetail?.resultProjectParcel?.parcel_id}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Төлөвлөлтийн арга хэмжээний нэр"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {parcelDetail?.resultProjectParcel?.plan_zone_name}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Газрын нэр"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {parcelDetail?.resultProjectParcel?.gazner}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Газрын хэмжээ"
                      labelStyle={descriptionItemLabelStyle}
                      contentStyle={descriptionItemContentStyle}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      {parcelDetail?.resultProjectParcel?.area_m2} м2
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
                {parcelDetail?.resultFee && (
                  <Card style={{ marginBottom: 20 }}>
                    <Image
                      src="/images/icon-folder.png"
                      width={13}
                      height={13}
                      alt=""
                      preview={false}
                      style={{ marginBottom: 4 }}
                    />
                    <Text
                      style={{
                        marginLeft: 8,
                        color: '#171441',
                        fontWeight: 600,
                      }}
                    >
                      Газрын төлбөр, татварын мэдээлэл
                    </Text>
                    {parcelDetail?.resultFee?.map((item: any, index: any) => {
                      return (
                        <div key={index}>
                          <Divider style={{ marginTop: 10 }} />
                          <Descriptions column={1} className="auction-table">
                            <Descriptions.Item
                              label="Төлбөр, татварын бүс"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {item.zone_type} | {item.zone_name} |{' '}
                              {item.zone_no}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Газрын төлбөр, татварын хувь хэмжээ"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {item.confidence_percent}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Газрын төлбөр, татвар/1m2/"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {SmartHelper.formatCurrency(item.base_fee_per_m2)}{' '}
                              ₮
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Газрын төлбөр, татварын дүн"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {SmartHelper.formatCurrency(item.payment)} ₮
                            </Descriptions.Item>
                          </Descriptions>
                        </div>
                      )
                    })}
                  </Card>
                )}

                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Газрын үнэлгээний мэдээлэл
                  </Text>
                  {parcelDetail?.resultLevel?.map((item: any, index: any) => {
                    return (
                      <div key={index}>
                        <Divider style={{ marginTop: 10 }} />
                        <Descriptions column={1} className="auction-table">
                          <Descriptions.Item
                            label="Газрын суурь үнэлгээний зэрэглэл /Бүс/"
                            labelStyle={largeDescriptionItemLabelStyle}
                            contentStyle={largeDescriptionItemContentStyle}
                            style={{
                              paddingBottom: 10,
                            }}
                          >
                            {item.level_type} | {item.level_name} |{' '}
                            {item.level_no} | {item.level_type_name}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label="Газрын суурь үнэ /1га/"
                            labelStyle={largeDescriptionItemLabelStyle}
                            contentStyle={largeDescriptionItemContentStyle}
                            style={{
                              paddingBottom: 10,
                            }}
                          >
                            {SmartHelper.formatCurrency(item.base_price)} ₮
                          </Descriptions.Item>
                          <Descriptions.Item
                            label="Дуудлага худалдааны анхны үнийн итгэлцүүр"
                            labelStyle={largeDescriptionItemLabelStyle}
                            contentStyle={largeDescriptionItemContentStyle}
                            style={{
                              paddingBottom: 10,
                            }}
                          >
                            {item.confidence}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label="Дуудлага худалдааны анхны үнэ"
                            labelStyle={largeDescriptionItemLabelStyle}
                            contentStyle={largeDescriptionItemContentStyle}
                            style={{
                              paddingBottom: 10,
                            }}
                          >
                            {SmartHelper.formatCurrency(item.auction_amount)} ₮
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    )
                  })}
                  {parcelDetail?.resultProjectPayment?.map(
                    (item: any, index: any) => {
                      return (
                        <div key={index}>
                          <Divider style={{ marginTop: 10 }} />
                          <Descriptions column={1} className="auction-table">
                            <Descriptions.Item
                              label="Газрын суурь үнэлгээний зэрэглэл /Бүс/"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {item.level_type} | {item.level_name} |{' '}
                              {item.level_no} | {item.level_type_name}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Газрын суурь үнэ /1га/"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {SmartHelper.formatCurrency(item.base_price)} ₮
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Дуудлага худалдааны анхны үнийн итгэлцүүр"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {item.confidence}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Дуудлага худалдааны анхны үнэ"
                              labelStyle={largeDescriptionItemLabelStyle}
                              contentStyle={largeDescriptionItemContentStyle}
                              style={{
                                paddingBottom: 10,
                              }}
                            >
                              {SmartHelper.formatCurrency(item.auction_amount)}{' '}
                              ₮
                            </Descriptions.Item>
                          </Descriptions>
                        </div>
                      )
                    }
                  )}
                </Card>

                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Газрын төлөв байдал
                  </Text>
                  <Divider style={{ marginTop: 10 }} />
                  <LandStatus id={detailId} active={2} />
                </Card>

                <Card style={{ marginBottom: 20 }}>
                  <Image
                    src="/images/icon-folder.png"
                    width={13}
                    height={13}
                    alt=""
                    preview={false}
                    style={{ marginBottom: 4 }}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      color: '#171441',
                      fontWeight: 600,
                    }}
                  >
                    Төлбөрийн мэдээлэл
                  </Text>
                  <Divider style={{ marginTop: 10 }} />

                  <Table pagination={false} columns={columns} dataSource={data} 
                    locale={{
                      emptyText: <Empty description="Бичлэг байхгүй байна" />,
                    }}/>


                </Card>

                {parcelDetail?.resultPrice && (
                  <Card style={{ marginBottom: 20 }}>
                    <Image
                      src="/images/icon-folder.png"
                      width={13}
                      height={13}
                      alt=""
                      preview={false}
                      style={{ marginBottom: 4 }}
                    />
                    <Text
                      style={{
                        marginLeft: 8,
                        color: '#171441',
                        fontWeight: 600,
                      }}
                    >
                      Газрын дэлгэрэнгүй мэдээлэл
                    </Text>
                    <Divider style={{ marginTop: 10 }} />
                    <Descriptions column={1} className="auction-table">
                      {parcelDetail?.resultPrice?.factor_values?.map(
                        (item: any, index: any) => {
                          return (
                            <div key={index}>
                              <Descriptions.Item
                                label={item.name}
                                labelStyle={largeDescriptionItemLabelStyle}
                                contentStyle={largeDescriptionItemContentStyle}
                                style={{
                                  paddingBottom: 10,
                                }}
                                key={index}
                              >
                                {item.factor_value}
                              </Descriptions.Item>
                            </div>
                          )
                        }
                      )}
                    </Descriptions>
                  </Card>
                )}
              </Col>
            </Row>
            <FooterLayout isFull />
          </Col>
        </Row>

        <Modal
          title="Анхааруулга"
          open={isShowModal}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="ok" type="primary" onClick={handleOk}>
              Хаах
            </Button>,
          ]}
        >
          <div
            style={{ color: isSuccess ? 'green' : 'red' }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </Modal>
      </Content>
    </Layout>
  )
}

export default ProjectDetail