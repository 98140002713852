import { Steps, Typography } from "antd";
import React, { useEffect, useState } from "react";
import SmartHelper from 'helper/SmartHelper'


const { Title } = Typography;

const ApplicationIndex = (appStatus: any) => {  

  const [simpleList, setSimpleList] = useState([] as any)
  const [simpleStatus, setSimpleStatus] = useState([] as any)
  const [errorIndex, setErrorIndex] = useState([] as any)

  useEffect(() => {
    setSimpleStatus(appStatus.appStatus.length);
    
    for (let index = 0; index < simpleStatus; index++) {      
      if (appStatus.appStatus[index] === 7){
        setErrorIndex(appStatus.appStatus[index]);
      }
    }   
    setSimpleList(SmartHelper.applicationSimpleType);
  });

  return (
    <>
      <Title type="danger" style={{ marginBottom: 20, textAlign: 'center' }} level={5}> {errorIndex === 7 ? 'Шийдвэрлэх боломжгүй': ''} </Title>
      <Steps
        direction="vertical"
        size="small"
        status={errorIndex === 7 ? 'wait' : 'process'}
        current={simpleStatus}
        items={simpleList}
      />
    </>
  );
}

export default ApplicationIndex;