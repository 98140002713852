import React from 'react'
import { CustomRouter } from './customRouter'
import customHistory from './customHistory'
import { Route, Routes } from 'react-router-dom'
// Layouts
import CoverLayout from './layouts/CoverLayout'
import MainLayout from './layouts/MainLayout'
// Pages
import CoverPage from './pages/cover'
import HomePage from './pages/home'
import Project from './pages/project'
import Auction from './pages/auction'
import AuctionDetail from './pages/auction/detail'
import AttendAuction from './pages/auction/attend'
import TodayAuction from './pages/auction/today'
import PriceQuotes from './pages/auction/price-quotes'
import ProjectDetail from './pages/project/detail'
import WinnerDetail from './pages/project/winner'
import Sales from './pages/sales'
import SalesDetail from './pages/sales/detail'
import SalesNew from './pages/sales/new'
import PriceInfo from './pages/price-info'
import AboutUs from './pages/about-us'
import IntroVideo from './pages/intro-video'
import Blogs from './pages/blogs'
import BlogDetail from './pages/blogs/detail'
import Authorized from './pages/authorized'
import Logged from './pages/logged'
import MapPage from './pages/map'
import ProfileProjectRequestPage from './pages/profile/project-request'
import ProfileProjectPage from './pages/profile/project'
import ProfileAuctionPage from './pages/profile/auction'
import AuctionJoin from './pages/auction/join'
import ProjectJoin from 'pages/project/join'
import ProfilePage from './pages/profile'

import { LayoutProvider } from './LayoutContext';


const App: React.FC = () => {
  return (
    <CustomRouter history={customHistory}>
      <LayoutProvider>
        <Routes>
          <Route path="/">
            <Route
              path={'home'}
              element={
                <MainLayout>
                  <HomePage />
                </MainLayout>
              }
            ></Route>
            <Route
              path={''}
              element={
                <CoverLayout>
                  <CoverPage />
                </CoverLayout>
              }
            ></Route>
            <Route path={'project'}>
              <Route
                index
                element={
                  <MainLayout>
                    <Project />
                  </MainLayout>
                }
              />
              <Route
                path={'winner/:id/:active'}
                element={
                  <MainLayout>
                    <WinnerDetail />
                  </MainLayout>
                }
              />
              <Route
                path={'detail/:id'}
                element={
                  <MainLayout>
                    <ProjectDetail />
                  </MainLayout>
                }
              />
              <Route
                path={'join/:id'}
                element={
                  <MainLayout>
                    <ProjectJoin isReadOnly={false}/>
                  </MainLayout>
                }
              />
              <Route
                path={'show/:id'}
                element={
                  <MainLayout>
                    <ProjectJoin isReadOnly={true}/>
                  </MainLayout>
                }
              />
            </Route>
            <Route path={'auction'}>
              <Route
                index
                element={
                  <MainLayout>
                    <Auction />
                  </MainLayout>
                }
              />
              <Route
                path={'detail/:id'}
                element={
                  <MainLayout>
                    <AuctionDetail />
                  </MainLayout>
                }
              />
              <Route
                path={'winner/:id/:active'}
                element={
                  <MainLayout>
                    <WinnerDetail />
                  </MainLayout>
                }
              />
              <Route
                path={'attend'}
                element={
                  <MainLayout>
                    <AttendAuction />
                  </MainLayout>
                }
              />
              <Route
                path={'today'}
                element={
                  <MainLayout>
                    <TodayAuction />
                  </MainLayout>
                }
              />
              <Route
                path={'join/:id'}
                element={
                  <MainLayout>
                    <AuctionJoin />
                  </MainLayout>
                }
              />
              <Route
                path={'detail/:id/price-quotes'}
                element={
                  <MainLayout>
                    <PriceQuotes />
                  </MainLayout>
                }
              />
            </Route>
            <Route path={'sales'}>
              <Route
                index
                element={
                  <MainLayout>
                    <Sales />
                  </MainLayout>
                }
              />
              <Route
                path={'detail/:id'}
                element={
                  <MainLayout>
                    <SalesDetail />
                  </MainLayout>
                }
              />
              <Route
                path={'new'}
                element={
                  <MainLayout>
                    <SalesNew />
                  </MainLayout>
                }
              />
            </Route>
            <Route
              path={'price-info'}
              element={
                <MainLayout>
                  <PriceInfo />
                </MainLayout>
              }
            ></Route>
            <Route
              path={'about-us'}
              element={
                <MainLayout>
                  <AboutUs />
                </MainLayout>
              }
            ></Route>
            <Route
              path={'authorized'}
              element={
                <MainLayout>
                  <Authorized />
                </MainLayout>
              }
            ></Route>
            <Route
              path={'logged'}
              element={
                <MainLayout>
                  <Logged isProfile={false}/>
                </MainLayout>
              }
            ></Route>
            <Route
              path={'intro-video'}
              element={
                <MainLayout>
                  <IntroVideo />
                </MainLayout>
              }
            ></Route>
            <Route path={'blogs'}>
              <Route
                index
                element={
                  <MainLayout>
                    <Blogs />
                  </MainLayout>
                }
              ></Route>
              <Route
                path={'detail/:id'}
                element={
                  <MainLayout>
                    <BlogDetail />
                  </MainLayout>
                }
              />
            </Route>
            <Route
              path={'map'}
              element={
                <MainLayout>
                  <MapPage />
                </MainLayout>
              }
            ></Route>
            <Route path={'profile'}>
              <Route
                index
                element={
                  <MainLayout>                    
                    <Logged isProfile={true}/>
                  </MainLayout>
                }
              />
              <Route
                path={'project-request'}
                element={
                  <MainLayout>
                    <ProfileProjectRequestPage />
                  </MainLayout>
                }
              />
              <Route
                path={'project'}
                element={
                  <MainLayout>
                    <ProfileProjectPage />
                  </MainLayout>
                }
              />
              <Route
                path={'auction'}
                element={
                  <MainLayout>
                    <ProfileAuctionPage />
                  </MainLayout>
                }
              />
            </Route>
          </Route>
        </Routes>
      </LayoutProvider>
    </CustomRouter>
  )
}

export default App
