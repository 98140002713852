import React, { useEffect, useState } from 'react'
import {
  Layout,
  Row,
  Col,
  Divider,
  Card,
  Button,
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  ConfigProvider,
  Flex,
  Typography,
  List,
  Pagination,
  Spin,
  Modal
} from 'antd'
import {
  DeleteOutlined,
  SearchOutlined,
  InfoCircleOutlined,
  MailOutlined,
  FileAddOutlined ,
} from '@ant-design/icons'

import BlogItem from './../../components/BlogItem'
import FooterLayout from './../../layouts/Footer'
import { SalesCardData } from './../../constants/dummy.constants'
import SCard from './../../components/SCard'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import dayjs from 'dayjs'
import 'dayjs/locale/mn'
import * as WEBSERVICES from '../../config/WebServices'
import SmartHelper from 'helper/SmartHelper'
dayjs.locale('mn')

const { Option } = Select
const { Content } = Layout
const { Text, Link, Title, Paragraph } = Typography
const { Meta } = Card

const Sales: React.FC = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate();
  const location = useLocation()

  const onGenderChange = (value: string) => {
    switch (value) {
      case 'male':
        form.setFieldsValue({ field_type: 'man' })
        break
      case 'female':
        form.setFieldsValue({ field_type: 'female' })
        break
      case 'other':
        form.setFieldsValue({ field_type: 'other' })
        break
      default:
    }
  }

  const [sales, setSales] = useState([] as any)
  const [loaded, setLoaded] = useState(false)
  const [landUses, setLandUses] = useState([] as any)
  const [ads, setAds] = useState([] as any)
  const [shouldSearchSales, setShouldSearchSales] = useState(true)
  const [shouldCallFirstTime, setShouldCallFirstTime] = useState(true)
  const [selectedAdsId, setSelectedAdsId] = useState(
    location?.state?.adsId
  )
  const [userLand, setUserLand] = useState([] as any)
  const [isAuth, setIsAuth] = useState(false)
  const [auth, setAuth] = useState([] as any)
  const [entity, setEntity] = useState([] as any)
  const [isShowModal, setIsShowModal] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [message, setMessage] = useState('')
  const [selectedFindBy, setSelectedFindBy] = useState(location?.state?.findBy)
  const [isFavorite, setIsFavorite] = useState(location?.state?.favorite || false);

  useEffect(() => {
    async function fetchData() {
      await fetchSalesFilter()
      if(isAuth){await fetchUserLandList()}
    }

    if (shouldSearchSales) {
      searchSales(1)
      setShouldSearchSales(false)
    }

    if (shouldCallFirstTime) {
      fetchData().then(() => {
        setLoaded(true)
        setShouldCallFirstTime(false)
      })
    }

    setIsAuth(SmartHelper.getSession('isAuth') as boolean | false)
    setAuth(SmartHelper.getAuth())
    setEntity(SmartHelper.getEntity())

    fetchData().then(() => {
      setLoaded(true)
    })
  }, [shouldSearchSales]);

  const onFinish = (values: any) => {
    console.log(values)
  }

  const onReset = () => {
    form.resetFields()
    setSelectedAdsId(null)
    setShouldSearchSales(true);
    setSelectedFindBy(null)
  }

  const searchSales = (page: any) => {
    let search = ''    
    search += selectedAdsId && typeof selectedAdsId !== undefined ? '&ads=' + selectedAdsId : ''
    search += selectedFindBy && typeof selectedFindBy !== undefined && selectedFindBy !== '' ? '&entity_id=' + selectedFindBy : ''
    if (isFavorite) {
      search += '&favorite=true';
    }
    fetchSalesList(page, search)
  }
  
  const searchByAds = (adsId: any) => {
    setSelectedAdsId(adsId)
    setShouldSearchSales(true)
  } 

  const addSale = () => {
    if (isAuth) {
      if (entity && entity.is_active === false) {
        setMessage(
          '<p>Таны бүртгэлийн мэдээллийг баталгаажуулаагүй байна. Бүртгэл баталгаажих хүртэл хүлээнэ үү<p>'
        )
        setIsSuccess(false)
        setIsShowModal(true)
      } else {
        if (userLand.status == true){
          navigate(`/sales/new`)
          navigate(0)
        }
        else 
        {
          setMessage(
            '<p>Танд бүртгэлтэй нэгж талбар олдсонгүй...<p>'
          )
          setIsSuccess(false)
          setIsShowModal(true)
        }
      }
    } else {
      setMessage(
        '<p>Та зар нэмэхын тулд нэвтэрсэн байх шаардлагатай<p>'
      )
      setIsSuccess(false)
      setIsShowModal(true)
    }
  }

  const handleOk = () => {
    setIsShowModal(false)
  }

  const handleCancel = () => {
    setIsShowModal(false)
  }

  // const navigateToSalesNew = () => {
  //   navigate(`/sales/new`)
  //   navigate(0);
  // }

  const fetchUserLandList = async () => {
      setLoaded(false);
      let url = WEBSERVICES.URL_USER_LAND_LIST + '?' + 'register_number' + '=' + SmartHelper.getAuth().register;

      await fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson && responseJson.status === true) {
            setUserLand(responseJson)
          } else {
            setUserLand([])
          }
        })
        .catch((error) => {
          setUserLand([])
        })
  };

  const fetchSalesList = async (page: any, search: any = '') => {
    setLoaded(false)
    let url =
      WEBSERVICES.URL_SALES_PAGINATION_LIST +
      '?au1=' +
      SmartHelper.getSession('au1') +
      '&au2=' +
      SmartHelper.getSession('au2') +
      '&au3=' +
      SmartHelper.getSession('au3') +
      '&page=' +
      page +
      search

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {
          setSales(responseJson.data)
        } else {
          setSales([])
        }
      })
      .catch((error) => {
        setSales([])
      })
      .finally(() => {
        setLoaded(true)
      })
  }

  const fetchSalesFilter = async () => {
    let url = WEBSERVICES.URL_SALES_FILTER

    await fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.status === true) {          
          setLandUses(responseJson.landUses)
          setAds(responseJson.ads)
        } else {
          setLandUses([])
          setAds([])
        }
      })
      .catch((error) => {
        setLandUses([])
        setAds([])
      })
  }

  return (
    <Layout>
      {!loaded && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Spin size="large" />
        </div>
      )}
      {loaded && (
        <Content style={{ padding: '0 20px', marginTop: 20 }}>
          <Row gutter={20}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Flex
                style={{ marginTop: 40 }}
                justify="space-between"
                align="center"
                wrap="wrap"
              >
                <Meta
                  title={
                    <Title
                      level={4}
                      style={{
                        color: '#FF0000',
                        marginBottom: 5,
                        marginTop: 0,
                        textTransform: 'uppercase',
                      }}
                    >
                      Худалдаа, түрээсийн зар
                    </Title>
                  }
                  description={
                    <Paragraph style={{ color: '#6E6B98', fontWeight: 500 }}>
                      Нийт {sales?.data?.length || 0} худалдах болон түрээсийн зар байна
                    </Paragraph>
                  }
                />                
                <Space size="middle" wrap>
                <Button
                  type="text"
                  onClick={addSale}
                  style={{
                    background: 'transparent',
                    border: '2px solid #FF0000',
                    borderRadius: '8px',
                    padding: '4px 12px',
                  }}
                  icon={<FileAddOutlined style={{ fontSize: '20px', color: '#FF0000' }} />}
                >
                  Зар нэмэх
                </Button>
                </Space>                
              </Flex>
              <Space style={{ marginBottom: 10, marginTop: 10 }} wrap>
                <Button
                  type="text"
                  onClick={() => {
                    searchByAds(null)
                  }}
                >
                  Бүгдийг харах
                </Button>
                {ads?.map((item: any, index: any) => {
                  return (
                    <Button
                      key={index}
                      type="text"
                      style={{ color: item.color }}
                      onClick={() => {
                        searchByAds(item.id)
                      }}
                    >
                      {item.name} - {item.total}
                    </Button>
                  )
                })}
              </Space>
              <List
                grid={{
                  gutter: 20,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 3,
                  xl: 4,
                  xxl: 6,
                }}
                dataSource={sales?.data}
                renderItem={(item) => (
                  <List.Item>
                    <SCard item={item} url={'detail'} />
                  </List.Item>
                )}
                style={{ marginBottom: 5 }}
              />

              <FooterLayout isFull />
            </Col>
          </Row>
          <Modal
            title="Анхааруулга"
            open={isShowModal}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="ok" type="primary" onClick={handleOk}>
                Хаах
              </Button>,
            ]}
          >
            <div
              style={{ color: isSuccess ? 'green' : 'red' }}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </Modal>
        </Content>
      )}
    </Layout>
  )
}

export default Sales
