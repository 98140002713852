import React, { useEffect, useState } from 'react'
import {
  Layout,
  Row,
  Col,
  Card,
  Form,
  Flex,
  Typography,
  Breadcrumb,
  Spin,
} from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import FooterLayout from './../../../layouts/Footer'

import * as WEBSERVICES from '../../../config/WebServices'

import dayjs from 'dayjs'
import 'dayjs/locale/mn'

dayjs.locale('mn')

const { Content } = Layout
const { Title } = Typography
const { Meta } = Card

const BlogDetail: React.FC = () => {
  const [form] = Form.useForm()

  const { id: detailId } = useParams()

  const [news, setNews] = useState(null as any)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const fetchNewsData = async () => {
      let url = WEBSERVICES.URL_NEWS_DETAIL + detailId

      await fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson && responseJson.status === true) {
            let news = responseJson.data
            setNews(news)
          } else {
            setNews(null)
          }
        })
        .catch((error) => {
          setNews(null)
        })
    }

    fetchNewsData().then(() => {
      setLoaded(true)
    })
  }, [detailId])

  const onFinish = (values: any) => {
    console.log(values)
  }

  const onReset = () => {
    form.resetFields()
  }

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        {!loaded && <Spin size="large" fullscreen />}
        <Row gutter={20}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Flex
              style={{ marginTop: 40 }}
              justify="space-between"
              align="center"
              wrap="wrap"
            >
              <Meta
                title={
                  <Title
                    level={4}
                    style={{
                      color: '#393874',
                      marginBottom: 5,
                      marginTop: 0,
                      textTransform: 'uppercase',
                    }}
                  >
                    мэдээ мэдээлэл
                  </Title>
                }
              />
            </Flex>
            <Breadcrumb
              separator={<ArrowRightOutlined />}
              items={[
                {
                  title: 'Эхлэл',
                  href: '/',
                },
                {
                  title: 'мэдээ мэдээлэл',
                  href: '/blogs',
                },
                {
                  title: 'Мэдээ дэлгэрэнгүй',
                  className: 'breadcrumb-active',
                },
              ]}
            />
            <Card style={{ marginTop: 10, marginBottom: 40 }}>
              <Row>
                <Col xl={{ offset: 2, span: 20 }} xxl={{ offset: 4, span: 16 }}>
                  <Title style={{ fontSize: 21, marginBottom: 20 }}>
                    {news?.title}
                  </Title>

                  <div dangerouslySetInnerHTML={{ __html: news?.content }} />
                </Col>
              </Row>
            </Card>
            <FooterLayout isFull />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default BlogDetail
