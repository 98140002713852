import React from 'react'
import { Card, Table, Typography, Divider, Empty } from 'antd'

const { Text } = Typography

interface PriceQuoteItemProps {
  item: any
  spacing?: number
}

const columns = [
  {
    title: 'Д/Д',
    dataIndex: 'id',
    width: 80,
    key: 'id',
  },
  {
    title: 'Хэрэглэгч',
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: 'Огноо',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Үнэ',
    dataIndex: 'price',
    key: 'price',
    width: 100,
  },
]

const PriceQuoteItem: React.FC<PriceQuoteItemProps> = ({ item, spacing }) => {
  return (
    <Card
      style={{
        boxShadow: '0px 4px 19px #D8DEE9',
        margin: spacing ? '0 10px 20px 10px' : 0,
      }}
    >
      <Text style={{ fontWeight: 700, color: '#FF7A00' }}>
        #{item.id} дугаартай дуудлага худалдаа{' '}
        <span style={{ color: '#393874' }}>{item.address}</span>
      </Text>
      <Divider style={{ borderBlockStartColor: '#FF7A00', margin: '8px 0' }} />
      <Table
        rowKey={(record) => `id-${record.id}`}
        pagination={false}
        columns={columns}
        dataSource={item.quotes}
        scroll={{ y: 190 }}
        size="small"
        className="auction-price-quote-item-table"
        locale={{
          emptyText: <Empty description="Бичлэг байхгүй байна" />,
        }}
      />
    </Card>
  )
}

export default PriceQuoteItem
