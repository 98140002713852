import { Button, Divider, Flex, Form, Image, List, Typography} from "antd";
import type { FlexProps } from 'antd';
import Dragger from "antd/es/upload/Dragger";
import React, { useState, useEffect } from "react";
import * as WEBSERVICES from '../../../config/WebServices'

const { Text, Title, Paragraph } = Typography;

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

const requestFormLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
}

const PayIndex = (detail: any) => {

  const [requestForm] = Form.useForm()

  const [defaultFileUrl, setDefaultFileUrl] = useState("/images/icon-upload-multiple.jpg");
  const [base64Image, setBase64Image] = useState(null as any);
  const [base64ImageUpdate, setBase64ImageUpdate] = useState(null as any);

  const alignOptions = [ 'center'];
  const [payInfo, setPayInfo] = useState([] as any)
  const [info, setInfo] = useState([] as any)
  const [alignItems, setAlignItems] = useState<FlexProps['align']>(alignOptions[0]);

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setPayInfo(detail.contract)
    setInfo(detail.info)
    setBase64Image(detail?.contract?.payattach)
  })  

  const handleBeforeUpload = (file: any) => {
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setBase64ImageUpdate(reader.result);        
      };
    } else {
      console.log('error');
    }
    return false;
  }  

  const numberWithCommas = (price: any) => {
    price = price.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(price))
      price = price.replace(pattern, "$1,$2");
    return price;
  }
  

  const data = parseInt(detail?.active) === 1 ? [
    `Албан газрын нэр: ${payInfo?.bank_name}`,
    `Дансны нэр: ${payInfo?.bank_account_name}`,
    `Дансны дугаар: ${payInfo?.bank_account_number}`,
    `Дэнчин мөнгө: ${numberWithCommas(parseInt(info?.pawn))}₮`,
    `ДХ үнэ: ${numberWithCommas(parseInt(info?.bid_amount))}₮`,
    `Шилжүүлвэл зохих дүн: ${numberWithCommas(info?.bid_amount - info?.pawn)}₮`,
  ] : [
    `Албан газрын нэр: ${payInfo?.bank_id}`,
    `Дансны нэр: ${payInfo?.bank_account_name}`,
    `Дансны дугаар: ${payInfo?.bank_account_number}`,
    `Шилжүүлвэл зохих дүн: ${numberWithCommas(parseInt(payInfo?.winner_land_price))}₮`,
  ]

  const sendRequest = async () => {
    setLoaded(false);
    requestForm.validateFields()
      .then(async () => {
        try {
          let url =  detail.active === 1 ? WEBSERVICES.URL_AUCTION_WINNER_PAY_ATTACH : WEBSERVICES.URL_PROJECT_WINNER_PAY_ATTACH;
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              image: base64ImageUpdate ? base64ImageUpdate : base64Image,
              id: detail.id
            })
          });
          if(response.status === 200){
            window.location.reload(); 
          }

          setLoaded(true);
        }catch (error) {
          setLoaded(true);
        }
      })
      .catch((error) => {
        setLoaded(true);
      });
  }

  return (
    <>
      <List
        size="large"
        bordered
        dataSource={data}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
      <Form
        layout={'horizontal'}
        form={requestForm}
        {...requestFormLayout}
        style={{
          maxWidth: '100%',
          marginTop: '20px'
        }}>

        <Form.Item
          name="file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          noStyle
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: 'Зураг заавал хавсаргана',
            },
          ]}
          label={
            <Text
              style={{
                color: '#171441',
                fontSize: 12,
                fontWeight: 700,
              }}
            >
              Зураг
            </Text>
          }
        >
          <Dragger
            name="picture"
            multiple={false}
            maxCount={1}
            accept='.jpg, .png'
            beforeUpload={handleBeforeUpload}
            style={{
              backgroundColor: 'rgba(227, 231, 239, 0.5)',
              height: 160,
            }}
          >
            {base64Image ? (
              <img src={base64ImageUpdate ? base64ImageUpdate : `data:image/jpeg;base64,`+ base64Image} alt="Uploaded" style={{ maxWidth: '100%' }} />
            ) : (
              <div className="ant-upload-drag-icon">
                <Image
                  src={defaultFileUrl}
                  width={40}
                  height={40}
                  alt=""
                  preview={false}
                  style={{ marginBottom: 8 }}
                />
              </div>
            )}
            {payInfo?.payment_approved_date !== null ? 
            <>
              <p
                className="ant-upload-text"
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: '#393874',
                }}
              >
                Таны төлбөр {payInfo?.payment_approved_date} баталгаажсан байна
              </p>
            </> : 
            <>
              <p
                className="ant-upload-text"
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: '#393874',
                }}
              >
                Энд дарж файлаа сонгоно уу
              </p>
              <p className="ant-upload-hint">
                Та иргэний үнэмлэхний зургаа хавсарган оруулах шаардлагатай (jpg, png формат байна)
              </p>
            </>
            }

          </Dragger>
        </Form.Item>

        <Flex align={alignItems} vertical>
        {payInfo?.payment_approved_date !== null ?
          <></>
         : 
          <Button
              onClick={sendRequest}
              type="primary"
              style={{
                  color: '#fff',
                  marginTop: '10px',
                  width: '60%',
              }}
              block
              className={'project-evaluation-hover-border'}
          >
                Хавсралт илгээх
          </Button>
         }

        </Flex>
      </Form>

      

    </>
  );
}

export default PayIndex;
