import React from 'react'
import {
  List,
  Space,
  Layout,
  Col,
  Row,
  Flex,
  Typography,
  Divider,
  Form,
  Input,
  Button,
  Image,
  Card,
} from 'antd'
import { InfoCircleOutlined, MailOutlined } from '@ant-design/icons'
import BlogItem from '../../../components/BlogItem'
import FooterLayout from '../../../layouts/Footer'
import { ProjectCardData } from '../../../constants/dummy.constants'

const { Content } = Layout
const { Text, Link, Title } = Typography

const InfoText = ({
  label,
  text,
  price,
}: {
  label: string
  text: string
  price?: boolean
}) => (
  <Space align="center" style={{ display: 'flex' }}>
    <Text
      style={{
        fontWeight: 500,
        color: '#393874',
        width: 124,
        display: 'inline-block',
      }}
    >
      {label}
    </Text>
    <Text
      ellipsis
      style={{
        fontWeight: price ? 700 : 400,
        color: price ? '#1AA752' : '#6E6B98',
        fontSize: price ? 24 : 14,
        maxWidth: 600,
        width: '100%',
      }}
    >
      {text}
    </Text>
  </Space>
)

const ProfileProjectRequest: React.FC = () => {
  const [form] = Form.useForm()

  return (
    <Layout>
      <Content style={{ padding: '0 20px', marginTop: 20 }}>
        <Row gutter={20}>
          <Col span={24}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: '#393874',
                marginBottom: 10,
                display: 'block',
              }}
            >
              Миний Дуудлага худалдаанд оролцох хүсэлтүүд
            </Text>
            <Card>
              <List
                header={
                  <>
                    <Text
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: '#1AA752',
                        textTransform: 'uppercase',
                      }}
                    >
                      Төсөл шалгаруулалт
                    </Text>
                    <Divider
                      style={{ marginTop: 10, borderColor: '#1AA752' }}
                    />
                  </>
                }
                itemLayout="vertical"
                size="large"
                pagination={{
                  onChange: (page) => {
                    console.log(page)
                  },
                }}
                dataSource={ProjectCardData}
                renderItem={(item: any) => (
                  <List.Item style={{ position: 'relative' }}>
                    <Space size={20} align="start" wrap>
                      <Image
                        src={item.cover}
                        width={295}
                        height={160}
                        preview={false}
                        style={{
                          width: '100%',
                          objectFit: 'cover',
                          borderRadius: 5,
                        }}
                      />
                      <div
                        style={{
                          marginTop: -12,
                        }}
                      >
                        <Link
                          style={{ position: 'absolute', top: 10, right: 20 }}
                        >
                          <Image
                            src="/images/icon-bookmark.png"
                            width={12}
                            height={15}
                            preview={false}
                          />
                        </Link>
                        <Title
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: '#393874',
                          }}
                          ellipsis
                        >
                          {item.address}
                        </Title>
                        <div>
                          <InfoText
                            label={'Газрын зориулалт:'}
                            text={item.category}
                          />
                          <InfoText
                            label={'Газрын хэмжээ:'}
                            text={item.square}
                          />
                          <InfoText label={'Үнэ:'} text={item.price} price />
                          <Button
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: '#E7E7EE',
                              color: '#1AA752',
                              width: 200,
                              marginTop: 10,
                            }}
                          >
                            Дэлгэрэнгүй
                          </Button>
                        </div>
                      </div>
                    </Space>
                  </List.Item>
                )}
              />
            </Card>
            <FooterLayout isFull />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default ProfileProjectRequest
